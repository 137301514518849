import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { useOrdersQuery } from 'api/customer-service';
import { handleDropdownToggle, maxHeightModifiers } from 'utils/dropdown';
import {
  selectYears,
  selectYear,
  selectWeeks,
  selectOrders,
  setYear,
} from './customer-detail-slice';
import { OrderItem } from './OrderItem';

interface OrdersTabProps {
  customerId: number;
}

export function OrdersTab({ customerId }: OrdersTabProps) {
  const dispatch = useDispatch(),
    years = useSelector(selectYears),
    year = useSelector(selectYear),
    weeks = useSelector(selectWeeks),
    orders = useSelector(selectOrders),
    [catalogType, setCatalogType] = useState<string | null>(null),
    [week, setWeek] = useState<number | null>(null),
    [sort, setSort] = useState<OrderSort>('ShipDate Asc'),
    { refetch } = useOrdersQuery({
      customerId,
      year,
      week,
      catalogType,
      sort,
    });

  const handleYearClick = (year: number) => {
    dispatch(setYear(year));
  };

  const handleWeekClick = (week: number | null) => {
    setWeek(week);
  };

  const handleCatalogTypeClick = (catalogType: string | null) => {
    setCatalogType(catalogType);
  };

  const handleSortClick = (sort: OrderSort) => {
    setSort(sort);
  };

  return (
    <div className="overflow-hidden-x">
      <div className="row">
        <div className="col-12 col-md-auto">
          <label htmlFor="orders-year" className="d-block">
            Year
          </label>
          <UncontrolledDropdown>
            <DropdownToggle caret color="secondary">
              {year}
            </DropdownToggle>
            <DropdownMenu>
              {years.map((y) => (
                <DropdownItem key={y} onClick={() => handleYearClick(y)}>
                  {y}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <div className="col-12 col-md-auto">
          <label htmlFor="crm-week" className="d-block">
            Week
          </label>
          <UncontrolledDropdown onToggle={handleDropdownToggle}>
            <DropdownToggle caret color="secondary">
              {week || 'All Weeks'}
            </DropdownToggle>
            <DropdownMenu modifiers={maxHeightModifiers()}>
              <DropdownItem onClick={() => handleWeekClick(null)}>
                All Weeks
              </DropdownItem>
              {weeks.map((w) => (
                <DropdownItem key={w} onClick={() => handleWeekClick(w)}>
                  {w}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <div className="col-12 col-md-auto">
          <label htmlFor="orders-catalog-type" className="d-block">
            Catalog Type
          </label>
          <UncontrolledDropdown>
            <DropdownToggle caret color="secondary">
              {catalogType || 'All Types'}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => handleCatalogTypeClick(null)}>
                All Types
              </DropdownItem>
              <DropdownItem
                onClick={() => handleCatalogTypeClick('Manual Orders')}>
                Manual Orders
              </DropdownItem>
              <DropdownItem onClick={() => handleCatalogTypeClick('Plants')}>
                Plants
              </DropdownItem>
              <DropdownItem
                onClick={() => handleCatalogTypeClick('Cut Flowers')}>
                Cut Flowers
              </DropdownItem>
              <DropdownItem
                onClick={() => handleCatalogTypeClick('Plant Prebook')}>
                Prebook (Plants)
              </DropdownItem>
              <DropdownItem
                onClick={() => handleCatalogTypeClick('Cut Flowers Prebook')}>
                Prebook (Cut Flowers)
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <div className="col-12 col-md-auto">
          <label htmlFor="orders-sort" className="d-block">
            Sort By
          </label>
          <UncontrolledDropdown>
            <DropdownToggle caret color="secondary">
              {orderSortDisplay(sort)}
            </DropdownToggle>
            <DropdownMenu>
              {(
                [
                  'ShipDate Asc',
                  'ShipDate Desc',
                  'OrderDate Asc',
                  'OrderDate Desc',
                ] as OrderSort[]
              ).map((s) => (
                <DropdownItem key={s} onClick={() => handleSortClick(s)}>
                  {orderSortDisplay(s)}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <table className="table table-striped table-sm">
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Order Type</th>
                <th>
                  Ship
                  <br />
                  Week
                </th>
                <th>Status</th>
                <th>PO #</th>
                <th>Order Date</th>
                <th>Placed By</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <OrderItem
                  key={order.orderId}
                  order={order}
                  refresh={refetch}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export type OrderSort =
  | 'ShipDate Asc'
  | 'ShipDate Desc'
  | 'OrderDate Asc'
  | 'OrderDate Desc';

export function orderSortDisplay(sort: OrderSort): string {
  switch (sort) {
    case 'ShipDate Asc':
      return 'Ship Week (earliest)';
    case 'ShipDate Desc':
      return 'Ship Week (latest)';
    case 'OrderDate Asc':
      return 'Order Date (earliest)';
    case 'OrderDate Desc':
      return 'Order Date (latest)';
  }
}
