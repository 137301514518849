import React from 'react';
import moment from 'moment';
import * as models from 'api/models/booking-catalogs';
import { Link } from 'react-router-dom';
import { routes } from 'app/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface BookingCatalogListItemProps {
  c: models.BookingCatalogListItem;
}

export function BookingCatalogListItem(props: BookingCatalogListItemProps) {
  const {c} = props,
    isPast = moment(c.availableTo).startOf('isoWeek').isBefore(moment().startOf('isoWeek')),
    fromDate = moment.utc(c.availableFrom).local().format('MMM D, YYYY'),
    fromTime = moment.utc(c.availableFrom).local().format('h:mm a'),
    toDate = moment.utc(c.availableTo).local().format('MMM D, YYYY'),
    toTime = moment.utc(c.availableTo).local().format('h:mm a');

  return (
  <div key={c.id} className={`row py-2 border-bottom ${isPast ? 'text-muted': ''}`}>
    <div className="col-12 col-md-1 text-center">
      <Link to={routes.bookingCatalogDetail.to(c.id)}>
        <FontAwesomeIcon icon={['fad', 'edit']} />
      </Link>
    </div>
    <div className="col-12 col-md-2">{c.catalogType}</div>
    <div className="col-12 col-md-3">{c.name}</div>
    <div className="col-12 col-md-2">{c.week}</div>
    <div className="col-12 col-md-4">
      {fromDate}&nbsp;{fromTime}
      &nbsp;&ndash;&nbsp;
      {toDate}&nbsp;{toTime}
    </div>
  </div>
  );  
}
