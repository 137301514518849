import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { routes } from 'app/routes';
import { BookingCatalogItem } from './BookingCatalogItem';
import { selectBookingCatalogs } from './home-slice';
import { Link } from 'react-router-dom';

export function BookingCatalogs() {
  const bookingCatalogs = useSelector(selectBookingCatalogs),
    limited = bookingCatalogs.slice(0, 3);

  return (
    <div>
      <div className="row">
        <div className="col">
          <a href={routes.bookingCatalogs.path} className="h2 btn btn-link">
            <FontAwesomeIcon icon={['fad', 'atlas']} />
            &nbsp; Active Booking Catalogs
          </a>
        </div>
        <div className="col-auto">
          <Button
            color="info"
            tag={Link}
            outline
            size="sm mt-2"
            to={routes.bookingCatalogNew.path}>
            New Booking Catalog &nbsp;
            <FontAwesomeIcon icon={['fad', 'plus']} />
          </Button>
        </div>
      </div>
      <table className="table table-sm table-responsive-md">
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th
              className="text-capitalize font-weight-bold"
              style={{ fontSize: '12.8px' }}>
              Type
            </th>
            <th
              className="text-capitalize font-weight-bold"
              style={{ fontSize: '12.8px' }}>
              Name
            </th>
            <th
              className="text-capitalize font-weight-bold"
              style={{ fontSize: '12.8px' }}>
              Week(<span className="text-lowercase">s</span>) / <br />
              Available
            </th>
          </tr>
        </thead>
        <tbody>
          {limited.map((o) => (
            <BookingCatalogItem key={o.id} catalog={o} />
          ))}
        </tbody>
        {!!bookingCatalogs.length && (
          <tfoot>
            <tr>
              <td colSpan={4} className="text-center">
                Showing {limited.length} of {bookingCatalogs.length} Active
                Booking Catalogs &nbsp;
                <a href={routes.bookingCatalogs.path}>See All</a>
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
}
