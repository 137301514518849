import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Legend,
  LabelList,
} from 'recharts';
import {
  useCrmQuery,
  useApfQuery,
  useCallLogStatusesQuery,
} from 'api/customer-service';
import { handleDropdownToggle, maxHeightModifiers } from 'utils/dropdown';
import { formatCurrency } from 'utils/format';
import {
  selectYears,
  selectWeeks,
  selectYear,
  selectWeek,
  selectCallLog,
  selectSummary,
  selectCallLogStatuses,
  selectSalesYears,
  selectSalesWeeks,
  setYear,
  setWeek,
  expandAllWeeks,
  setCrmStatus,
} from './customer-detail-slice';
import { CrmSalesWeek } from './CrmSalesWeek';
import { equals } from 'utils/equals';

const margin = { top: 5, right: 30, left: 20, bottom: 5 };

interface CrmTabProps {
  customerId: number;
}

export function CrmTab({ customerId }: CrmTabProps) {
  const dispatch = useDispatch(),
    [expandSalesWeeks, setExpandSalesWeeks] = useState(false),
    years = useSelector(selectYears),
    year = useSelector(selectYear),
    weeks = useSelector(selectWeeks),
    week = useSelector(selectWeek),
    callLog = useSelector(selectCallLog),
    summary = useSelector(selectSummary),
    salesYears = useSelector(selectSalesYears),
    salesWeekItemsByYear = useSelector(selectSalesWeeks),
    callLogStatuses = useSelector(selectCallLogStatuses),
    average = summary?.totalOrders
      ? summary.totalSales / summary.totalOrders
      : 0,
    statusClass = equals(callLog?.status, 'callback')
      ? 'danger'
      : equals(callLog?.status, 'order')
      ? 'success'
      : 'secondary';

  useCrmQuery({ customerId, year, week });
  useApfQuery();
  useCallLogStatusesQuery();

  useEffect(() => {
    setExpandSalesWeeks(false);
  }, [customerId]);

  const handleYearClick = (year: number) => {
    dispatch(setYear(year));
  };

  const handleWeekClick = (week: number) => {
    dispatch(setWeek(week));
  };

  const handleCallLogStatusClick = (status: string | null) => {
    dispatch(setCrmStatus(status));
  };

  const handleExpandSalesWeeksChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(expandAllWeeks(e.target.checked));
    setExpandSalesWeeks(e.target.checked);
  };

  const renderSalesLabel = (props: any) => {
    const { x, y, value } = props;

    return (
      <text x={x} y={y - 5} fontWeight="bold" fontSize="0.75rem">
        {formatCurrency(value, '$0,0')}
      </text>
    );
  };

  const renderLegend = ({ payload }: any) => {
    return (
      <div className="ml-5">
        {(payload as any[]).map(({ value, color }) => (
          <div style={{ color }} key={value} className="font-weight-bold">
            Annual Sales $
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <div className="row">
        <div className="col-12 col-md-auto">
          <label htmlFor="crm-year" className="d-block">
            Year
          </label>
          <UncontrolledDropdown>
            <DropdownToggle caret color="secondary">
              {year}
            </DropdownToggle>
            <DropdownMenu>
              {years.map((y) => (
                <DropdownItem key={y} onClick={() => handleYearClick(y)}>
                  {y}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <div className="col-12 col-md-auto">
          <label htmlFor="crm-week" className="d-block">
            Week
          </label>
          <UncontrolledDropdown onToggle={handleDropdownToggle}>
            <DropdownToggle caret color="secondary">
              {week}
            </DropdownToggle>
            <DropdownMenu modifiers={maxHeightModifiers()}>
              {weeks.map((w) => (
                <DropdownItem key={w} onClick={() => handleWeekClick(w)}>
                  {w}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <div className="col-12 col-md-auto">
          <label htmlFor="crm-status" className="d-block">
            Status
          </label>
          <UncontrolledDropdown onToggle={handleDropdownToggle}>
            <DropdownToggle caret color={statusClass}>
              {callLog?.status || '--'}
            </DropdownToggle>
            <DropdownMenu modifiers={maxHeightModifiers()}>
              <DropdownItem onClick={() => handleCallLogStatusClick(null)}>
                --
              </DropdownItem>
              {callLogStatuses.map((s) => (
                <DropdownItem
                  key={s}
                  onClick={() => handleCallLogStatusClick(s)}>
                  {s}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12 col-md-6">
          {!!summary && (
            <div className="p-4 rounded bg-light">
              <h2>Yearly Summary: {year}</h2>
              <div>
                <strong>Total Orders:</strong> {summary.totalOrders}
              </div>
              <div>
                <strong>Total Sales:</strong>{' '}
                {formatCurrency(summary.totalSales)}
              </div>
              {!!average && (
                <div>
                  <strong>Average Order Cost:</strong> {formatCurrency(average)}
                </div>
              )}
              <div className="mt-4">
                <strong>Total Plant Orders:</strong> {summary.totalPlantOrders}
              </div>
              <div>
                <strong>Total Cut Flower Orders:</strong>{' '}
                {summary.totalCutFlowerOrders}
              </div>
            </div>
          )}
        </div>
        <div className="col-12 col-md-6">
          <div className="py-4 rounded bg-light h-100">
            <ResponsiveContainer maxHeight={300}>
              <BarChart data={salesYears} margin={margin}>
                <CartesianGrid />
                <XAxis dataKey="year" />
                <YAxis
                  padding={{ top: 15 }}
                  tickFormatter={(tick) => formatCurrency(tick, '$0,0')}
                />
                <Legend content={renderLegend} />
                <Bar dataKey="totalSales" fill="#6b7d69">
                  <LabelList content={renderSalesLabel} position="top" />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col">
          <h2>Weekly Sales</h2>
        </div>
        <div className="col-auto">
          <label htmlFor="show-weekly-details" className="font-weight-bold">
            Expand / Collapse
          </label>
          &nbsp;
          <label
            className="custom-toggle custom-toggle-success mb--2 ml-2"
            htmlFor="show-weekly-details">
            <input
              id="show-weekly-details"
              type="checkbox"
              checked={expandSalesWeeks}
              onChange={handleExpandSalesWeeksChange}
            />
            <span className="custom-toggle-slider rounded-circle" />
          </label>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <table className="table">
            <thead>
              <tr>
                <th className="auto-width">Year</th>
                <th className="auto-width">Week</th>
                <th className="auto-width">Sales Qty</th>
                <th className="auto-width">Sales $</th>
                <th className="auto-width">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {salesWeekItemsByYear.map((week) => (
                <CrmSalesWeek key={week.week} week={week} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
