import { useSelector } from 'react-redux';
import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Line,
  LabelList,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { routes } from 'app/routes';
import { formatCurrency, formatNumber } from 'utils/format';
import { selectPlantsAnalytics, selectCutsAnalytics } from './home-slice';
import { contains } from 'utils/equals';

const cutsColour = '#f0bf42',
  plantsColour = '#5fa9d3',
  margin = { top: 5, right: 30, left: 20, bottom: 5 };

export function Analytics() {
  const plantsAnalytics = useSelector(selectPlantsAnalytics),
    cutsAnalytics = useSelector(selectCutsAnalytics),
    sales = plantsAnalytics?.dollars.map(
      ({ weekId, weekNumber, year, weekDisplay, value: plantValue }, index) => {
        const cutValue = cutsAnalytics?.dollars[index].value;
        return {
          weekId,
          weekNumber,
          year,
          weekDisplay,
          plantValue,
          cutValue,
        };
      }
    ),
    orders = plantsAnalytics?.orderCount.map(
      ({ weekId, weekNumber, year, weekDisplay, value: plantValue }, index) => {
        const cutValue = cutsAnalytics?.orderCount[index].value;
        return {
          weekId,
          weekNumber,
          year,
          weekDisplay,
          plantValue,
          cutValue,
        };
      }
    );

  const renderSalesLabel = (props: any) => {
    const { x, y, value } = props;

    return (
      <text
        x={x}
        y={y - 20}
        textAnchor="middle"
        dominantBaseline="middle"
        fontWeight="bold"
        fontSize="0.75rem">
        {formatCurrency(value, '$0,0')}
      </text>
    );
  };

  const renderOrdersLabel = (props: any) => {
    const { x, y, value } = props;

    return (
      <text
        x={x}
        y={y - 20}
        textAnchor="middle"
        dominantBaseline="middle"
        fontWeight="bold"
        fontSize="0.75rem">
        {formatNumber(value)}
      </text>
    );
  };

  const renderLegend = ({ payload }: any) => {
    return (
      <div className="ml-5">
        {(payload as any[]).map(({ value, color }) => (
          <div style={{ color }} key={value} className="font-weight-bold">
            {contains(value, 'plant') ? 'Plants' : 'Cut Flowers'}
          </div>
        ))}
      </div>
    );
  };

  if (!plantsAnalytics || !cutsAnalytics) {
    return null;
  }

  return (
    <div>
      <a href={routes.analytics.path} className="h2 btn btn-link">
        <FontAwesomeIcon icon={['fad', 'chart-line']} />
        &nbsp; Weekly Catalog Comparison
      </a>
      <div className="row">
        <div className="col-6">
          <div className="border rounded shadow h-100 py-3">
            <h3 className="text-center">Sales</h3>
            <ResponsiveContainer height={275} width="100%">
              <LineChart data={sales} margin={margin}>
                <CartesianGrid />
                <Legend content={renderLegend} />
                <XAxis
                  dataKey="weekDisplay"
                  padding={{ right: 25, left: 25 }}
                />
                <YAxis
                  fontSize="0.75rem"
                  padding={{ top: 25 }}
                  tickFormatter={(tick) => formatCurrency(tick, '$0,0')}
                />
                <Line
                  dataKey="plantValue"
                  color={plantsColour}
                  stroke={plantsColour}
                  strokeWidth={5}
                  isAnimationActive={false}>
                  <LabelList content={renderSalesLabel} position="top" />
                </Line>
                <Line
                  dataKey="cutValue"
                  color={cutsColour}
                  stroke={cutsColour}
                  strokeWidth={5}
                  isAnimationActive={false}>
                  <LabelList content={renderSalesLabel} position="top" />
                </Line>
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="col-6">
          <div className="border rounded shadow h-100 py-3">
            <h3 className="text-center">Orders</h3>
            <ResponsiveContainer height={275} width="100%">
              <LineChart data={orders} margin={margin}>
                <CartesianGrid />
                <Legend content={renderLegend} />
                <XAxis
                  dataKey="weekDisplay"
                  padding={{ right: 25, left: 25 }}
                />
                <YAxis
                  fontSize="0.75rem"
                  padding={{ top: 25 }}
                  tickFormatter={(tick) => formatNumber(tick)}
                />
                <Line
                  dataKey="plantValue"
                  color={plantsColour}
                  stroke={plantsColour}
                  strokeWidth={5}
                  isAnimationActive={false}>
                  <LabelList content={renderOrdersLabel} position="top" />
                </Line>
                <Line
                  dataKey="cutValue"
                  color={cutsColour}
                  stroke={cutsColour}
                  strokeWidth={5}
                  isAnimationActive={false}>
                  <LabelList content={renderOrdersLabel} position="top" />
                </Line>
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
