import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Input, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import numeral from 'numeral';
import { OrderDetailItem } from 'api/models/orders';
import {
  selectOrder,
  setUnitPrice as setItemUnitPrice,
  setQuantity as setItemQuantity,
  setItemComments,
} from './order-detail-slice';
import { formatCurrency } from 'utils/format';
import * as cuts from 'utils/cuts';
import { contains } from 'utils/equals';
import { handleFocus } from 'utils/focus';

interface DetailRowProps {
  item: OrderDetailItem;
}

export function DetailRow(props: DetailRowProps) {
  const dispatch = useDispatch(),
    { item } = props,
    order = useSelector(selectOrder),
    isCuts = contains(order?.catalogType, 'cut flower'),
    packQuantity = isCuts ? 1 : item.packQuantity,
    price = item.unitPrice * packQuantity,
    qty = item.quantity / packQuantity,
    [unitPrice, setUnitPrice] = useState(formatCurrency(price)),
    [quantity, setQuantity] = useState(qty),
    [extension, setExtension] = useState(formatCurrency(quantity * price)),
    [comments, setComments] = useState(item.comments),
    [showCustomerComments, setShowCustomerComments] = useState(!!item.comments),
    hasShipWeeks = order?.items.some((i) => i.shipWeek),
    // pack quantity > 1 rules out cases where we don't know how many stems/case
    hasStems = order?.items.some(
      (i) => i.unitOfMeasure !== 'St' && i.stemsPerBunch && i.packQuantity > 1
    ),
    stems =
      item.unitOfMeasure !== 'St' &&
      item.stemsPerBunch &&
      item.packQuantity > 1 &&
      item.unitOfMeasure !== 'St'
        ? (cuts.stemsPerUnit({
            caseQuantity: item.packQuantity,
            unitOfMeasureCuts: item.unitOfMeasure,
            stemsPerBunch: item.stemsPerBunch,
          }) || 0) * quantity
        : 0;

  const handleUnitPriceBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const price = numeral(e.target.value).value() || 0,
      unitPrice = price / packQuantity,
      extension = unitPrice * item.quantity,
      args = { id: item.id, unitPrice };

    setUnitPrice(formatCurrency(price));
    setExtension(formatCurrency(extension));

    dispatch(setItemUnitPrice(args));
  };

  const handleQuantityBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const unitQuantity = parseInt(e.target.value) || 0,
      quantity = unitQuantity * packQuantity,
      extension = quantity * item.unitPrice,
      args = { id: item.id, quantity };

    setQuantity(unitQuantity);
    setExtension(formatCurrency(extension));

    dispatch(setItemQuantity(args));
  };

  const handleUnitPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUnitPrice(e.target.value);
  };

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuantity(parseFloat(e.target.value) || 0);
  };

  const handleCommentsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setComments(e.target.value);
  };

  const handleCommentsBlur = () => {
    const args = { id: item.id, comments };
    dispatch(setItemComments(args));
  };

  const handleShowCustomerCommentsClick = () => {
    setShowCustomerComments(true);
  };

  return (
    <div className="row pt-2 mt-2 border-top">
      <div className="col">
        <Input
          value={`${item.size} ${item.description1} ${item.description2} (${item.colour})`}
          readOnly
          plaintext
          tabIndex={-1}
        />
      </div>
      {hasShipWeeks && (
        <div className="col-1">
          <Input
            value={item.shipWeek || ''}
            readOnly
            plaintext
            className="quantity"
          />
        </div>
      )}
      <div className="col-2">
        <div className="row no-gutters">
          <div className="col">
            <Input
              value={quantity}
              onChange={handleQuantityChange}
              onBlur={handleQuantityBlur}
              onFocus={handleFocus}
              className="text-right border-light"
            />
          </div>
          <div className="col-2 ml-1 pt-3 small">
            {item.unitOfMeasure !== 'Ea' && item.unitOfMeasure}
          </div>
        </div>
      </div>
      {hasStems && (
        <div className="col-1 text-center">
          <span className="d-inline-block pt-3 small">{stems || '-'}</span>
        </div>
      )}
      <div className="col-2">
        <Input
          value={unitPrice}
          onChange={handleUnitPriceChange}
          onBlur={handleUnitPriceBlur}
          onFocus={handleFocus}
          className="text-right quantity border-light"
        />
      </div>
      <div className="col-2">
        <Input
          value={extension}
          readOnly
          className="text-right bg-white quantity border-0 font-weight-bold"
          tabIndex={-1}
        />
      </div>
      <div className="col-12 font-italic small">
        {!!showCustomerComments && (
          <label htmlFor={`comments-${item.id}`} className="d-inline">
            Customer comments &nbsp;
          </label>
        )}
        {!showCustomerComments && (
          <>
            &nbsp;
            <Button
              color="link"
              size="sm"
              onClick={handleShowCustomerCommentsClick}
              id={`add-comments-${item.id}`}>
              <FontAwesomeIcon icon={['fal', 'comment-plus']} />
            </Button>
            <UncontrolledTooltip target={`add-comments-${item.id}`}>
              Add Comments
            </UncontrolledTooltip>
          </>
        )}
      </div>
      {showCustomerComments && (
        <div className="col-12 font-italic small">
          <Input
            type="textarea"
            rows={2}
            value={comments}
            onChange={handleCommentsChange}
            onBlur={handleCommentsBlur}
          />
        </div>
      )}
    </div>
  );
}
