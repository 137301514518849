import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import numeral from 'numeral';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getConfirmedOrderDetail,
  clearError,
  selectLoading,
  selectError,
  selectOrder,
  selectSubTotal,
} from './confirmed-order-detail-slice';
import { formatDate, formatCurrency } from 'utils/format';
import { Button, Input } from 'reactstrap';
import { DetailRow } from './DetailRow';
import { Error } from 'features/errors/Error';
import { CubingModal } from './CubingModal';
import { contains } from 'utils/equals';

export function Detail() {
  const dispatch = useDispatch(),
    navigate = useNavigate(),
    loading = useSelector(selectLoading),
    error = useSelector(selectError),
    order = useSelector(selectOrder),
    { id } = useParams<{ id: string }>(),
    idValue = numeral(id).value() || 0,
    subTotal = useSelector(selectSubTotal),
    [showCubingModal, setShowCubingModal] = useState(false),
    isCuts = contains(order?.catalogType, 'cut flower'),
    isPrebook = contains(order?.catalogType, 'prebook'),
    subtitle =
      (isCuts ? 'Cut Flowers' : isPrebook ? 'Prebook' : 'Plants') +
      ': ' +
      order?.shipWeek;

  useEffect(() => {
    dispatch(getConfirmedOrderDetail(idValue));
  }, [dispatch, idValue]);

  const toggleError = () => {
    dispatch(clearError());
  };

  const handleEditCubingClick = () => {
    setShowCubingModal(true);
  };

  const toggleShowCubingModal = () => {
    setShowCubingModal(false);
  };

  const handleCloseClick = () => {
    navigate(-1);
  };

  return (
    <div className="container mt-4">
      <div className="row">
        <h1 className="col">
          <FontAwesomeIcon icon={['fad', 'usd-square']} fixedWidth />
          &nbsp; Order Detail: {id}
          <br />
          <FontAwesomeIcon
            icon={['fad', 'usd-square']}
            fixedWidth
            className="invisible"
          />
          &nbsp;
          <small className="font-italic ml-2">{subtitle}</small>
        </h1>
        <div className="col-auto">
          <Button
            size="lg"
            outline
            color="success"
            onClick={handleCloseClick}
            className="px-5">
            Close
          </Button>
        </div>
      </div>
      {loading && (
        <h1 className="text-success text-center">
          &nbsp;
          <FontAwesomeIcon icon={['fad', 'spinner']} spin />
        </h1>
      )}
      <Error error={error} clearError={toggleError} />
      {!!order && (
        <>
          <div className="row">
            <div className="col-6">
              <label className="form-control-label" htmlFor="customer">
                Customer
              </label>
              <Input
                id="customer"
                value={order.customer}
                readOnly
                className="form-control-alternative bg-white"
              />
            </div>
            <div className="col-6">
              <label className="form-control-label" htmlFor="createdBy">
                Placed By
              </label>
              <Input
                id="createdBy"
                value={order.createdBy}
                readOnly
                className="form-control-alternative bg-white"
              />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-6">
              <label className="form-control-label" htmlFor="createdOn">
                Order Date
              </label>
              <Input
                id="createdOn"
                value={`${formatDate(order.createdOn)} @ ${formatDate(
                  order.createdOn,
                  'h:mm a'
                )}`}
                readOnly
                className="form-control-alternative bg-white"
              />
            </div>
            <div className="col-6">
              <label className="form-control-label" htmlFor="shipWeek">
                Ship Week
              </label>
              <Input
                id="shipWeek"
                value={order.shipWeek}
                readOnly
                className="form-control-alternative bg-white"
              />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-6">
              <label className="form-control-label" htmlFor="poNumber">
                PO Number
              </label>
              <Input
                id="poNumber"
                value={order.poNumber || ''}
                readOnly
                className="form-control-alternative bg-white"
              />
            </div>
            <div className="col-6">
              <label className="form-control-label" htmlFor="comments">
                Comments from Customer
              </label>
              <Input
                type={order.comments ? 'textarea' : 'text'}
                rows={3}
                id="comments"
                value={order.comments || ''}
                readOnly
                className="form-control-alternative bg-white"
              />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-2">
              <label className="form-control-label" htmlFor="cubing">
                Cubing
              </label>
              <Input
                id="cubing"
                value={order.cubing}
                readOnly
                className="form-control-alternative bg-white"
              />
            </div>
            <div className="col-2">
              <label className="form-control-label" htmlFor="box-count">
                Box Count
              </label>
              <Input
                id="box-count"
                value={order.boxCount}
                readOnly
                className="form-control-alternative bg-white"
              />
            </div>
            <div className="col-2">
              <label>&nbsp;</label>
              <Button block onClick={handleEditCubingClick}>
                Edit &nbsp;
                <FontAwesomeIcon icon={['fad', 'box-check']} />
              </Button>
            </div>
          </div>
          {!!order.webOrders.length && (
            <div className="row mb-4">
              {order.webOrders
                .filter((o) => !!o.commentsToCustomer)
                .map((o) => (
                  <div key={o.id} className="col-12">
                    <label
                      htmlFor={`comments-to-customer-${o.id}`}
                      className="form-control-label">
                      Response to Customer
                      {order.webOrders.filter((o) => !!o.commentsToCustomer)
                        .length > 1 && (
                        <span>
                          &nbsp;
                          {formatDate(o.createdOn)}
                        </span>
                      )}
                    </label>
                    <Input
                      type="textarea"
                      rows={3}
                      id={`comments-to-customer-${o.id}`}
                      value={o.commentsToCustomer || ''}
                      readOnly
                      className="form-control-alternative bg-white"
                    />
                  </div>
                ))}
            </div>
          )}
          <div className="row">
            <div className="col-6 font-weight-bold">Description</div>
            <div className="col-2 font-weight-bold">
              <Input
                value="Quantity"
                className="quantity pb-0"
                readOnly
                plaintext
              />
            </div>
            <div className="col-2 font-weight-bold">
              <Input
                value="Unit Price"
                className="quantity pb-0"
                readOnly
                plaintext
              />
            </div>
            <div className="col-2 font-weight-bold">
              <Input
                value="Total"
                className="quantity text-center pb-0"
                readOnly
                plaintext
              />
            </div>
          </div>
          {order.items.map((item) => (
            <DetailRow item={item} key={item.id} />
          ))}
          <div className="row border-top-double mt-2 pt-2">
            <div className="col">
              <Input
                value="Total"
                readOnly
                plaintext
                className="form-control-lg font-weight-bold"
              />
            </div>
            <div className="col-auto">
              <Input
                value={formatCurrency(subTotal)}
                readOnly
                className="text-right bg-white quantity font-weight-bold border-0"
                tabIndex={-1}
              />
            </div>
          </div>
        </>
      )}
      <CubingModal show={showCubingModal} hide={toggleShowCubingModal} />
    </div>
  );
}
