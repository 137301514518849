import { ProblemDetails } from 'utils/problem-details';
import { Alert } from 'reactstrap';

interface ErrorProps {
  error: ProblemDetails | null | undefined;
  clearError?: () => void;
}

export function Error({ error, clearError }: ErrorProps) {
  const handleClearError = () => {
    clearError && clearError();
  };

  return (
    <Alert
      color="neutral"
      className="text-danger"
      toggle={handleClearError}
      isOpen={!!error}
      closeClassName="text-danger opacity-6">
      {!!error?.title && <p className="lead">{error?.title}</p>}
      {!!error?.detail && <p>{error.detail}</p>}
    </Alert>
  );
}
