import { useState } from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomerOrder } from 'api/models/customers';
import { routes } from 'app/routes';
import { formatCurrency, formatDate } from 'utils/format';
import { approveOrder, cancelOrder } from './customer-detail-slice';

interface OrderItemProps {
  order: CustomerOrder;
  refresh: () => void;
}

export function OrderItem({ order, refresh }: OrderItemProps) {
  const dispatch = useDispatch(),
    [approveOrderId, setApproveOrderId] = useState<number | null>(null),
    [cancelOrderId, setCancelOrderId] = useState<number | null>(null);

  const handleApproveClick = (id: number) => {
    setApproveOrderId(id);
  };

  const handleApproveCancel = () => {
    setApproveOrderId(null);
  };

  const handleApproveConfirm = async (response: string) => {
    if (approveOrderId) {
      setApproveOrderId(null);

      const args = {
        orderId: approveOrderId,
        commentsToCustomer: response,
        caseCountCuts: null,
        cubesCuts: null,
      };
      await dispatch(approveOrder(args));

      refresh();
    }
  };

  const handleCancelClick = (id: number) => {
    setCancelOrderId(id);
  };

  const handleCancelCancel = () => {
    setCancelOrderId(null);
  };

  const handleCancelConfirm = async (response: string) => {
    setCancelOrderId(null);

    if (cancelOrderId) {
      const args = { orderId: cancelOrderId, commentsToCustomer: response };
      await dispatch(cancelOrder(args));

      refresh();
    }
  };

  return (
    <tr key={order.orderId}>
      <td>
        {!!order.orderId && (
          <Link to={routes.confirmedOrderDetail.to(order.orderId)}>
            {order.displayText}
          </Link>
        )}
        {!order.orderId && (
          <Link to={routes.orderDetail.to(order.webOrderId!)}>
            {order.displayText}
          </Link>
        )}
        {order.status === 'Placed' && !!order.webOrderId && (
          <>
            <br />
            <Button
              id={`approve-order-${order.webOrderId}`}
              color="success"
              size="sm"
              outline
              onClick={() => handleApproveClick(order.webOrderId!)}>
              <FontAwesomeIcon icon={['fad', 'thumbs-up']} />
            </Button>
            <UncontrolledTooltip target={`approve-order-${order.webOrderId}`}>
              Approve Order
            </UncontrolledTooltip>

            <Button
              id={`cancel-order-${order.webOrderId}`}
              color="danger"
              size="sm"
              outline
              onClick={() => handleCancelClick(order.webOrderId!)}>
              <FontAwesomeIcon icon={['fad', 'thumbs-down']} />
            </Button>
            <UncontrolledTooltip target={`cancel-order-${order.webOrderId}`}>
              Cancel Order
            </UncontrolledTooltip>
          </>
        )}
      </td>
      <td>{`${order.catalogType || ''} ${order.catalogName || ''}`}</td>
      <td>{order.shipWeek}</td>
      <td>{order.status}</td>
      <td>{order.poNumber || '--'}</td>
      <td>
        {order.createdOn ? (
          <div>
            {formatDate(order.createdOn)}{' '}
            <div>@ {formatDate(order.createdOn, 'h:mm a')}</div>
          </div>
        ) : (
          <div></div>
        )}
      </td>
      <td>{order.createdBy || 'Manual Entry'}</td>
      <td>{formatCurrency(order.orderTotal)}</td>
      {approveOrderId && (
        <ReactBSAlert
          input
          required={false}
          title="Approve Order"
          confirmBtnText="OK"
          cancelBtnText="Cancel"
          showCancel
          confirmBtnBsStyle="success"
          onConfirm={handleApproveConfirm}
          onCancel={handleApproveCancel}>
          Please enter the Comments to the Customer and click OK to approve this
          order.
        </ReactBSAlert>
      )}
      {cancelOrderId && (
        <ReactBSAlert
          input
          required={false}
          title="Cancel Order"
          confirmBtnText="OK"
          cancelBtnText="Cancel"
          showCancel
          confirmBtnBsStyle="danger"
          onConfirm={handleCancelConfirm}
          onCancel={handleCancelCancel}>
          Please enter the Comments to the Customer and click OK to cancel this
          order.
        </ReactBSAlert>
      )}
    </tr>
  );
}
