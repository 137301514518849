import { ApiBase } from './api-base';
import * as models from './models/save-on-foods';

class SaveOnFoodsService extends ApiBase {
  homeData(): Promise<SaveOnFoodsResponse> {
    return this.get('/api/orders/saveonfoods');
  }

  createOrders(orders: models.OrderCreationOrder[]): Promise<SaveOnFoodsCreateOrdersResponse> {
    return this.post('/api/orders/saveonfoods', {orders});
  }
}

export interface SaveOnFoodsResponse {
  customers: models.Customer[];
  zones: models.Zone[];
  products: models.Product[];
}

export interface SaveOnFoodsCreateOrdersResponse {
  orders: models.CreatedOrder[];
}

export const saveOnFoodsApi = new SaveOnFoodsService();
