import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setSearch, clearError, clearState, getSearchResults, selectSearch, selectError, selectCustomers, selectResultCount } from './add-customer-slice';
import { UserDetail } from 'api/models/users';
import { Error } from 'features/errors/Error';
import { addCustomer } from './customer-user-slice';
import { SearchCustomer } from 'api/models/customers';


interface AddCustomerModalProps {
  user: UserDetail | null;
  close: () => void;
}

export function AddCustomerModal(props: AddCustomerModalProps) {
  const {user, close} = props,
    dispatch = useDispatch(),
    searchRef = useRef<HTMLInputElement>(null),
    search = useSelector(selectSearch),
    customers = useSelector(selectCustomers),
    resultCount = useSelector(selectResultCount),
    error = useSelector(selectError);

  const handleModalOpened = () => {
    searchRef.current?.focus();
  };

  const handleModalClosed = () => {
    dispatch(clearState());
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearch(e.target.value));
  };

  const handleClearError = () => {
    dispatch(clearError());
  }

  const handleSearchKeydown = (e: React.KeyboardEvent) => {
    if(user && e.key === 'Enter') {
      dispatch(getSearchResults(user.id));
    }
  };

  const handleSearchClick = () => {
    if(user) {
      dispatch(getSearchResults(user.id));
    }
  };

  const handleAddClick = async (customer: SearchCustomer) => {
    if(user) {
      const args = { userId: user.id, customerId: customer.id},
        response: any = await dispatch(addCustomer(args));

      if(!response.error) {
        close();
      }
    }
  };

  return (
    <Modal isOpen={!!user} toggle={close} onOpened={handleModalOpened} onClosed={handleModalClosed} size="xl" scrollable>
      <ModalHeader toggle={close}>Add Account to User</ModalHeader>
      <ModalBody>
        <div className="row mb-2">
          <div className="col-12">
            <label htmlFor="search">Search for Customers</label>
            <InputGroup>
              <Input id="search" type="search" value={search} onChange={handleSearchChange} onKeyDown={handleSearchKeydown} innerRef={searchRef} />
              <InputGroupAddon addonType="append">
                <Button type="submit" onClick={handleSearchClick}>
                  <FontAwesomeIcon icon={['fad', 'search']} />
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Address</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {customers?.map(customer => 
              <tr key={customer.id}>
                <td>{customer.shipName}</td>
                <td>{customer.shipAddress}, {customer.shipProvince}</td>
                <td>
                  <Button color="success" outline onClick={() => handleAddClick(customer)}>
                    Add
                    &nbsp;
                    <FontAwesomeIcon icon={['fad', 'chevron-double-right']} />
                  </Button>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Error error={error} clearError={handleClearError} />
      </ModalBody>
      <ModalFooter>
        {customers?.length === 0 &&
          <p className="text-success mr-auto">No customers found</p>
        }
        {customers && customers.length > 0 &&
          <p className="text-success mr-auto">Showing {customers.length} of {resultCount} customers.</p>
        }
        <Button onClick={close}>Close</Button>
      </ModalFooter>
    </Modal>
  )
}