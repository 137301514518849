import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  selectError,
  selectLoading,
  selectCarts,
  selectSalesperson,
  selectSalespeople,
  getSalespeople,
  getCarts,
  setSalesperson,
} from './cart-list-slice';
import { Link } from 'react-router-dom';
import { routes } from 'app/routes';
import { formatDate, formatCurrency } from 'utils/format';

export function List() {
  const dispatch = useDispatch(),
    error = useSelector(selectError),
    loading = useSelector(selectLoading),
    carts = useSelector(selectCarts),
    salesperson = useSelector(selectSalesperson),
    salespeople = useSelector(selectSalespeople);

  const refresh = useCallback(() => {
    dispatch(getCarts({ salesperson }));
  }, [dispatch, salesperson]);

  const handleSalespersonClick = (salesperson: string) => {
    dispatch(setSalesperson(salesperson));
  };

  useEffect(() => {
    dispatch(getSalespeople());
  }, [dispatch]);

  useEffect(() => {
    refresh();
    let timer = window.setInterval(refresh, 1000 * 5);

    return function cleanup() {
      if (timer) {
        window.clearInterval(timer);
      }
    };
  }, [dispatch, refresh]);

  return (
    <div className="container mt-4">
      <div className="row">
        <h1 className="col">
          <FontAwesomeIcon icon={['fad', 'shopping-cart']} />
          &nbsp; Shopping Carts
        </h1>
        <div className="col-12 col-md-auto mb-md-2">
          {salespeople && (
            <UncontrolledDropdown>
              <DropdownToggle caret color="secondary">
                {salesperson || 'All Salespeople'}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => handleSalespersonClick('')}>
                  All Salespeople
                </DropdownItem>
                {salespeople.map((s) => (
                  <DropdownItem
                    key={s.name}
                    onClick={() => handleSalespersonClick(s.name)}>
                    {s.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
        </div>
        <div className="col-12 col-md-auto mb-md-2">
          <Button outline color="success" onClick={refresh}>
            Refresh &nbsp;
            <FontAwesomeIcon icon={['fad', 'sync']} />
          </Button>
        </div>
      </div>
      {error && (
        <div className="alert alert-danger">
          <p className="lead">{error.title}</p>
          <p>{error.detail}</p>
        </div>
      )}
      <div className="row d-none d-md-flex mt-4 mb-3 mb-md-0 font-weight-bold">
        <div className="col">
          {/* spacer */}
          <Link to={routes.carts.path} className="invisible">
            <FontAwesomeIcon icon={['fad', 'edit']} />
          </Link>
          &nbsp; Ship Week
        </div>
        <div className="col-2">Booking Catalog</div>
        <div className="col-2">Last Updated</div>
        <div className="col-2">Customer</div>
        <div className="col-2">Salesperson</div>
        <div className="col-2">Cart Total</div>
      </div>
      {carts?.map((c) => (
        <div key={c.id} className="row py-2 border-bottom">
          <div className="col-2">
            <Link to={routes.cartDetail.to(c.id)}>
              <FontAwesomeIcon icon={['fad', 'edit']} />
            </Link>
            &nbsp;
            {c.shipWeek}
          </div>
          <div className="col-2">
            {c.catalogName}
            <br />
            <span className="font-italic">({c.catalogType})</span>
          </div>
          <div className="col-2">
            {formatDate(c.lastUpdated)}
            <br />
            @&nbsp;{formatDate(c.lastUpdated, 'h:mm a')}
          </div>
          <div className="col-2">
            {c.customer}
            <br />
            <span className="font-italic text-muted">{c.createdBy}</span>
          </div>
          <div className="col-2">{c.salesperson}</div>
          <div className="col-2">{formatCurrency(c.cartTotal)}</div>
        </div>
      ))}
      {loading && (
        <h1 className="text-success text-center">
          &nbsp;
          <FontAwesomeIcon icon={['fad', 'spinner']} spin />
        </h1>
      )}
      {!!carts && !carts.length && (
        <div className="lead text-center">No carts found</div>
      )}
    </div>
  );
}
