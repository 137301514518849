import { createSlice, createAsyncThunk, AsyncThunk, createAction, PayloadAction, createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import { RootState } from 'app/store';
import { ProblemDetails } from 'utils/problem-details';
import { analyticsApi, AnalyticsIndexResponse, SalesAnalyticsResponse } from 'api/analytics-service';
import { AnalyticsBookingCatalog, SalesAnalytics } from 'api/models/analytics';
import { Week, getWeek, nextWeek, createWeekFromId, dateFromWeek }  from 'api/models/weeks';
import { CatalogTypes } from 'api/models/booking-catalogs';
import { sortBy } from 'utils/sort';
import { contains, equals } from 'utils/equals';

export interface AnalyticsState {
  salesAnalytics: SalesAnalytics | null;
  weeks: Week[];
  startWeek: Week;
  endWeek: Week;
  catalogType: CatalogTypes | null;
  bookingCatalogs: AnalyticsBookingCatalog[];
  catalog: AnalyticsBookingCatalog | null;
  showPrebookByWeeks: boolean;
  year: number;
  loading: boolean;
  error: ProblemDetails | null;
}

const initialState: AnalyticsState = {
  salesAnalytics: null,
  weeks: [getWeek(), nextWeek()],
  startWeek: getWeek(),
  endWeek: nextWeek(),
  catalogType: null,
  year: new Date().getFullYear(),
  bookingCatalogs: [],
  catalog: null,
  showPrebookByWeeks: false,
  loading: false,
  error: null
};

export const getAnalyticsData: AsyncThunk<AnalyticsIndexResponse, void, {state: RootState}> = createAsyncThunk(
  'analytics/getAnalyticsData',
  async(_, {rejectWithValue}) => {
    try {
      return await analyticsApi.index();
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

interface GetSalesAnalyticsArgs {
  startWeekId: string | null;
  endWeekId: string | null;
  catalogType: CatalogTypes | null;
  bookingCatalogId: number | null;
}

export const getSalesAnalytics: AsyncThunk<SalesAnalyticsResponse, GetSalesAnalyticsArgs, {state: RootState}> = createAsyncThunk(
  'analytics/getSalesAnalytics',
  async(args, {rejectWithValue}) => {
    try {
      const {startWeekId, endWeekId, catalogType, bookingCatalogId} = args;
      return await analyticsApi.sales(startWeekId, endWeekId, catalogType, bookingCatalogId);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

const getSalesAnalyticsPending = createAction(getSalesAnalytics.pending.type),
  getSalesAnalyticsFulfilled = createAction<SalesAnalyticsResponse>(getSalesAnalytics.fulfilled.type),
  getSalesAnalyticsRejected = createAction<ProblemDetails>(getSalesAnalytics.rejected.type),
  getAnalyticsDataPending = createAction(getAnalyticsData.pending.type),
  getAnalyticsDataFulfilled = createAction<AnalyticsIndexResponse>(getAnalyticsData.fulfilled.type),
  getAnalyticsDataRejected = createAction<ProblemDetails>(getAnalyticsData.rejected.type);

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
    clearState(state) {
      state.salesAnalytics = null;
      state.loading = false;
      state.error = null;
    },
    setStartWeek(state, action: PayloadAction<Week>) {
      state.startWeek = action.payload;
    },
    setEndWeek(state, action: PayloadAction<Week>) {
      state.endWeek = action.payload;
    },
    setCatalogType(state, action: PayloadAction<CatalogTypes | null>) {
      state.catalogType = action.payload;
      state.catalog = null;
      if(!contains(state.catalogType, 'prebook')) {
        state.catalog = null;
        state.showPrebookByWeeks = false;
      }
    },
    setYear(state, {payload: year}: PayloadAction<number>) {
      state.year = year;

      const yearWeeks = state.weeks.filter(w => w.year === year);

      if(state.startWeek) {
        state.startWeek = yearWeeks[0] || null;
      }
      if(state.endWeek) {
        state.endWeek = yearWeeks[yearWeeks.length - 1] || null;
      }
    },
    setCatalog(state, action: PayloadAction<AnalyticsBookingCatalog | null>) {
      const catalog = action.payload;
      state.catalog = catalog;
      if(catalog) {
        const startWeek = createWeekFromId(catalog.weekId),
          endWeek = catalog.endWeekId ? createWeekFromId(catalog.endWeekId) : startWeek;
        state.startWeek = startWeek;
        state.endWeek = endWeek;
      }
    },
    setShowPrebookByWeeks(state, action: PayloadAction<boolean>) {
      state.showPrebookByWeeks = action.payload;
    }
  },
  extraReducers: builder =>
    builder
      .addCase(getSalesAnalyticsPending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSalesAnalyticsFulfilled, (state, action) => {
        state.loading = false;
        state.salesAnalytics = action.payload.analytics;
      })
      .addCase(getSalesAnalyticsRejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getAnalyticsDataPending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAnalyticsDataFulfilled, (state, action) => {
        state.loading = false;
        state.weeks = action.payload.weeks;
        state.bookingCatalogs = action.payload.catalogs;
      })
      .addCase(getAnalyticsDataRejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
});

export const { clearError, clearState, setStartWeek, setEndWeek, setCatalogType, setYear, setCatalog,setShowPrebookByWeeks } = analyticsSlice.actions;

export const selectError = (state: RootState) => state.analytics.error;
export const selectLoading = (state: RootState) => state.analytics.loading;
export const selectSalesAnalytics = (state: RootState) => state.analytics.salesAnalytics;
export const selectStartWeek = (state: RootState) => state.analytics.startWeek;
export const selectEndWeek = (state: RootState) => state.analytics.endWeek;
export const selectCatalogType = (state: RootState) => state.analytics.catalogType;
export const selectWeeks = (state: RootState) => state.analytics.weeks;
export const selectYear = (state: RootState) => state.analytics.year;
export const selectCatalog = (state: RootState) => state.analytics.catalog;
export const selectShowPrebookByWeeks = (state: RootState) => state.analytics.showPrebookByWeeks;
export const selectBookingCatalogs = createSelector(
  selectYear,
  selectCatalogType,
  (state: RootState) => state.analytics.bookingCatalogs,
  (year, catalogType, prebookBookingCatalogs) => 
    prebookBookingCatalogs
      .filter(c => c.year === year)
      .filter(c => !catalogType || equals(c.catalogType, catalogType))      
);
export const selectYears = createSelector(
  (state: RootState) => state.analytics.bookingCatalogs,
  catalog => catalog.reduce((memo, week) => {
    if(memo.indexOf(week.year) === -1) {
      memo.push(week.year);
    }
    memo.sort();
    return memo;
  }, [] as number[])
);
export const selectPrebookWeeks = createSelector(
  selectBookingCatalogs,
  selectCatalog,
  selectCatalogType,
  selectYear,
  (prebooks, catalog, catalogType, year) => {
    const prebookWeeks: Week[] = [],
      isPrebook = contains(catalogType, 'prebook');

    if(catalog == null) {
      prebooks.forEach(prebook => {
        if(prebook.endWeekId == null || prebook.endWeekId < prebook.weekId) {
          if(!prebookWeeks.some(w => w.id === prebook.weekId)){
            const week = createWeekFromId(prebook.weekId);
            if(!isPrebook || week.year === year) {
              prebookWeeks.push(week);
            }
          }
        } else {
          const current = moment(dateFromWeek(prebook.weekId)),
            end = moment(dateFromWeek(prebook.endWeekId));
  
          while(current.isBefore(end)) {
            const week = getWeek(current.toDate());
            if((!isPrebook || week.year === year) && !prebookWeeks.some(w => w.id === week.id)) {
              prebookWeeks.push(week);
            }
            current.add(1, 'week');
          }
        }
      })
    } else {
      if(catalog.endWeekId == null || catalog.endWeekId < catalog.weekId) {
        const week = createWeekFromId(catalog.weekId);
        prebookWeeks.push(week);
      } else {
        const current = moment(dateFromWeek(catalog.weekId)),
          end = moment(dateFromWeek(catalog.endWeekId));

        while(current.isBefore(end)) {
          const week = getWeek(current.toDate());
          prebookWeeks.push(week);
          current.add(1, 'week');
        }
      }
    }
    return prebookWeeks.sort(sortBy('id'));
  }
);

export default analyticsSlice.reducer;
