import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as models from 'api/models/booking-catalogs';
import { AddProductsItem } from './AddProductsItem';
import { addBookingCatalogInventory } from './booking-catalog-detail-slice';
import {
  getProducts,
  setItemQuantity as setQuantity,
  setItemQuantityMessage as setQuantityMessage,
  setMaximumOrderQuantity,
  setMinimumOrderStemQuantityCuts,
  setAvailabilityCutoff,
  selectError,
  setError,
  clearError,
  selectPages,
  selectPage,
  selectSearch,
  selectCategory,
  selectCategories,
  setBookingCatalog,
  selectLoading,
  setSearch,
  setCategory,
  selectPaginatedProducts,
  clearState,
  setPage,
  setShowInactive,
  selectShowInactive,
  selectProducts,
} from './add-products-slice';
import { Error } from 'features/errors/Error';
import { contains } from 'utils/equals';
import { handleDropdownToggle, maxHeightModifiers } from 'utils/dropdown';
import { createProblemDetails } from 'utils/problem-details';

interface AddProductsModalProps {
  isOpen: boolean;
  hide: () => void;
  bookingCatalog: models.BookingCatalogDetail;
  availableFrom: moment.Moment;
  availableTo: moment.Moment;
}

export function AddProductsModal(props: AddProductsModalProps) {
  const dispatch = useDispatch(),
    { isOpen, hide, bookingCatalog, availableFrom, availableTo } = props,
    products = useSelector(selectPaginatedProducts),
    allProducts = useSelector(selectProducts),
    error = useSelector(selectError),
    loading = useSelector(selectLoading),
    page = useSelector(selectPage),
    pages = useSelector(selectPages),
    search = useSelector(selectSearch),
    category = useSelector(selectCategory),
    categories = useSelector(selectCategories),
    showInActive = useSelector(selectShowInactive),
    [dataError, setDataError] = useState<{ [index: number]: boolean }>({}),
    hasDataError = !!Object.keys(dataError).length,
    isCuts = contains(bookingCatalog.catalogType, 'Cut Flower');

  const handleModalOpened = () => {
    dispatch(setBookingCatalog(bookingCatalog));
    dispatch(
      getProducts({
        catalogType: bookingCatalog.catalogType,
        bookingCatalogId: bookingCatalog.id,
      })
    );
  };

  const handleModalClosed = () => {
    dispatch(clearState());
  };

  const setItemQuantity = (
    productId: number,
    value: number | string | null
  ) => {
    if (typeof value === 'number') {
      dispatch(setQuantity({ productId, value }));
      dispatch(setQuantityMessage({ productId, value: null }));
    } else if (typeof value == 'string' && !!value) {
      dispatch(setQuantity({ productId, value: null }));
      dispatch(setQuantityMessage({ productId, value }));
    } else if (value == null) {
      dispatch(setQuantity({ productId, value: null }));
      dispatch(setQuantityMessage({ productId, value: null }));
    }
  };

  const setItemMaximumOrderQuantity = (
    productId: number,
    value: number | null
  ) => {
    const args = { productId, value };
    dispatch(setMaximumOrderQuantity(args));
  };

  const setItemMinimumOrderStemQuantityCuts = (
    productId: number,
    value: number | null
  ) => {
    const args = { productId, value };
    dispatch(setMinimumOrderStemQuantityCuts(args));
  };

  const setItemAvailabilityCutoff = (
    productId: number,
    value: string | null
  ) => {
    const args = { productId, value };
    dispatch(setAvailabilityCutoff(args));
  };

  const handleDataError = (productId: number, value: string | null) => {
    const newError = { ...dataError };

    if (!value && newError[productId]) {
      delete newError[productId];
    } else if (value && !Object.hasOwnProperty(productId)) {
      newError[productId] = true;
    }

    setDataError(newError);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearch(e.target.value));
  };

  const handleCategoryChange = (category: string | null) => {
    dispatch(setCategory(category));
  };

  const handleShowInactiveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setShowInactive(e.target.checked));
  };

  const handlePageClick = (e: React.MouseEvent, page: number) => {
    e.preventDefault();
    dispatch(setPage(page));
  };

  const handleSaveClick = async () => {
    const additions = allProducts
      .filter((p) => p.id || p.quantity || p.quantityMessage)
      .map((i) => {
        const availabilityCutoff = i.availabilityCutoff
            ? moment(i.availabilityCutoff).utc().toISOString()
            : null,
          quantity = typeof i.quantity === 'number' ? i.quantity : null;
        return {
          productId: i.productId,
          quantity,
          quantityMessage: i.quantityMessage,
          maximumOrderQuantity: i.maximumOrderQuantity,
          minimumOrderStemQuantityCuts: i.minimumOrderStemQuantityCuts,
          availabilityCutoff,
          weeks: [],
        };
      });

    const partials = additions.filter((a) => a.quantity && a.quantity % 1);

    if (partials.length) {
      const partialIds = partials.map((p) => p.productId),
        errorItems = allProducts.filter((a) =>
          partialIds.includes(a.productId)
        ),
        message =
          'The following items have available quantities that are not full pack quantities. Please correct these before saving: ' +
          errorItems
            .map((p) => `${p.size} ${p.description1} ${p.description2}`)
            .join(', ');
      dispatch(setError(createProblemDetails(message)));
      return;
    }

    await dispatch(addBookingCatalogInventory(additions));
    hide();
  };

  const handleClearError = () => {
    dispatch(clearError());
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={hide}
      onOpened={handleModalOpened}
      onClosed={handleModalClosed}
      scrollable
      style={{ maxWidth: '90vw' }}>
      <ModalHeader toggle={hide}>Edit Products in Booking Catalog</ModalHeader>
      <ModalBody>
        <div className="d-flex flex-column overflow-auto-y overflow-hidden-x h-100">
          <div className="row mb-2">
            <div className="col-12 col-md-6">
              <label htmlFor="search" className="w-50">
                Search for products
              </label>
              <Input
                id="search"
                type="search"
                value={search}
                onChange={handleSearchChange}
                placeholder="Search for products"
              />
            </div>
            <div className="col-12 col-md-3">
              <label className="w-100 invisible">Category</label>
              <UncontrolledDropdown onToggle={handleDropdownToggle}>
                <DropdownToggle caret>
                  {category || 'All Categories'}
                </DropdownToggle>
                <DropdownMenu
                  modifiers={maxHeightModifiers()}
                  style={{ zIndex: 1025 }}>
                  {!!category && (
                    <DropdownItem onClick={() => handleCategoryChange(null)}>
                      All Categories
                    </DropdownItem>
                  )}
                  {categories.map((c) => (
                    <DropdownItem
                      key={c}
                      onClick={() => handleCategoryChange(c)}>
                      {c}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
            <div className="col-12 col-md-3 text-right">
              <label htmlFor="show-inactive" className="w-100 invisible">
                Inactive
              </label>
              <label
                className="custom-toggle custom-toggle-success"
                htmlFor="show-inactive">
                <input
                  id="show-inactive"
                  type="checkbox"
                  checked={showInActive}
                  onChange={handleShowInactiveChange}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
              <label htmlFor="show-inactive" className="ml-2 align-top">
                Show inactive products
              </label>
            </div>
          </div>
          <div className="d-flex flex-grow-1 overflow-auto-y">
            <table className="table overflow-auto-y">
              <thead>
                <tr className="sticky-top bg-white">
                  <th className="align-top">Category</th>
                  <th className="align-top">Description</th>
                  <th className="align-top">Colour</th>
                  <th className="align-top">Supplier</th>
                  <th className="align-top">Comment</th>
                  <th className="text-center text-nowrap px-2 align-top">
                    Pack Qty
                  </th>
                  <th className="text-center">
                    Available Qty
                    {!isCuts && (
                      <>
                        <br />
                        (in cases)
                      </>
                    )}
                  </th>
                  <th className="text-center">
                    Max Order
                    <br />
                    Qty
                  </th>
                  {isCuts && (
                    <th className="text-center">
                      Minimum Stem
                      <br />
                      Qty
                    </th>
                  )}
                  <th colSpan={3} className="text-center">
                    Cutoff
                  </th>
                </tr>
              </thead>
              <tbody>
                {products.map((product) => (
                  <AddProductsItem
                    key={product.productId}
                    item={product}
                    availableFrom={availableFrom}
                    availableTo={availableTo}
                    setItemQuantity={setItemQuantity}
                    setItemMaximumOrderQuantity={setItemMaximumOrderQuantity}
                    setItemMinimumOrderStemQuantityCuts={
                      setItemMinimumOrderStemQuantityCuts
                    }
                    setItemAvailabilityCutoff={setItemAvailabilityCutoff}
                    setItemDataError={handleDataError}
                  />
                ))}
              </tbody>
              {!loading && !products.length && (
                <tbody>
                  <tr>
                    <th colSpan={7}>No products found</th>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
          {!!error && <Error error={error} clearError={handleClearError} />}
        </div>
      </ModalBody>
      <ModalFooter>
        <nav className="ml-auto mr-auto">
          <ul className="pagination pagination-lg justify-content-center">
            <li className={`page-item ${page <= 1 ? 'disabled' : ''}`}>
              <Button
                className={`page-link ${
                  page <= 1 ? '' : 'text-success border-success'
                }`}
                onClick={(e) => handlePageClick(e, 1)}>
                <FontAwesomeIcon icon={['fal', 'chevron-double-left']} />
              </Button>
            </li>
            <li className={`page-item ${page <= 1 ? 'disabled' : ''}`}>
              <Link
                className={`page-link ${
                  page <= 1 ? '' : 'text-success border-success'
                }`}
                to="/"
                onClick={(e) => handlePageClick(e, page - 1)}>
                <FontAwesomeIcon icon={['fal', 'chevron-left']} />
              </Link>
            </li>
            <li className="page-item">
              <span
                className="text-muted text-nowrap w-auto mx-4"
                style={{ fontSize: '125%' }}>{`Page ${page} of ${pages}`}</span>
            </li>
            <li className={`page-item ${page >= pages ? 'disabled' : ''}`}>
              <Link
                className={`page-link ${
                  page >= pages ? '' : 'text-success border-success'
                }`}
                to="/"
                onClick={(e) => handlePageClick(e, page + 1)}>
                <FontAwesomeIcon icon={['fal', 'chevron-right']} />
              </Link>
            </li>
            <li className={`page-item ${page >= pages ? 'disabled' : ''}`}>
              <Link
                className={`page-link ${
                  page >= pages ? '' : 'text-success border-success'
                }`}
                to="/"
                onClick={(e) => handlePageClick(e, pages)}>
                <FontAwesomeIcon icon={['fal', 'chevron-double-right']} />
              </Link>
            </li>
          </ul>
        </nav>

        <Button onClick={hide}>Cancel</Button>
        <Button
          color="success"
          outline
          onClick={handleSaveClick}
          disabled={loading || hasDataError}>
          <FontAwesomeIcon icon={['fad', 'save']} />
          &nbsp; Update Booking Catalog
        </Button>
      </ModalFooter>
    </Modal>
  );
}
