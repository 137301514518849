import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { selectLoading, selectError, clearError } from './booking-catalog-detail-slice';
import * as models from 'api/models/booking-catalogs';
import { Error } from 'features/errors/Error';
import { ProblemDetails } from 'utils/problem-details';

export interface SaveAlertArgs {
  alertTitle1: string | null;
  alertTitle2: string | null;
  alertDescription: string | null;
}

export interface AlertModalProps {
    isOpen: boolean;
    hide: () => void;
    save: (args: SaveAlertArgs) => Promise<ProblemDetails | null>;
    bookingCatalog: models.BookingCatalogDetail;
}

export function AlertModal(props: AlertModalProps) {
    const dispatch = useDispatch(),
        loading = useSelector(selectLoading),
        error = useSelector(selectError),
        {isOpen, hide, save, bookingCatalog} = props,
        [alertTitle1, setAlertTitle1] = useState(bookingCatalog.alertTitle1),
        [alertTitle2, setAlertTitle2] = useState(bookingCatalog.alertTitle2),
        [alertDescription, setAlertDescription] = useState(bookingCatalog.alertDescription);

    const handleModalOpened = () => {
        setAlertTitle1(bookingCatalog.alertTitle1);
        setAlertTitle2(bookingCatalog.alertTitle2);
        setAlertDescription(bookingCatalog.alertDescription);
      };

    const handleAlertTitle1Change = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAlertTitle1(e.target.value || null);
    };

    const handleAlertTitle2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAlertTitle2(e.target.value || null);
    };

    const handleAlertDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAlertDescription(e.target.value || null);
    };

    const handleSaveClick = async () => {
      const args = {alertTitle1, alertTitle2, alertDescription},
        response = await save(args);

      if(!response) {
        hide();
      }
    };
    
    const handleClearError = () => {
      dispatch(clearError());
    };

    return (
        <Modal isOpen={isOpen} toggle={hide} onOpened={handleModalOpened} scrollable>
            <ModalHeader toggle={hide}>Booking Catalog Alert</ModalHeader>
            <ModalBody>
                <div className="row">
                    <label htmlFor="title-1" className="col-12">Title 1</label>
                    <div className="col-12">
                        <Input id="title-1" value={alertTitle1 || ''} onChange={handleAlertTitle1Change} maxLength={100} />
                    </div>
                </div>
                <div className="row mt-2">
                    <label htmlFor="title-2" className="col-12">Title 2</label>
                    <div className="col-12">
                        <Input id="title-2" value={alertTitle2 || ''} onChange={handleAlertTitle2Change} maxLength={100} />
                    </div>
                </div>
                <div className="row mt-2">
                    <label htmlFor="alert-description" className="col-12">Description <small>(600 character limit)</small></label>
                    <div className="col-12">
                        <Input id="alert-description" type="textarea" rows={5} value={alertDescription || ''} onChange={handleAlertDescriptionChange} maxLength={600} />
                    </div>
                </div>
                <Error error={error} clearError={handleClearError} />
            </ModalBody>
            <ModalFooter>
                <Button onClick={hide}>Cancel</Button>
                <Button color="success" outline disabled={loading} onClick={handleSaveClick}>
                Save
                &nbsp;
                <FontAwesomeIcon icon={['fad', 'save']} />
                </Button>
            </ModalFooter>
            </Modal>
    )
}
