import { ApiBase } from './api-base';
import { SalesAnalytics } from './models/analytics';
import { BookingCatalogListItem } from './models/booking-catalogs';
import { ConfirmedOrderListItem } from './models/confirmed-orders';
import { CustomerListItem } from './models/customers';
import { OrderListItem } from './models/orders';
import { OrderSummary } from './models/home';

class HomeService extends ApiBase {
  home(): Promise<IntranetHomeResponse> {
    return this.get('/api');
  }
}

export interface IntranetHomeResponse {
  orderSummary: OrderSummary;
  webOrders: OrderListItem[];
  confirmedOrders: ConfirmedOrderListItem[];
  bookingCatalogs: BookingCatalogListItem[];
  plantsAnalytics: SalesAnalytics;
  cutsAnalytics: SalesAnalytics;
  favouriteCustomers: CustomerListItem[];
}

export const homeApi = new HomeService();
