import { createSlice, createAsyncThunk, AsyncThunk, createAction, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ProblemDetails } from 'utils/problem-details';
import { UserResponse, CreateUserArgs, UpdateUserArgs, userApi } from 'api/user-service';
import { UserDetail } from 'api/models/users';

export interface CustomerUserState {
  user: UserDetail | null;
  saving: boolean;
  error: ProblemDetails | null;
}

const initialState: CustomerUserState = {
  user: null,
  saving: false,
  error: null
};

export const createUser: AsyncThunk<UserResponse, CreateUserArgs, {state: RootState}> = createAsyncThunk(
  'customerDetail/createUser',
  async(args, {rejectWithValue}) => {
    try {
      return await userApi.createUser(args);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const getUser: AsyncThunk<UserResponse, number, {state: RootState}> = createAsyncThunk(
  'customerDetail/getUser',
  async(id, {rejectWithValue}) => {
    try {
      return await userApi.getDetail(id);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const updateUser: AsyncThunk<UserResponse, UpdateUserArgs, {state: RootState}> = createAsyncThunk(
  'customerDetail/updateUser',
  async(args, {rejectWithValue}) => {
    try {
      return await userApi.updateUser(args);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const disableUser: AsyncThunk<UserResponse, number, {state: RootState}> = createAsyncThunk(
  'customerDetail/disableUser',
  async(id, {rejectWithValue}) => {
    try {
      return await userApi.disableUser(id);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const enableUser: AsyncThunk<UserResponse, number, {state: RootState}> = createAsyncThunk(
  'customerDetail/enableUser',
  async(id, {rejectWithValue}) => {
    try {
      return await userApi.enableUser(id);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const passwordReset: AsyncThunk<void, number, {state: RootState}> = createAsyncThunk(
  'customerDetail/passwordReset',
  async(id, {rejectWithValue}) => {
    try {
      return await userApi.passwordReset(id);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

interface UserCustomerArgs{
  userId: number;
  customerId: number;
}

export const addCustomer: AsyncThunk<UserResponse, UserCustomerArgs, {state: RootState}> = createAsyncThunk(
  'customerDetail/addCustomer',
  async(args, {rejectWithValue}) => {
    try {
      const {userId, customerId} = args;
      return await userApi.addCustomer(userId, customerId);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const deleteCustomer: AsyncThunk<UserResponse, UserCustomerArgs, {state: RootState}> = createAsyncThunk(
  'customerDetail/deleteCustomer',
  async(args, {rejectWithValue}) => {
    try {
      const {userId, customerId} = args;
      return await userApi.deleteCustomer(userId, customerId);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

const createUserPending = createAction(createUser.pending.type),
  createUserFulfilled = createAction<UserResponse>(createUser.fulfilled.type),
  createUserRejected = createAction<ProblemDetails>(createUser.rejected.type),
  getUserPending = createAction(getUser.pending.type),
  getUserFulfilled = createAction<UserResponse>(getUser.fulfilled.type),
  getUserRejected = createAction<ProblemDetails>(getUser.rejected.type),
  updateUserPending = createAction(updateUser.pending.type),
  updateUserFulfilled = createAction<UserResponse>(updateUser.fulfilled.type),
  updateUserRejected = createAction<ProblemDetails>(updateUser.rejected.type),
  disableUserPending = createAction(disableUser.pending.type),
  disableUserFulfilled = createAction<UserResponse>(disableUser.fulfilled.type),
  disableUserRejected = createAction<ProblemDetails>(disableUser.rejected.type),
  enableUserPending = createAction(enableUser.pending.type),
  enableUserFulfilled = createAction<UserResponse>(enableUser.fulfilled.type),
  enableUserRejected = createAction<ProblemDetails>(enableUser.rejected.type),
  addCustomerPending = createAction(addCustomer.pending.type),
  addCustomerFulfilled = createAction<UserResponse>(addCustomer.fulfilled.type),
  addCustomerRejected = createAction<ProblemDetails>(addCustomer.rejected.type),
  deleteCustomerPending = createAction(deleteCustomer.pending.type),
  deleteCustomerFulfilled = createAction<UserResponse>(deleteCustomer.fulfilled.type),
  deleteCustomerRejected = createAction<ProblemDetails>(deleteCustomer.rejected.type),
  passwordResetPending = createAction(passwordReset.pending.type),
  passwordResetFulfilled = createAction(passwordReset.fulfilled.type),
  passwordResetRejected = createAction<ProblemDetails>(passwordReset.rejected.type);

export const customerUserSlice = createSlice({
  name: 'customerUser',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<ProblemDetails | null>) => {
      state.error = action.payload;
    },
    setUser: (state, action: PayloadAction<UserDetail | null>) => {
      state.user = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(createUserPending, (state) => {
        state.saving = true;
        state.error = null;
      })
      .addCase(createUserFulfilled, (state) => {
        state.user = null;
        state.saving = false;
      })
      .addCase(createUserRejected, (state, action) => {
        state.saving = false;
        state.error = action.payload;
      })
      .addCase(getUserPending, (state) => {
        state.saving = true;
        state.error = null;
      })
      .addCase(getUserFulfilled, (state, action) => {
        state.user = action.payload.user;
        state.saving = false;
      })
      .addCase(getUserRejected, (state, action) => {
        state.saving = false;
        state.error = action.payload;
      })
      .addCase(updateUserPending, (state) => {
        state.saving = true;
        state.error = null;
      })
      .addCase(updateUserFulfilled, (state, action) => {
        state.user = action.payload.user;
        state.saving = false;
      })
      .addCase(updateUserRejected, (state, action) => {
        state.saving = false;
        state.error = action.payload;
      })
      .addCase(disableUserPending, (state) => {
        state.saving = true;
        state.error = null;
      })
      .addCase(disableUserFulfilled, (state) => {
        state.user = null;
        state.saving = false;
      })
      .addCase(disableUserRejected, (state, action) => {
        state.saving = false;
        state.error = action.payload;
      })
      .addCase(enableUserPending, (state) => {
        state.saving = true;
        state.error = null;
      })
      .addCase(enableUserFulfilled, (state) => {
        state.user = null;
        state.saving = false;
      })
      .addCase(enableUserRejected, (state, action) => {
        state.saving = false;
        state.error = action.payload;
      })
      .addCase(addCustomerPending, (state) => {
        state.saving = true;
        state.error = null;
      })
      .addCase(addCustomerFulfilled, (state, action) => {
        state.user = action.payload.user;
        state.saving = false;
      })
      .addCase(addCustomerRejected, (state, action) => {
        state.saving = false;
        state.error = action.payload;
      })
      .addCase(deleteCustomerPending, (state) => {
        state.saving = true;
        state.error = null;
      })
      .addCase(deleteCustomerFulfilled, (state, action) => {
        state.user = action.payload.user;
        state.saving = false;
      })
      .addCase(deleteCustomerRejected, (state, action) => {
        state.saving = false;
        state.error = action.payload;
      })
      .addCase(passwordResetPending, state => {
        state.saving = true;
        state.error = null;
      })
      .addCase(passwordResetFulfilled, state => {
        state.saving = false;
      })
      .addCase(passwordResetRejected, (state, action) => {
        state.saving = false;
        state.error = action.payload;
      })
  }
});

export const { setError, setUser } = customerUserSlice.actions;

export const selectUser = (state: RootState) => state.customerUser.user;
export const selectSaving = (state: RootState) => state.customerUser.saving;
export const selectError = (state: RootState) => state.customerUser.error;

export default customerUserSlice.reducer;
