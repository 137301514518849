import { Suspense, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMatch } from 'react-router';
import { Routes, Route, Navigate } from 'react-router-dom';
import 'boot';
import { NavMenu } from 'app/NavMenu';
import { routes } from './routes';
import { selectAuthenticated, setUser } from 'features/auth/auth-slice';
import './App.scss';
import { Login } from 'features/auth/Login';
import { Loading } from 'features/loading/Loading';
import { authService } from 'features/auth/authorization-service';

export default function App() {
  const dispatch = useDispatch(),
    [ready, setReady] = useState(false),
    authenticated = useSelector(selectAuthenticated),
    path = useMatch(routes.login.path);

  useEffect(() => {
    async function initAuth() {
      const user = await authService.init();
      await dispatch(setUser(user));
      setReady(true);
    }

    initAuth();
  }, [dispatch, authenticated]);

  if (path?.pathname === routes.login.path) {
    return (
      <div className="App">
        <Login />
      </div>
    );
  } else if (!authenticated && ready) {
    return <Navigate to={routes.login.path} />;
  }

  return (
    <div className="App">
      <NavMenu />
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path={routes.home.path} element={<routes.home.component />} />
          <Route
            path={routes.orders.path}
            element={<routes.orders.component />}
          />
          <Route
            path={routes.orderDetail.path}
            element={<routes.orderDetail.component />}
          />
          <Route
            path={routes.confirmedOrders.path}
            element={<routes.confirmedOrders.component />}
          />
          <Route
            path={routes.confirmedOrderDetail.path}
            element={<routes.confirmedOrderDetail.component />}
          />
          <Route
            path={routes.cartDetail.path}
            element={<routes.cartDetail.component />}
          />
          <Route
            path={routes.bookingCatalogs.path}
            element={<routes.bookingCatalogs.component />}
          />
          <Route
            path={routes.bookingCatalogNew.path}
            element={<routes.bookingCatalogNew.component />}
          />
          <Route
            path={routes.bookingCatalogDetail.path}
            element={<routes.bookingCatalogDetail.component />}
          />
          <Route
            path={routes.customers.path}
            element={<routes.customers.component />}
          />
          <Route
            path={routes.customerDetail.path}
            element={<routes.customerDetail.component />}
          />
          <Route
            path={routes.carts.path}
            element={<routes.carts.component />}
          />
          <Route
            path={routes.analytics.path}
            element={<routes.analytics.component />}
          />
          <Route
            path={routes.xero.home.path}
            element={<routes.xero.home.component />}
          />
          <Route
            path={routes.xero.login.path}
            element={<routes.xero.login.component />}
          />
          <Route
            path={routes.xero.callback.path}
            element={<routes.xero.callback.component />}
          />
          <Route
            path={routes.xero.freight.path}
            element={<routes.xero.freight.component />}
          />
          <Route
            path={routes.xero.wilma.path}
            element={<routes.xero.wilma.component />}
          />
          <Route
            path={routes.xero.credits.path}
            element={<routes.xero.credits.component />}
          />
          <Route
            path={routes.xero.pos.path}
            element={<routes.xero.pos.component />}
          />
          <Route
            path={routes.reports.home.path}
            element={<routes.reports.home.component />}
          />
          <Route
            path={routes.reports.wholesale.salesByProvinceCustomer.path}
            element={
              <routes.reports.wholesale.salesByProvinceCustomer.component />
            }
          />
          <Route
            path={routes.reports.wholesale.salesCreditsYTDByCategory.path}
            element={
              <routes.reports.wholesale.salesCreditsYTDByCategory.component />
            }
          />
          <Route
            path={routes.reports.wholesale.salesCreditsYTDByCustomer.path}
            element={
              <routes.reports.wholesale.salesCreditsYTDByCustomer.component />
            }
          />
          <Route
            path={routes.reports.wholesale.salesCreditsYTDBySalesperson.path}
            element={
              <routes.reports.wholesale.salesCreditsYTDBySalesperson.component />
            }
          />
          <Route
            path={routes.reports.wholesale.salesByWeek.path}
            element={<routes.reports.wholesale.salesByWeek.component />}
          />
          <Route
            path={routes.reports.wholesale.weeklyMarkups.path}
            element={<routes.reports.wholesale.weeklyMarkups.component />}
          />
          <Route
            path={routes.saveOnFoods.home.path}
            element={<routes.saveOnFoods.home.component />}
          />
          <Route
            path={routes.thriftys.single.path}
            element={<routes.thriftys.single.component />}
          />
          <Route
            path={routes.thriftys.multiple.path}
            element={<routes.thriftys.multiple.component />}
          />
          <Route
            path={routes.marketOfChoice.home.path}
            element={<routes.marketOfChoice.home.component />}
          />
          <Route
            path={routes.calgaryCoop.home.path}
            element={<routes.calgaryCoop.home.component />}
          />
          <Route
            path={routes.fresonBros.home.path}
            element={<routes.fresonBros.home.component />}
          />
          <Route
            path={routes.products.plants.list.path}
            element={<routes.products.plants.list.component />}
          />
          <Route
            path={routes.products.plants.sizes.path}
            element={<routes.products.plants.sizes.component />}
          />
          <Route
            path={routes.products.plants.products.path}
            element={<routes.products.plants.products.component />}
          />
          <Route
            path={routes.products.plants.upgrades.path}
            element={<routes.products.plants.upgrades.component />}
          />
          <Route
            path={routes.products.plants.plants.path}
            element={<routes.products.plants.plants.component />}
          />
          <Route
            path={routes.products.plants.detail.path}
            element={<routes.products.plants.detail.component />}
          />
          <Route
            path={routes.products.settings.path}
            element={<routes.products.settings.component />}
          />
          <Route
            path={routes.alerts.list.path}
            element={<routes.alerts.list.component />}
          />
        </Routes>
      </Suspense>
    </div>
  );
}
