import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { AlertListItem } from 'api/models/alerts';
import { alertApi } from 'api/alert-service';
import { ProblemDetails } from 'utils/problem-details';

const PageSize = 25;

export interface CustomerListState {
  alerts: AlertListItem[];
  totalAlertCount: number;
  page: number;
  showSnoozed: boolean;
  showDismissed: boolean;
  type: string | null;
  loading: boolean;
  error: ProblemDetails | null;
}

const initialState: CustomerListState = {
  alerts: [],
  totalAlertCount: 0,
  page: 1,
  showSnoozed: false,
  showDismissed: false,
  type: null,
  loading: false,
  error: null,
};

export const customerListSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setShowSnoozed(state, { payload }: PayloadAction<boolean>) {
      state.showSnoozed = payload;
      state.page = 1;
    },
    setShowDismissed(state, { payload }: PayloadAction<boolean>) {
      state.showDismissed = payload;
      state.page = 1;
    },
    setType(state, { payload }: PayloadAction<string | null>) {
      state.type = payload;
      state.page = 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(alertApi.endpoints.list.matchPending, (state) => {
        state.loading = true;
      })
      .addMatcher(
        alertApi.endpoints.list.matchFulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.alerts = payload.alerts;
          state.totalAlertCount = payload.totalAlertCount;
        }
      )
      .addMatcher(
        alertApi.endpoints.list.matchRejected,
        (state, { payload }) => {
          state.loading = false;
          if (payload) {
            state.error = payload;
          }
        }
      );
  },
});

export const { setPage, setShowDismissed, setShowSnoozed, setType } =
  customerListSlice.actions;

export const selectAlerts = ({ alerts }: RootState) => alerts.alerts;
export const selectTotalAlertCount = ({ alerts }: RootState) =>
  alerts.totalAlertCount;
export const selectError = ({ alerts }: RootState) => alerts.error;
export const selectLoading = ({ alerts }: RootState) => alerts.loading;
export const selectPage = ({ alerts }: RootState) => alerts.page;
export const selectShowSnoozed = ({ alerts }: RootState) => alerts.showSnoozed;
export const selectShowDismissed = ({ alerts }: RootState) =>
  alerts.showDismissed;
export const selectType = ({ alerts }: RootState) => alerts.type;

export const selectPages = createSelector(selectTotalAlertCount, (alertCount) =>
  Math.ceil(alertCount / PageSize)
);

export default customerListSlice.reducer;
