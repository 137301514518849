export interface QuantityPerUnitProduct {
  unitOfMeasureCuts: string | null;
  caseQuantity: number;
  stemsPerBunch: number | null;
}

export function stemsPerUnit(product: QuantityPerUnitProduct) {
  if(product.unitOfMeasureCuts === 'St') {
    return 1;
  }

  if(product.unitOfMeasureCuts === 'Bu') {
    return product.stemsPerBunch || 1;
  }

  if(product.unitOfMeasureCuts === 'Cs') {
    // if the product is only sold in cases, we don't know how many stems per
    if(product.caseQuantity === 1) {
      return null;
    }
    
    return (product.stemsPerBunch || 1) * product.caseQuantity;
  }

  return 1;
}
