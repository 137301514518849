import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import * as XLSX from 'xlsx';
import { bookingCatalogApi } from 'api/booking-catalog-service';
import { OrderedProduct } from 'api/models/booking-catalogs';
import { formatCurrency, formatNumber } from 'utils/format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface OrderedProductsModalProps {
  bookingCatalogId: number;
  show: boolean;
  hide: () => void;
}

export function OrderedProductsModal(props: OrderedProductsModalProps) {
  const { bookingCatalogId, show, hide } = props,
    [weekly, setWeekly] = useState(false),
    [products, setProducts] = useState<OrderedProduct[] | null>(null);

  const refresh = async (weekly: boolean) => {
    const { products } = await bookingCatalogApi.ordered(
      bookingCatalogId,
      weekly
    );
    setProducts(products);
  };

  const handleModalOpened = () => {
    setProducts(null);
    refresh(weekly);
  };

  const handleWeeklyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const weekly = e.target.checked;
    setWeekly(weekly);
    refresh(weekly);
  };

  const handleDownloadClick = () => {
    if (products) {
      const data = weekly
          ? (
              [
                [
                  'Product ID',
                  'Category',
                  'Item',
                  'Week',
                  'Cases Ordered',
                  '$',
                ],
              ] as any[][]
            ).concat(
              products.map((product) => {
                return [
                  product.productId,
                  product.category,
                  `${product.size} ${product.description1} ${product.description2}`,
                  `Week ${product.weekNumber}, ${product.year}`,
                  product.quantityInCases,
                  product.dollars,
                ];
              })
            )
          : (
              [
                ['Product ID', 'Category', 'Item', 'Cases Ordered', '$'],
              ] as any[][]
            ).concat(
              products.map((product) => {
                return [
                  product.productId,
                  product.category,
                  `${product.size} ${product.description1} ${product.description2}`,
                  product.quantityInCases,
                  product.dollars,
                ];
              })
            ),
        book = XLSX.utils.book_new(),
        sheet = XLSX.utils.aoa_to_sheet(data);

      XLSX.utils.book_append_sheet(book, sheet);
      XLSX.writeFile(book, 'Ordered Products.xlsx');
    }
  };

  return (
    <Modal
      scrollable
      isOpen={show}
      toggle={hide}
      size="xl"
      onOpened={handleModalOpened}>
      <ModalHeader toggle={hide}>Products Ordered</ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-12">
            <label
              className="custom-toggle custom-toggle-success"
              htmlFor="weekly">
              <input
                id="weekly"
                type="checkbox"
                checked={weekly}
                onChange={handleWeeklyChange}
              />
              <span className="custom-toggle-slider rounded-circle" />
            </label>
            <label htmlFor="weekly" className="ml-2 align-middle">
              Show ordered values by week
            </label>
          </div>
        </div>

        {!!products && (
          <table className="table">
            <thead>
              <th>Category</th>
              <th>Product</th>
              {weekly && <th>Week</th>}
              <th className="text-center">Cases Ordered</th>
              <th className="text-right">$</th>
            </thead>
            <tbody>
              {products.map((product) => (
                <tr key={product.productId}>
                  <td>{product.category}</td>
                  <td>
                    {product.size}&nbsp;{product.description1}&nbsp;
                    {product.description2}
                    &nbsp;
                    <span className="small font-italic">{product.colour}</span>
                  </td>
                  {weekly && (
                    <td>
                      Week {product.weekNumber}, {product.year}
                    </td>
                  )}
                  <td className="text-center">
                    {formatNumber(product.quantityInCases)}
                  </td>
                  <td className="text-right">
                    {formatCurrency(product.dollars)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          className="mr-auto"
          color="success"
          outline
          onClick={handleDownloadClick}
          disabled={!products}>
          <FontAwesomeIcon icon={['fal', 'file-excel']} />
          &nbsp; Download
        </Button>
        <Button onClick={hide}>Close</Button>
      </ModalFooter>
    </Modal>
  );
}
