import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { handleDropdownToggle, maxHeightModifiers } from 'utils/dropdown';
import { CustomerFilter, selectFilter, setFilter } from './customer-list-slice';

interface ListFilterArgs {
  title: string;
  property: keyof CustomerFilter;
  values: string[];
}

export function ListFilter({ title, property, values }: ListFilterArgs) {
  const dispatch = useDispatch(),
    filter = useSelector(selectFilter),
    selectedValue = filter[property];

  const handleClearClick = () => {
    const newFilter = { ...filter, [property]: null };
    dispatch(setFilter(newFilter));
  };

  const handleValueClick = (value: string) => {
    const newFilter = { ...filter, [property]: value };
    dispatch(setFilter(newFilter));
  };

  if (selectedValue != null) {
    return (
      <div className="text-success font-weight-bold d-flex flex-row">
        {selectedValue}&nbsp;
        <Button
          color="link"
          className="p-0"
          size="sm"
          close
          onClick={handleClearClick}
        />
      </div>
    );
  }

  return (
    <div>
      {title}&nbsp;
      <UncontrolledDropdown
        className={values.length <= 1 ? 'invisible' : ''}
        onToggle={handleDropdownToggle}>
        <DropdownToggle caret size="sm" />
        <DropdownMenu modifiers={maxHeightModifiers()}>
          {values.map((value) => (
            <DropdownItem key={value} onClick={() => handleValueClick(value)}>
              {value}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
}
