import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { selectPages, selectPage, getConfirmedOrders, selectSalesperson,
  selectWeek, setPage, setShowAll, selectCatalogType, selectSort, selectShowAll, selectTotalOrderCount, ListPageSize } from './confirmed-order-list-slice';
import { routes } from 'app/routes';
import { Button } from 'reactstrap';

export function OrderPaginator() {
  const dispatch = useDispatch(),
    listPath = routes.orders.path,
    page = useSelector(selectPage),
    salesperson = useSelector(selectSalesperson),
    catalogType = useSelector(selectCatalogType),
    week = useSelector(selectWeek),
    sort = useSelector(selectSort),
    showAll = useSelector(selectShowAll),
    totalOrderCount = useSelector(selectTotalOrderCount),
    pages = useSelector(selectPages),
    hasPages = totalOrderCount > ListPageSize;

  const handlePageClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, page: number) => {
    e.preventDefault();
    dispatch(setPage(page));
    dispatch(getConfirmedOrders({page, salesperson, catalogType, week, sort, showAll}));
  };

  const handleShowAllClick = () => {
    dispatch(setShowAll(!showAll));
  };

  return (    
    <nav className="ml-auto mr-auto">      
        <ul className="pagination pagination-lg justify-content-center">
        {pages > 1 &&
        <>
          <li className={`page-item ${page <= 1 ? 'disabled' : ''}`}>
            <Link className={`page-link ${page <= 1 ? '' : 'text-success border-success'}`} to={listPath} onClick={e => handlePageClick(e, 1)}>
              <FontAwesomeIcon icon={['fal', 'chevron-double-left']} />
            </Link>
          </li>
          <li className={`page-item ${page <= 1 ? 'disabled' : ''}`}>
            <Link className={`page-link ${page <= 1 ? '' : 'text-success border-success'}`} to={listPath} onClick={e => handlePageClick(e, page - 1)}>
              <FontAwesomeIcon icon={['fal', 'chevron-left']} />
            </Link>
          </li>
        </>
        }
          <li className="page-item">
            {pages > 1 &&
              <span className="text-muted text-nowrap w-auto mx-4" style={{fontSize: '125%'}}>{`Page ${page} of ${pages}`}</span>
            }
            {showAll &&
              <span className="text-muted text-nowrap w-auto mx-4" style={{fontSize: '125%'}}>{`Showing ${totalOrderCount} Orders`}</span>
            }
            {hasPages &&
              <Button color="link" className="text-success pt-0 w-100" onClick={handleShowAllClick}>
                {showAll ? 'SHOW PAGED' : 'SHOW ALL'}
              </Button>
            }
          </li>
        {pages > 1 &&
        <>
          <li className={`page-item ${page >= pages ? 'disabled' : ''}`}>
            <Link className={`page-link ${page >= pages ? '' : 'text-success border-success'}`} to={listPath} onClick={e => handlePageClick(e, page+1)}>
              <FontAwesomeIcon icon={['fal', 'chevron-right']} />
            </Link>
          </li>
          <li className={`page-item ${page >= pages ? 'disabled' : ''}`}>
            <Link className={`page-link ${page >= pages ? '' : 'text-success border-success'}`} to={listPath} onClick={e => handlePageClick(e, pages)}>
              <FontAwesomeIcon icon={['fal', 'chevron-double-right']} />
            </Link>
          </li>
        </>
        }
        </ul>
      </nav>
  );
}
