import {
  createSlice,
  createAsyncThunk,
  AsyncThunk,
  createAction,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  thriftysApi,
  ThriftysCreateSingleOrderResponse,
  ThriftysResponse,
} from 'api/thriftys-service';
import * as models from 'api/models/thriftys';
import { ThriftysOrder } from './single-order-excel-reader';
import { createProblemDetails, ProblemDetails } from 'utils/problem-details';
import { RootState } from 'app/store';
import { getWeek, Week } from 'api/models/weeks';
import moment from 'moment';

export interface ProductMatch {
  itemName: string;
  product: models.Product | null;
}

interface ThriftysSingleOrderState {
  isLoading: boolean;
  customers: models.Customer[];
  products: models.Product[];
  productMatches: ProductMatch[];
  weeks: Week[];
  order: ThriftysOrder | null;
  error: ProblemDetails | null;
}

const initialState: ThriftysSingleOrderState = {
  isLoading: false,
  customers: [],
  products: [],
  productMatches: [],
  weeks: [],
  order: null,
  error: null,
};

export const getHomeData: AsyncThunk<
  ThriftysResponse,
  void,
  { state: RootState }
> = createAsyncThunk(
  'thriftys-single/getHomeData',
  async (_, { rejectWithValue }) => {
    try {
      return await thriftysApi.homeData();
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const saveOrder: AsyncThunk<
  ThriftysCreateSingleOrderResponse,
  void,
  { state: RootState }
> = createAsyncThunk(
  'thriftys-single/saveOrder',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { order } = (getState() as RootState).thriftysSingleOrder;

      if (!order?.customer) {
        return rejectWithValue(
          createProblemDetails('Please choose the customer.')
        );
      }

      if (!!order?.items.some((i) => !i.product)) {
        return rejectWithValue(
          createProblemDetails(
            'Please ensure all items have the product selected.'
          )
        );
      }

      const model = {
        customerId: order.customer?.id || 0,
        orderYear: order.year,
        orderWeek: order.week,
        items: order.items.map((i) => ({
          productId: i.product?.id || 0,
          quantity: i.boxQuantity * (i.product?.packQuantity || 1),
        })),
      };

      return await thriftysApi.createSingleOrder(model);
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

const getHomeDataPending = createAction(getHomeData.pending.type),
  getHomeDataFulfilled = createAction<ThriftysResponse>(
    getHomeData.fulfilled.type
  ),
  getHomeDataRejected = createAction<ProblemDetails>(getHomeData.rejected.type),
  saveOrderPending = createAction(saveOrder.pending.type),
  saveOrderFulfilled = createAction<ThriftysCreateSingleOrderResponse>(
    saveOrder.fulfilled.type
  ),
  saveOrderRejected = createAction<ProblemDetails>(saveOrder.rejected.type);

interface SetItemPropertyArgs<T> {
  itemId: number;
  value: T;
}

export const thriftysSingleOrderSlice = createSlice({
  name: 'thriftys-single',
  initialState,
  reducers: {
    clearState(state) {
      state.error = null;
      state.order = null;
      state.productMatches = [];
    },
    clearError(state) {
      state.error = null;
    },
    setError(state, { payload }: PayloadAction<ProblemDetails>) {
      state.error = payload;
    },
    setOrder(state, { payload }: PayloadAction<ThriftysOrder>) {
      const { products, customers } = state,
        customer =
          customers.find(
            (c) => c.internalStoreNumber === payload.storeNumber
          ) || null,
        items = payload.items.map((i) => {
          const product = products.find((p) => p.upc === i.upc) || null;
          return { ...i, product };
        }),
        order = { ...payload, customer, items };

      state.order = order;
    },
    setCustomerId(state, { payload }: PayloadAction<number | null>) {
      if (state.order) {
        const customer = state.customers.find((c) => c.id === payload) || null,
          order = { ...state.order };

        order.customer = customer;
        order.customerName = customer?.name || '';
        order.storeNumber = customer?.internalStoreNumber || '';

        state.order = order;
      }
    },
    setWeek(state, { payload }: PayloadAction<Week>) {
      if (state.order) {
        const { year, weekNumber: week } = payload,
          order = { ...state.order, year, week };

        state.order = order;
      }
    },
    setItemProductId(
      state,
      { payload }: PayloadAction<SetItemPropertyArgs<number>>
    ) {
      if (state.order) {
        const { products } = state,
          order = { ...state.order },
          items = order.items.map((i) => ({ ...i })),
          item = items.find((i) => i.id === payload.itemId),
          product = products.find((p) => p.id === payload.value) || null;

        if (item) {
          item.itemName = product
            ? `${product.size} ${product.description1} ${product.description2}`
            : '';
          item.product = product;

          order.items = items;
          state.order = order;
        }
      }
    },
    setItemBoxQuantity(
      state,
      { payload }: PayloadAction<SetItemPropertyArgs<number>>
    ) {
      if (state.order) {
        const order = { ...state.order },
          items = order.items.map((i) => ({ ...i })),
          item = items.find((i) => i.id === payload.itemId);

        if (item) {
          item.boxQuantity = payload.value;

          order.items = items;
          state.order = order;
        }
      }
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getHomeDataPending, (state) => {
        state.isLoading = true;
      })
      .addCase(getHomeDataFulfilled, (state, { payload }) => {
        state.isLoading = false;
        const { customers, products } = payload;
        state.customers = customers;
        state.products = products;

        const weeks: Week[] = [],
          date = moment(),
          nextYear = new Date().getFullYear() + 1;
        while (date.year() <= nextYear) {
          weeks.push(getWeek(date.toDate()));
          date.add(1, 'week');
        }
        state.weeks = weeks;
      })
      .addCase(getHomeDataRejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(saveOrderPending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveOrderFulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (state.order) {
          const { orderId } = payload.order,
            order = { ...state.order, orderId };

          state.order = order;
        }
      })
      .addCase(saveOrderRejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      }),
});

export const {
  clearState,
  clearError,
  setError,
  setOrder,
  setCustomerId,
  setWeek,
  setItemProductId,
  setItemBoxQuantity,
} = thriftysSingleOrderSlice.actions;

export const selectIsLoading = (state: RootState) =>
  state.thriftysSingleOrder.isLoading;
export const selectError = (state: RootState) =>
  state.thriftysSingleOrder.error;
export const selectCustomers = (state: RootState) =>
  state.thriftysSingleOrder.customers;
export const selectProducts = (state: RootState) =>
  state.thriftysSingleOrder.products;
export const selectProductMatches = (state: RootState) =>
  state.thriftysSingleOrder.productMatches;
export const selectWeeks = (state: RootState) =>
  state.thriftysSingleOrder.weeks;
export const selectOrder = (state: RootState) =>
  state.thriftysSingleOrder.order;

export default thriftysSingleOrderSlice.reducer;
