import { createSlice, createAsyncThunk, AsyncThunk, createAction, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { thriftysApi, ThriftysCreateMultipleOrderResponse, ThriftysResponse } from 'api/thriftys-service';
import * as models from 'api/models/thriftys';
import { getWeek, Week } from 'api/models/weeks';
import { RootState } from 'app/store';
import { ProblemDetails } from 'utils/problem-details';
import { ThriftysCustomer, ThriftysItem } from './multiple-order-excel-reader';

export interface ProductMatch {
  itemName: string;
  product: models.Product | null;
}

interface ThriftysMultipleOrderState {
  isLoading: boolean;
  customers: models.Customer[];
  products: models.Product[];
  productMatches: ProductMatch[];
  weeks: Week[];
  thriftyItems: ThriftysItem[];
  thriftyCustomers: ThriftysCustomer[];
  error: ProblemDetails | null;
}

const initialState: ThriftysMultipleOrderState = {
  isLoading: false,
  customers: [],
  products: [],
  productMatches: [],
  weeks: [],
  thriftyItems: [],
  thriftyCustomers: [],
  error: null
};

export const getHomeData: AsyncThunk<ThriftysResponse, void, {state: RootState}> = createAsyncThunk(
  'thriftys-multiple/getHomeData',
  async(_, {rejectWithValue}) => {
    try {
      return await thriftysApi.homeData();

    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

interface SaveOrdersArgs {
  orders: models.OrderCreationOrder[];
}

export const saveOrders: AsyncThunk<ThriftysCreateMultipleOrderResponse, SaveOrdersArgs, {state: RootState}> = createAsyncThunk(
  'thriftys-multiple/saveOrders',
  async({orders}, {rejectWithValue}) => {
    try {
      
      return await thriftysApi.createMultipleOrders(orders);

    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

const getHomeDataPending = createAction(getHomeData.pending.type),
  getHomeDataFulfilled = createAction<ThriftysResponse>(getHomeData.fulfilled.type),
  getHomeDataRejected = createAction<ProblemDetails>(getHomeData.rejected.type),
  saveOrdersPending = createAction(saveOrders.pending.type),
  saveOrdersFulfilled = createAction<ThriftysCreateMultipleOrderResponse>(saveOrders.fulfilled.type),
  saveOrdersRejected = createAction<ProblemDetails>(saveOrders.rejected.type);

interface SetItemPropertyArgs<T> {
  itemNumber: string;
  value: T;
}

interface SetCustomerPropertyArgs<T> {
  storeNumber: string;
  value: T;
}

interface SetOrderItemPropertyArgs<T> extends SetItemPropertyArgs<T>, SetCustomerPropertyArgs<T> { }

export const thriftysMultipleOrderSlice = createSlice({
  name: 'thriftys-multiple',
  initialState,
  reducers: {
    clearState(state) {
      state.error = null;
      state.thriftyItems = [];
      state.thriftyCustomers = [];
      state.productMatches = [];
    },
    clearError(state) {
      state.error = null;
    },
    setError(state, {payload}: PayloadAction<ProblemDetails>) {
      state.error = payload;
    },
    setThriftyItems(state, {payload}: PayloadAction<ThriftysItem[]>) {
      const {products} = state,
        thriftyItems = payload.map(i => ({...i}));

      thriftyItems.forEach(item => {
        const upcProducts = products.filter(p => p.upc === item.upc);
        if(upcProducts.length === 1) {
          item.productId = upcProducts[0].id;
        }
      });
      state.thriftyItems = thriftyItems;
    },
    setThriftyCustomers(state, {payload}: PayloadAction<ThriftysCustomer[]>) {
      const {customers} = state,
        thriftyCustomers = payload.map(c => ({...c}));

      thriftyCustomers.forEach(customer => 
        customer.customerId = customers.find(c => c.internalStoreNumber === customer.storeNumber)?.id || null
      );
      state.thriftyCustomers = thriftyCustomers;
    },
    setCustomerId(state, {payload}: PayloadAction<SetCustomerPropertyArgs<number | null>>) {
      const thriftyCustomers = state.thriftyCustomers.map(c => ({...c})),
        customer = thriftyCustomers.find(c => c.storeNumber === payload.storeNumber);

      if(customer) {
        customer.customerId = payload.value;

        state.thriftyCustomers = thriftyCustomers;
      }
    },
    setItemWeek(state, {payload}: PayloadAction<SetItemPropertyArgs<Week>>) {
      const thriftyItems = state.thriftyItems.map(i => ({...i})),
        product = thriftyItems.find(p => p.itemNumber === payload.itemNumber);

      if(product) {
        const {year, weekNumber} = payload.value;
        product.year = year;
        product.weekNumber = weekNumber;

        state.thriftyItems = thriftyItems;
      }
    },
    setItemProductId(state, {payload}: PayloadAction<SetItemPropertyArgs<number | null>>) {
      const thriftyItems = state.thriftyItems.map(i => ({...i})),
        product = thriftyItems.find(p => p.itemNumber === payload.itemNumber);

      if(product) {
        product.productId = payload.value;

        state.thriftyItems = thriftyItems;
      }
    },
    setItemQuantity(state, {payload}: PayloadAction<SetOrderItemPropertyArgs<number>>) {
      const {storeNumber, itemNumber, value} = payload,
        thriftyCustomers = state.thriftyCustomers.map(c => ({...c})),
        customer = thriftyCustomers.find(c => c.storeNumber === storeNumber);

      if(customer) {
        const items = customer.items.map(i => ({...i})),
          item = items.find(i => i.productItemNumber === itemNumber);

        if(item) {
          item.quantity = value;
          customer.items = items;
          state.thriftyCustomers = thriftyCustomers;
        }
      }
    }
  },
  extraReducers: builder =>
    builder
      .addCase(getHomeDataPending, state => {
        state.isLoading = true;
      })
      .addCase(getHomeDataFulfilled, (state, {payload}) => {
        state.isLoading = false;
        const {customers, products} = payload;
        state.customers = customers;
        state.products = products;
        
        const weeks: Week[] = [],
          date = moment(),
          nextYear = new Date().getFullYear() + 1;
        while(date.year() <= nextYear) {
          weeks.push(getWeek(date.toDate()));
          date.add(1, 'week');
        }
        state.weeks = weeks;
      })
      .addCase(getHomeDataRejected, (state, {payload}) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(saveOrdersPending, (state) => {
        state.isLoading = true;        
      })
      .addCase(saveOrdersFulfilled, (state, {payload}) => {
        state.isLoading = false;
        const {orders} = payload,
          {thriftyItems} = state,
          thriftyCustomers = state.thriftyCustomers.map(c => ({...c}));

        thriftyCustomers.forEach(customer =>
          customer.items.forEach(i => {
            const thriftyItem = thriftyItems.find(ti => ti.itemNumber === i.productItemNumber);
            if(thriftyItem) {
              const order = orders.find(o => o.customerId === customer.customerId && o.orderWeek === thriftyItem.weekNumber && o.orderYear === thriftyItem.year);
              if(order) {
                i.orderId = order.orderId;
              }
            }
          })
        );
      })
      .addCase(saveOrdersRejected, (state, {payload}) => {
        state.isLoading = false;
        state.error = payload;
      })
});

export const {clearState, clearError, setError, setThriftyItems, setThriftyCustomers, setCustomerId, setItemWeek, setItemProductId, setItemQuantity} = thriftysMultipleOrderSlice.actions;

export const selectIsLoading = (state: RootState) => state.thriftysMultipleOrder.isLoading;
export const selectError = (state: RootState) => state.thriftysMultipleOrder.error;
export const selectCustomers = (state: RootState) => state.thriftysMultipleOrder.customers;
export const selectProducts = (state: RootState) => state.thriftysMultipleOrder.products;
export const selectProductMatches = (state: RootState) => state.thriftysMultipleOrder.productMatches;
export const selectWeeks = (state: RootState) => state.thriftysMultipleOrder.weeks;
export const selectThriftyItems = (state: RootState) => state.thriftysMultipleOrder.thriftyItems;
export const selectThriftyCustomers = (state: RootState) => state.thriftysMultipleOrder.thriftyCustomers;

export default thriftysMultipleOrderSlice.reducer;
