import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { User } from 'api/models/user';
import { ProblemDetails } from 'utils/problem-details';

export interface AuthState {
  user: User | null;
  error: ProblemDetails | null;
}

const initialState: AuthState = {
  user: null,
  error: null
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
    setError: (state, action: PayloadAction<ProblemDetails | null>) => {
      state.error = action.payload;
    }
  }
});

export const {setUser, setError} = authSlice.actions;

export const selectError = (state: RootState) => state.auth.error;
export const selectUser = (state: RootState) => state.auth.user;
export const selectAuthenticated = createSelector(
  selectUser,
  user => !!user
);

export default authSlice.reducer;
