import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  AsyncThunk,
  createAction,
  createSelector,
} from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import * as models from 'api/models/products';
import {
  productApi,
  PlantListResponse,
  PlantListArgs,
} from 'api/product-service';
import { ProblemDetails } from 'utils/problem-details';

export const ListPageSize = 25;

export interface PlantListState {
  page: number;
  totalPlantCount: number;
  search: string;
  category: string | null;
  categories: string[];
  plants: models.PlantListItem[] | null;
  includeInactive: boolean;
  loading: boolean;
  error: ProblemDetails | null;
}

const initialState: PlantListState = {
  page: 1,
  totalPlantCount: 0,
  search: '',
  category: null,
  categories: [],
  plants: null,
  includeInactive: false,
  loading: false,
  error: null,
};

export const getPlants: AsyncThunk<
  PlantListResponse,
  PlantListArgs,
  { state: RootState }
> = createAsyncThunk('plants/getPlants', async (args, { rejectWithValue }) => {
  try {
    return await productApi.plantList(args);
  } catch (e) {
    return rejectWithValue(e as ProblemDetails);
  }
});

export const getCategories: AsyncThunk<string[], void, { state: RootState }> =
  createAsyncThunk('plants/getCategories', async (_, { rejectWithValue }) => {
    try {
      return await productApi.plantCategories();
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  });

const getPlantsPending = createAction(getPlants.pending.type),
  getPlantsFullfilled = createAction<PlantListResponse>(
    getPlants.fulfilled.type
  ),
  getPlantsRejected = createAction<ProblemDetails>(getPlants.rejected.type),
  getCategoriesFulfilled = createAction<string[]>(getCategories.fulfilled.type),
  getCategoriesRejected = createAction<ProblemDetails>(
    getCategories.rejected.type
  );

const plantListSlice = createSlice({
  name: 'plants',
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setError(state, action: PayloadAction<ProblemDetails | null>) {
      state.error = action.payload;
    },
    setSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    setCategory(state, action: PayloadAction<string | null>) {
      const category = action.payload;
      if (category !== state.category) {
        state.category = category;
        state.page = 1;
      }
    },
    setIncludeInactive(state, action: PayloadAction<boolean>) {
      const includeInactive = action.payload;
      if (includeInactive !== state.includeInactive) {
        state.includeInactive = includeInactive;
        state.page = 1;
      }
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getPlantsPending, (state) => {
        state.loading = true;
      })
      .addCase(getPlantsFullfilled, (state, { payload }) => {
        state.loading = false;
        state.plants = payload.plants;
        state.totalPlantCount = payload.totalPlantCount;
      })
      .addCase(getPlantsRejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getCategoriesFulfilled, (state, { payload }) => {
        state.categories = payload;
      })
      .addCase(getCategoriesRejected, (state, { payload }) => {
        state.error = payload;
      }),
});

export const { setPage, setError, setSearch, setCategory, setIncludeInactive } =
  plantListSlice.actions;

export const selectPlants = ({ plantList }: RootState) => plantList.plants;
export const selectTotalPlantCount = ({ plantList }: RootState) =>
  plantList.totalPlantCount;
export const selectSearch = ({ plantList }: RootState) => plantList.search;
export const selectCategory = ({ plantList }: RootState) => plantList.category;
export const selectCategories = ({ plantList }: RootState) =>
  plantList.categories;
export const selectIncludeInactive = ({ plantList }: RootState) =>
  plantList.includeInactive;
export const selectPage = ({ plantList }: RootState) => plantList.page;
export const selectLoading = ({ plantList }: RootState) => plantList.loading;
export const selectError = ({ plantList }: RootState) => plantList.error;

export const selectPages = createSelector(
  selectTotalPlantCount,
  (plantCount) => {
    return plantCount < ListPageSize ? 0 : Math.ceil(plantCount / ListPageSize);
  }
);

export default plantListSlice.reducer;
