import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { customerService, useCallLogStatusesQuery } from 'api/customer-service';
import { CustomerListItem } from 'api/models/customers';
import { routes } from 'app/routes';
import { handleDropdownToggle, maxHeightModifiers } from 'utils/dropdown';
import { equals } from 'utils/equals';
import { selectYear, selectWeek } from './customer-list-slice';

interface ListItemProps {
  customer: CustomerListItem;
  refreshList: () => void;
}

export function ListItem({ customer, refreshList }: ListItemProps) {
  const year = useSelector(selectYear),
    week = useSelector(selectWeek),
    { data: callLogStatuses } = useCallLogStatusesQuery(),
    statusClass = equals(customer.callLogStatus, 'callback')
      ? 'danger'
      : equals(customer.callLogStatus, 'order')
      ? 'success'
      : 'secondary';

  const handleCallLogStatusClick = async (status: string | null) => {
    await customerService.setStatus(customer.id, year, week, status);
    refreshList();
  };

  return (
    <tr key={customer.id}>
      <td className="font-weight-bold">
        <Link to={routes.customerDetail.to(customer.id)}>
          {customer.shipName}
        </Link>
      </td>
      <td>{customer.contactName}</td>
      <td>{customer.workPhone}</td>
      <td>{customer.mobilePhone}</td>
      <td>{customer.emailAddress}</td>
      <td>{customer.salesRep}</td>
      <td>{customer.zone}</td>
      <td>{customer.territory}</td>
      <td>{customer.customerProductType}</td>
      <td>{customer.apf}</td>
      <td>
        <UncontrolledDropdown onToggle={handleDropdownToggle} className="w-100">
          <DropdownToggle caret color={statusClass} size="sm" className="w-100">
            {customer.callLogStatus || '--'}
          </DropdownToggle>
          <DropdownMenu modifiers={maxHeightModifiers()}>
            <DropdownItem onClick={() => handleCallLogStatusClick(null)}>
              --
            </DropdownItem>
            {callLogStatuses?.statuses.map((s) => (
              <DropdownItem key={s} onClick={() => handleCallLogStatusClick(s)}>
                {s}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    </tr>
  );
}
