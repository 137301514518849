import { createSlice, PayloadAction, createAsyncThunk, AsyncThunk, createAction, createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import { RootState } from 'app/store';
import { bookingCatalogApi, BookingCatalogListResponse } from 'api/booking-catalog-service';
import { BookingCatalogListItem, CatalogTypes } from 'api/models/booking-catalogs';
import { ProblemDetails } from 'utils/problem-details';

export interface OrderListState {
  year: number;
  catalogType: CatalogTypes | 'All',
  bookingCatalogs: BookingCatalogListItem[] | null;
  showPrevious: boolean;
  loading: boolean;
  error: ProblemDetails | null;
}

const initialState: OrderListState = {
  year: new Date().getFullYear(),
  catalogType: 'All',
  bookingCatalogs: null,
  showPrevious: false,
  loading: false,
  error: null
};

export const getBookingCatalogs: AsyncThunk<BookingCatalogListResponse, number, {state: RootState}> = createAsyncThunk(
  'booking-catalogs/getBookingCatalogs',
  async(year, {dispatch, rejectWithValue}) => {
    try {
      dispatch(bookingCatalogListSlice.actions.setYear(year));
      return await bookingCatalogApi.getList(year);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

const getBookingCatalogsPending = createAction(getBookingCatalogs.pending.type),
  getBookingCatalogsFulfilled = createAction<BookingCatalogListResponse>(getBookingCatalogs.fulfilled.type),
  getBookingCatalogsRejected = createAction<ProblemDetails>(getBookingCatalogs.rejected.type);

export const bookingCatalogListSlice = createSlice({
  name: 'booking catalogs',
  initialState,
  reducers: {
    setYear (state, action: PayloadAction<number>) {
      state.year = action.payload;
    },
    setCatalogType(state, action: PayloadAction<CatalogTypes | 'All'>) {
      state.catalogType = action.payload;
    },
    setShowPrevious(state, action: PayloadAction<boolean>) {
      state.showPrevious = action.payload;
    },
    setError (state, action: PayloadAction<ProblemDetails | null>) {
      state.error = action.payload;
    },
    setLoading (state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getBookingCatalogsPending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBookingCatalogsFulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.bookingCatalogs = action.payload.bookingCatalogs;
      })
      .addCase(getBookingCatalogsRejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const {setYear, setCatalogType, setShowPrevious, setLoading, setError} = bookingCatalogListSlice.actions;

export const selectBookingCatalogs = (state: RootState) => state.bookingCatalogs.bookingCatalogs;
export const selectYear = (state: RootState) => state.bookingCatalogs.year;
export const selectCatalogType = (state: RootState) => state.bookingCatalogs.catalogType;
export const selectShowPrevious = (state: RootState) => state.bookingCatalogs.showPrevious;
export const selectLoading = (state: RootState) => state.bookingCatalogs.loading;
export const selectError = (state: RootState) => state.bookingCatalogs.error;

const createFilter = (catalogType: CatalogTypes | 'All', showPrevious: boolean) =>
    (bookingCatalog: BookingCatalogListItem) => {
      if(!showPrevious) {
        if(moment().isAfter(bookingCatalog.availableTo)) {
          return false;
        }
      }

      if(catalogType === 'Cut Flowers' && bookingCatalog.catalogType !== 'Cut Flowers') {
        return false;
      }

      if(catalogType === 'Plants' && bookingCatalog.catalogType !== 'Plants') {
        return false;
      }

      if(catalogType === 'Plant Prebook' && bookingCatalog.catalogType !== 'Plant Prebook') {
        return false;
      }

      if(catalogType === 'Cut Flowers Prebook' && bookingCatalog.catalogType !== 'Cut Flowers Prebook') {
        return false;
      }

      return true;
    }

export const selectFilteredBookingCatalogs = createSelector(
  selectBookingCatalogs,
  selectCatalogType,
  selectShowPrevious,
  (bookingCatalogs, catalogType, showPrevious) => {
    return bookingCatalogs?.filter(createFilter(catalogType, showPrevious)) || null;
  }
);

export default bookingCatalogListSlice.reducer;
