import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { handleFocus } from 'utils/focus';
import { selectOrder, cubingUpdate } from './confirmed-order-detail-slice';

interface CubingModalProps {
  show: boolean;
  hide: () => void;
}

export function CubingModal(props: CubingModalProps) {
  const dispatch = useDispatch(),
    {show, hide} = props,
    order = useSelector(selectOrder),
    [cubes, setCubes] = useState(0),
    [boxCount, setBoxCount] = useState(0);

  const handleModalOpened = () => {
    setCubes(order?.cubing || 0);
    setBoxCount(order?.boxCount || 0);
  }

  const handleCubingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCubes(e.target.valueAsNumber || 0);
  };

  const handleBoxCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBoxCount(e.target.valueAsNumber || 0);
  };

  const handleSaveClick = () => {
    if(order) {
      const args = {id: order.id, cubes, boxCount};
      dispatch(cubingUpdate(args));
      hide();
    }
  };

  return (
    <Modal isOpen={show} toggle={hide} onOpened={handleModalOpened}>
      <ModalHeader toggle={hide}>Set Cubing &amp; Box Count</ModalHeader>
      <ModalBody>
        <label htmlFor="new-cubing">Cubes</label>
        <Input id="new-cubing" type="number" value={cubes} onChange={handleCubingChange} onFocus={handleFocus} />

        <label htmlFor="new-box-count">Box Count</label>
        <Input id="new-box-count" type="number" value={boxCount} onChange={handleBoxCountChange} onFocus={handleFocus} />
      </ModalBody>
      <ModalFooter>
        <Button onClick={hide}>Cancel</Button>
        <Button color="success" outline onClick={handleSaveClick}>
          Save
          &nbsp;
          <FontAwesomeIcon icon={['fad', 'save']} />
        </Button>
      </ModalFooter>
    </Modal>
  );
}
