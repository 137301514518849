import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoading, selectError, clearError, selectBookingCatalog, selectInventory } from './booking-catalog-detail-slice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Error } from 'features/errors/Error';
import { ProblemDetails } from 'utils/problem-details';

export interface SaveFeaturesArgs {
  featuredInventoryId: number | null;
  featuredInventoryDisplayText: string;
  special1Id: number | null;
  special2Id: number | null;
}

export interface FeaturesModalProps {
  isOpen: boolean;
  save: (args: SaveFeaturesArgs) => Promise<null | ProblemDetails>;
  hide: () => void;
}

export function FeaturesModal({isOpen, hide, save}: FeaturesModalProps) {
  const dispatch = useDispatch(),
    loading = useSelector(selectLoading),
    error = useSelector(selectError),
    bookingCatalog = useSelector(selectBookingCatalog),
    inventory = useSelector(selectInventory) || [],
    [featuredInventoryId, setFeaturedInventoryId] = useState<number | null>(null),
    [featuredInventoryDisplayText, setFeaturedInventoryDisplayText] = useState<string>(''),
    [special1Id, setSpecial1Id] = useState<number | null>(null),
    [special2Id, setSpecial2Id] = useState<number | null>(null),
    feature = inventory.find(i => i.id === featuredInventoryId),
    special1 = inventory.find(i => i.id === special1Id),
    special2 = inventory.find(i => i.id === special2Id);

  const handleModalOpened = () => {
    setFeaturedInventoryId(bookingCatalog?.featuredInventoryId || null);
    setFeaturedInventoryDisplayText(bookingCatalog?.featuredInventoryDisplayText || 'Planter Special');
    setSpecial1Id(bookingCatalog?.special1Id || null);
    setSpecial2Id(bookingCatalog?.special2Id || null);
  };

  const handleFeatureIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const featureId = parseInt(e.target.value) || null;
    setFeaturedInventoryId(featureId);
  };

  const handleFeatureTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setFeaturedInventoryDisplayText(text);
  };

  const handleSpecial1Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    const special = parseInt(e.target.value) || null;
    setSpecial1Id(special);
  };

  const handleSpecial2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    const special = parseInt(e.target.value) || null;
    setSpecial2Id(special);
  };

  const handleSaveClick = async () => {
    const args = {featuredInventoryId, featuredInventoryDisplayText, special1Id, special2Id},
      response = await save(args);

    if(!response) {
      hide();
    }
  };

  const handleClearError = () => {
    dispatch(clearError());
  }

  return (
    <Modal isOpen={isOpen} toggle={hide} onOpened={handleModalOpened} scrollable>
      <ModalHeader toggle={hide}>Booking Catalog Features</ModalHeader>
      <ModalBody>
        <div className="row">
          <label htmlFor="feature-id" className="col-12">Featured Product</label>
          <div className="col-12">
            <Input id="feature-id" type="select" value={featuredInventoryId || ''} onChange={handleFeatureIdChange}>
              <option value="">&mdash;</option>
              {inventory.map(item =>
                <option key={item.id} value={item.id}>{item.size} {item.description1} {item.description2} {item.colour}</option>
              )}
            </Input>
          </div>
        </div>
        <div className="row mt-2">
          {!!featuredInventoryId &&
            <img src={feature?.imageUrl || '/img/coming-soon.png'} alt="Featured product" style={{maxWidth: '75%'}} className="mx-auto mt-2" />
          }
        </div>
        <div className="row mt-2">
          <label htmlFor="feature-text" className="col-12">Feature Text</label>
          <div className="col-12">
            <Input id="feature-text" value={featuredInventoryDisplayText} onChange={handleFeatureTextChange} />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-6">
            <div className="row">
              <label htmlFor="special-1" className="col-12">Special #1</label>
              <div className="col-12">
                <Input id="special-1" type="select" value={special1Id || ''} onChange={handleSpecial1Change}>
                  <option value="">&mdash;</option>
                  {inventory.map(item =>
                    <option key={item.id} value={item.id}>{item.size} {item.description1} {item.description2} {item.colour}</option>
                  )}
                </Input>
              </div>

                {!!special1Id &&
                <div className="col-12">
                  <img src={special1?.imageUrl || '/img/coming-soon.png'} alt="Special product" style={{maxWidth: '75%'}} className="mx-auto mt-2" />
                </div>
                }
            </div>
          </div>
          <div className="col-6">
            <div className="row">            
              <label htmlFor="special-2" className="col-12">Special #2</label>
              <div className="col-12">
                <Input id="special-2" type="select" value={special2Id || ''} onChange={handleSpecial2Change}>
                  <option value="">&mdash;</option>
                  {inventory.map(item =>
                    <option key={item.id} value={item.id}>{item.size} {item.description1} {item.description2} {item.colour}</option>
                  )}
                </Input>
              </div>
              {!!special2Id &&
              <div className="col-12">
                <img src={special2?.imageUrl || '/img/coming-soon.png'} alt="Special product" style={{maxWidth: '75%'}} className="mx-auto mt-2" />
              </div>
              }
            </div>
          </div>
        </div>
        <Error error={error} clearError={handleClearError} />
      </ModalBody>
      <ModalFooter>
        <Button onClick={hide}>Cancel</Button>
        <Button color="success" outline disabled={loading} onClick={handleSaveClick}>
          Save
          &nbsp;
          <FontAwesomeIcon icon={['fad', 'save']} />
        </Button>
      </ModalFooter>
    </Modal>
  );
}
