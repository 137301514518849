import { useState } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomerNote } from 'api/models/customers';
import { formatDate } from 'utils/format';
import { NoteModal } from './NoteModal';

interface NoteItemProps {
  customerId: number;
  note: CustomerNote;
}

export function NoteItem({ customerId, note }: NoteItemProps) {
  const [editNote, setEditNote] = useState<CustomerNote | null>(null);

  const handleEditNoteClick = () => {
    setEditNote(note);
  };

  const handleEditNoteCancel = () => {
    setEditNote(null);
  };

  return (
    <div className="row mt-4">
      <div className="col-1 text-center">
        <Button
          outline
          color="link"
          size="sm"
          className="text-success"
          onClick={handleEditNoteClick}>
          <FontAwesomeIcon icon={['fal', 'edit']} />
        </Button>
      </div>
      <div className="col-11 font-weight-bold">
        {formatDate(note.dateCreated)}
      </div>
      <div className="col-11 offset-1 font-italic">{note.whoCreated}</div>
      <div className="col-11 offset-1 pre">{note.notes}</div>
      <NoteModal
        note={editNote}
        customerId={customerId}
        close={handleEditNoteCancel}
      />
    </div>
  );
}
