import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, CustomInput, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import numeral from 'numeral';
import { BookingCatalogInventoryWeekSelector } from './BookingCatalogItemEditModal';
import {
  selectBookingCatalog,
  selectEditItem,
} from './booking-catalog-detail-slice';
import { contains } from 'utils/equals';
import { handleFocus } from 'utils/focus';
import { createProblemDetails, ProblemDetails } from 'utils/problem-details';
import { isDate } from 'utils/is';
import { unitName } from 'utils/units';

interface BookingCatalogItemEditModalWeekProps {
  week: BookingCatalogInventoryWeekSelector;
  availableFrom: moment.Moment;
  availableTo: moment.Moment;
  setWeek: (week: BookingCatalogInventoryWeekSelector) => void;
}

export function BookingCatalogItemEditModalWeek(
  props: BookingCatalogItemEditModalWeekProps
) {
  const { week, availableFrom, availableTo, setWeek } = props,
    item = useSelector(selectEditItem),
    bookingCatalog = useSelector(selectBookingCatalog),
    now = new Date(),
    cutoffDate = week.availabilityCutoff
      ? moment.utc(week.availabilityCutoff).format('YYYY-MM-DD')
      : '',
    // we need to use today's date to avoid DST issues,
    // if the cutoff date is in DST & the current date isn't
    cutoffTime = week.availabilityCutoff
      ? moment
          .utc(week.availabilityCutoff)
          .year(now.getFullYear())
          .month(now.getMonth())
          .date(now.getDate())
          .local()
          .format('HH:mm')
      : '',
    useInventory = week.quantity != null,
    isCuts = contains(bookingCatalog?.catalogType, 'cut flower'),
    caseQuantity = (isCuts ? 1 : item?.caseQuantity) || 1,
    quantityInCases =
      week.quantity == null ? null : week.quantity / caseQuantity,
    maximumInCases =
      week.maximumOrderQuantity == null
        ? null
        : week.maximumOrderQuantity / caseQuantity,
    min = availableFrom.format('YYYY-MM-DD'),
    max = availableTo.format('YYYY-MM-DD');

  const setCutoffError = useCallback(
    (error: ProblemDetails | null) => {
      if (week.error?.title !== error?.title) {
        const updated = { ...week, error };
        setWeek(updated);
      }
    },
    [setWeek, week]
  );

  useEffect(() => {
    if (useInventory && !numeral(week.quantity).value()) {
      setCutoffError(null);
    } else if (!week.availabilityCutoff) {
      setCutoffError(null);
    } else if (availableTo.isBefore(week.availabilityCutoff)) {
      setCutoffError(
        createProblemDetails('Cutoff is after availability expires')
      );
    } else if (availableFrom.isAfter(week.availabilityCutoff)) {
      setCutoffError(
        createProblemDetails('Cutoff is before availability begins')
      );
    } else {
      setCutoffError(null);
    }
  }, [
    availableFrom,
    availableTo,
    useInventory,
    week,
    min,
    max,
    setCutoffError,
  ]);

  const handleCheckedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked,
      updated = { ...week, checked };

    if (checked) {
      const quantity = week.quantity == null ? caseQuantity : week.quantity;
      updated.quantity = quantity;
    } else {
      updated.quantity = null;
      updated.quantityMessage = null;
      updated.availabilityCutoff = null;
      updated.maximumOrderQuantity = null;
      updated.minimumOrderStemQuantityCuts = null;
    }

    setWeek(updated);
  };

  const handleUseInventoryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked,
      quantity = checked ? caseQuantity : null,
      updated = { ...week, quantity };
    setWeek(updated);
  };

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.valueAsNumber,
      quantity = isNaN(value) ? null : value * caseQuantity,
      updated = { ...week, quantity };
    setWeek(updated);
  };

  const handleQuantityMessageChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const quantityMessage = e.target.value || null,
      updated = { ...week, quantityMessage };
    setWeek(updated);
  };

  const handleMaxQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.valueAsNumber,
      maximumOrderQuantity = isNaN(value) ? null : value * caseQuantity,
      updated = { ...week, maximumOrderQuantity };
    setWeek(updated);
  };

  const handleMinStemsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.valueAsNumber,
      minimumOrderStemQuantityCuts = isNaN(value) ? null : value,
      updated = { ...week, minimumOrderStemQuantityCuts };
    setWeek(updated);
  };

  const handleAvailabilityCutoffDateChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.valueAsDate;
    if (isDate(value)) {
      const date = moment(value).utc(),
        m = week.availabilityCutoff
          ? moment.utc(week.availabilityCutoff)
          : moment.utc(),
        availabilityCutoff = m
          .clone()
          .year(date.year())
          .month(date.month())
          .date(date.date())
          .toISOString(),
        updated = { ...week, availabilityCutoff };
      setWeek(updated);
    } else {
      const updated = { ...week, availabilityCutoff: null };
      setWeek(updated);
    }
  };

  const handleAvailabilityCutoffTimeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const time = moment(e.target.value, 'HH:mm').utc();
    if (time.isValid()) {
      const m = week.availabilityCutoff
          ? moment.utc(week.availabilityCutoff)
          : moment.utc(),
        availabilityCutoff = m
          .clone()
          .hour(time.hour())
          .minute(time.minute())
          .toISOString(),
        updated = { ...week, availabilityCutoff };
      setWeek(updated);
    }
  };

  const handleClearCutoffClick = () => {
    const availabilityCutoff = null,
      updated = { ...week, availabilityCutoff };
    setWeek(updated);
  };

  return (
    <div className="border rounded p-2">
      <div className="row mb-2">
        <div className="col-12 font-weight-bold">
          <CustomInput
            id={`include-week-${week.weekId}`}
            type="checkbox"
            label={`Week ${week.weekNumber}`}
            checked={week.checked}
            onChange={handleCheckedChange}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-12">
          <label
            htmlFor={`use-inventory-${week.weekId}`}
            className="d-inline-block small">
            Fixed Inventory Quantity
          </label>
          &nbsp;
          <label
            className="custom-toggle custom-toggle-success mb--2 ml-2"
            htmlFor={`use-inventory-${week.weekId}`}>
            <input
              id={`use-inventory-${week.weekId}`}
              type="checkbox"
              checked={useInventory}
              onChange={handleUseInventoryChange}
              onFocus={handleFocus}
              disabled={!week.checked}
            />
            <span className="custom-toggle-slider rounded-circle" />
          </label>
        </div>
      </div>
      <div className="row small">
        <div className="col-6">
          {useInventory && (
            <>
              <label htmlFor={`quantity-${week.weekId}`}>
                Inventory
                {isCuts && !!item?.unitOfMeasureCuts && (
                  <em>&nbsp; ({unitName(item.unitOfMeasureCuts, 2)})</em>
                )}
              </label>
              <Input
                id={`quantity-${week.weekId}`}
                type="number"
                bsSize="sm"
                value={quantityInCases || ''}
                onChange={handleQuantityChange}
                onFocus={handleFocus}
                disabled={!week.checked}
                className="text-right"
              />
            </>
          )}
          {!useInventory && (
            <>
              <label htmlFor={`quantity-message-${week.weekId}`}>Message</label>
              <Input
                id={`quantity-message-${week.weekId}`}
                bsSize="sm"
                value={week.quantityMessage || ''}
                onChange={handleQuantityMessageChange}
                disabled={!week.checked}
                onFocus={handleFocus}
              />
            </>
          )}
        </div>
        <div className="col-6">
          <label htmlFor={`max-quantity-${week.weekId}`}>Max Order Qty</label>
          <Input
            id={`max-quantity-${week.weekId}`}
            type="number"
            bsSize="sm"
            value={maximumInCases || ''}
            onChange={handleMaxQuantityChange}
            disabled={!week.checked}
            onFocus={handleFocus}
            className="text-right"
          />
        </div>
      </div>
      {isCuts && (
        <div className="row small">
          <div className="col-6">
            <label htmlFor={`minimum-stems-${week.weekId}`}>
              Minimum Stem Qty
            </label>
            <Input
              id={`minimum-stems-${week.weekId}`}
              type="number"
              bsSize="sm"
              value={week.minimumOrderStemQuantityCuts || ''}
              onChange={handleMinStemsChange}
              disabled={!week.checked}
              onFocus={handleFocus}
              className="text-right"
            />
          </div>
        </div>
      )}
      <div className="row small">
        <div className="col-6">
          <label htmlFor={`cutoff-date-${week.weekId}`}>Cutoff Date</label>
          <Input
            id={`cutoff-date-${week.weekId}`}
            type="date"
            bsSize="sm"
            min={min}
            max={max}
            value={cutoffDate}
            onChange={handleAvailabilityCutoffDateChange}
            disabled={!week.checked}
            onFocus={handleFocus}
          />
        </div>
        <div className="col-6">
          <label htmlFor={`cutoff-time-${week.weekId}`}>Cutoff Time</label>
          <div className="input-group">
            <Input
              id={`cutoff-time-${week.weekId}`}
              type="time"
              bsSize="sm"
              value={cutoffTime}
              onChange={handleAvailabilityCutoffTimeChange}
              disabled={!week.checked}
              onFocus={handleFocus}
            />
            <div className="input-group-append">
              <Button
                id={`clear-cutoff-${week.weekId}`}
                size="sm"
                color="danger"
                outline
                onClick={handleClearCutoffClick}
                disabled={!week.checked}>
                <FontAwesomeIcon icon={['fal', 'times']} />
              </Button>
            </div>
          </div>
        </div>
      </div>
      {!!week.error && (
        <div className="text-danger font-weight-bold">{week.error.title}</div>
      )}
    </div>
  );
}
