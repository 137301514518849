import {
  createSlice,
  createAsyncThunk,
  AsyncThunk,
  createAction,
  PayloadAction,
  createSelector,
} from '@reduxjs/toolkit';
import moment from 'moment';
import { RootState } from 'app/store';
import {
  bookingCatalogApi,
  BookingCatalogDetailResponse,
  CreateBookingCatalogRequest,
  UpdateBookingCatalogRequest,
  BookingCatalogInventoryUpdate,
  UpdateBookingCatalogFeatureRequest,
  UpdateBookingCatalogAlertRequest,
  BookingCatalogInventoryResponse,
} from 'api/booking-catalog-service';
import {
  BookingCatalogDetail,
  BookingCatalogDetailInventory,
} from 'api/models/booking-catalogs';
import { weeksInYear } from 'api/models/weeks';
import { ProblemDetails } from 'utils/problem-details';
import { contains } from 'utils/equals';
import { Draft } from 'immer/dist/immer';

const IgnoreZeroPriceErrorKey = 'ignoreZeroPriceError';

export interface OrderDetailState {
  bookingCatalog: BookingCatalogDetail | null;
  inventory: BookingCatalogDetailInventory[] | null;
  editItem: BookingCatalogDetailInventory | null;
  editItemError: ProblemDetails | null;
  loading: boolean;
  sendingNotifications: boolean;
  error: ProblemDetails | null;
  zeroPriceError: ProblemDetails | null;
  ignoreZeroPriceError: boolean;
}

const initialState: OrderDetailState = {
  bookingCatalog: null,
  inventory: null,
  editItem: null,
  editItemError: null,
  loading: false,
  sendingNotifications: false,
  error: null,
  zeroPriceError: null,
  ignoreZeroPriceError: false,
};

export const getBookingCatalogDetail: AsyncThunk<
  BookingCatalogDetailResponse,
  number,
  { state: RootState }
> = createAsyncThunk(
  'booking-catalog-detail/getBookingCatalogDetail',
  async (id, { rejectWithValue }) => {
    try {
      return await bookingCatalogApi.getOne(id);
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const saveNewBookingCatalog: AsyncThunk<
  BookingCatalogDetailResponse,
  CreateBookingCatalogRequest,
  { state: RootState }
> = createAsyncThunk(
  'booking-catalog-detail/saveNewBookingCatalog',
  async (request, { rejectWithValue }) => {
    try {
      return await bookingCatalogApi.create(request);
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const updateBookingCatalog: AsyncThunk<
  BookingCatalogDetailResponse,
  UpdateBookingCatalogRequest,
  { state: RootState }
> = createAsyncThunk(
  'booking-catalog-detail/updateBookingCatalog',
  async (request, { rejectWithValue }) => {
    try {
      return await bookingCatalogApi.update(request);
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const deleteBookingCatalog: AsyncThunk<
  void,
  number,
  { state: RootState }
> = createAsyncThunk(
  'booking-catalog-detail/deleteBookingCatalog',
  async (id, { rejectWithValue }) => {
    try {
      return await bookingCatalogApi.deleteOne(id);
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const updateBookingCatalogInventory: AsyncThunk<
  BookingCatalogDetailResponse | null,
  BookingCatalogInventoryUpdate[],
  { state: RootState }
> = createAsyncThunk(
  'booking-catalog-detail/updateBookingCatalogInventory',
  async (inventory, { getState, rejectWithValue }) => {
    try {
      const state = (getState() as RootState).bookingCatalogDetail,
        bookingCatalog = state.bookingCatalog;
      if (bookingCatalog) {
        const request = { inventory };
        return await bookingCatalogApi.updateInventory(
          bookingCatalog.id,
          request
        );
      }

      return null;
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const sendBookingCatalogNotifications: AsyncThunk<
  BookingCatalogDetailResponse | null,
  void,
  { state: RootState }
> = createAsyncThunk(
  'booking-catalog-detail/sendBookingCatalogNotifications',
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState,
        bookingCatalog = state.bookingCatalogDetail.bookingCatalog;
      if (bookingCatalog) {
        return await bookingCatalogApi.notify(bookingCatalog.id);
      }

      return null;
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const updateBookingCatalogFeatures: AsyncThunk<
  BookingCatalogDetailResponse | null,
  UpdateBookingCatalogFeatureRequest,
  { state: RootState }
> = createAsyncThunk(
  'booking-catalog-detail/updateBookingCatalogFeatures',
  async (request, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState,
        bookingCatalog = state.bookingCatalogDetail.bookingCatalog;
      if (bookingCatalog) {
        return await bookingCatalogApi.updateFeatures(
          bookingCatalog.id,
          request
        );
      }

      return null;
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const updateBookingCatalogAlert: AsyncThunk<
  BookingCatalogDetailResponse | null,
  UpdateBookingCatalogAlertRequest,
  { state: RootState }
> = createAsyncThunk(
  'booking-catalog-detail/updateBookingCatalogAlert',
  async (request, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState,
        bookingCatalog = state.bookingCatalogDetail.bookingCatalog;
      if (bookingCatalog) {
        return await bookingCatalogApi.updateAlert(bookingCatalog.id, request);
      }

      return null;
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const getBookingCatalogInventory: AsyncThunk<
  BookingCatalogInventoryResponse | null,
  void,
  { state: RootState }
> = createAsyncThunk(
  'booking-catalog-detail/getBookingCatalogInventory',
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState,
        bookingCatalog = state.bookingCatalogDetail.bookingCatalog;
      if (bookingCatalog) {
        return await bookingCatalogApi.inventory(bookingCatalog.id);
      }

      return null;
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const addBookingCatalogInventory: AsyncThunk<
  BookingCatalogDetailResponse | null,
  BookingCatalogInventoryUpdate[],
  { state: RootState }
> = createAsyncThunk(
  'booking-catalog-detail/addBookingCatalogInventory',
  async (additions, { getState, rejectWithValue }) => {
    try {
      const state = (getState() as RootState).bookingCatalogDetail,
        bookingCatalog = state.bookingCatalog;
      if (bookingCatalog && state.inventory) {
        const isCuts = contains(bookingCatalog.catalogType, 'cut flower'),
          inventory = state.inventory
            .map((i) => ({ ...i }))
            // need to convert to cases
            .map((i) => {
              const caseQuantity = isCuts ? 1 : i.caseQuantity,
                quantity =
                  i.quantity == null ? null : i.quantity / caseQuantity,
                weeks = i.weeks.map((w) => ({ ...w }));
              return {
                productId: i.productId,
                quantity,
                maximumOrderQuantity:
                  i.maximumOrderQuantity == null
                    ? null
                    : i.maximumOrderQuantity / caseQuantity,
                minimumOrderStemQuantityCuts: i.minimumOrderStemQuantityCuts,
                availabilityCutoff: i.availabilityCutoff,
                quantityMessage: i.quantityMessage,
                weeks,
              };
            });

        for (const addition of additions) {
          const index = inventory.findIndex(
            (i) => i.productId === addition.productId
          );
          if (index === -1) {
            inventory.push({ ...addition, weeks: [] });
          } else {
            const item = inventory[index],
              quantity = addition.quantity,
              maximumOrderQuantity = addition.maximumOrderQuantity || null,
              minimumOrderStemQuantityCuts =
                addition.minimumOrderStemQuantityCuts || null;
            inventory.splice(index, 1, {
              ...item,
              quantity,
              maximumOrderQuantity,
              minimumOrderStemQuantityCuts,
            });
          }
        }

        const {
            id,
            name,
            catalogType,
            weekId,
            endWeekId,
            availableFrom,
            availableTo,
            notifyAutomatically,
          } = bookingCatalog,
          request = {
            id,
            name,
            catalogType,
            weekId,
            endWeekId,
            availableFrom,
            availableTo,
            notifyAutomatically,
            inventory,
          };
        return await bookingCatalogApi.updateInventory(
          bookingCatalog.id,
          request
        );
      }

      return null;
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export interface EditBookingCatalogInventoryArgs {
  item: BookingCatalogInventoryUpdate;
}

export const editBookingCatalogInventory: AsyncThunk<
  BookingCatalogDetailResponse | null,
  EditBookingCatalogInventoryArgs,
  { state: RootState }
> = createAsyncThunk(
  'booking-catalog-detail/editBookingCatalogInventory',
  async (args, { getState, rejectWithValue }) => {
    try {
      const { item } = args,
        {
          productId,
          quantity,
          quantityMessage,
          maximumOrderQuantity,
          minimumOrderStemQuantityCuts,
          availabilityCutoff,
        } = item,
        rootState = getState() as RootState,
        state = rootState.bookingCatalogDetail,
        bookingCatalog = state.bookingCatalog,
        year = rootState.bookingCatalogs.year,
        allWeeks = weeksInYear(year).concat(weeksInYear(year + 1));
      if (bookingCatalog && state.inventory) {
        const isCuts = contains(bookingCatalog.catalogType, 'cut flower'),
          inventoryItems = state.inventory.map((i) => ({ ...i })),
          index = inventoryItems.findIndex((i) => i.productId === productId);

        if (index !== -1) {
          const inventoryItem = inventoryItems[index],
            productId = inventoryItem.productId,
            today = moment(),
            availableWeeks = (item.weeks || []).map((w) => {
              const week = allWeeks.find((w2) => w2.id === w.weekId),
                year = week?.year || today.isoWeekYear(),
                weekNumber = week?.weekNumber || today.isoWeek(),
                value = { ...w, year, weekNumber, productId };
              return value;
            }),
            newProduct = {
              ...inventoryItem,
              quantity,
              quantityMessage,
              maximumOrderQuantity,
              minimumOrderStemQuantityCuts,
              availabilityCutoff,
              weeks: availableWeeks,
            };
          inventoryItems.splice(index, 1, newProduct);
        }

        const inventory = inventoryItems
          // convert to cases here
          .map((i) => {
            const caseQuantity = isCuts ? 1 : i.caseQuantity,
              quantity = i.quantity == null ? null : i.quantity / caseQuantity;
            return {
              productId: i.productId,
              quantity,
              maximumOrderQuantity:
                i.maximumOrderQuantity == null
                  ? null
                  : i.maximumOrderQuantity / caseQuantity,
              minimumOrderStemQuantityCuts: i.minimumOrderStemQuantityCuts,
              availabilityCutoff: i.availabilityCutoff,
              quantityMessage: i.quantityMessage,
              weeks: i.weeks,
            };
          });

        const {
            id,
            name,
            catalogType,
            weekId,
            endWeekId,
            availableFrom,
            availableTo,
            notifyAutomatically,
          } = bookingCatalog,
          request = {
            id,
            name,
            catalogType,
            weekId,
            endWeekId,
            availableFrom,
            availableTo,
            notifyAutomatically,
            inventory,
          };
        return await bookingCatalogApi.updateInventory(
          bookingCatalog.id,
          request
        );
      }

      return null;
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const removeBookingCatalogInventory: AsyncThunk<
  BookingCatalogDetailResponse | null,
  BookingCatalogDetailInventory,
  { state: RootState }
> = createAsyncThunk(
  'booking-catalog-detail/removeBookingCatalogInventory',
  async (item, { getState, rejectWithValue }) => {
    try {
      const state = (getState() as RootState).bookingCatalogDetail,
        { bookingCatalog, inventory } = state;
      if (bookingCatalog && inventory) {
        const isCuts = contains(bookingCatalog.catalogType, 'cut flower'),
          items = inventory.slice(),
          index = items.findIndex((i) => i.id === item.id);
        if (index !== -1) {
          items.splice(index, 1);

          const inventory = items
              // convert to cases here
              .map((i) => {
                const caseQuantity = isCuts ? 1 : i.caseQuantity,
                  quantity =
                    i.quantity == null ? null : i.quantity / caseQuantity,
                  weeks = i.weeks.map((w) => ({ ...w }));
                return {
                  productId: i.productId,
                  quantity,
                  maximumOrderQuantity:
                    i.maximumOrderQuantity == null
                      ? null
                      : i.maximumOrderQuantity / caseQuantity,
                  minimumOrderStemQuantityCuts: i.minimumOrderStemQuantityCuts,
                  availabilityCutoff: i.availabilityCutoff,
                  quantityMessage: i.quantityMessage,
                  weeks,
                };
              }),
            {
              id,
              name,
              catalogType,
              weekId,
              endWeekId,
              availableFrom,
              availableTo,
              notifyAutomatically,
            } = bookingCatalog,
            request = {
              id,
              name,
              catalogType,
              weekId,
              endWeekId,
              availableFrom,
              availableTo,
              notifyAutomatically,
              inventory,
            };
          return await bookingCatalogApi.updateInventory(
            bookingCatalog.id,
            request
          );
        }
      }

      return null;
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

const getBookingCatalogDetailPending = createAction(
    getBookingCatalogDetail.pending.type
  ),
  getBookingCatalogDetailFulfilled =
    createAction<BookingCatalogDetailResponse | null>(
      getBookingCatalogDetail.fulfilled.type
    ),
  getBookingCatalogDetailRejected = createAction<ProblemDetails>(
    getBookingCatalogDetail.rejected.type
  ),
  saveNewBookingCatalogPending = createAction(
    saveNewBookingCatalog.pending.type
  ),
  saveNewBookingCatalogFulfilled =
    createAction<BookingCatalogDetailResponse | null>(
      saveNewBookingCatalog.fulfilled.type
    ),
  saveNewBookingCatalogRejected = createAction<ProblemDetails>(
    saveNewBookingCatalog.rejected.type
  ),
  deleteBookingCatalogPending = createAction(deleteBookingCatalog.pending.type),
  deleteBookingCatalogFulfilled =
    createAction<BookingCatalogDetailResponse | null>(
      deleteBookingCatalog.fulfilled.type
    ),
  deleteBookingCatalogRejected = createAction<ProblemDetails>(
    deleteBookingCatalog.rejected.type
  ),
  updateInventoryPending = createAction(
    updateBookingCatalogInventory.pending.type
  ),
  updateInventoryFulfilled = createAction<BookingCatalogDetailResponse | null>(
    updateBookingCatalogInventory.fulfilled.type
  ),
  updateInventoryRejected = createAction<ProblemDetails>(
    updateBookingCatalogInventory.rejected.type
  ),
  updateFeaturesPending = createAction(
    updateBookingCatalogFeatures.pending.type
  ),
  updateFeaturesFulfilled = createAction<BookingCatalogDetailResponse | null>(
    updateBookingCatalogFeatures.fulfilled.type
  ),
  updateFeaturesRejected = createAction<ProblemDetails>(
    updateBookingCatalogFeatures.rejected.type
  ),
  updateAlertPending = createAction(updateBookingCatalogAlert.pending.type),
  updateAlertFulfilled = createAction<BookingCatalogDetailResponse | null>(
    updateBookingCatalogAlert.fulfilled.type
  ),
  updateAlertRejected = createAction<ProblemDetails>(
    updateBookingCatalogAlert.rejected.type
  ),
  addInventoryPending = createAction(addBookingCatalogInventory.pending.type),
  addInventoryFulfilled = createAction<BookingCatalogDetailResponse | null>(
    addBookingCatalogInventory.fulfilled.type
  ),
  addInventoryRejected = createAction<ProblemDetails>(
    addBookingCatalogInventory.rejected.type
  ),
  editBookingCatalogInventoryPending = createAction(
    editBookingCatalogInventory.pending.type
  ),
  editBookingCatalogInventoryFulfilled =
    createAction<BookingCatalogDetailResponse | null>(
      editBookingCatalogInventory.fulfilled.type
    ),
  editBookingCatalogInventoryRejected = createAction<ProblemDetails>(
    editBookingCatalogInventory.rejected.type
  ),
  sendBookingCatalogNotificationsPending = createAction(
    sendBookingCatalogNotifications.pending.type
  ),
  sendBookingCatalogNotificationsFulfilled =
    createAction<BookingCatalogDetailResponse | null>(
      sendBookingCatalogNotifications.fulfilled.type
    ),
  sendBookingCatalogNotificationsRejected = createAction<ProblemDetails>(
    sendBookingCatalogNotifications.rejected.type
  ),
  removeBookingCatalogInventoryPending = createAction(
    removeBookingCatalogInventory.pending.type
  ),
  removeBookingCatalogInventoryFulfilled =
    createAction<BookingCatalogDetailResponse | null>(
      removeBookingCatalogInventory.fulfilled.type
    ),
  removeBookingCatalogInventoryRejected = createAction<ProblemDetails>(
    removeBookingCatalogInventory.rejected.type
  ),
  getBookingCatalogInventoryFulfilled =
    createAction<BookingCatalogInventoryResponse | null>(
      getBookingCatalogInventory.fulfilled.type
    ),
  getBookingCatalogInventoryRejected = createAction<ProblemDetails>(
    getBookingCatalogInventory.rejected.type
  );

export const bookingCatalogDetailSlice = createSlice({
  name: 'booking catalog detail',
  initialState,
  reducers: {
    clearState(state) {
      state.loading = false;
      state.bookingCatalog = null;
      state.error = null;
      state.zeroPriceError = null;
      state.editItem = null;
    },
    setError(state, action: PayloadAction<ProblemDetails>) {
      state.error = action.payload;
    },
    clearError(state) {
      state.error = null;
    },
    clearZeroPriceError(state) {
      state.zeroPriceError = null;
    },
    clearEditItemError(state) {
      state.editItemError = null;
    },
    setEditItemError(state, action: PayloadAction<ProblemDetails>) {
      state.editItemError = action.payload;
    },
    setIgnoreZeroPriceError(state, action: PayloadAction<boolean>) {
      const ignore = action.payload,
        id = state.bookingCatalog?.id;
      state.ignoreZeroPriceError = ignore;

      if (ignore && id) {
        ignoreCatalog(id);
      }
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setEditItem(
      state,
      action: PayloadAction<BookingCatalogDetailInventory | null>
    ) {
      state.editItem = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBookingCatalogDetailPending, (state) => {
        state.loading = true;
      })
      .addCase(getBookingCatalogDetailFulfilled, handleBookingCatalogUpdate)
      .addCase(getBookingCatalogDetailRejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(saveNewBookingCatalogPending, (state) => {
        state.loading = true;
      })
      .addCase(saveNewBookingCatalogFulfilled, handleBookingCatalogUpdate)
      .addCase(saveNewBookingCatalogRejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteBookingCatalogPending, (state) => {
        state.loading = true;
      })
      .addCase(deleteBookingCatalogFulfilled, handleBookingCatalogUpdate)
      .addCase(deleteBookingCatalogRejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateInventoryPending, (state) => {
        state.loading = true;
      })
      .addCase(updateInventoryFulfilled, (state, { payload }) => {
        if (state.bookingCatalog && payload?.bookingCatalog) {
          const { inventory } = payload.bookingCatalog;
          state.loading = false;
          state.inventory = inventory;
        }
      })
      .addCase(updateInventoryRejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateFeaturesPending, (state) => {
        state.loading = true;
      })
      .addCase(updateFeaturesFulfilled, (state, action) => {
        if (state.bookingCatalog && action.payload?.bookingCatalog) {
          const {
              featuredInventoryId,
              featuredInventoryDisplayText,
              special1Id,
              special2Id,
              special1ImageUrl,
              special2ImageUrl,
            } = action.payload.bookingCatalog,
            bookingCatalog = {
              ...state.bookingCatalog,
              featuredInventoryId,
              featuredInventoryDisplayText,
              special1Id,
              special2Id,
              special1ImageUrl,
              special2ImageUrl,
            };
          state.loading = false;

          state.bookingCatalog = bookingCatalog;
        }
      })
      .addCase(updateFeaturesRejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateAlertPending, (state) => {
        state.loading = true;
      })
      .addCase(updateAlertFulfilled, (state, action) => {
        if (state.bookingCatalog && action.payload?.bookingCatalog) {
          const { alertTitle1, alertTitle2, alertDescription } =
              action.payload.bookingCatalog,
            bookingCatalog = {
              ...state.bookingCatalog,
              alertTitle1,
              alertTitle2,
              alertDescription,
            };
          state.loading = false;
          state.bookingCatalog = bookingCatalog;
        }
      })
      .addCase(updateAlertRejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addInventoryPending, (state) => {
        state.loading = true;
      })
      .addCase(addInventoryFulfilled, (state, { payload }) => {
        if (state.bookingCatalog && payload?.bookingCatalog) {
          const {
              inventory,
              featuredInventoryId,
              featuredInventoryImageUrl,
              special1Id,
              special1ImageUrl,
              special2Id,
              special2ImageUrl,
            } = payload.bookingCatalog,
            bookingCatalog = {
              ...state.bookingCatalog,
              featuredInventoryId,
              featuredInventoryImageUrl,
              special1Id,
              special1ImageUrl,
              special2Id,
              special2ImageUrl,
            };
          state.loading = false;
          state.inventory = inventory;
          state.bookingCatalog = bookingCatalog;
        }
      })
      .addCase(addInventoryRejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(editBookingCatalogInventoryPending, (state) => {
        state.loading = true;
      })
      .addCase(editBookingCatalogInventoryFulfilled, (state, { payload }) => {
        if (state.bookingCatalog && payload?.bookingCatalog) {
          const {
            inventory,
            featuredInventoryId,
            featuredInventoryImageUrl,
            special1Id,
            special1ImageUrl,
            special2Id,
            special2ImageUrl,
          } = payload.bookingCatalog;
          state.loading = false;
          state.inventory = inventory;
          state.bookingCatalog.featuredInventoryId = featuredInventoryId;
          state.bookingCatalog.featuredInventoryImageUrl =
            featuredInventoryImageUrl;
          state.bookingCatalog.special1Id = special1Id;
          state.bookingCatalog.special1ImageUrl = special1ImageUrl;
          state.bookingCatalog.special2Id = special2Id;
          state.bookingCatalog.special2ImageUrl = special2ImageUrl;
        }
      })
      .addCase(editBookingCatalogInventoryRejected, (state, action) => {
        state.loading = false;
        if (state.editItem) {
          state.editItemError = action.payload;
        } else {
          state.error = action.payload;
        }
      })
      .addCase(sendBookingCatalogNotificationsPending, (state) => {
        state.sendingNotifications = true;
      })
      .addCase(sendBookingCatalogNotificationsFulfilled, (state, action) => {
        state.sendingNotifications = false;
        if (state.bookingCatalog && action.payload?.bookingCatalog) {
          const { notificationEmailSent } = action.payload.bookingCatalog,
            bookingCatalog = { ...state.bookingCatalog, notificationEmailSent };
          state.loading = false;
          state.bookingCatalog = bookingCatalog;
        }
      })
      .addCase(sendBookingCatalogNotificationsRejected, (state, action) => {
        state.sendingNotifications = false;
        state.error = action.payload;
      })
      .addCase(removeBookingCatalogInventoryPending, (state) => {
        state.loading = true;
      })
      .addCase(removeBookingCatalogInventoryFulfilled, (state, { payload }) => {
        if (state.bookingCatalog && payload?.bookingCatalog) {
          const { inventory } = payload.bookingCatalog;
          state.loading = false;
          state.inventory = inventory;
        }
      })
      .addCase(removeBookingCatalogInventoryRejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getBookingCatalogInventoryFulfilled, (state, action) => {
        if (action.payload) {
          const inventoryMap = action.payload.inventory.reduce((memo, i) => {
              memo[i.productId] = i;
              return memo;
            }, {} as { [index: number]: BookingCatalogDetailInventory }),
            inventory = (state.inventory || []).map((i) => {
              const item = { ...i },
                updates = inventoryMap[i.productId];
              if (updates) {
                const {
                  quantityInCarts,
                  quantityOnOrders,
                  quantityOnWebOrders,
                } = updates;
                Object.assign(item, {
                  quantityInCarts,
                  quantityOnOrders,
                  quantityOnWebOrders,
                });
              }

              return item;
            });
          state.inventory = inventory;
        }
      })
      .addCase(getBookingCatalogInventoryRejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export const {
  setError,
  clearError,
  clearZeroPriceError,
  clearEditItemError,
  setEditItemError,
  setIgnoreZeroPriceError,
  setLoading,
  setEditItem,
  clearState,
} = bookingCatalogDetailSlice.actions;

export const selectBookingCatalog = (state: RootState) =>
  state.bookingCatalogDetail.bookingCatalog;
export const selectInventory = (state: RootState) =>
  state.bookingCatalogDetail.inventory;
export const selectLoading = (state: RootState) =>
  state.bookingCatalogDetail.loading;
export const selectError = (state: RootState) =>
  state.bookingCatalogDetail.error;
export const selectEditItemError = (state: RootState) =>
  state.bookingCatalogDetail.editItemError;
export const selectZeroPriceError = (state: RootState) =>
  state.bookingCatalogDetail.zeroPriceError;
export const selectIgnoreZeroPriceError = (state: RootState) =>
  state.bookingCatalogDetail.ignoreZeroPriceError;
export const selectEditItem = (state: RootState) =>
  state.bookingCatalogDetail.editItem;
export const selectSendingNotifications = (state: RootState) =>
  state.bookingCatalogDetail.sendingNotifications;

export const selectWeeks = createSelector(
  (state: RootState) => state.bookingCatalogs.year,
  (year) => weeksInYear(year).concat(weeksInYear(year + 1))
);

export default bookingCatalogDetailSlice.reducer;

function getIgnoredCatalogs(): number[] {
  if (!localStorage.getItem(IgnoreZeroPriceErrorKey)) {
    localStorage.setItem(IgnoreZeroPriceErrorKey, '[]');
  }

  const ignoredCatalogs: number[] = JSON.parse(
    localStorage.getItem(IgnoreZeroPriceErrorKey) || '[]'
  ) as number[];
  return ignoredCatalogs;
}

function ignoreCatalog(id: number) {
  const ignored = getIgnoredCatalogs();
  if (ignored.indexOf(id) === -1) {
    ignored.push(id);
    const json = JSON.stringify(ignored);
    localStorage.setItem(IgnoreZeroPriceErrorKey, json);
  }
}

function handleBookingCatalogUpdate(
  state: Draft<OrderDetailState>,
  action: PayloadAction<BookingCatalogDetailResponse | null>
) {
  if (action.payload?.bookingCatalog) {
    const { bookingCatalog } = action.payload;
    state.loading = false;
    state.inventory = bookingCatalog.inventory;
    if (!state.bookingCatalog) {
      state.bookingCatalog = bookingCatalog;
      const ignored = getIgnoredCatalogs();
      if (ignored.indexOf(bookingCatalog.id) !== -1) {
        state.ignoreZeroPriceError = true;
      }
    }
  }
}
