import { CustomerSalesWeekItem } from 'api/models/customers';
import { formatCurrency, formatNumber } from 'utils/format';

interface CrmSalesWeekItemProps {
  item: CustomerSalesWeekItem;
}

export function CrmSalesWeekItem({ item }: CrmSalesWeekItemProps) {
  return (
    <tr>
      <td>{item.category}</td>
      <td>{item.size}</td>
      <td>{item.description}</td>
      <td>{item.colour}</td>
      <td>{formatNumber(item.quantity)}</td>
      <td>{formatCurrency(item.sales)}</td>
    </tr>
  );
}
