import { createSlice, PayloadAction, createAsyncThunk, AsyncThunk, createAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { orderApi, SalespersonListResponse } from 'api/order-service';
import { ProblemDetails } from 'utils/problem-details';
import { Salesperson } from 'api/models/salesperson';
import { CartListItem } from 'api/models/carts';
import { cartApi, CartListResponse } from 'api/cart-service';

export const ListPageSize = 3;

export interface OrderListState {
  salesperson: string;
  carts: CartListItem[] | null;
  salespeople: Salesperson[] | null;
  loading: boolean;
  error: ProblemDetails | null;
}

const initialState: OrderListState = {
  salesperson: '',
  carts: null,
  salespeople: null,
  loading: false,
  error: null
};

export interface GetCartsArgs {
  salesperson: string;
}

export const getCarts: AsyncThunk<CartListResponse, GetCartsArgs, {state: RootState}> = createAsyncThunk(
  'orders/getCarts',
  async(args, {rejectWithValue}) => {
    try {
      const {salesperson} = args;
      return await cartApi.getList(salesperson);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const getSalespeople: AsyncThunk<SalespersonListResponse, void, {state: RootState}> = createAsyncThunk(
  'orders/getSalespeople',
  async(_, {rejectWithValue}) => {
    try {
      return await orderApi.salespeople();
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

const getCartsPending = createAction(getCarts.pending.type),
  getCartsFulfilled = createAction<CartListResponse>(getCarts.fulfilled.type),
  getCartsRejected = createAction<ProblemDetails>(getCarts.rejected.type),
  getSalespeopleFulfilled = createAction<SalespersonListResponse>(getSalespeople.fulfilled.type),
  getSalespeopleRejected = createAction<ProblemDetails>(getSalespeople.rejected.type);


export const cartSlice = createSlice({
  name: 'carts',
  initialState,
  reducers: {
    setSalesperson: (state, action: PayloadAction<string>) => {
      state.salesperson = action.payload;
    }
  },
  extraReducers: builder => 
    builder.addCase(getCartsPending, state => {
      state.loading = true;
      state.error = null;
    })
    .addCase(getCartsFulfilled, (state, action) => {
      state.error = null;
      state.loading = false;
      state.carts = action.payload.carts;
    })
    .addCase(getCartsRejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(getSalespeopleFulfilled, (state, action) => {
      state.salespeople = action.payload.salespeople;
    })
    .addCase(getSalespeopleRejected, (state, action) => {
      state.error = action.payload;
    })
});

export const { setSalesperson } = cartSlice.actions;

export const selectCarts = (state: RootState) => state.carts.carts;
export const selectSalesperson = (state: RootState) => state.carts.salesperson;
export const selectSalespeople = (state: RootState) => state.carts.salespeople;
export const selectLoading = (state: RootState) => state.carts.loading;
export const selectError = (state: RootState) => state.carts.error;

export default cartSlice.reducer;
