import { ApiBase } from './api-base';
import * as models from './models/calgary-coop';

class CalgaryCoopService extends ApiBase {
  homeData(): Promise<CalgaryCoopResponse> {
    return this.get('/api/orders/calgarycoop');
  }

  createOrders(
    orders: models.OrderCreationOrder[]
  ): Promise<CalgaryCoopCreateOrderResponse> {
    return this.post('/api/orders/calgarycoop', { orders });
  }
}

export interface CalgaryCoopResponse {
  customers: models.Customer[];
  products: models.Product[];
}

export interface CalgaryCoopCreateOrderResponse {
  orders: models.CreatedOrder[];
}

export const calgaryCoopApi = new CalgaryCoopService();
