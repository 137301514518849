import { useSelector } from 'react-redux';
import { Input } from 'reactstrap';
import { OrderDetailItem } from 'api/models/orders';
import { contains } from 'utils/equals';
import { formatCurrency } from 'utils/format';
import { selectOrder } from './confirmed-order-detail-slice';

interface DetailRowProps {
  item: OrderDetailItem;
}

export function DetailRow(props: DetailRowProps) {
  const { item } = props,
    order = useSelector(selectOrder),
    isCuts = contains(order?.catalogType, 'cut flower'),
    packQuantity = isCuts ? 1 : item.packQuantity,
    unitPrice = formatCurrency(item.unitPrice * packQuantity),
    quantity = item.quantity / packQuantity,
    extension = formatCurrency(quantity * item.unitPrice);

  return (
    <div className="row pt-2 mt-2 border-top">
      <div className="col-6">
        <Input
          value={`${item.size} ${item.description1} ${item.description2} (${item.colour})`}
          readOnly
          plaintext
          tabIndex={-1}
        />
      </div>
      <div className="col-2">
        <div className="row no-gutters">
          <div className="col">
            <Input value={quantity} readOnly className="text-right bg-white" />
          </div>
          <div className="col-2 ml-1 pt-3 small">{item.unitOfMeasure !== 'Ea' && item.unitOfMeasure}</div>
        </div>
      </div>
      <div className="col-2">
        <Input value={unitPrice} readOnly className="text-right bg-white quantity" />
      </div>
      <div className="col-2">
        <Input value={extension} readOnly className="text-right bg-white quantity border-0" tabIndex={-1} />
      </div>
      {!!item.comments && <div className="col-12 small font-italic">{item.comments}</div>}
    </div>
  );
}
