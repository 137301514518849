import { ApiBase } from './api-base';
import { UserDetail } from './models/users';

export interface CreateUserArgs {
  customerId: number;
  name: string;
  email: string;
  canOrderPlants: boolean;
  canOrderCutFlowers: boolean;
}

export interface UpdateUserArgs {
  id: number;
  name: string;
  email: string;
  password?: string;
  canOrderPlants: boolean;
  canOrderCutFlowers: boolean;
}

class UserService extends ApiBase {
  getDetail(id: number): Promise<UserResponse> {
    return this.get(`/api/users/${id}`);
  }

  createUser(args: CreateUserArgs): Promise<UserResponse> {
    return this.post('/api/users', args);
  }

  updateUser(args: UpdateUserArgs): Promise<UserResponse> {
    return this.put(`/api/users/${args.id}`, args);
  }

  disableUser(id: number): Promise<UserResponse> {
    return this.put(`/api/users/${id}/disable`);
  }

  enableUser(id: number): Promise<UserResponse> {
    return this.put(`/api/users/${id}/enable`);
  }

  addCustomer(id: number, customerId: number): Promise<UserResponse> {
    return this.put(`/api/users/${id}/customers/${customerId}`);
  }

  deleteCustomer(id: number, customerId: number): Promise<UserResponse> {
    return this.delete(`/api/users/${id}/customers/${customerId}`);
  }

  passwordReset(id: number): Promise<void> {
    return this.post(`/api/users/${id}/password-reset`);
  }
}

export interface UserResponse {
  user: UserDetail;
}

export const userApi = new UserService();
