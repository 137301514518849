import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import {
  faMediumM,
  faCreativeCommons,
  faFacebookF,
} from '@fortawesome/free-brands-svg-icons';

library.add(fal, fad, faMediumM, faCreativeCommons, faFacebookF);
