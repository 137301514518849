import { ApiBase } from './api-base';
import * as models from './models/thriftys';

class ThriftysService extends ApiBase {
  homeData(): Promise<ThriftysResponse> {
    return this.get('/api/orders/thriftys');
  }

  createSingleOrder(order: models.OrderCreationOrder): Promise<ThriftysCreateSingleOrderResponse> {
    return this.post('/api/orders/thriftys/single', {order});
  }

  createMultipleOrders(orders: models.OrderCreationOrder[]): Promise<ThriftysCreateMultipleOrderResponse> {
    return this.post('/api/orders/thriftys/multiple', {orders});
  }
}

export interface ThriftysResponse {
  customers: models.Customer[];
  products: models.Product[];
}

export interface ThriftysCreateSingleOrderResponse {
  order: models.CreatedOrderSingle;
}

export interface ThriftysCreateMultipleOrderResponse {
  orders: models.CreatedOrderMultiple[];
}

export const thriftysApi = new ThriftysService();
