import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import numeral from 'numeral';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getCartDetail,
  selectSubTotal,
  selectError,
  selectLoading,
  selectCart,
  clearError,
} from './cart-detail-slice';
import { formatDate, formatCurrency } from 'utils/format';
import { Input, Button } from 'reactstrap';
import { CartDetailRow } from './CartDetailRow';
import { routes } from 'app/routes';
import { Error } from 'features/errors/Error';

export function CartDetail() {
  const dispatch = useDispatch(),
    { id } = useParams<{ id: string }>(),
    idValue = numeral(id).value() || 0,
    loading = useSelector(selectLoading),
    error = useSelector(selectError),
    cart = useSelector(selectCart),
    subTotal = useSelector(selectSubTotal);

  useEffect(() => {
    dispatch(getCartDetail(idValue));
  }, [dispatch, idValue]);

  const toggleError = () => {
    dispatch(clearError());
  };

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col">
          <h1>
            <FontAwesomeIcon icon={['fad', 'shopping-cart']} />
            &nbsp; Cart Detail: {cart?.createdBy}
          </h1>
          <h2>
            {cart?.catalogName}
            &nbsp;
            <span className="font-italic">({cart?.catalogType})</span>
          </h2>
        </div>
        <div className="col-auto">
          <Button
            tag={Link}
            size="lg"
            outline
            color="success"
            to={routes.carts.path}
            className="px-5">
            Close
          </Button>
        </div>
      </div>
      {loading && (
        <h1 className="text-success text-center">
          &nbsp;
          <FontAwesomeIcon icon={['fad', 'spinner']} spin />
        </h1>
      )}
      <Error error={error} clearError={toggleError} />
      {!!cart && (
        <>
          <div className="row">
            <div className="col-12 col-md-6">
              <label className="form-control-label" htmlFor="customer">
                Customer
              </label>
              <Input
                id="customer"
                value={cart.customer}
                readOnly
                className="form-control-alternative bg-white"
                tabIndex={-1}
              />
            </div>
            <div className="col-12 col-md-6">
              <label className="form-control-label" htmlFor="createdBy">
                Placed By
              </label>
              <Input
                id="createdBy"
                value={cart.createdBy}
                readOnly
                className="form-control-alternative bg-white"
                tabIndex={-1}
              />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-12 col-md-6">
              <label className="form-control-label" htmlFor="createdOn">
                Order Date
              </label>
              <Input
                id="createdOn"
                value={`${formatDate(cart.lastUpdated)} @ ${formatDate(
                  cart.lastUpdated,
                  'h:mm a'
                )}`}
                readOnly
                className="form-control-alternative bg-white"
                tabIndex={-1}
              />
            </div>
            <div className="col-12 col-md-6">
              <label className="form-control-label" htmlFor="shipWeek">
                Ship Week
              </label>
              <Input
                id="createdOn"
                value={cart.shipWeek}
                readOnly
                className="form-control-alternative bg-white"
                tabIndex={-1}
              />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-12 col-md-6">
              <label className="form-control-label" htmlFor="poNumber">
                PO Number
              </label>
              <Input
                id="poNumber"
                value={cart.poNumber || ''}
                readOnly
                className="form-control form-control-alternative bg-white"
                tabIndex={-1}
              />
            </div>
            <div className="col-12 col-md-6">
              <label className="form-control-label" htmlFor="comments">
                Comments
              </label>
              <textarea
                rows={3}
                id="comments"
                value={cart.comments || '--'}
                readOnly
                className="form-control form-control-alternative bg-white"
                tabIndex={-1}></textarea>
            </div>
          </div>
          <div className="row">
            <div className="col font-weight-bold">Description</div>
            <div className="col-auto font-weight-bold">
              <Input
                value="Quantity"
                className="text-right quantity"
                readOnly
                plaintext
                tabIndex={-1}
              />
            </div>
            <div className="col-auto font-weight-bold">
              <Input
                value="Unit Price"
                className="text-right quantity"
                readOnly
                plaintext
                tabIndex={-1}
              />
            </div>
            <div className="col-auto font-weight-bold">
              <Input
                value="Total"
                className="text-right quantity"
                readOnly
                plaintext
                tabIndex={-1}
              />
            </div>
          </div>
          {cart.items.map((item) => (
            <CartDetailRow item={item} key={item.id} />
          ))}
          <div className="row border-top-double mt-2 pt-2">
            <div className="col">
              <Input
                value="Total"
                readOnly
                plaintext
                className="form-control-lg font-weight-bold"
                tabIndex={-1}
              />
            </div>
            <div className="col-auto">
              <Input
                value={formatCurrency(subTotal)}
                readOnly
                className="text-right bg-white quantity form-control-alternative font-weight-bold"
                tabIndex={-1}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
