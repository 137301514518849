import { createSlice, PayloadAction, createAsyncThunk, AsyncThunk, createAction, createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { orderApi, OrderListResponse, SalespersonListResponse } from 'api/order-service';
import { OrderListItem, OrderSort } from 'api/models/orders';
import { ProblemDetails } from 'utils/problem-details';
import { CatalogTypes } from 'api/models/booking-catalogs';
import { Salesperson } from 'api/models/salesperson';
import { getWeek, Week, weeksInYear } from 'api/models/weeks';

export const ListPageSize = 25;

export interface OrderListState {
  page: number;
  salesperson: string;
  catalogType: CatalogTypes | null,
  totalOrderCount: number;
  orders: OrderListItem[] | null;
  salespeople: Salesperson[] | null;
  week: Week | null;
  sort: OrderSort;
  showAll: boolean;
  loading: boolean;
  error: ProblemDetails | null;
}

const initialState: OrderListState = {
  page: 1,
  salesperson: '',
  catalogType: null,
  totalOrderCount: 0,
  orders: null,
  salespeople: null,
  week: getWeek(),
  sort: 'ShipDate Asc',
  showAll: false,
  loading: false,
  error: null
};

export interface GetOrderArgs {
  page: number;
  salesperson: string;
  catalogType: CatalogTypes | null;
  week: Week | null;
  sort: OrderSort;
  showAll: boolean;
}

export const getOrders: AsyncThunk<OrderListResponse, GetOrderArgs, {state: RootState}> = createAsyncThunk(
  'orders/getOrders',
  async(args, {rejectWithValue}) => {
    try {
      const {page, salesperson, catalogType, week, sort, showAll} = args;
      return await orderApi.getList(page, salesperson, catalogType, week, sort, showAll);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const getSalespeople: AsyncThunk<SalespersonListResponse, void, {state: RootState}> = createAsyncThunk(
  'orders/getSalespeople',
  async(_, {rejectWithValue}) => {
    try {
      return await orderApi.salespeople();
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

const getOrdersPending = createAction(getOrders.pending.type),
  getOrdersFulfilled = createAction<OrderListResponse>(getOrders.fulfilled.type),
  getOrdersRejected = createAction<ProblemDetails>(getOrders.rejected.type),
  getSalespeopleFulfilled = createAction<SalespersonListResponse>(getSalespeople.fulfilled.type),
  getSalespeopleRejected = createAction<ProblemDetails>(getSalespeople.rejected.type);

export const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setSalesperson(state, action: PayloadAction<string>) {
      state.salesperson = action.payload;
    },
    setCatalogType(state, action: PayloadAction<CatalogTypes | null>) {
      const catalogType = action.payload;
      if(catalogType !== state.catalogType) {
        state.catalogType = catalogType;
        state.page = 1;
      }
    },
    setWeek(state, action: PayloadAction<Week | null>) {
      const week = action.payload;
      if(week !== state.week) {
        state.week = week;
        state.page = 1;
      }
    },
    setSort(state, action: PayloadAction<OrderSort>) {
      state.sort = action.payload;
    },
    setShowAll(state, action: PayloadAction<boolean>) {
      const showAll = action.payload;
      if(showAll !== state.showAll) {
        state.showAll = showAll;
        state.page = 1;
      }
    }
  },
  extraReducers: builder =>
    builder.addCase(getOrdersPending, state => {
      state.loading = true;
      state.error = null;
    })
    .addCase(getOrdersFulfilled, (state, action) => {
      state.error = null;
      state.loading = false;
      state.orders = action.payload.orders;
      state.totalOrderCount = action.payload.totalOrderCount;
    })
    .addCase(getOrdersRejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(getSalespeopleFulfilled, (state, action) => {
      state.salespeople = action.payload.salespeople;
    })
    .addCase(getSalespeopleRejected, (state, action) => {
      state.error = action.payload;
    })
});

export const { setSalesperson, setCatalogType, setWeek, setPage, setSort, setShowAll } = orderSlice.actions;

export const selectOrders = (state: RootState) => state.orders.orders;
export const selectPage = (state: RootState) => state.orders.page;
export const selectSalesperson = (state: RootState) => state.orders.salesperson;
export const selectCatalogType = (state: RootState) => state.orders.catalogType;
export const selectSalespeople = (state: RootState) => state.orders.salespeople;
export const selectWeek = (state: RootState) => state.orders.week;
export const selectSort = (state: RootState) => state.orders.sort;
export const selectTotalOrderCount = (state: RootState) => state.orders.totalOrderCount;
export const selectShowAll = (state: RootState) => state.orders.showAll;
export const selectLoading = (state: RootState) => state.orders.loading;
export const selectError = (state: RootState) => state.orders.error;

export const selectPages = createSelector(
  selectTotalOrderCount,
  selectShowAll,
  (totalOrderCount, showAll) => {
    if(showAll) {
      return 0;
    }

    return totalOrderCount < ListPageSize ? 0 : Math.ceil(totalOrderCount / ListPageSize)
  }
);

export const selectWeeks = () => {
  const year = new Date().getFullYear();
  return weeksInYear(year - 1).concat(weeksInYear(year)).concat(weeksInYear(year + 1));
};

export default orderSlice.reducer;
