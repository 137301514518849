import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIntranetHome, selectLoaded } from 'features/home/home-slice';
import { Summary } from 'features/home/Summary';
import { PendingOrders } from 'features/home/PendingOrders';
import { ConfirmedOrders } from 'features/home/ConfirmedOrders';
import { BookingCatalogs } from 'features/home/BookingCatalogs';
import { Analytics } from 'features/home/Analytics';
import { FavouriteCustomers } from 'features/home/FavouriteCustomers';
import { Alerts } from 'features/home/Alerts';
import { Loading } from 'features/loading/Loading';

export function Home() {
  const dispatch = useDispatch(),
    loaded = useSelector(selectLoaded);

  useEffect(() => {
    function refresh() {
      dispatch(getIntranetHome());
    }

    refresh();
    let timer = window.setInterval(refresh, 1000 * 5);

    return function cleanup() {
      if (timer) {
        window.clearInterval(timer);
      }
    };
  }, [dispatch]);

  if (!loaded) {
    return <Loading />;
  }

  return (
    <div className="page-container-fluid">
      <div className="row">
        <div className="col-12 col-lg-6">
          <Summary />

          <div className="border p-2">
            <PendingOrders />
            <ConfirmedOrders />
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="border p-2 bg-white mb-4">
            <Analytics />
          </div>
          <div className="border p-2 bg-white mb-4">
            <BookingCatalogs />
          </div>
          <div className="border p-2 bg-white mb-4">
            <Alerts />
          </div>
          <div className="border p-2 bg-white">
            <FavouriteCustomers />
          </div>
        </div>
      </div>
    </div>
  );
}
