import { lazy } from 'react';
import { Home } from 'pages/Home';
import { Detail as OrderDetail } from 'features/orders/Detail';
import { List as BookingCatalogList } from 'features/booking-catalogs/List';
import { Detail as BookingCatalogDetail } from 'features/booking-catalogs/Detail';
import { New as BookingCatalogNew } from 'features/booking-catalogs/New';
import { Login } from 'features/auth/Login';
import { CartDetail } from 'features/carts/CartDetail';
import { List as CartList } from 'features/carts/List';
import { List as ConfirmedOrderList } from 'features/confirmed-orders/List';
import { Detail as ConfirmedOrderDetail } from 'features/confirmed-orders/Detail';
import { List as CustomerList } from 'features/customers/List';
import { Detail as CustomerDetail } from 'features/customers/Detail';
import { Analytics } from 'features/analytics/Analytics';

export const routes = {
  login: {
    path: '/login',
    component: Login,
  },
  home: {
    path: '/',
    component: Home,
  },
  orders: {
    path: '/orders',
    component: lazy(() => import('features/orders/List')),
  },
  orderDetail: {
    path: '/orders/:id',
    component: OrderDetail,
    to: (id: number) => `/orders/${id}`,
  },
  carts: {
    path: '/carts',
    component: CartList,
  },
  cartDetail: {
    path: '/carts/:id',
    component: CartDetail,
    to: (id: number) => `/carts/${id}`,
  },
  confirmedOrders: {
    path: '/confirmed-orders',
    component: ConfirmedOrderList,
  },
  confirmedOrderDetail: {
    path: '/confirmed-orders/:id',
    component: ConfirmedOrderDetail,
    to: (id: number) => `/confirmed-orders/${id}`,
  },
  bookingCatalogs: {
    path: '/booking-catalogs',
    component: BookingCatalogList,
  },
  bookingCatalogNew: {
    path: '/booking-catalogs/new',
    component: BookingCatalogNew,
  },
  bookingCatalogDetail: {
    path: '/booking-catalogs/:id',
    component: BookingCatalogDetail,
    to: (id: number) => `/booking-catalogs/${id}`,
  },
  customers: {
    path: '/customers',
    component: CustomerList,
  },
  customerDetail: {
    path: '/customers/:id',
    component: CustomerDetail,
    to: (id: number) => `/customers/${id}`,
  },
  analytics: {
    path: '/analytics',
    component: Analytics,
  },
  xero: {
    home: {
      path: '/xero',
      component: lazy(() => import('features/xero/Xero')),
    },
    login: {
      path: '/xero/login',
      component: lazy(() => import('features/xero/Login')),
    },
    callback: {
      path: '/xero/login-callback',
      component: lazy(() => import('features/xero/Callback')),
    },
    freight: {
      path: '/xero/freight',
      component: lazy(() => import('features/xero/Freight')),
    },
    wilma: {
      path: '/xero/wholesale',
      component: lazy(() => import('features/xero/Wilma')),
    },
    credits: {
      path: '/xero/credits',
      component: lazy(() => import('features/xero/Credits')),
    },
    pos: {
      path: '/xero/pos',
      component: lazy(() => import('features/xero/POS')),
    },
  },
  reports: {
    home: {
      path: '/reports',
      component: lazy(() => import('features/reports/Reports')),
    },
    wholesale: {
      salesByProvinceCustomer: {
        path: '/reports/sales-province-customer',
        component: lazy(
          () =>
            import(
              'features/reports/wholesale/WholesaleSalesByProvinceCustomer'
            )
        ),
      },
      salesCreditsYTDByCategory: {
        path: '/reports/sales-credits-ytd-by-category',
        component: lazy(
          () =>
            import(
              'features/reports/wholesale/WholesaleSalesCreditsYTDByCategory'
            )
        ),
      },
      salesCreditsYTDByCustomer: {
        path: '/reports/sales-credits-ytd-by-customer',
        component: lazy(
          () =>
            import(
              'features/reports/wholesale/WholesaleSalesCreditsYTDByCustomer'
            )
        ),
      },
      salesCreditsYTDBySalesperson: {
        path: '/reports/sales-credits-ytd-by-salesperson',
        component: lazy(
          () =>
            import(
              'features/reports/wholesale/WholesaleSalesCreditsYTDBySalesperson'
            )
        ),
      },
      salesByWeek: {
        path: '/reports/sales-by-week',
        component: lazy(
          () => import('features/reports/wholesale/WholesaleSalesByWeek')
        ),
      },
      weeklyMarkups: {
        path: '/reports/weekly-markups',
        component: lazy(
          () => import('features/reports/wholesale/WeeklyMarkups')
        ),
      },
    },
  },
  saveOnFoods: {
    home: {
      path: '/save-on-foods',
      component: lazy(() => import('features/save-on-foods/Home')),
    },
  },
  thriftys: {
    single: {
      path: '/thriftys/single',
      component: lazy(() => import('features/thriftys/Single')),
    },
    multiple: {
      path: '/thriftys/multiple',
      component: lazy(() => import('features/thriftys/Multiple')),
    },
  },
  marketOfChoice: {
    home: {
      path: '/market-of-choice',
      component: lazy(() => import('features/market-of-choice/Home')),
    },
  },
  calgaryCoop: {
    home: {
      path: '/calgary-coop',
      component: lazy(() => import('features/calgary-coop/Home')),
    },
  },
  fresonBros: {
    home: {
      path: '/freson-bros',
      component: lazy(() => import('features/freson-bros/Home')),
    },
  },
  products: {
    settings: {
      path: '/products/settings',
      component: lazy(() => import('features/products/Settings')),
    },
    plants: {
      list: {
        path: '/products/plants',
        component: lazy(() => import('features/products/plants/List')),
      },
      detail: {
        path: '/products/plants/:id',
        to: (id: number | 'new') => `/products/plants/${id}`,
        component: lazy(() => import('features/products/plants/Detail')),
      },
      sizes: {
        path: '/products/plants/sizes',
        component: lazy(() => import('features/products/plants/Sizes')),
      },
      products: {
        path: '/products/plants/products',
        component: lazy(() => import('features/products/plants/Products')),
      },
      upgrades: {
        path: '/products/plants/upgrades',
        component: lazy(() => import('features/products/plants/Upgrades')),
      },
      plants: {
        path: '/products/plants/plants',
        component: lazy(() => import('features/products/plants/Plants')),
      },
    },
  },
  alerts: {
    list: {
      path: '/alerts',
      component: lazy(() => import('features/alerts/List')),
    },
    detail: {
      path: '/alerts/:id',
      to: (id: number) => `/alerts/${id}`,
    },
  },
};
