import { ApiBase } from './api-base';
import { CatalogTypes } from './models/booking-catalogs';
import * as models  from './models/orders';
import { Salesperson } from './models/salesperson';
import { Week } from './models/weeks';

class OrderService extends ApiBase {
  getList(page: number, salesperson: string, catalogType: CatalogTypes | null, week: Week | null, sort: models.OrderSort, showAll: boolean): Promise<OrderListResponse> {
    const query = week ? `&week=${week.weekNumber}&year=${week.year}` : '';
    return this.get(`/api/orders?page=${page}&salesperson=${salesperson}&catalogType=${catalogType || ''}&sort=${sort}${query}${showAll ? '&showAll=true': ''}`);
  }

  getOrderDetail(id: number): Promise<OrderDetailResponse> {
    return this.get(`/api/orders/${id}`);
  }

  approveOrder(order: models.OrderDetail, caseCountCuts: number | null, cubesCuts: number | null): Promise<OrderApproveResponse> {
    const model = {id: order.id, commentsToCustomer: order.commentsToCustomer, caseCountCuts, cubesCuts, items: order.items};
    return this.post(`/api/orders/${order.id}/approve`, model);
  }

  cancelOrder(model: OrderCancellationModel): Promise<OrderCancelResponse> {
    return this.post(`/api/orders/${model.id}/cancel`, model);
  }

  salespeople(): Promise<SalespersonListResponse> {
    return this.get('/api/orders/salespeople');
  }

  cubing(id: number) : Promise<OrderCubingResponse> {
    return this.get(`/api/orders/${id}/cubing`);
  }
}

export interface OrderListResponse {
  totalOrderCount: number;
  orders: models.OrderListItem[];
}

export interface OrderDetailResponse {
  order: models.OrderDetail;
}

export interface OrderApproveResponse {
  order: models.OrderDetail;
}

export interface OrderCancelResponse {
  order: models.OrderDetail;
}

export interface SalespersonListResponse {
  salespeople: Salesperson[];
}

export interface OrderCancellationModel {
  id: number;
  commentsToCustomer: string | null;
}

export interface OrderCubingResponse {
  cubing: models.OrderCubing;
}

export const orderApi = new OrderService();
