import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { routes } from 'app/routes';
import { selectOrderSummary } from './home-slice';

export function Summary() {
  const orderSummary = useSelector(selectOrderSummary);

  return (
    <div className="card-deck">
      <Link to={routes.carts.path} className="card">
        <div className="card-header text-center">
          <FontAwesomeIcon icon={['fad', 'shopping-cart']} size="4x" />
        </div>
        <div className="card-body text-center">
          <h1 className="text-default">Shopping Carts:</h1>
          <div className="display-1 text-default">
            {orderSummary.shoppingCartCount}
          </div>
        </div>
      </Link>
      <Link className="card" to={routes.orders.path}>
        <div className="card-header text-center">
          <FontAwesomeIcon icon={['fad', 'usd-square']} size="4x" />
        </div>
        <div className="card-body text-center">
          <h1 className="text-default">Pending Orders:</h1>
          <div className="display-1 text-default">
            {orderSummary.webOrderCount}
          </div>
        </div>
      </Link>
      <Link className="card" to={routes.confirmedOrders.path}>
        <div className="card-header text-center">
          <FontAwesomeIcon icon={['fad', 'box-usd']} size="4x" />
        </div>
        <div className="card-body text-center">
          <h1 className="text-default">Confirmed Orders:</h1>
          <div className="display-1 text-default">
            {orderSummary.orderCount}
          </div>
        </div>
      </Link>
    </div>
  );
}
