import { ApiBase } from './api-base';
import * as models from './models/freson-bros';

class FresonBrosService extends ApiBase {
  homeData(): Promise<FresonBrosResponse> {
    return this.get('/api/orders/fresonbros');
  }

  createOrders(
    orders: models.OrderCreationOrder[]
  ): Promise<FresonBrosCreateOrderResponse> {
    return this.post('/api/orders/fresonbros', { orders });
  }
}

export interface FresonBrosResponse {
  customers: models.Customer[];
  products: models.Product[];
}

export interface FresonBrosCreateOrderResponse {
  orders: models.CreatedOrder[];
}

export const fresonBrosApi = new FresonBrosService();
