import { ApiBase } from './api-base';
import * as models from './models/market-of-choice';

class MarketOfChoiceService extends ApiBase {
  homeData(): Promise<MarketOfChoiceResponse> {
    return this.get('/api/orders/marketofchoice');
  }

  createOrders(
    orders: models.OrderCreationOrder[]
  ): Promise<MarketOfChoiceCreateOrderResponse> {
    return this.post('/api/orders/marketofchoice', { orders });
  }
}

export interface MarketOfChoiceResponse {
  customers: models.Customer[];
  products: models.Product[];
}

export interface MarketOfChoiceCreateOrderResponse {
  orders: models.CreatedOrder[];
}

export const marketOfChoiceApi = new MarketOfChoiceService();
