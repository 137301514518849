import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { routes } from 'app/routes';
import { WebOrderItem } from './WebOrderItem';
import { selectWebOrders } from './home-slice';

export function PendingOrders() {
  const webOrders = useSelector(selectWebOrders),
    limited = webOrders.slice(0, 5);

  return (
    <div className="overflow-auto-x">
      <a href={routes.orders.path} className="h2 btn btn-link">
        <FontAwesomeIcon icon={['fad', 'usd-square']} />
        &nbsp; Pending Orders
      </a>
      <div className="small">
        <div className="row no-gutters mb-1 pb-1 border-bottom font-weight-bold">
          <div className="col-2">&nbsp;</div>
          <div className="col-2 text-center">Order Type</div>
          <div className="col-2 text-center">
            Order Date / <br />
            Ship Week
          </div>
          <div className="col-4">
            Salesperson / <br />
            Customer
          </div>
          <div className="col-2 text-right">Order Total</div>
        </div>
        {limited.map((o) => (
          <WebOrderItem key={o.id} order={o} />
        ))}
        {!!webOrders.length && (
          <div className="row no-gutters">
            <div className="col text-center">
              Showing {limited.length} of {webOrders.length} Pending Orders
              &nbsp;
              <a href={routes.orders.path}>See All</a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
