import moment from 'moment';
import {
  createSlice,
  createAsyncThunk,
  AsyncThunk,
  createAction,
  PayloadAction,
  createSelector,
} from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import {
  CustomerDetailResponse,
  customerService,
  customerApi,
} from 'api/customer-service';
import { UserResponse } from 'api/user-service';
import { orderApi } from 'api/order-service';
import {
  CrmSummary,
  CustomerCallLogItem,
  CustomerDetail,
  CustomerOrder,
  CustomerSalesWeekItem,
  CustomerSalesYear,
  CustomerNote,
} from 'api/models/customers';
import { ProblemDetails } from 'utils/problem-details';
import {
  createUser,
  updateUser,
  enableUser,
  disableUser,
} from './customer-user-slice';
import { sortBy } from 'utils/sort';

const sortByWeekDescending = sortBy('week', 'descending');

export interface ExpandableWeek {
  week: number;
  expanded: boolean;
}

const years: number[] = [];
let y = moment().isoWeekYear();

// the first year of online orders
while (y >= 2021) {
  years.push(y);
  y--;
}

export interface CustomerDetailState {
  customer: CustomerDetail | null;
  summary: CrmSummary | null;
  callLog: CustomerCallLogItem[];
  salesYears: CustomerSalesYear[];
  salesWeekItems: CustomerSalesWeekItem[];
  salesWeeks: ExpandableWeek[];
  orders: CustomerOrder[];
  notes: CustomerNote[];
  year: number;
  week: number;
  apf: string[];
  callLogStatuses: string[];
  loading: boolean;
  error: ProblemDetails | null;
}

const initialState: CustomerDetailState = {
  customer: null,
  summary: null,
  callLog: [],
  salesYears: [],
  salesWeekItems: [],
  salesWeeks: [],
  orders: [],
  notes: [],
  year: moment().isoWeekYear(),
  week: moment().isoWeek(),
  apf: [],
  callLogStatuses: [],
  loading: false,
  error: null,
};

export const setMinimumExempt: AsyncThunk<
  CustomerDetailResponse,
  void,
  { state: RootState }
> = createAsyncThunk(
  'customerDetail/setMinimumExempt',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = (getState() as RootState).customerDetail,
        id = state.customer?.id || 0,
        isWebOrderMinimumExempt = !state.customer?.isWebOrderMinimumExempt;
      return await customerService.setMinimumExempt(
        id,
        isWebOrderMinimumExempt
      );
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const setCrmStatus: AsyncThunk<
  string | null,
  string | null,
  { state: RootState }
> = createAsyncThunk(
  'customerDetail/setCrmStatus',
  async (status, { rejectWithValue, getState }) => {
    try {
      const state = (getState() as RootState).customerDetail,
        id = state.customer?.id || 0,
        { year, week } = state;

      await customerService.setStatus(id, year, week, status);

      return status;
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const setIsFavourite: AsyncThunk<
  boolean,
  boolean,
  { state: RootState }
> = createAsyncThunk(
  'customerDetail/setIsFavourite',
  async (favourite, { rejectWithValue, getState }) => {
    try {
      const state = (getState() as RootState).customerDetail,
        id = state.customer?.id || 0;

      await customerService.setIsFavourite(id, favourite);

      return favourite;
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const setCustomerProductType: AsyncThunk<
  string,
  string,
  { state: RootState }
> = createAsyncThunk(
  'customerDetail/setCustomerProductType',
  async (type, { rejectWithValue, getState }) => {
    try {
      const state = (getState() as RootState).customerDetail,
        id = state.customer?.id || 0;

      await customerService.setCustomerProductType(id, type);

      return type;
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

interface OrderApprovalArgs {
  orderId: number;
  commentsToCustomer: string | null;
  caseCountCuts: number | null;
  cubesCuts: number | null;
}

export const approveOrder: AsyncThunk<
  number,
  OrderApprovalArgs,
  { state: RootState }
> = createAsyncThunk(
  'customerDetail/approveOrder',
  async (args, { rejectWithValue }) => {
    try {
      const { orderId, commentsToCustomer, caseCountCuts, cubesCuts } = args,
        { order } = await orderApi.getOrderDetail(orderId);

      order.commentsToCustomer = commentsToCustomer;

      await orderApi.approveOrder(order, caseCountCuts, cubesCuts);

      return args.orderId;
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

interface OrderCancellationArgs {
  orderId: number;
  commentsToCustomer: string | null;
}

export const cancelOrder: AsyncThunk<
  number,
  OrderCancellationArgs,
  { state: RootState }
> = createAsyncThunk(
  'customerDetail/cancelOrder',
  async (args, { rejectWithValue }) => {
    try {
      const { orderId, commentsToCustomer } = args,
        { order } = await orderApi.getOrderDetail(orderId);

      order.commentsToCustomer = commentsToCustomer;

      await orderApi.cancelOrder(order);

      return args.orderId;
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

const setMinimumExemptPending = createAction(setMinimumExempt.pending.type),
  setMinimumExemptFulfilled = createAction<CustomerDetailResponse>(
    setMinimumExempt.fulfilled.type
  ),
  setMinimumExemptRejected = createAction<ProblemDetails>(
    setMinimumExempt.rejected.type
  ),
  createUserFulfilled = createAction<UserResponse>(createUser.fulfilled.type),
  updateUserFulfilled = createAction<UserResponse>(updateUser.fulfilled.type),
  disableUserFulfilled = createAction<UserResponse>(disableUser.fulfilled.type),
  enableUserFulfilled = createAction<UserResponse>(enableUser.fulfilled.type),
  approveOrderPending = createAction(approveOrder.pending.type),
  approveOrderFulfilled = createAction<number>(approveOrder.fulfilled.type),
  approveOrderRejected = createAction<ProblemDetails>(
    approveOrder.rejected.type
  ),
  cancelOrderPending = createAction(cancelOrder.pending.type),
  cancelOrderFulfilled = createAction<number>(cancelOrder.fulfilled.type),
  cancelOrderRejected = createAction<ProblemDetails>(cancelOrder.rejected.type),
  setCrmStatusFulfilled = createAction<string | null>(
    setCrmStatus.fulfilled.type
  ),
  setCrmStatusRejected = createAction<ProblemDetails>(
    setCrmStatus.rejected.type
  ),
  setIsFavouriteFulfilled = createAction<boolean>(
    setIsFavourite.fulfilled.type
  ),
  setIsFavouriteRejected = createAction<ProblemDetails>(
    setIsFavourite.rejected.type
  ),
  setCustomerProductTypeFulfilled = createAction<string>(
    setCustomerProductType.fulfilled.type
  ),
  setCustomerProductTypeRejected = createAction<ProblemDetails>(
    setCustomerProductType.rejected.type
  );

interface ExpandWeekArgs {
  week: number;
  expanded: boolean;
}

export const customerDetailSlice = createSlice({
  name: 'customerDetail',
  initialState,
  reducers: {
    clearState: () => {
      return Object.assign({}, initialState);
    },
    clearError: (state) => {
      state.error = null;
    },
    clearCustomer: (state) => {
      state.customer = null;
    },
    setYear: (state, { payload }: PayloadAction<number>) => {
      state.year = payload;
    },
    setWeek: (state, { payload }: PayloadAction<number>) => {
      state.week = payload;
    },
    expandAllWeeks(state, { payload }: PayloadAction<boolean>) {
      const salesWeeks = state.salesWeeks.map((i) => ({
        ...i,
        expanded: payload,
      }));
      state.salesWeeks = salesWeeks;
    },
    expandWeek(state, { payload }: PayloadAction<ExpandWeekArgs>) {
      const salesWeeks = state.salesWeeks.map((i) => ({ ...i })),
        week = salesWeeks.find((w) => w.week === payload.week);

      if (week) {
        week.expanded = payload.expanded;
      }
      state.salesWeeks = salesWeeks;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setMinimumExemptPending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(setMinimumExemptFulfilled, (state, { payload }) => {
        state.loading = false;
        state.customer = payload.customer;
      })
      .addCase(setMinimumExemptRejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(createUserFulfilled, (state, { payload }) => {
        if (state.customer) {
          const { user } = payload,
            customer = state.customer,
            users = customer.users.slice().concat([user]);

          customer.users = users;
          state.customer = customer;
        }
        state.loading = false;
      })
      .addCase(updateUserFulfilled, (state, { payload }) => {
        if (state.customer) {
          const { user } = payload,
            customer = state.customer,
            users = customer.users.slice(),
            index = users.findIndex((u) => u.id === user.id);

          if (index !== -1) {
            users.splice(index, 1, user);
          }

          customer.users = users;
          state.customer = customer;
        }
        state.loading = false;
      })
      .addCase(disableUserFulfilled, (state, { payload }) => {
        if (state.customer) {
          const { user } = payload,
            customer = state.customer,
            users = customer.users.slice(),
            index = users.findIndex((u) => u.id === user.id);

          if (index !== -1) {
            users.splice(index, 1, user);
          }

          customer.users = users;
          state.customer = customer;
        }
        state.loading = false;
      })
      .addCase(enableUserFulfilled, (state, { payload }) => {
        if (state.customer) {
          const { user } = payload,
            customer = state.customer,
            users = customer.users.slice(),
            index = users.findIndex((u) => u.id === user.id);

          if (index !== -1) {
            users.splice(index, 1, user);
          }

          customer.users = users;
          state.customer = customer;
        }
        state.loading = false;
      })
      .addCase(approveOrderPending, (state) => {
        state.loading = true;
      })
      .addCase(approveOrderFulfilled, (state, { payload }) => {
        state.loading = false;
        const orders = state.orders.map((o) => ({ ...o })),
          order = orders.find((o) => o.webOrderId === payload);

        if (order) {
          order.status = 'Approved';
        }
      })
      .addCase(approveOrderRejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(cancelOrderPending, (state) => {
        state.loading = true;
      })
      .addCase(cancelOrderFulfilled, (state, { payload }) => {
        state.loading = false;
        const orders = state.orders.map((o) => ({ ...o })),
          order = orders.find((o) => o.webOrderId === payload);

        if (order) {
          order.status = 'Cancelled';
        }
      })
      .addCase(cancelOrderRejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(setCrmStatusFulfilled, (state, { payload }) => {
        const { year, week } = state,
          callLog = state.callLog.map((l) => ({ ...l })),
          current = callLog.find((c) => c.year === year && c.week === week);

        if (current) {
          current.status = payload;
        } else {
          callLog.push({ year, week, status: payload, notes: null, id: 0 });
        }

        state.callLog = callLog;
      })
      .addCase(setCrmStatusRejected, (state, { payload }) => {
        state.error = payload;
      })
      .addCase(setIsFavouriteFulfilled, (state, { payload }) => {
        if (state.customer) {
          const customer = { ...state.customer };
          customer.isFavourite = payload;
          state.customer = customer;
        }
      })
      .addCase(setIsFavouriteRejected, (state, { payload }) => {
        state.error = payload;
      })
      .addCase(setCustomerProductTypeFulfilled, (state, { payload }) => {
        if (state.customer) {
          const customer = { ...state.customer };
          customer.customerProductType = payload;
          state.customer = customer;
        }
      })
      .addCase(setCustomerProductTypeRejected, (state, { payload }) => {
        state.error = payload;
      })
      .addMatcher(customerApi.endpoints.detail.matchPending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addMatcher(
        customerApi.endpoints.detail.matchFulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.customer = payload.customer;
        }
      )
      .addMatcher(
        customerApi.endpoints.detail.matchRejected,
        (state, { payload }) => {
          state.loading = false;
          if (payload) {
            state.error = payload;
          }
        }
      )
      .addMatcher(
        customerApi.endpoints.crm.matchFulfilled,
        (state, { payload }) => {
          state.summary = payload.summary;
          state.callLog = payload.callLog;
          state.salesYears = payload.salesYears;
          state.salesWeekItems = payload.salesWeekItems;
          state.salesWeeks = payload.salesWeekItems
            .filter((s) => s.year === state.year)
            .map((s) => s.week)
            .filter((value, index, self) => self.indexOf(value) === index)
            .sort((a, b) => a - b)
            .map((week) => ({ week, expanded: false }));
        }
      )
      .addMatcher(
        customerApi.endpoints.orders.matchFulfilled,
        (state, { payload }) => {
          state.orders = payload.orders;
        }
      )
      .addMatcher(
        customerApi.endpoints.notes.matchFulfilled,
        (state, { payload }) => {
          state.notes = payload.notes;
        }
      )
      .addMatcher(
        customerApi.endpoints.apf.matchFulfilled,
        (state, { payload }) => {
          state.apf = payload.apf;
        }
      )
      .addMatcher(
        customerApi.endpoints.callLogStatuses.matchFulfilled,
        (state, { payload }) => {
          state.callLogStatuses = payload.statuses;
        }
      );
  },
});

export const {
  clearState,
  clearError,
  clearCustomer,
  setYear,
  setWeek,
  expandWeek,
  expandAllWeeks,
} = customerDetailSlice.actions;

export const selectCustomer = ({ customerDetail }: RootState) =>
  customerDetail.customer;
export const selectSummary = ({ customerDetail }: RootState) =>
  customerDetail.summary;
export const selectCallLogList = ({ customerDetail }: RootState) =>
  customerDetail.callLog;
export const selectSalesYears = ({ customerDetail }: RootState) =>
  customerDetail.salesYears;
export const selectSalesWeekItems = ({ customerDetail }: RootState) =>
  customerDetail.salesWeekItems;
export const selectSalesWeeks = ({ customerDetail }: RootState) =>
  customerDetail.salesWeeks.map((w) => ({ ...w })).sort(sortByWeekDescending);
export const selectYears = () => years;
export const selectOrders = ({ customerDetail }: RootState) =>
  customerDetail.orders;
export const selectNotes = ({ customerDetail }: RootState) =>
  customerDetail.notes;
export const selectYear = ({ customerDetail }: RootState) =>
  customerDetail.year;
export const selectWeek = ({ customerDetail }: RootState) =>
  customerDetail.week;
export const selectApf = ({ customerDetail }: RootState) => customerDetail.apf;
export const selectCallLogStatuses = ({ customerDetail }: RootState) =>
  customerDetail.callLogStatuses;
export const selectError = ({ customerDetail }: RootState) =>
  customerDetail.error;
export const selectLoading = ({ customerDetail }: RootState) =>
  customerDetail.loading;

export const selectWeeks = createSelector(selectYear, (year) => {
  const length = moment().isoWeekYear(year).isoWeeksInISOWeekYear(),
    weeks = Array.from({ length }, (_, i) => i + 1);

  return weeks;
});

export const selectCallLog = createSelector(
  selectCallLogList,
  selectYear,
  selectWeek,
  (callLogs, year, week) =>
    callLogs.find((l) => l.week === week) || {
      id: 0,
      year,
      week,
      status: null,
      notes: null,
    }
);

export default customerDetailSlice.reducer;
