import { useSelector } from 'react-redux';
import { Input, InputGroup } from 'reactstrap';
import { CartDetailItem } from 'api/models/carts';
import { formatCurrency } from 'utils/format';
import { selectCart } from './cart-detail-slice';
import { contains } from 'utils/equals';

interface CartDetailRowProps {
  item: CartDetailItem;
}

export function CartDetailRow(props: CartDetailRowProps) {
  const {item} = props,
    cart = useSelector(selectCart),
    isCuts = contains(cart?.catalogType, 'cut flower'),
    packQuantity = isCuts ? 1 : item.packQuantity,
    unitPrice = item.unitPrice * packQuantity,
    quantity = item.quantity / packQuantity,
    extension = quantity * unitPrice;

  return (
    <div className="row pt-2 mt-2 border-top">
      <div className="col">
        <Input value={`${item.size} ${item.description1} ${item.description2} (${item.colour})`} readOnly plaintext tabIndex={-1} />
      </div>
      <div className="col-auto">
        <InputGroup>
          <Input value={quantity} readOnly className="text-right quantity bg-white form-control-alternative" tabIndex={-1} />
          {item.unitOfMeasure !== 'Ea' &&
            <span className="pt-3 small">&nbsp;{item.unitOfMeasure}</span>
          }
        </InputGroup>
      </div>
      <div className="col-auto">
        <Input value={formatCurrency(unitPrice)} readOnly className="text-right quantity bg-white form-control-alternative" tabIndex={-1} />
      </div>
      <div className="col-auto">
        <Input value={formatCurrency(extension)} readOnly className="text-right quantity bg-white form-control-alternative" tabIndex={-1} />
      </div>
    </div>
  );
}
