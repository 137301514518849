import { Amounts } from 'features/xero/amounts';
import moment from 'moment';
import { ApiBase } from './api-base';
import * as models from './models/xero';

class XeroService extends ApiBase {
  configuration(): Promise<XeroConfigResponse> {
    return this.get('/api/xero/_configuration');
  }

  token(code: string, state: string): Promise<LoginResponse> {
    return this.get(`/api/xero/token?code=${code}&state=${state}`);
  }

  freightInvoices(weekId: string): Promise<FreightInvoicesResponse> {
    return this.get(`/api/xero/freight/invoices?week=${weekId}`);
  }

  wilmaInvoices(date: Date): Promise<WilmaOrdersResponse> {
    return this.get(`/api/xero/wholesale/invoices?date=${moment(date).format('YYYY-MM-DD')}`);
  }

  wilmaCredits(): Promise<WilmaCreditsResponse> {
    return this.get('/api/xero/wholesale/credits');
  }

  postPOSToXero(token: string, tenantId: string, amounts: Amounts): Promise<models.InvoiceProcessedResult> {
    return this.post('/api/xero/POS/invoices', {token, tenantId, amounts});
  }
}

export interface LoginResponse {
  token: models.XeroToken;
}

export interface XeroConfigResponse {
  authenticationPath: string;
}

export interface FreightInvoicesResponse {
  invoices: FreightUnpostedInvoice[];
}

export interface WilmaOrdersResponse {
  orders: WilmaUnpostedOrder[];
}

export interface WilmaCreditsResponse {
  credits: WilmaUnpostedCredit[];
}

export interface FreightUnpostedInvoice {
  invoiceId: number;
  billingId: string;
  companyName: string;
  invoiceDate: string;
  fuelSurchargeRate: number | null;
  fuelSurcharge: number | null;
  salesTaxGroup: string;
  terms: string;
  customerId: number | null;
  customerXeroId: string;
  isNotCanadian: boolean;
  emailAddress: string;
  invoiceTotal: number;
}

export interface WilmaUnpostedOrder {
  orderId: number;
  billingId: string;
  companyName: string;
  invoiceDate: string;
  customerPo: number | null;
  salesTaxGroup: string;
  terms: string;
  customerId: number | null;
  customerXeroId: string;
  isNotCanadian: boolean;
  emailAddress: string;
  shipTo: string | null;
  cutCount: number;
  plantCount: number;
  invoiceTotal: number;
}

export interface WilmaUnpostedCredit {
  orderCreditId: number;
  billingId: string;
  companyName: string;
  invoiceCreditDate: string;
  customerPo: number | null;
  salesTaxGroup: string;
  terms: string;
  customerId: number | null;
  customerXeroId: string;
  isNotCanadian: boolean;
  emailAddress: string;
  cutCount: number;
  plantCount: number;
  creditTotal: number;
}

export const xeroApi = new XeroService();
