import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { routes } from 'app/routes';
import { selectAuthenticated } from 'features/auth/auth-slice';
import { authService } from 'features/auth/authorization-service';

export function NavMenu() {
  const [isOpen, setIsOpen] = useState(false),
    toggle = () => setIsOpen(!isOpen),
    authenticated = useSelector(selectAuthenticated);

  const handleLogoutClick = () => {
    authService.logout();
  };

  return (
    <header>
      <Navbar expand="md" dark fixed="top" className={isOpen ? 'expanded' : ''}>
        <NavbarBrand to={routes.home.path} tag={Link}>
          <FontAwesomeIcon icon={['fad', 'home']} />
          &nbsp; Home
        </NavbarBrand>
        {authenticated && (
          <>
            <NavbarToggler onClick={toggle} id="navbar-toggler">
              <FontAwesomeIcon icon={['fal', 'bars']} />
            </NavbarToggler>
            <Collapse isOpen={isOpen} navbar>
              <Nav className="mr-auto" navbar>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    <FontAwesomeIcon
                      icon={['fal', 'flower-daffodil']}
                      fixedWidth
                    />
                    &nbsp; Products
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      to={routes.products.plants.list.path}
                      tag={Link}>
                      <FontAwesomeIcon
                        icon={['fal', 'flower-daffodil']}
                        fixedWidth
                      />
                      &nbsp; Plants
                    </DropdownItem>
                    <DropdownItem to={routes.products.settings.path} tag={Link}>
                      <FontAwesomeIcon icon={['fal', 'cog']} fixedWidth />
                      &nbsp; Settings
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <NavItem>
                  <NavLink to={routes.bookingCatalogs.path} tag={Link}>
                    <FontAwesomeIcon icon={['fad', 'atlas']} />
                    &nbsp; Booking Catalogs
                  </NavLink>
                </NavItem>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    <FontAwesomeIcon icon={['fad', 'users']} />
                    &nbsp; Sales
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem to={routes.customers.path} tag={Link}>
                      <FontAwesomeIcon icon={['fad', 'users']} />
                      &nbsp; Customers
                    </DropdownItem>
                    <DropdownItem to={routes.alerts.list.path} tag={Link}>
                      <FontAwesomeIcon icon={['fad', 'bell']} fixedWidth />
                      &nbsp; Alerts
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    <FontAwesomeIcon icon={['fal', 'box-open']} fixedWidth />
                    &nbsp; Order Management
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem to={routes.carts.path} tag={Link}>
                      <FontAwesomeIcon
                        icon={['fad', 'shopping-cart']}
                        fixedWidth
                      />
                      &nbsp; Shopping Carts
                    </DropdownItem>
                    <DropdownItem to={routes.orders.path} tag={Link}>
                      <FontAwesomeIcon
                        icon={['fad', 'usd-square']}
                        fixedWidth
                      />
                      &nbsp; Pending Orders
                    </DropdownItem>
                    <DropdownItem to={routes.confirmedOrders.path} tag={Link}>
                      <FontAwesomeIcon icon={['fad', 'box-usd']} fixedWidth />
                      &nbsp; Confirmed Orders
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    <FontAwesomeIcon icon={['fad', 'shopping-basket']} />
                    &nbsp; Order Imports
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem to={routes.saveOnFoods.home.path} tag={Link}>
                      <FontAwesomeIcon
                        icon={['fal', 'badge-dollar']}
                        fixedWidth
                      />
                      &nbsp; Save On Foods
                    </DropdownItem>
                    <DropdownItem to={routes.thriftys.single.path} tag={Link}>
                      <FontAwesomeIcon icon={['fal', 'text']} fixedWidth />
                      &nbsp; Thrifty's (Single)
                    </DropdownItem>
                    <DropdownItem to={routes.thriftys.multiple.path} tag={Link}>
                      <FontAwesomeIcon icon={['fal', 'text']} fixedWidth />
                      <FontAwesomeIcon
                        icon={['fal', 'text']}
                        fixedWidth
                        style={{ position: 'absolute', left: '1.2rem' }}
                      />
                      &nbsp; Thrifty's (Multiple)
                    </DropdownItem>
                    <DropdownItem
                      to={routes.marketOfChoice.home.path}
                      tag={Link}>
                      <FontAwesomeIcon icon={['fab', 'medium-m']} fixedWidth />
                      &nbsp; Market of Choice
                    </DropdownItem>
                    <DropdownItem to={routes.calgaryCoop.home.path} tag={Link}>
                      <FontAwesomeIcon
                        icon={['fab', 'creative-commons']}
                        fixedWidth
                      />
                      &nbsp; Calgary Co-op
                    </DropdownItem>
                    <DropdownItem to={routes.fresonBros.home.path} tag={Link}>
                      <FontAwesomeIcon
                        icon={['fab', 'facebook-f']}
                        fixedWidth
                      />
                      &nbsp; Freson Bros
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    <FontAwesomeIcon icon={['fal', 'print']} fixedWidth />
                    &nbsp; Reports
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem to={routes.analytics.path} tag={Link}>
                      <FontAwesomeIcon
                        icon={['fad', 'chart-line']}
                        fixedWidth
                      />
                      &nbsp; Analytics
                    </DropdownItem>
                    <DropdownItem to={routes.xero.home.path} tag={Link}>
                      <FontAwesomeIcon
                        icon={['fad', 'file-invoice-dollar']}
                        fixedWidth
                      />
                      &nbsp; Xero
                    </DropdownItem>
                    <DropdownItem to={routes.reports.home.path} tag={Link}>
                      <FontAwesomeIcon icon={['fal', 'print']} fixedWidth />
                      &nbsp; Reports
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink
                    to={routes.home.path}
                    onClick={handleLogoutClick}
                    tag={Link}>
                    <FontAwesomeIcon icon={['fad', 'sign-out-alt']} />
                    &nbsp; Logout
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </>
        )}
      </Navbar>
    </header>
  );
}
