import { ApiBase } from './api-base';
import { CartListItem, CartDetail } from './models/carts';

class CartService extends ApiBase {
  getList(salesperson: string): Promise<CartListResponse> {
    return this.get(`/api/carts?salesperson=${salesperson}`);
  }

  getCartDetail(id: number): Promise<CartDetailResponse> {
    return this.get(`/api/carts/${id}`);
  }
}

export interface CartListResponse {
  carts: CartListItem[];
}

export interface CartDetailResponse {
  cart: CartDetail;
}

export const cartApi = new CartService();
