import { ApiBase } from './api-base';
import {
  BookingCatalogListItem,
  BookingCatalogDetail,
  BookingCatalogDetailInventory,
  CatalogTypes,
  OrderedProduct,
  BookingCatalogDetailInventoryPrice,
  BookingCatalogDetailInventoryWeek,
  PriceList
} from './models/booking-catalogs';
import { Week } from './models/weeks';

class BookingCatalogService extends ApiBase {
  getList(year: number): Promise<BookingCatalogListResponse> {
    return this.get(`/api/booking-catalogs?year=${year}`);
  }

  getOne(id: number): Promise<BookingCatalogDetailResponse> {
    return this.get(`/api/booking-catalogs/${id}`);
  }

  create(request: CreateBookingCatalogRequest): Promise<BookingCatalogDetailResponse> {
    return this.post('/api/booking-catalogs', request);
  }

  update(request: UpdateBookingCatalogRequest): Promise<BookingCatalogDetailResponse> {
    return this.put(`/api/booking-catalogs/${request.id}`, request);
  }

  notify(id: number): Promise<BookingCatalogDetailResponse> {
    return this.post(`/api/booking-catalogs/${id}/notify`);
  }

  updateInventory(id: number, request: UpdateBookingCatalogInventoryRequest): Promise<BookingCatalogDetailResponse> {
    return this.post(`/api/booking-catalogs/${id}/inventory`, request);
  }

  updatePrices(id: number, prices: BookingCatalogDetailInventoryPrice[]): Promise<BookingCatalogUpdatePricesResponse> {
    return this.post(`/api/booking-catalogs/${id}/prices`, { prices });
  }

  updateFeatures(id: number, request: UpdateBookingCatalogFeatureRequest): Promise<BookingCatalogDetailResponse> {
    return this.post(`/api/booking-catalogs/${id}/features`, request);
  }

  updateAlert(id: number, request: UpdateBookingCatalogAlertRequest): Promise<BookingCatalogDetailResponse> {
    return this.post(`/api/booking-catalogs/${id}/alert`, request);
  }

  deleteOne(id: number): Promise<void> {
    return this.delete(`/api/booking-catalogs/${id}`);
  }

  weeks(): Promise<WeeksResponse> {
    return this.get('/api/booking-catalogs/weeks');
  }

  products(catalogType: string, bookingCatalogId?: number): Promise<ProductsResponse> {
    const query = `?catalogType=${catalogType}` + (bookingCatalogId ? `&bookingCatalogId=${bookingCatalogId}` : '');
    return this.get(`/api/booking-catalogs/products${query}`);
  }

  inventory(bookingCatalogId: number): Promise<BookingCatalogInventoryResponse> {
    return this.get(`/api/booking-catalogs/${bookingCatalogId}/inventory`);
  }

  prices(id: number): Promise<BookingCatalogPriceListResponse> {
    return this.get(`/api/booking-catalogs/${id}/prices`);
  }

  reprice(bookingCatalogId: number): Promise<RepriceResponse> {
    return this.get(`/api/booking-catalogs/${bookingCatalogId}/reprice`);
  }

  ordered(id: number, weekly: boolean): Promise<BookingCatalogOrderedResponse> {
    const query = weekly ? '?weekly=true' : '';
    return this.get(`/api/booking-catalogs/${id}/ordered${query}`);
  }
}

export interface BookingCatalogListResponse {
  bookingCatalogs: BookingCatalogListItem[];
}

export interface BookingCatalogDetailResponse {
  bookingCatalog: BookingCatalogDetail;
}

export interface CreateBookingCatalogRequest {
  name: string;
  catalogType: CatalogTypes;
  weekId: string;
  endWeekId: string | null;
  availableFrom: string;
  availableTo: string;
}

export interface UpdateBookingCatalogRequest {
  id: number;
  name: string;
  catalogType: CatalogTypes;
  weekId: string;
  endWeekId: string | null;
  availableFrom: string;
  availableTo: string;
  notifyAutomatically: boolean;
}

export interface UpdateBookingCatalogInventoryRequest {
  inventory: BookingCatalogInventoryUpdate[];
}

export interface UpdateBookingCatalogFeatureRequest {
  featuredInventoryId: number | null;
  featuredInventoryDisplayText: string | null;
  special1Id: number | null;
  special2Id: number | null;
}

export interface UpdateBookingCatalogAlertRequest {
  alertTitle1: string | null;
  alertTitle2: string | null;
  alertDescription: string | null;
}

export interface BookingCatalogInventoryUpdate {
  productId: number;
  quantity: number | null;
  quantityMessage: string | null;
  maximumOrderQuantity: number | null;
  minimumOrderStemQuantityCuts: number | null;
  availabilityCutoff: string | null;

  weeks: BookingCatalogInventoryWeekUpdate[] | null;
}

export interface BookingCatalogInventoryWeekUpdate {
  weekId: string;
  quantity: number | null;
  maximumOrderQuantity: number | null;
  availabilityCutoff: string | null;
  quantityMessage: string | null;
  minimumOrderStemQuantityCuts: number | null;
}

export interface BookingCatalogInventoryPriceUpdate {
  productId: number;
  territory: string;
  unitPrice: number;
  casePrice: number | null;
}

export interface WeeksResponse {
  weeks: Week[];
}

export interface ProductsResponse {
  products: BookingCatalogDetailInventory[];
  prices: BookingCatalogDetailInventoryPrice[];
  weeks: BookingCatalogDetailInventoryWeek[];
}

export interface BookingCatalogInventoryResponse {
  inventory: BookingCatalogDetailInventory[];
}

export interface RepriceResponse {
  prices: BookingCatalogDetailInventoryPrice[];
}

export interface BookingCatalogPriceListResponse {
  priceList: PriceList;
}

export interface BookingCatalogUpdatePricesResponse {
  prices: BookingCatalogDetailInventoryPrice[];
}

export interface BookingCatalogOrderedResponse {
  products: OrderedProduct[];
}

export const bookingCatalogApi = new BookingCatalogService();
