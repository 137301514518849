import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  selectLoading,
  selectError,
  selectWeeks,
  setError,
  saveNewBookingCatalog,
  clearError,
  selectBookingCatalog,
} from './booking-catalog-detail-slice';
import {
  Input,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { routes } from 'app/routes';
import { getWeek, Week } from 'api/models/weeks';
import { Link, Navigate } from 'react-router-dom';
import { createProblemDetails } from 'utils/problem-details';
import { Error } from 'features/errors/Error';
import { CatalogTypes } from 'api/models/booking-catalogs';
import { contains } from 'utils/equals';
import { handleDropdownToggle, maxHeightModifiers } from 'utils/dropdown';

export function New() {
  const dispatch = useDispatch(),
    navigate = useNavigate(),
    loading = useSelector(selectLoading),
    error = useSelector(selectError),
    allWeeks = useSelector(selectWeeks),
    bookingCatalog = useSelector(selectBookingCatalog),
    [name, setName] = useState(''),
    [catalogType, setCatalogType] = useState<CatalogTypes>('Plants'),
    [week, setWeek] = useState(getWeek(new Date())),
    [endWeek, setEndWeek] = useState<Week | null>(null),
    [availableFrom, setAvailableFrom] = useState(moment().startOf('isoWeek')),
    [availableTo, setAvailableTo] = useState(
      moment().endOf('isoWeek').startOf('day').hour(15)
    ),
    invalid = !name,
    weeks = allWeeks || [],
    thisWeek = getWeek(),
    today = moment().format('YYYY-MM-DD'),
    startWeeks = (weeks || [])
      .slice()
      .filter(
        (w) =>
          (w.year === thisWeek.year && w.weekNumber >= thisWeek.weekNumber) ||
          w.year > thisWeek.year
      ),
    endWeeks = (weeks || [])
      .slice()
      .filter(
        (w) =>
          (w.year === week.year && w.weekNumber >= week.weekNumber) ||
          w.year > week.year
      ),
    isPrebook = contains(catalogType, 'Prebook');

  const handleCatalogTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const type = e.target.value as CatalogTypes;
    setCatalogType(type);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value || '');
  };

  const handleWeekClick = (value: Week) => {
    setWeek(value);
    const from = availableFrom
        .clone()
        .isoWeek(value.weekNumber)
        .startOf('isoWeek'),
      to = from.clone().isoWeekday(4).hour(15).minute(0);
    setAvailableFrom(from);
    setAvailableTo(to);
  };

  const handleEndWeekClick = (value: Week | null) => {
    setEndWeek(value);
  };

  const handleAvailableFromDateChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const date = moment(e.target.value, 'YYYY-MM-DD');
    if (date.isValid()) {
      const from = availableFrom
        .clone()
        .year(date.year())
        .month(date.month())
        .date(date.date())
        .startOf('day');
      setAvailableFrom(from);

      if (date.isBefore(moment().startOf('day'))) {
        dispatch(
          setError(createProblemDetails('From Date is prior to today.'))
        );
      } else {
        dispatch(clearError());
      }
    }
  };

  const handleAvailableFromTimeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const time = moment(e.target.value, 'HH:mm');
    if (time.isValid()) {
      const from = availableFrom
        .clone()
        .hour(time.hour())
        .minute(time.minute());
      setAvailableFrom(from);
    }
  };

  const handleAvailableToDateChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const date = moment(e.target.value, 'YYYY-MM-DD');
    if (date.isValid()) {
      const to = availableTo
        .clone()
        .year(date.year())
        .month(date.month())
        .date(date.date())
        .startOf('day');
      setAvailableTo(to);

      if (date.isBefore(moment().startOf('day'))) {
        dispatch(setError(createProblemDetails('To Date is prior to today.')));
      } else {
        dispatch(clearError());
      }
    }
  };

  const handleAvailableToTimeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const time = moment(e.target.value, 'HH:mm');
    if (time.isValid()) {
      const to = availableTo.clone().hour(time.hour()).minute(time.minute());
      setAvailableTo(to);
    }
  };

  const handleSaveClick = async () => {
    if (!name) {
      const error = createProblemDetails(
        'Please enter the Booking Catalog Name'
      );
      dispatch(setError(error));
    } else if (endWeek && endWeek.id < week.id) {
      const error = createProblemDetails(
        'Invalid End Week',
        'The End Week must be after the Start Week.'
      );
      dispatch(setError(error));
    } else {
      const weekId = week.id,
        endWeekId = endWeek?.id || null,
        from = availableFrom.clone().utc().toISOString(),
        to = availableTo.clone().utc().toISOString();

      dispatch(
        saveNewBookingCatalog({
          catalogType,
          name,
          weekId,
          endWeekId,
          availableFrom: from,
          availableTo: to,
        })
      );
    }
  };

  const toggleError = () => {
    dispatch(clearError());
  };

  const handleCancelClick = () => {
    navigate(-1);
  };

  if (bookingCatalog) {
    return <Navigate to={routes.bookingCatalogDetail.to(bookingCatalog.id)} />;
  }

  return (
    <div className="container mt-4">
      <div className="row">
        <h1 className="col">
          <FontAwesomeIcon icon={['fad', 'atlas']} />
          &nbsp;
          <span>New Booking Catalog</span>
        </h1>
      </div>
      {loading && (
        <h1 className="text-success text-center">
          &nbsp;
          <FontAwesomeIcon icon={['fad', 'spinner']} spin />
        </h1>
      )}
      <Error error={error} clearError={toggleError} />
      <div className="row">
        <div className="col-12 col-md-6">
          <label htmlFor="catalog-type" className="form-control-label">
            Type
          </label>
          <Input
            id="catalog-type"
            type="select"
            value={catalogType}
            onChange={handleCatalogTypeChange}>
            <option value="Plants">Plants</option>
            <option value="Cut Flowers">Cut Flowers</option>
            <option value="Plant Prebook">Plant Prebook</option>
            <option value="Cut Flowers Prebook">Cut Flowers Prebook</option>
          </Input>
        </div>
        <div className="col-12 col-md-6">
          <label className="form-control-label" htmlFor="name">
            Name
          </label>
          <Input id="name" value={name} onChange={handleNameChange} />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12 col-md-6">
          <label className="form-control-label" htmlFor="week">
            {isPrebook ? 'Start ' : ''}Week
          </label>
          <div>
            <UncontrolledDropdown onToggle={handleDropdownToggle}>
              <DropdownToggle caret color="secondary">
                {week.weekNumber}, {week.year}
              </DropdownToggle>
              <DropdownMenu modifiers={maxHeightModifiers()}>
                {startWeeks.map((w) => (
                  <DropdownItem key={w.id} onClick={() => handleWeekClick(w)}>
                    {w.weekNumber}, {w.year}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
        {isPrebook && (
          <div className="col-12 col-md-6">
            <label className="form-control-label">End Week</label>
            <div>
              <UncontrolledDropdown onToggle={handleDropdownToggle}>
                <DropdownToggle caret color="secondary">
                  {endWeek?.weekNumber || '--'}
                  {endWeek ? `, ${endWeek.year}` : ''}
                </DropdownToggle>
                <DropdownMenu modifiers={maxHeightModifiers()}>
                  {endWeeks.map((w) => (
                    <DropdownItem
                      key={w.id}
                      onClick={() => handleEndWeekClick(w)}>
                      {w.weekNumber}, {w.year}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
              {endWeek && (
                <Button
                  color="danger"
                  outline
                  size="sm"
                  onClick={() => handleEndWeekClick(null)}
                  className="mt-2">
                  <FontAwesomeIcon icon={['fal', 'times']} />
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="row mt-2">
        <div className="col-12 col-md-6 col-lg-4">
          <label htmlFor="available-from-date">Available From</label>
          <div className="row no-gutters">
            <div className="col">
              <Input
                id="available-from-date"
                type="date"
                min={today}
                value={availableFrom.format('YYYY-MM-DD')}
                onChange={handleAvailableFromDateChange}
              />
            </div>
            <div className="col">
              <Input
                id="available-from-time"
                type="time"
                value={availableFrom.format('HH:mm')}
                onChange={handleAvailableFromTimeChange}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <label htmlFor="available-from-date">Available To</label>
          <div className="row no-gutters">
            <div className="col">
              <Input
                id="available-to-date"
                type="date"
                min={today}
                value={availableTo.format('YYYY-MM-DD')}
                onChange={handleAvailableToDateChange}
              />
            </div>
            <div className="col">
              <Input
                id="available-to-time"
                type="time"
                value={availableTo.format('HH:mm')}
                onChange={handleAvailableToTimeChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-4 offset-0 offset-lg-4 text-right mt-4">
          <Button onClick={handleCancelClick}>Cancel</Button>
          &nbsp;&nbsp;
          <Button
            color="success"
            onClick={handleSaveClick}
            disabled={invalid || loading}>
            <FontAwesomeIcon icon={['fad', 'save']} />
            &nbsp; Save &amp; Continue
            <FontAwesomeIcon icon={['fad', 'chevron-double-right']} />
          </Button>
        </div>
      </div>
    </div>
  );
}
