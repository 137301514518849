import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './app/App';
import { store } from './app/store';
import * as serviceWorker from './serviceWorker';

const baseUrl =
    document.getElementsByTagName('base')[0].getAttribute('href') || '',
  container = document.getElementById('root'),
  root = createRoot(container!);

root.render(
  //<React.StrictMode>
  <Provider store={store}>
    <Router basename={baseUrl}>
      <App />
    </Router>
    ,
  </Provider>
  //</React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
