import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CustomInput,
  Button,
} from 'reactstrap';
import * as XLSX from 'xlsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ProductsResponse,
  bookingCatalogApi,
} from 'api/booking-catalog-service';
import { setError, setLoading } from './booking-catalog-list-slice';
import { UnusedTerritories } from 'features/products/plants/plant-detail-slice';
import { ProblemDetails } from 'utils/problem-details';

interface ProductDownloadModalProps {
  show: boolean;
  hide: () => void;
}

export function ProductDownloadModal(props: ProductDownloadModalProps) {
  const dispatch = useDispatch(),
    { show, hide } = props,
    [downloadAll, setDownloadAll] = useState(false),
    [catalogType, setCatalogType] = useState('Plants'),
    [productResponse, setProductResponse] = useState<ProductsResponse | null>(
      null
    );

  useEffect(() => {
    if (productResponse) {
      let territories = [] as string[];
      const { products, prices } = productResponse,
        priceMap = prices.reduce((map, p) => {
          if (
            territories.indexOf(p.territory) === -1 &&
            UnusedTerritories.indexOf(p.territory) === -1 &&
            p.unitPrice
          ) {
            territories.push(p.territory);
          }
          if (map[p.productId] === undefined) {
            map[p.productId] = {};
          }

          const territoryMap = map[p.productId];
          territoryMap[p.territory] = p.unitPrice;
          return map;
        }, {} as { [key: string]: { [key: string]: number | null } });

      territories.sort();
      const data = (
          [
            [
              'Product ID',
              'Category',
              'Item',
              'Comment',
              'Quantity',
              'MaximumOrderQuantity',
              'Availability Cutoff',
            ].concat(territories),
          ] as any[][]
        ).concat(
          products
            .filter((p) => p.isActive || downloadAll)
            .map((i) => {
              const colour =
                  catalogType === 'Cut Flowers' && i.colour
                    ? ` (${i.colour})`
                    : '',
                territoryMap = priceMap[i.productId] || {};
              return [
                i.productId,
                i.category,
                `${i.size} ${i.description1} ${i.description2}${colour}`,
                i.comment,
                0,
                null,
                null,
              ].concat(territories.map((t) => territoryMap[t] || null));
            })
        ),
        book = XLSX.utils.book_new(),
        sheet = XLSX.utils.aoa_to_sheet(data);

      XLSX.utils.book_append_sheet(book, sheet);

      XLSX.writeFile(book, 'Products.xlsx');
      setProductResponse(null);
    }
  }, [productResponse, catalogType, downloadAll]);

  const handleDownloadAllChange = (all: boolean) => {
    setDownloadAll(all);
  };

  const handleCatalogTypeChange = (type: string) => {
    setCatalogType(type);
  };

  const handleDownloadClick = async () => {
    try {
      dispatch(setLoading(true));
      const response = await bookingCatalogApi.products(catalogType);
      setProductResponse(response);
      hide();
    } catch (e) {
      dispatch(setError(e as ProblemDetails));
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <Modal isOpen={show} toggle={hide}>
      <ModalHeader toggle={hide}>Download Products</ModalHeader>
      <ModalBody>
        <p className="lead text-center">
          Please choose the products to download:
        </p>
        <div className="row">
          <div className="col-6">
            <h3>Products to Download</h3>
            <CustomInput
              id="download-all-active"
              name="download-all"
              type="radio"
              label="Active Products"
              checked={!downloadAll}
              onChange={() => handleDownloadAllChange(false)}
              className="custom-checkbox-success"
            />
            <CustomInput
              id="download-all-all"
              name="download-all"
              type="radio"
              label="All Products"
              checked={downloadAll}
              onChange={() => handleDownloadAllChange(true)}
              className="custom-checkbox-success"
            />
          </div>
          <div className="col-6">
            <h3>Catalog Type</h3>
            <CustomInput
              id="catalog-type-plants"
              name="catalog-type"
              type="radio"
              label="Plants"
              checked={catalogType === 'Plants'}
              onChange={() => handleCatalogTypeChange('Plants')}
              className="custom-checkbox-success"
            />
            <CustomInput
              id="catalog-type-cuts"
              name="catalog-type"
              type="radio"
              label="Cut Flowers"
              checked={catalogType !== 'Plants'}
              onChange={() => handleCatalogTypeChange('Cut Flowers')}
              className="custom-checkbox-success"
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={hide}>Cancel</Button>
        <Button color="success" outline onClick={handleDownloadClick}>
          <FontAwesomeIcon icon={['fad', 'file-excel']} />
          &nbsp; Download
        </Button>
      </ModalFooter>
    </Modal>
  );
}
