import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CatalogTypes } from 'api/models/booking-catalogs';
import { Week } from 'api/models/weeks';
import { Error } from 'features/errors/Error';
import { contains } from 'utils/equals';
import { formatNumber, formatCurrency } from 'utils/format';
import { getSalesAnalytics, getAnalyticsData, selectError, selectLoading, clearError, clearState,
  selectWeeks, selectYears, selectYear, setYear, selectCatalog, setCatalog, selectPrebookWeeks, selectShowPrebookByWeeks,
  selectStartWeek, setStartWeek, selectEndWeek, setEndWeek, selectSalesAnalytics, selectCatalogType, setCatalogType, selectBookingCatalogs, setShowPrebookByWeeks } from './analytics-slice';
import { AnalyticsChart } from './AnalyticsChart';
import { AnalyticsBookingCatalog } from 'api/models/analytics';
import { handleDropdownToggle, maxHeightModifiers } from 'utils/dropdown';

export function Analytics() {
  const dispatch = useDispatch(),
    error = useSelector(selectError),
    loading = useSelector(selectLoading),
    bookingCatalogs = useSelector(selectBookingCatalogs),
    prebookWeeks = useSelector(selectPrebookWeeks),
    catalog = useSelector(selectCatalog),
    weeks = useSelector(selectWeeks),
    years = useSelector(selectYears),
    year = useSelector(selectYear),
    startWeek = useSelector(selectStartWeek),
    endWeek = useSelector(selectEndWeek),
    catalogType = useSelector(selectCatalogType),
    analytics = useSelector(selectSalesAnalytics),
    showPrebookByWeeks = useSelector(selectShowPrebookByWeeks),
    isPrebook = contains(catalogType, 'prebook');

  const refresh = useCallback(() => {
    const bookingCatalogId = isPrebook ? catalog?.id || null : null,
        startWeekId = (bookingCatalogId && !showPrebookByWeeks) ? null : startWeek.id,
        endWeekId = (bookingCatalogId && !showPrebookByWeeks) ? null : endWeek.id,
      args = {startWeekId, endWeekId, catalogType, bookingCatalogId}
    dispatch(getSalesAnalytics(args));
  }, [dispatch, startWeek, endWeek, catalogType,isPrebook, catalog, showPrebookByWeeks]);

  useEffect(() => {
    dispatch(getAnalyticsData());
    refresh();

    let timer = window.setInterval(refresh, 1000 * 60);

    return function cleanup() {
      dispatch(clearState());
      if(timer) {
        window.clearInterval(timer);
      }
    }
  }, [dispatch, refresh]);

  useEffect(() => {
    if(startWeek.id <= endWeek.id) {
      refresh();
    }
  }, [dispatch, refresh, startWeek, endWeek]);

  useEffect(() => {
    if(isPrebook && prebookWeeks.length) {
      batch(() => {
        if(!prebookWeeks.some(w => w.id === startWeek.id)) {
          const startWeek = prebookWeeks[0];
          dispatch(setStartWeek(startWeek));
        }
        if(!prebookWeeks.some(w => w.id === endWeek.id)) {
          const endWeek = prebookWeeks[prebookWeeks.length - 1];
          dispatch(setEndWeek(endWeek));
        }
      });
    }
  }, [dispatch, endWeek, isPrebook, prebookWeeks, startWeek, year]);

  const toggleError = () => {
    dispatch(clearError());
  };

  const handleStartWeekClick = (week: Week) => {
    dispatch(setStartWeek(week));
  };

  const handleEndWeekClick = (week: Week) => {
    dispatch(setEndWeek(week));
  };

  const handleCatalogTypeClick = (type: CatalogTypes | null) => {
    dispatch(setCatalogType(type));
  };

  const handleYearClick = (year: number) => {
    dispatch(setYear(year));
  };

  const handleCatalogClick = (catalog: AnalyticsBookingCatalog | null) => {
    dispatch(setCatalog(catalog));
  };

  const handleShowPrebookByWeeksChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setShowPrebookByWeeks(e.target.checked));
  };

  return (
    <div id="analytics" className="container mt-4">
      <h1>
        <FontAwesomeIcon icon={['fad', 'chart-line']} />
        &nbsp;
        Sales Analytics
      </h1>
      {!analytics && loading &&
        <h1 className="text-success text-center">
          &nbsp;
          <FontAwesomeIcon icon={['fad', 'spinner']} spin />
        </h1>
      }
      {!!error &&
        <Error error={error} clearError={toggleError} />
      }
      <div className="mb-4">      
        <UncontrolledDropdown color="success" onToggle={handleDropdownToggle}>
          <DropdownToggle caret>{catalogType || 'All Types'}</DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => handleCatalogTypeClick(null)}>All Types</DropdownItem>
            <DropdownItem onClick={() => handleCatalogTypeClick('Plants')}>Plants</DropdownItem>
            <DropdownItem onClick={() => handleCatalogTypeClick('Cut Flowers')}>Cut Flowers</DropdownItem>
            <DropdownItem onClick={() => handleCatalogTypeClick('Plant Prebook')}>Prebook (Plants)</DropdownItem>
            <DropdownItem onClick={() => handleCatalogTypeClick('Cut Flowers Prebook')}>Prebook (Cut Flowers)</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        &nbsp;
        <UncontrolledDropdown color="success">
          <DropdownToggle caret>{year}</DropdownToggle>
          <DropdownMenu>
            {years.map(y =>
              <DropdownItem key={y} onClick={() => handleYearClick(y)}>{y}</DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
        &nbsp;
        <UncontrolledDropdown color="success" onToggle={handleDropdownToggle}>
          <DropdownToggle caret>{catalog?.name || 'All Catalogs'}</DropdownToggle>
          <DropdownMenu modifiers={maxHeightModifiers()}>
            {!!catalog &&
              <DropdownItem onClick={() => handleCatalogClick(null)}>All Catalogs</DropdownItem>
            }
            {bookingCatalogs.map(c =>
              <DropdownItem key={c.id} onClick={() => handleCatalogClick(c)}>{c.name}</DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
        {isPrebook &&
          <>
            &nbsp;
            {!!catalog &&
            <div className="d-inline-block mt-2">
              <label htmlFor="show-prebooks-by-weeks" className="d-inline-block">Summarize Results</label>
              &nbsp;
              <label className="custom-toggle custom-toggle-success mb--2 ml-2" htmlFor="show-prebooks-by-weeks">
                  <input id="show-prebooks-by-weeks" type="checkbox" checked={showPrebookByWeeks} onChange={handleShowPrebookByWeeksChange} />
                  <span className="custom-toggle-slider rounded-circle" />
              </label>
              &nbsp;
              <label htmlFor="show-prebooks-by-weeks" className="d-inline-block">Show Weekly Results</label>
            </div>
            }
            {!catalog &&
            <>
              <UncontrolledDropdown color="success" onToggle={handleDropdownToggle}>
                <DropdownToggle caret>{formatWeek(startWeek)}</DropdownToggle>
                <DropdownMenu modifiers={maxHeightModifiers()}>
                  {prebookWeeks.map(w =>
                    <DropdownItem key={w.id} onClick={() => handleStartWeekClick(w)}>{formatWeek(w)}</DropdownItem>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
              &nbsp;
              <UncontrolledDropdown color="success" onToggle={handleDropdownToggle}>
                <DropdownToggle caret>{formatWeek(endWeek)}</DropdownToggle>
                <DropdownMenu modifiers={maxHeightModifiers()}>
                  {prebookWeeks.map(w =>
                    <DropdownItem key={w.id} onClick={() => handleEndWeekClick(w)}>{formatWeek(w)}</DropdownItem>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
            }
          </>
        }
        {!isPrebook &&
        <>
          <UncontrolledDropdown color="success" onToggle={handleDropdownToggle}>
            <DropdownToggle caret>{formatWeek(startWeek)}</DropdownToggle>
            <DropdownMenu modifiers={maxHeightModifiers()}>
              {weeks.filter(w => w.year === year).map(w =>
                <DropdownItem key={w.id} onClick={() => handleStartWeekClick(w)}>{formatWeek(w)}</DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
          &nbsp;
          <UncontrolledDropdown color="success" onToggle={handleDropdownToggle}>
            <DropdownToggle caret>{formatWeek(endWeek)}</DropdownToggle>
            <DropdownMenu modifiers={maxHeightModifiers()}>
              {weeks.filter(w => w.year === year).map(w =>
                <DropdownItem key={w.id} onClick={() => handleEndWeekClick(w)}>{formatWeek(w)}</DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        </>
        }
      </div>
      {(isPrebook && showPrebookByWeeks) &&
        <div className="mt--2 mb-4">
          <UncontrolledDropdown color="success" onToggle={handleDropdownToggle}>
            <DropdownToggle caret>{formatWeek(startWeek)}</DropdownToggle>
            <DropdownMenu modifiers={maxHeightModifiers()}>
              {prebookWeeks.map(w =>
                <DropdownItem key={w.id} onClick={() => handleStartWeekClick(w)}>{formatWeek(w)}</DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
          &nbsp;
          <UncontrolledDropdown color="success" onToggle={handleDropdownToggle}>
            <DropdownToggle caret>{formatWeek(endWeek)}</DropdownToggle>
            <DropdownMenu modifiers={maxHeightModifiers()}>
              {prebookWeeks.map(w =>
                <DropdownItem key={w.id} onClick={() => handleEndWeekClick(w)}>{formatWeek(w)}</DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        }
      {!!analytics &&
      <div className="row chart-wrapper">
        <div className="col-12 col-md-6 mb-4">
          <AnalyticsChart title="Total Sales" value={analytics.dollars} formatFunction={formatCurrency} summaryType="sum" />
        </div>
        <div className="col-12 col-md-6 mb-4">
          <AnalyticsChart title="Total Orders" value={analytics.orderCount} formatFunction={formatNumber} summaryType="sum" />
        </div>
        <div className="col-12 col-md-6 mb-4">
          <AnalyticsChart title="Average Order Cost" value={analytics.averageOrderValue} formatFunction={formatCurrency} summaryType="average" />
        </div>
        <div className="col-12 col-md-6 mb-4">
          <AnalyticsChart title="Quantity of Cases" value={analytics.caseQuantity} formatFunction={formatNumber} summaryType="sum" />
        </div>        
      </div>
      }
    </div>
  )
}

function formatWeek(week: Week) {
  return `Week ${week.weekNumber}, ${week.year}`;
}
