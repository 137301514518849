import moment from 'moment';
import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { CustomerListItem } from 'api/models/customers';
import { customerApi } from 'api/customer-service';
import { ProblemDetails } from 'utils/problem-details';

export const ShowAll = 'Show All';

export interface CustomerFilter {
  salesRep: string | null;
  zone: string | null;
  territory: string | null;
  apf: string | null;
  callLogStatus: string | null;
  customerProductType: string | null;
}

const PageSize = 25;

const years: number[] = [];
let y = moment().isoWeekYear();

// the first year of the call log
while (y >= 2024) {
  years.push(y);
  y--;
}

export interface CustomerListState {
  customers: CustomerListItem[];
  totalCustomerCount: number;
  page: number;
  year: number;
  week: number;
  search: string;
  filter: CustomerFilter;
  salesReps: string[];
  zones: string[];
  territories: string[];
  apfs: string[];
  callLogStatuses: string[];
  customerProductTypes: string[];
  loading: boolean;
  error: ProblemDetails | null;
}

const initialState: CustomerListState = {
  customers: [],
  totalCustomerCount: 0,
  page: 1,
  year: moment().isoWeekYear(),
  week: moment().isoWeek(),
  search: '',
  filter: {
    salesRep: null,
    zone: null,
    territory: null,
    apf: null,
    callLogStatus: null,
    customerProductType: null,
  },
  salesReps: [],
  zones: [],
  territories: [],
  apfs: [],
  callLogStatuses: [],
  customerProductTypes: [],
  loading: false,
  error: null,
};

export const customerListSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setSearch: (state, { payload }: PayloadAction<string>) => {
      state.search = payload;
      state.page = 1;
    },
    setYear: (state, { payload }: PayloadAction<number>) => {
      state.year = payload;
    },
    setWeek: (state, { payload }: PayloadAction<number>) => {
      state.week = payload;
    },
    setFilter: (state, { payload }: PayloadAction<CustomerFilter>) => {
      state.filter = payload;
      state.page = 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(customerApi.endpoints.list.matchPending, (state) => {
        state.loading = true;
      })
      .addMatcher(
        customerApi.endpoints.list.matchFulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.customers = payload.customers;
          state.totalCustomerCount = payload.totalCustomerCount;
          state.salesReps = payload.salesReps;
          state.zones = payload.zones;
          state.territories = payload.territories;
          state.apfs = payload.apfs;
          state.callLogStatuses = payload.callLogStatuses;
          state.customerProductTypes = payload.customerProductTypes;
        }
      )
      .addMatcher(
        customerApi.endpoints.list.matchRejected,
        (state, { payload }) => {
          state.loading = false;
          if (payload) {
            state.error = payload;
          }
        }
      );
  },
});

export const { setPage, setSearch, setYear, setWeek, setFilter } =
  customerListSlice.actions;

export const selectCustomers = ({ customers }: RootState) =>
  customers.customers;
export const selectTotalCustomerCount = ({ customers }: RootState) =>
  customers.totalCustomerCount;
export const selectError = ({ customers }: RootState) => customers.error;
export const selectLoading = ({ customers }: RootState) => customers.loading;
export const selectPage = ({ customers }: RootState) => customers.page;
export const selectSearch = ({ customers }: RootState) => customers.search;
export const selectYears = () => years;
export const selectYear = ({ customers }: RootState) => customers.year;
export const selectWeek = ({ customers }: RootState) => customers.week;
export const selectFilter = ({ customers }: RootState) => customers.filter;
export const selectSalesReps = ({ customers }: RootState) =>
  customers.salesReps;
export const selectZones = ({ customers }: RootState) => customers.zones;
export const selectTerritories = ({ customers }: RootState) =>
  customers.territories;
export const selectApfs = ({ customers }: RootState) => customers.apfs;
export const selectCallLogStatuses = ({ customers }: RootState) =>
  customers.callLogStatuses;
export const selectCustomerProductTypes = ({ customers }: RootState) =>
  customers.customerProductTypes;

export const selectPages = createSelector(
  selectTotalCustomerCount,
  (customerCount) => Math.ceil(customerCount / PageSize)
);

export const selectWeeks = createSelector(selectYear, (year) => {
  const length = moment().isoWeekYear(year).isoWeeksInISOWeekYear(),
    weeks = Array.from({ length }, (_, i) => i + 1);

  return weeks;
});

export default customerListSlice.reducer;
