import { Link } from 'react-router-dom';
import * as models from 'api/models/confirmed-orders';
import { routes } from 'app/routes';
import { formatDate, formatCurrency } from 'utils/format';

export interface ConfirmedOrderItemProps {
  order: models.ConfirmedOrderListItem;
}

export function ConfirmedOrderItem({ order }: ConfirmedOrderItemProps) {
  return (
    <div className="row no-gutters mb-1 pb-1 border-bottom">
      <div className="col-2 text-center">
        <Link to={routes.confirmedOrderDetail.to(order.id)}>{order.id}</Link>
      </div>
      <div className="col-2">{order.catalogType}</div>
      <div className="col-2 text-center">
        {formatDate(order.createdOn)}
        <br />
        @&nbsp;{formatDate(order.createdOn, 'h:mm a')}
        <div className="mt-2">{order.shipWeek}</div>
      </div>
      <div className="col-4">
        <div className="font-weight-bold">{order.salesperson}</div>
        {order.customer}
        <br />
        <span className="font-italic text-muted">{order.createdBy}</span>
      </div>
      <div className="col-2 text-right">{formatCurrency(order.orderTotal)}</div>
    </div>
  );
}
