import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { routes } from 'app/routes';
import { ConfirmedOrderItem } from './ConfirmedOrderItem';
import { selectConfirmedOrders } from './home-slice';

export function ConfirmedOrders() {
  const confirmedOrders = useSelector(selectConfirmedOrders),
    limited = confirmedOrders.slice(0, 5);

  return (
    <div className="overflow-auto-x">
      <a href={routes.confirmedOrders.path} className="h2 btn btn-link">
        <FontAwesomeIcon icon={['fad', 'box-usd']} />
        &nbsp; Confirmed Orders
      </a>
      <div className="small">
        <div className="row no-gutters mb-1 pb-1 border-bottom font-weight-bold">
          <div className="col-2 text-center">Order #</div>
          <div className="col-2">Order Type</div>
          <div className="col-2 text-center">
            Order Date / <br />
            Ship Week
          </div>
          <div className="col-4">
            Salesperson / <br />
            Customer
          </div>
          <div className="col-2 text-right">Order Total</div>
        </div>
        {limited.map((o) => (
          <ConfirmedOrderItem key={o.id} order={o} />
        ))}
        {!!confirmedOrders.length && (
          <div className="row no-gutters">
            <div className="col text-center">
              Showing latest {limited.length} Confirmed Orders &nbsp;
              <a href={routes.confirmedOrders.path}>See All</a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
