import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { routes } from 'app/routes';
import { selectPages, selectPage, setPage } from './customer-list-slice';

export function CustomerPaginator() {
  const dispatch = useDispatch(),
    customersPath = routes.customers.path,
    page = useSelector(selectPage),
    pages = useSelector(selectPages);

  const handlePageClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, page: number) => {
    e.preventDefault();
    dispatch(setPage(page));
  }

  if(!pages) {
    return (<div />);
  }

  return (
    <nav className="ml-auto mr-auto">
        <ul className="pagination pagination-lg justify-content-center">
          <li className={`page-item ${page <= 1 ? 'disabled' : ''}`}>
            <Link className={`page-link ${page <= 1 ? '' : 'text-success border-success'}`} to={customersPath} onClick={e => handlePageClick(e, 1)}>
              <FontAwesomeIcon icon={['fal', 'chevron-double-left']} />
            </Link>
          </li>
          <li className={`page-item ${page <= 1 ? 'disabled' : ''}`}>
            <Link className={`page-link ${page <= 1 ? '' : 'text-success border-success'}`} to={customersPath} onClick={e => handlePageClick(e, page - 1)}>
              <FontAwesomeIcon icon={['fal', 'chevron-left']} />
            </Link>
          </li>
          <li className="page-item">
            <span className="text-muted text-nowrap w-auto mx-4" style={{fontSize: '125%'}}>{`Page ${page} of ${pages}`}</span>
          </li>
          <li className={`page-item ${page >= pages ? 'disabled' : ''}`}>
            <Link className={`page-link ${page >= pages ? '' : 'text-success border-success'}`} to={customersPath} onClick={e => handlePageClick(e, page+1)}>
              <FontAwesomeIcon icon={['fal', 'chevron-right']} />
            </Link>
          </li>
          <li className={`page-item ${page >= pages ? 'disabled' : ''}`}>
            <Link className={`page-link ${page >= pages ? '' : 'text-success border-success'}`} to={customersPath} onClick={e => handlePageClick(e, pages)}>
              <FontAwesomeIcon icon={['fal', 'chevron-double-right']} />
            </Link>
          </li>
        </ul>
      </nav>
  );
}
