import { useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from 'reactstrap';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  useCreateNoteMutation,
  useUpdateNoteMutation,
} from 'api/customer-service';
import { useDeleteNoteMutation } from 'api/customer-service';
import { CustomerNote } from 'api/models/customers';
import { Error } from 'features/errors/Error';
import { ProblemDetails, createProblemDetails } from 'utils/problem-details';

interface NoteModalProps {
  customerId: number;
  note: CustomerNote | null;
  close: () => void;
}

export function NoteModal({ note, customerId, close }: NoteModalProps) {
  const [create] = useCreateNoteMutation(),
    [update] = useUpdateNoteMutation(),
    [notes, setNotes] = useState(''),
    [error, setError] = useState<ProblemDetails | null>(null),
    [confirmDelete, setConfirmDelete] = useState(false),
    [deleteNote] = useDeleteNoteMutation(),
    isNew = !note?.id;

  const handleModalOpened = () => {
    if (note) {
      setNotes(note.notes);
    }
  };

  const handleNotesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNotes(e.target.value);
  };

  const clearError = () => {
    setError(null);
  };

  const handleSaveClick = async () => {
    setError(null);

    if (!notes) {
      return setError(createProblemDetails('Please enter the notes'));
    }

    if (isNew) {
      const response = await create({ customerId, notes });
      if ('error' in response) {
        return setError(response.error as ProblemDetails);
      }
      close();
    } else {
      const response = await update({ customerId, id: note!.id, notes });
      if ('error' in response) {
        return setError(response.error as ProblemDetails);
      }
      close();
    }
  };

  const handleDeleteNoteClick = () => {
    setConfirmDelete(true);
  };

  const handleDeleteNoteCancel = () => {
    setConfirmDelete(false);
  };

  const handleDeleteNoteConfirm = async () => {
    if (note) {
      setConfirmDelete(false);
      await deleteNote(note.id);
      close();
    }
  };

  return (
    <Modal
      isOpen={!!note}
      toggle={close}
      onOpened={handleModalOpened}
      autoFocus={false}>
      <ModalHeader toggle={close}>
        {isNew ? 'New Note' : 'Edit Note'}
      </ModalHeader>
      <ModalBody>
        <div className="row mb-2">
          <div className="col-12">
            <label htmlFor="notes" className="d-block">
              Notes
            </label>
            <Input
              type="textarea"
              id="notes"
              rows={5}
              value={notes}
              onChange={handleNotesChange}
              autoFocus
            />
          </div>
        </div>
        <Error error={error} clearError={clearError} />
      </ModalBody>
      <ModalFooter>
        {!isNew && (
          <Button
            onClick={handleDeleteNoteClick}
            color="danger"
            outline
            className="mr-auto">
            Delete &nbsp;
            <FontAwesomeIcon icon={['fad', 'trash-alt']} />
          </Button>
        )}
        <Button onClick={close}>Cancel</Button>
        <Button color="success" outline onClick={handleSaveClick}>
          Save &nbsp;
          <FontAwesomeIcon icon={['fad', 'save']} />
        </Button>
      </ModalFooter>
      {confirmDelete && (
        <ReactBSAlert
          title="Delete Note"
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
          confirmBtnBsStyle="success"
          onConfirm={handleDeleteNoteConfirm}
          onCancel={handleDeleteNoteCancel}>
          Are you sure you want to delete this note? This action cannot be
          undone.
        </ReactBSAlert>
      )}
    </Modal>
  );
}
