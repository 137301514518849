import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSaving,
  selectError,
  selectUser,
  setError,
  setUser,
  createUser,
  updateUser,
  disableUser,
  enableUser,
  deleteCustomer as deleteCustomerAction,
  passwordReset,
} from 'features/customers/customer-user-slice';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  CustomInput,
  UncontrolledTooltip,
} from 'reactstrap';
import { CreateUserArgs, UpdateUserArgs } from 'api/user-service';
import { UserCustomer, UserDetail } from 'api/models/users';
import { createProblemDetails } from 'utils/problem-details';
import { Error } from 'features/errors/Error';
import { AddCustomerModal } from './AddCustomerModal';
import { formatDate } from 'utils/format';

interface CustomerUserProps {
  customerId: number;
}

export function CustomerUser(props: CustomerUserProps) {
  const dispatch = useDispatch(),
    nameRef = useRef<HTMLInputElement>(null),
    { customerId } = props,
    [setPassword, setSetPassword] = useState(false),
    [resetPassword, setResetPassword] = useState(false),
    [deleteCustomer, setDeleteCustomer] = useState<UserCustomer | null>(null),
    [addCustomer, setAddCustomer] = useState<UserDetail | null>(null),
    saving = useSelector(selectSaving),
    error = useSelector(selectError),
    user = useSelector(selectUser),
    newUser = !user?.id,
    singleAccount = user?.customers.length === 1;

  const toggleError = () => {
    dispatch(setError(null));
  };

  const hideModal = () => {
    dispatch(setUser(null));
  };

  const handleModalOpened = () => {
    nameRef.current?.focus();
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (user) {
      const email = e.target.value || '',
        updated = { ...user, email };

      dispatch(setUser(updated));
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (user) {
      const name = e.target.value || '',
        updated = { ...user, name };

      dispatch(setUser(updated));
    }
  };

  const handleCanOrderPlantsChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (user) {
      const canOrderPlants = e.target.checked,
        updated = { ...user, canOrderPlants };

      dispatch(setUser(updated));
    }
  };

  const handleCanOrderCutFlowersChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (user) {
      const canOrderCutFlowers = e.target.checked,
        updated = { ...user, canOrderCutFlowers };

      dispatch(setUser(updated));
    }
  };

  const handleSaveClick = async () => {
    if (user) {
      if (!user.name) {
        return dispatch(
          setError(createProblemDetails("Please enter the user's name."))
        );
      }
      if (!user.email) {
        return dispatch(
          setError(
            createProblemDetails("Please enter the user's email address.")
          )
        );
      }
      if (newUser) {
        const args: CreateUserArgs = {
          customerId,
          name: user.name,
          email: user.email,
          canOrderPlants: user.canOrderPlants,
          canOrderCutFlowers: user.canOrderCutFlowers,
        };

        dispatch(createUser(args));
      } else {
        const args: UpdateUserArgs = {
          id: user.id,
          name: user.name,
          email: user.email,
          canOrderPlants: user.canOrderPlants,
          canOrderCutFlowers: user.canOrderCutFlowers,
        };

        const response: any = await dispatch(updateUser(args));
        if (!response.error) {
          dispatch(setUser(null));
        }
      }
    }
  };

  const handleDisableClick = () => {
    if (user) {
      dispatch(disableUser(user.id));
    }
  };

  const handleEnableClick = () => {
    if (user) {
      dispatch(enableUser(user.id));
    }
  };

  const handleSetPasswordClick = () => {
    setSetPassword(true);
  };

  const handleSetPasswordConfirm = async (password: string) => {
    if (user && password) {
      const args: UpdateUserArgs = {
        id: user.id,
        name: user.name,
        email: user.email,
        password,
        canOrderPlants: user.canOrderPlants,
        canOrderCutFlowers: user.canOrderCutFlowers,
      };

      const response: any = await dispatch(updateUser(args));
      if (!response.error) {
        setSetPassword(false);
      }
    }
  };

  const handleSetPasswordCancel = () => {
    setSetPassword(false);
  };

  const handleResetPasswordClick = () => {
    setResetPassword(true);
  };

  const handleResetPasswordConfirm = () => {
    setResetPassword(false);

    if (user) {
      dispatch(passwordReset(user.id));
    }
  };

  const handleResetPasswordCancel = () => {
    setResetPassword(true);
  };

  const handleDeleteCustomerClick = (customer: UserCustomer) => {
    setDeleteCustomer(customer);
  };

  const handleDeleteCustomerConfirm = async () => {
    if (user && deleteCustomer) {
      const args = { userId: user.id, customerId: deleteCustomer.customerId },
        response: any = await dispatch(deleteCustomerAction(args));

      if (!response.error) {
        setDeleteCustomer(null);
      }
    }
  };

  const handleDeleteCustomerCancel = () => {
    setDeleteCustomer(null);
  };

  const handleAddCustomerClick = () => {
    if (user) {
      setAddCustomer(user);
    }
  };

  const handleAddCustomerClose = () => {
    setAddCustomer(null);
  };

  return (
    <>
      <Modal
        isOpen={!!user}
        toggle={hideModal}
        onOpened={handleModalOpened}
        size="lg"
        scrollable
        autoFocus>
        <ModalHeader toggle={hideModal}>{`${
          newUser ? 'Add' : 'Edit'
        } User`}</ModalHeader>
        <ModalBody className="py-0">
          <div className="row">
            <div className="col-12">
              <label htmlFor="name">Name</label>
              <Input
                id="name"
                value={user?.name || ''}
                onChange={handleNameChange}
                innerRef={nameRef}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <label htmlFor="email">Email</label>
              <Input
                type="email"
                id="email"
                value={user?.email || ''}
                onChange={handleEmailChange}
              />
            </div>
          </div>
          <div className="row mt-4">
            <h3 className="col-12">Account Activity</h3>
            <div className="col-12">
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th>Last Login</th>
                    <th>Last Activity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="font-italic">
                      {user?.lastLogin
                        ? `Last logged in ${formatDate(
                            user.lastLogin,
                            'MMMM D, yyyy'
                          )} at ${formatDate(user.lastLogin, 'h:mm a')}`
                        : 'User has never logged in'}
                    </td>
                    <td className="font-italic">
                      {user?.lastAccessed
                        ? `Last activity ${formatDate(
                            user.lastAccessed,
                            'MMMM D, yyyy'
                          )} at ${formatDate(user.lastAccessed, 'h:mm a')}`
                        : 'User has no activity'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row mt-4">
            <h3 className="col-12">Catalog Availability</h3>
            <div className="col-6">
              <CustomInput
                type="checkbox"
                id="can-order-plants"
                color="success"
                checked={!!user?.canOrderPlants}
                onChange={handleCanOrderPlantsChange}
                label="Can Order Plants?"
              />
            </div>
            <div className="col-6">
              <CustomInput
                type="checkbox"
                id="can-order-cut-flowers"
                checked={!!user?.canOrderCutFlowers}
                onChange={handleCanOrderCutFlowersChange}
                label="Can Order Cut Flowers?"
              />
            </div>
          </div>
          <div className="row mt-4">
            <h3 className="col-12">Accounts</h3>
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Address</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {user?.customers.map((customer) => (
                  <tr key={customer.customerId}>
                    <td>{customer.name}</td>
                    <td>
                      {customer.shipAddress}, {customer.shipProvince}
                    </td>
                    <td id={`delete-user-${user.id}-${customer.customerId}`}>
                      <Button
                        color="danger"
                        outline
                        size="sm"
                        onClick={() => handleDeleteCustomerClick(customer)}
                        disabled={singleAccount}>
                        <FontAwesomeIcon icon={['fad', 'trash-alt']} />
                      </Button>
                    </td>
                    {singleAccount && (
                      <UncontrolledTooltip
                        target={`delete-user-${user.id}-${customer.customerId}`}
                        placement="right">
                        This is the last account for the user and cannot be
                        removed.
                      </UncontrolledTooltip>
                    )}
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={4} className="text-right">
                    <Button
                      color="success"
                      outline
                      onClick={handleAddCustomerClick}>
                      Add Account &nbsp;
                      <FontAwesomeIcon icon={['fal', 'plus']} />
                    </Button>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <Error error={error} clearError={toggleError} />
        </ModalBody>
        <ModalFooter>
          {!!user && !newUser && (
            <UncontrolledDropdown direction="up" className="mr-auto">
              <DropdownToggle caret>
                Password &nbsp;
                <FontAwesomeIcon icon={['fad', 'key']} />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={handleResetPasswordClick}>
                  <FontAwesomeIcon icon={['fad', 'paper-plane']} fixedWidth />
                  &nbsp; Send Password Reset Email
                </DropdownItem>
                <DropdownItem onClick={handleSetPasswordClick}>
                  <FontAwesomeIcon icon={['fad', 'key']} fixedWidth />
                  &nbsp; Set Password
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
          <Button onClick={hideModal}>Cancel</Button>
          {!!user && !newUser && (
            <>
              {user.isEnabled && (
                <Button
                  outline
                  color="danger"
                  onClick={handleDisableClick}
                  disabled={saving}>
                  Disable User &nbsp;
                  <FontAwesomeIcon icon={['fad', 'user-times']} />
                </Button>
              )}
              {!user.isEnabled && (
                <Button
                  outline
                  color="info"
                  onClick={handleEnableClick}
                  disabled={saving}>
                  Enable User &nbsp;
                  <FontAwesomeIcon icon={['fad', 'user-plus']} />
                </Button>
              )}
            </>
          )}
          <Button
            outline
            color="success"
            onClick={handleSaveClick}
            disabled={saving}>
            Save &nbsp;
            <FontAwesomeIcon icon={['fad', 'save']} />
          </Button>
        </ModalFooter>
      </Modal>
      {setPassword && (
        <ReactBSAlert
          input
          title="Set Password"
          confirmBtnText="OK"
          cancelBtnText="Cancel"
          showCancel
          confirmBtnBsStyle="success"
          onConfirm={handleSetPasswordConfirm}
          onCancel={handleSetPasswordCancel}>
          Please enter the new password for this user:
        </ReactBSAlert>
      )}
      {resetPassword && (
        <ReactBSAlert
          title="Reset Password"
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
          confirmBtnBsStyle="success"
          onConfirm={handleResetPasswordConfirm}
          onCancel={handleResetPasswordCancel}>
          This will send the user a Reset Password email with a link to set
          their password.
          <br />
          Would you like to continue?
        </ReactBSAlert>
      )}
      {deleteCustomer && (
        <ReactBSAlert
          title="Delete Account"
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
          confirmBtnBsStyle="danger"
          onConfirm={handleDeleteCustomerConfirm}
          onCancel={handleDeleteCustomerCancel}>
          Are you sure you want to remove {deleteCustomer.name} from this user?
        </ReactBSAlert>
      )}
      <AddCustomerModal user={addCustomer} close={handleAddCustomerClose} />
    </>
  );
}
