import Axios from 'axios';
import { authService } from 'features/auth/authorization-service';

const axios = Axios.create();

axios.interceptors.response.use(undefined, error);

async function error(error: any) {
  if(error?.response?.status === 401) {
    const token = await authService.getAccessToken();

    if(token) {
      try {
        const configuration = error.config;
          
        return axios.request(configuration);
      } catch(e) {
        await authService.logout();
      }
    } else {
      await authService.logout();
    }
  }

  return error;
}


export const anonAxios = Axios.create();
export default axios;