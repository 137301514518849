import { useDispatch, useSelector } from 'react-redux';
import {
  selectAuthenticated,
  selectError,
  setUser,
  setError,
} from './auth-slice';
import { authService } from './authorization-service';
import { ProblemDetails } from 'utils/problem-details';
import { Navigate } from 'react-router-dom';
import { routes } from 'app/routes';

export function Login() {
  const dispatch = useDispatch(),
    authenticated = useSelector(selectAuthenticated),
    error = useSelector(selectError);

  const handleLoginClick = async () => {
    try {
      dispatch(setError(null));
      const user = await authService.login();
      dispatch(setUser(user));
    } catch (e) {
      dispatch(setError(e as ProblemDetails));
    }
  };

  if (authenticated) {
    return <Navigate to={routes.home.path} />;
  }

  return (
    <form id="login" className="row mt-5">
      <div
        id="login-wrapper"
        className="col-12 col-md-4 col-lg-3 mx-auto border p-3 rounded shadow">
        <div className="row no-gutters p-3 bg-white rounded">
          <img src="/img/logo.png" alt="West Coast Floral" />
        </div>
        <div className="col-12 mt-3">
          <button
            type="button"
            className="btn btn-block btn-success"
            onClick={handleLoginClick}>
            Login
          </button>
        </div>
        {!!error && (
          <div className="col-12 mt-3">
            <div className="alert alert-danger">
              <p className="lead text-center">Error</p>
            </div>
          </div>
        )}
      </div>
    </form>
  );
}
