import React from 'react';

export function maxHeightModifiers(maxHeight: string = '250px') {
  return {
    setMaxHeight: {
      enabled: true,
      order: 890,
      fn: (data: any) => {
        return {
          ...data,
          styles: {
            ...data.styles,
            overflow: 'auto',
            maxHeight,
          },
        };
      },
    },
  };
}

export function handleDropdownToggle(e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>, isOpen: boolean) {

  if(isOpen) {
    const button = e.target as HTMLElement;

    window.setTimeout(() => {
      const date = button.innerText,
        parent = button.parentElement as Element,
        menu = parent.querySelector('.dropdown-menu'),
        children = Array.from(menu?.children || []),
        child = children
          .filter(c => c instanceof HTMLElement)
          .map(c => c as HTMLElement)
          .find(c => c.innerText === date),
        scrollTop = child?.offsetTop || 0;

      if(menu) {
        menu.scrollTop = scrollTop;
      }
    }, 100);
  }
}
