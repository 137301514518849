import { CatalogTypes } from './booking-catalogs';

export interface ConfirmedOrderListItem {
  id: number;
  webOrderId: number | null;
  salesperson: string;
  shipWeek: string;
  poNumber: string;
  customer: string;
  comments: string | null;
  orderTotal: number;
  createdOn: string;
  createdBy: string;
  catalogType: CatalogTypes;
  catalogName: string;
}

export type ConfirmedOrderSort = 'ShipDate Asc' | 'ShipDate Desc' | 'OrderDate Asc' | 'OrderDate Desc';

export function confirmedOrderSortDisplay(sort: ConfirmedOrderSort): string {
  switch(sort) {
    case 'ShipDate Asc':
      return 'Ship Week (earliest)';
    case 'ShipDate Desc':
      return 'Ship Week (latest)';
    case 'OrderDate Asc':
      return 'Order Date (earliest)';
    case 'OrderDate Desc':
      return 'Order Date (latest)';
  }
}
