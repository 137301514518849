import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { routes } from 'app/routes';
import { selectFavouriteCustomers } from './home-slice';

export function FavouriteCustomers() {
  const customers = useSelector(selectFavouriteCustomers),
    limited = customers.slice(0, 10);

  return (
    <div>
      <a href={routes.confirmedOrders.path} className="h2 btn btn-link">
        <FontAwesomeIcon icon={['fad', 'users']} />
        &nbsp; Starred Customers
      </a>
      <table className="table table-sm table-responsive-md">
        <tbody>
          {limited.map((c) => (
            <tr key={c.id}>
              <td className="auto-width">
                <FontAwesomeIcon
                  icon={['fad', 'star']}
                  className="text-warning"
                />
              </td>
              <td className="auto-width">
                <Link
                  to={routes.customerDetail.to(c.id)}
                  state={{ from: routes.home.path }}>
                  <FontAwesomeIcon icon={['fal', 'edit']} />
                </Link>
              </td>
              <td>{c.shipName}</td>
            </tr>
          ))}
        </tbody>
        {!!customers.length && (
          <tfoot>
            <tr>
              <td colSpan={3} className="text-center">
                Showing {limited.length} of {customers.length} Starred Customers
                &nbsp;
                <a href={routes.customers.path}>See All</a>
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
}
