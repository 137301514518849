import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  AsyncThunk,
  createAction,
} from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { orderApi, OrderDetailResponse } from 'api/order-service';
import { OrderDetail } from 'api/models/orders';
import { ProblemDetails } from 'utils/problem-details';

export interface OrderDetailState {
  order: OrderDetail | null;
  loading: boolean;
  error: ProblemDetails | null;
}

const initialState: OrderDetailState = {
  order: null,
  loading: false,
  error: null,
};

export const getOrderDetail: AsyncThunk<
  OrderDetailResponse,
  number,
  { state: RootState }
> = createAsyncThunk(
  'orders/getOrderDetail',
  async (id, { rejectWithValue }) => {
    try {
      return await orderApi.getOrderDetail(id);
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

interface ApproveOrderArgs {
  caseCountCuts: number | null;
  cubesCuts: number | null;
}

export const approveOrder: AsyncThunk<
  boolean,
  ApproveOrderArgs,
  { state: RootState }
> = createAsyncThunk(
  'orders/approveOrder',
  async (args, { getState, rejectWithValue }) => {
    try {
      const { caseCountCuts, cubesCuts } = args,
        order = (getState() as RootState).orderDetail.order;
      if (order) {
        await orderApi.approveOrder(order, caseCountCuts, cubesCuts);
        return true;
      }

      return false;
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const cancelOrder: AsyncThunk<boolean, void, { state: RootState }> =
  createAsyncThunk(
    'orders/cancelOrder',
    async (_, { getState, rejectWithValue }) => {
      try {
        const order = (getState() as RootState).orderDetail.order;
        if (order) {
          const model = {
            id: order.id,
            commentsToCustomer: order.commentsToCustomer,
          };
          await orderApi.cancelOrder(model);
          return true;
        }

        return false;
      } catch (e) {
        return rejectWithValue(e as ProblemDetails);
      }
    }
  );

export interface SetUnitPriceArgs {
  id: number;
  unitPrice: number;
}

export interface SetQuantityArgs {
  id: number;
  quantity: number;
}

export interface SetItemCommentsArgs {
  id: number;
  comments: string;
}

const getOrderDetailPending = createAction(getOrderDetail.pending.type),
  getOrderDetailFulfilled = createAction<OrderDetailResponse>(
    getOrderDetail.fulfilled.type
  ),
  getOrderDetailRejected = createAction<ProblemDetails>(
    getOrderDetail.rejected.type
  ),
  approveOrderPending = createAction(approveOrder.pending.type),
  approveOrderFulfilled = createAction(approveOrder.fulfilled.type),
  approveOrderRejected = createAction<ProblemDetails>(
    approveOrder.rejected.type
  ),
  cancelOrderPending = createAction(cancelOrder.pending.type),
  cancelOrderFulfilled = createAction(cancelOrder.fulfilled.type),
  cancelOrderRejected = createAction<ProblemDetails>(cancelOrder.rejected.type);

export const orderSlice = createSlice({
  name: 'orderDetail',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
    setUnitPrice(state, action: PayloadAction<SetUnitPriceArgs>) {
      const { order } = state,
        { id, unitPrice } = action.payload;
      if (order) {
        const items = order.items.slice(),
          index = items.findIndex((i) => i.id === id);

        if (index !== -1) {
          const item = items[index];
          item.unitPrice = unitPrice;
          items.splice(index, 1, item);
        }
      }
    },
    setQuantity(state, action: PayloadAction<SetQuantityArgs>) {
      const { order } = state,
        { id, quantity } = action.payload;
      if (order) {
        const items = order.items.slice(),
          index = items.findIndex((i) => i.id === id);

        if (index !== -1) {
          const item = items[index];
          item.quantity = quantity;
          items.splice(index, 1, item);
        }
      }
    },
    setItemComments(state, action: PayloadAction<SetItemCommentsArgs>) {
      const { order } = state,
        { id, comments } = action.payload;
      if (order) {
        const items = order.items.slice(),
          index = items.findIndex((i) => i.id === id);

        if (index !== -1) {
          const item = items[index];
          item.comments = comments;
          items.splice(index, 1, item);
        }
      }
    },
    setCommentsToCustomer(state, action: PayloadAction<string | null>) {
      const { order } = state;
      if (order) {
        order.commentsToCustomer = action.payload;
        state.order = order;
      }
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getOrderDetailPending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOrderDetailFulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
        state.order = action.payload.order;
      })
      .addCase(getOrderDetailRejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(approveOrderPending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(approveOrderFulfilled, (state) => {
        state.error = null;
        state.loading = false;
        state.order = null;
      })
      .addCase(approveOrderRejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(cancelOrderPending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(cancelOrderFulfilled, (state) => {
        state.error = null;
        state.loading = false;
        state.order = null;
      })
      .addCase(cancelOrderRejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }),
});

export const {
  clearError,
  setUnitPrice,
  setQuantity,
  setItemComments,
  setCommentsToCustomer,
} = orderSlice.actions;

export const selectOrder = (state: RootState) => state.orderDetail.order;
export const selectLoading = (state: RootState) => state.orderDetail.loading;
export const selectError = (state: RootState) => state.orderDetail.error;
export const selectSubTotal = (state: RootState) =>
  state.orderDetail.order?.items?.reduce(
    (total, item) => total + item.quantity * item.unitPrice,
    0
  ) || 0;

export default orderSlice.reducer;
