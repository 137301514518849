import {
  createSlice,
  createAsyncThunk,
  AsyncThunk,
  createAction,
  PayloadAction,
  createSelector,
} from '@reduxjs/toolkit';
import moment from 'moment';
import {
  saveOnFoodsApi,
  SaveOnFoodsCreateOrdersResponse,
  SaveOnFoodsResponse,
} from 'api/save-on-foods-service';
import * as models from 'api/models/save-on-foods';
import { RootState } from 'app/store';
import { equals } from 'utils/equals';
import { createProblemDetails, ProblemDetails } from 'utils/problem-details';
import { sortBy } from 'utils/sort';
import { SaveOnFoodsOrder } from './excel-reader';

const sortByItemName = sortBy('itemName');

export interface ZoneWeek {
  zone: string;
  year: number;
  week: number;
}

export interface ProductMatch {
  itemName: string;
  product: models.Product | null;
}

interface SaveOnFoodsState {
  isLoading: boolean;
  customers: models.Customer[];
  zones: models.Zone[];
  products: models.Product[];
  zoneWeeks: ZoneWeek[];
  productMatches: ProductMatch[];
  orders: SaveOnFoodsOrder[];
  error: ProblemDetails | null;
}

const initialState: SaveOnFoodsState = {
  isLoading: false,
  customers: [],
  zones: [],
  products: [],
  zoneWeeks: [],
  productMatches: [],
  orders: [],
  error: null,
};

export const getHomeData: AsyncThunk<
  SaveOnFoodsResponse,
  void,
  { state: RootState }
> = createAsyncThunk(
  'save-on-foods/getHomeData',
  async (_, { rejectWithValue }) => {
    try {
      return await saveOnFoodsApi.homeData();
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const saveOrders: AsyncThunk<
  SaveOnFoodsCreateOrdersResponse,
  void,
  { state: RootState }
> = createAsyncThunk(
  'save-on-foods/saveOrders',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { orders } = (getState() as RootState).saveOnFoods,
        errors = orders.filter(
          (o) => !o.customer || o.items.some((i) => !i.product)
        );

      if (errors.length) {
        const details = errors.map((e) => e.customerName).join(', ');
        return rejectWithValue(
          createProblemDetails(
            'Please ensure all items have the customer & product set.',
            `Missing: ${details}`
          )
        );
      }

      const items = orders.map((o) => ({
        customerId: o.customer?.id || 0,
        orderYear: o.year,
        orderWeek: o.week,
        items: o.items.map((i) => ({
          customerId: o.customer?.id || 0,
          productId: i.product?.id || 0,
          quantity: i.boxQuantity * (i.product?.packQuantity || 1),
        })),
      }));

      return await saveOnFoodsApi.createOrders(items);
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

const getHomeDataPending = createAction(getHomeData.pending.type),
  getHomeDataFulfilled = createAction<SaveOnFoodsResponse>(
    getHomeData.fulfilled.type
  ),
  getHomeDataRejected = createAction<ProblemDetails>(getHomeData.rejected.type),
  saveOrdersPending = createAction(saveOrders.pending.type),
  saveOrdersFulfilled = createAction<SaveOnFoodsCreateOrdersResponse>(
    saveOrders.fulfilled.type
  ),
  saveOrdersRejected = createAction<ProblemDetails>(saveOrders.rejected.type);

interface SetOrderArgs<T> {
  orderId: number;
  value: T;
}

interface SetOrderItemArgs<T> extends SetOrderArgs<T> {
  itemName: string;
}

export const saveOnFoodsSlice = createSlice({
  name: 'save-on-foods',
  initialState,
  reducers: {
    clearState(state) {
      state.error = null;
      state.orders = [];
      state.productMatches = [];
    },
    clearError(state) {
      state.error = null;
    },
    setError(state, { payload }: PayloadAction<ProblemDetails>) {
      state.error = payload;
    },
    setOrders(state, { payload: orders }: PayloadAction<SaveOnFoodsOrder[]>) {
      const { products, customers } = state,
        customerMap = customers.reduce(
          (memo, c) => memo.set(c.internalStoreNumber, c),
          new Map<string, models.Customer>()
        ),
        productMap = products.reduce(
          (memo, p) => memo.set(p.itemNumber, p),
          new Map<string, models.Product>()
        );

      orders.forEach((o) => {
        const customer = customerMap.get(o.storeNumber);
        if (customer) {
          o.customer = customer;
        }

        o.items.forEach((i) => {
          const product = productMap.get(i.itemName);
          if (product) {
            i.product = product;
          }
        });
      });

      state.orders = orders;

      state.productMatches = orders
        .reduce((memo, o) => {
          o.items.forEach((i) => {
            const { itemName } = i;
            if (!memo.some((m) => m.itemName === itemName)) {
              const product =
                products.find((p) => p.itemNumber === itemName) || null;
              memo.push({ itemName, product });
            }
          });
          return memo;
        }, [] as ProductMatch[])
        .sort(sortByItemName);

      const zoneWeeks = state.zoneWeeks.map((zw) => ({ ...zw }));

      zoneWeeks.forEach((zoneWeek) => {
        const order =
          orders.find((o) => equals(o.customer?.zone, zoneWeek.zone)) || null;
        if (order) {
          zoneWeek.week = order.week;
          zoneWeek.year = order.year;
        }
      });
      state.zoneWeeks = zoneWeeks;
    },
    setZoneOrderWeek(state, { payload }: PayloadAction<ZoneWeek>) {
      const { zone, week, year } = payload,
        zoneWeeks = state.zoneWeeks.map((zw) => ({ ...zw })),
        zoneWeek = zoneWeeks.find((zw) => equals(zw.zone, zone));
      if (zoneWeek) {
        zoneWeek.week = week;
        zoneWeek.year = year;

        state.zoneWeeks = zoneWeeks;

        const orders = state.orders.map((o) => ({ ...o }));
        orders
          .filter((o) => equals(o.customer?.zone, zone))
          .forEach((o) => {
            o.week = week;
            o.year = year;
          });
        state.orders = orders;
      }
    },
    setProductMatch(state, { payload }: PayloadAction<ProductMatch>) {
      const { itemName, product } = payload,
        productMatches = state.productMatches.map((pm) => ({ ...pm })),
        productMatch = productMatches.find((pm) =>
          equals(pm.itemName, itemName)
        );

      if (productMatch) {
        productMatch.product = product;

        state.productMatches = productMatches;

        const orders = state.orders.map((o) => ({ ...o }));
        orders.forEach((o) => {
          o.items
            .filter((i) => equals(i.itemName, itemName))
            .forEach((i) => (i.product = product));
        });
        state.orders = orders;
      }
    },
    setOrderYear(state, { payload }: PayloadAction<SetOrderArgs<number>>) {
      const { orderId, value } = payload,
        orders = state.orders.map((o) => ({ ...o })),
        order = orders.find((o) => o.id === orderId);

      if (order) {
        order.year = value;
        state.orders = orders;
      }
    },
    setOrderWeek(state, { payload }: PayloadAction<SetOrderArgs<number>>) {
      const { orderId, value } = payload,
        orders = state.orders.map((o) => ({ ...o })),
        order = orders.find((o) => o.id === orderId);

      if (order) {
        order.week = value;
        state.orders = orders;
      }
    },
    setOrderCustomer(
      state,
      { payload }: PayloadAction<SetOrderArgs<models.Customer | null>>
    ) {
      const { orderId, value } = payload,
        orders = state.orders.map((o) => ({ ...o })),
        order = orders.find((o) => o.id === orderId);

      if (order) {
        order.customer = value;
        state.orders = orders;
      }
    },
    setOrderBoxQuantity(
      state,
      { payload }: PayloadAction<SetOrderItemArgs<number>>
    ) {
      const { orderId, itemName, value } = payload,
        orders = state.orders.map((o) => ({ ...o })),
        order = orders.find((o) => o.id === orderId);

      if (order) {
        const items = order.items.map((i) => ({ ...i })),
          item = items.find((i) => i.itemName === itemName);

        if (item) {
          item.boxQuantity = value;
          order.items = items;
        }

        state.orders = orders;
      }
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getHomeDataPending, (state) => {
        state.isLoading = true;
      })
      .addCase(getHomeDataFulfilled, (state, { payload }) => {
        const { customers, zones, products } = payload;
        state.isLoading = false;
        state.customers = customers;
        state.zones = zones;
        state.products = products;

        const m = moment(),
          year = m.isoWeekYear(),
          week = m.isoWeek();

        state.zoneWeeks = zones.map(({ name }) => ({ year, week, zone: name }));
      })
      .addCase(getHomeDataRejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(saveOrdersPending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveOrdersFulfilled, (state, { payload }) => {
        state.isLoading = false;
        const created = payload.orders,
          orders = state.orders.map((o) => ({ ...o }));
        orders.forEach((o) => {
          const order = created.find((c) => c.customerId === o.customer?.id);
          o.orderId = order?.orderId || null;
        });
        state.orders = orders;
      })
      .addCase(saveOrdersRejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      }),
});

export const {
  clearError,
  setError,
  clearState,
  setOrders,
  setZoneOrderWeek,
  setProductMatch,
  setOrderYear,
  setOrderWeek,
  setOrderCustomer,
  setOrderBoxQuantity,
} = saveOnFoodsSlice.actions;

export const selectIsLoading = (state: RootState) =>
  state.saveOnFoods.isLoading;
export const selectCustomers = (state: RootState) =>
  state.saveOnFoods.customers;
export const selectProducts = (state: RootState) => state.saveOnFoods.products;
export const selectZoneWeeks = (state: RootState) =>
  state.saveOnFoods.zoneWeeks;
export const selectProductMatches = (state: RootState) =>
  state.saveOnFoods.productMatches;
export const selectOrders = (state: RootState) => state.saveOnFoods.orders;
export const selectError = createSelector(
  (state: RootState) => state.saveOnFoods.error,
  selectProductMatches,
  selectOrders,
  (error, productMatches, orders) => {
    if (error) {
      return error;
    }

    const errorProducts = productMatches.filter((pm) => !pm.product);

    if (errorProducts.length) {
      const details = errorProducts.map((pm) => pm.itemName).join(', ');
      return createProblemDetails(
        'Please choose a Whilma item for each Product.',
        `Missing: ${details}`
      );
    }

    const errorOrders = orders.filter((o) => !o.customer);

    if (errorOrders.length) {
      const details = errorOrders.map((o) => o.customerName).join(', ');
      return createProblemDetails(
        'Please choose a customer for all customers in the list.',
        `Missing: ${details}`
      );
    }

    return null;
  }
);

export default saveOnFoodsSlice.reducer;
