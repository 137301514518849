import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  AsyncThunk,
  createAction,
} from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import * as models from 'api/models/products';
import {
  productApi,
  PlantDetailResponse,
  UpgradeResponse,
  PlantResponse,
  NewPlantDetailResponse,
} from 'api/product-service';
import { equals } from 'utils/equals';
import { ProblemDetails } from 'utils/problem-details';

export const UnusedTerritories = [
  'Container',
  'GrnHse',
  'GrnHse Lmtd',
  'Local FF',
];

export interface PlantDetailState {
  plantDetail: models.PlantDetail | null;
  plantId: number | null;
  upgradeId: number | null;
  upgrade2Id: number | null;
  boxId: number | null;
  plants: models.Plant[];
  upgrades: models.PlantUpgrade[];
  boxes: models.PlantBox[];
  locations: models.ProductLocation[];
  colours: string[];
  categories: string[];
  suppliers: models.Supplier[];
  units: models.UnitOfIssue[];
  customerPlantGroups: string[];
  territories: models.Territory[];
  haapRates: models.HaapRate[];
  error: ProblemDetails | null;
}

const initialState: PlantDetailState = {
  plantDetail: null,
  plantId: null,
  upgradeId: null,
  upgrade2Id: null,
  boxId: null,
  plants: [],
  upgrades: [],
  boxes: [],
  locations: [],
  colours: [],
  categories: [],
  suppliers: [],
  units: [],
  customerPlantGroups: [],
  territories: [],
  haapRates: [],
  error: null,
};

export const getPlantDetail: AsyncThunk<
  PlantDetailResponse,
  number,
  { state: RootState }
> = createAsyncThunk(
  'plantDetail/getPlantDetail',
  async (id, { rejectWithValue }) => {
    try {
      return await productApi.plantDetail(id);
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const getNewPlantDetail: AsyncThunk<
  NewPlantDetailResponse,
  void,
  { state: RootState }
> = createAsyncThunk(
  'plantDetail/getNewPlantDetail',
  async (_, { rejectWithValue }) => {
    try {
      return await productApi.newPlantDetail();
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const createPlant: AsyncThunk<undefined, void, { state: RootState }> =
  createAsyncThunk(
    'plantDetail/createPlant',
    async (_, { rejectWithValue, getState }) => {
      try {
        const { plantDetail: state } = getState() as RootState;
        if (state.plantDetail) {
          const {
            category,
            size,
            description,
            upgrade,
            upgrade2,
            colour,
            upc,
            itemNumber,
            boxId,
            boxName,
            packQuantity,
            locationId,
            defaultSupplierId,
            defaultSupplierName,
            details,
            sellUIPurchaseUI,
            sellUIPurchaseQuantity,
            sellUI,
            hapCategory,
            hapMarkup,
            active,
            customerPlantGroups,
            prices,
            images,
          } = state.plantDetail;

          await productApi.plantCreate({
            category,
            size,
            description,
            upgrade,
            upgrade2,
            colour,
            upc,
            itemNumber,
            boxId,
            boxName,
            packQuantity,
            locationId,
            defaultSupplierId,
            defaultSupplierName,
            details,
            sellUIPurchaseUI,
            sellUIPurchaseQuantity,
            sellUI,
            hapCategory,
            hapMarkup,
            active,
            customerPlantGroups,
            prices,
            images,
          });
        }
      } catch (e) {
        return rejectWithValue(e as ProblemDetails);
      }
    }
  );

export const updatePlant: AsyncThunk<undefined, void, { state: RootState }> =
  createAsyncThunk(
    'plantDetail/updatePlant',
    async (_, { rejectWithValue, getState }) => {
      try {
        const { plantDetail: state } = getState() as RootState;
        if (state.plantDetail) {
          const {
            id,
            category,
            size,
            description,
            upgrade,
            upgrade2,
            colour,
            upc,
            itemNumber,
            boxId,
            boxName,
            packQuantity,
            locationId,
            defaultSupplierId,
            defaultSupplierName,
            details,
            sellUIPurchaseUI,
            sellUIPurchaseQuantity,
            sellUI,
            hapCategory,
            hapMarkup,
            active,
            customerPlantGroups,
            prices,
            images,
          } = state.plantDetail;

          await productApi.plantUpdate(id, {
            category,
            size,
            description,
            upgrade,
            upgrade2,
            colour,
            upc,
            itemNumber,
            boxId,
            boxName,
            packQuantity,
            locationId,
            defaultSupplierId,
            defaultSupplierName,
            details,
            sellUIPurchaseUI,
            sellUIPurchaseQuantity,
            sellUI,
            hapCategory,
            hapMarkup,
            active,
            customerPlantGroups,
            prices,
            images,
          });
        }
      } catch (e) {
        return rejectWithValue(e as ProblemDetails);
      }
    }
  );

export const deletePlant: AsyncThunk<undefined, void, { state: RootState }> =
  createAsyncThunk(
    'plantDetail/deletePlant',
    async (_, { rejectWithValue, getState }) => {
      try {
        const { plantDetail: state } = getState() as RootState;
        if (state.plantDetail) {
          await productApi.plantDelete(state.plantDetail.id);
        }
      } catch (e) {
        return rejectWithValue(e as ProblemDetails);
      }
    }
  );

export const refreshUpgrades: AsyncThunk<
  UpgradeResponse,
  void,
  { state: RootState }
> = createAsyncThunk(
  'plantDetail/refreshUpgrades',
  async (_, { rejectWithValue }) => {
    try {
      return await productApi.upgrades();
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const refreshPlants: AsyncThunk<
  PlantResponse,
  void,
  { state: RootState }
> = createAsyncThunk(
  'plantDetail/refreshPlants',
  async (_, { rejectWithValue }) => {
    try {
      return await productApi.plants();
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

const getPlantDetailFullfilled = createAction<PlantDetailResponse>(
    getPlantDetail.fulfilled.type
  ),
  getPlantDetailRejected = createAction<ProblemDetails>(
    getPlantDetail.rejected.type
  ),
  getNewPlantDetailFullfilled = createAction<NewPlantDetailResponse>(
    getNewPlantDetail.fulfilled.type
  ),
  getNewPlantDetailRejected = createAction<ProblemDetails>(
    getNewPlantDetail.rejected.type
  ),
  updatePlantRejected = createAction<ProblemDetails>(updatePlant.rejected.type),
  createPlantRejected = createAction<ProblemDetails>(createPlant.rejected.type),
  deletePlantRejected = createAction<ProblemDetails>(deletePlant.rejected.type),
  refreshUpgradesFullfilled = createAction<UpgradeResponse>(
    refreshUpgrades.fulfilled.type
  ),
  refreshUpgradesRejected = createAction<ProblemDetails>(
    refreshUpgrades.rejected.type
  ),
  refreshPlantsFullfilled = createAction<PlantResponse>(
    refreshPlants.fulfilled.type
  ),
  refreshPlantsRejected = createAction<ProblemDetails>(
    refreshPlants.rejected.type
  );

const plantDetailSlice = createSlice({
  name: 'plant-detail',
  initialState,
  reducers: {
    setError(state, { payload }: PayloadAction<ProblemDetails | null>) {
      state.error = payload;
    },
    clearState(state) {
      Object.assign(state, initialState);
    },
    setPlantId(state, { payload }: PayloadAction<number | null>) {
      state.plantId = payload;

      if (state.plantDetail) {
        const detail = { ...state.plantDetail },
          plant = state.plants.find((p) => p.id === payload);

        if (payload && plant) {
          detail.size = plant.size;
          detail.description = plant.product;
          detail.defaultSupplierId = plant.supplierId;
          detail.defaultSupplierName = plant.supplierName;
        } else {
          detail.size = '';
          detail.description = '';
        }
        state.plantDetail = detail;
      }
    },
    setUpgradeId(state, { payload }: PayloadAction<number | null>) {
      state.upgradeId = payload;

      if (state.plantDetail) {
        const detail = { ...state.plantDetail },
          upgrade = state.upgrades.find((u) => u.id === payload);

        if (payload && upgrade) {
          const upgradeWithPrefix = upgrade.prefix
            ? `${upgrade.prefix} ${upgrade.name}`
            : upgrade.name;
          detail.upgrade = upgradeWithPrefix;
        } else {
          detail.upgrade = '';
        }
        state.plantDetail = detail;
      }
    },
    setUpgrade2Id(state, { payload }: PayloadAction<number | null>) {
      state.upgrade2Id = payload;

      if (state.plantDetail) {
        const detail = { ...state.plantDetail },
          upgrade = state.upgrades.find((u) => u.id === payload);

        if (payload && upgrade) {
          const upgradeWithPrefix = upgrade.prefix
            ? `${upgrade.prefix} ${upgrade.name}`
            : upgrade.name;
          detail.upgrade2 = upgradeWithPrefix;
        } else {
          detail.upgrade2 = '';
        }
        state.plantDetail = detail;
      }
    },
    setBoxId(state, { payload }: PayloadAction<number | null>) {
      state.boxId = payload;

      if (state.plantDetail) {
        const detail = { ...state.plantDetail },
          box = state.boxes.find((b) => b.id === payload);

        if (payload && box) {
          detail.boxId = box.id;
          detail.boxName = box.name;
        } else {
          detail.boxId = null;
          detail.boxName = '';
        }
        state.plantDetail = detail;
      }
    },
    setSupplierId(state, { payload }: PayloadAction<string | null>) {
      if (state.plantDetail) {
        const detail = { ...state.plantDetail },
          supplier = state.suppliers.find((s) => s.id === payload);

        if (payload && supplier) {
          detail.defaultSupplierId = supplier.id;
          detail.defaultSupplierName = supplier.name;
        } else {
          detail.defaultSupplierId = null;
          detail.defaultSupplierName = '';
        }
        state.plantDetail = detail;
      }
    },
    setPlantDetail(state, { payload }: PayloadAction<models.PlantDetail>) {
      state.plantDetail = payload;
    },
    setHaapRate(state, { payload }: PayloadAction<string>) {
      if (state.plantDetail) {
        const haapRate = state.haapRates.find((r) => r.category === payload),
          hapMarkup = haapRate ? haapRate.rate : 0,
          hapCategory = haapRate ? haapRate.category : '',
          update = { ...state.plantDetail, hapMarkup, hapCategory };
        state.plantDetail = update;
      }
    },
    addCustomerGroup(state, { payload }: PayloadAction<string>) {
      state.customerPlantGroups = [...state.customerPlantGroups, payload];
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getPlantDetailFullfilled, (state, { payload }) => {
        state.plantDetail = payload.plant;
        state.plants = payload.plants;
        state.boxes = payload.boxes;
        state.upgrades = payload.upgrades;
        state.locations = payload.locations;
        state.colours = payload.colours;
        state.categories = payload.categories;
        state.suppliers = payload.suppliers;
        state.units = payload.units;
        state.customerPlantGroups = payload.customerPlantGroups;
        state.territories = payload.territories;
        state.haapRates = payload.haapRates;

        const sizeProductPlants = payload.plants.filter(
          (p) =>
            p.size === payload.plant.size &&
            p.product === payload.plant.description
        );
        if (sizeProductPlants.length === 1) {
          state.plantId = sizeProductPlants[0].id;
        } else if (sizeProductPlants.length > 1) {
          // use the one that matches on the supplier id, or doesn't have a supplier id, or just pick the first one
          state.plantId =
            sizeProductPlants.find(
              (p) => p.supplierId === payload.plant.defaultSupplierId
            )?.id ||
            sizeProductPlants.find((p) => !p.supplierId)?.id ||
            sizeProductPlants[0].id;
        } else {
          state.plantId = null;
        }

        state.upgradeId =
          payload.upgrades.find(
            (u) =>
              (u.prefix ? `${u.prefix} ` : '') + u.name ===
              payload.plant.upgrade
          )?.id || null;
        state.upgrade2Id =
          payload.upgrades.find(
            (u) =>
              (u.prefix ? `${u.prefix} ` : '') + u.name ===
              payload.plant.upgrade2
          )?.id || null;
        state.boxId =
          payload.boxes.find((b) => b.id === payload.plant.boxId)?.id || null;
      })
      .addCase(getPlantDetailRejected, (state, { payload }) => {
        state.error = payload;
      })
      .addCase(getNewPlantDetailFullfilled, (state, { payload }) => {
        const haap = payload.haapRates.find((r) => r.category === 'Low');
        state.plantDetail = {
          id: 0,
          category: '',
          size: '',
          description: '',
          upgrade: '',
          upgrade2: '',
          colour: '',
          upc: '',
          itemNumber: '',
          boxId: null,
          boxName: '',
          packQuantity: 0,
          locationId: null,
          defaultSupplierId: null,
          defaultSupplierName: '',
          details: '',
          sellUIPurchaseUI: 'Ea',
          sellUIPurchaseQuantity: 1,
          sellUI: 'Ea',
          active: true,
          hapMarkup: haap?.rate || 0,
          hapCategory: haap?.category || '',
          customerPlantGroups: payload.customerPlantGroups.map((c, idx) => ({
            id: -idx,
            groupName: c,
            caseSellPrice: 0,
            singleSellPrice: 0,
            upc: null,
            itemNumber: null,
          })),
          prices: payload.territories.map((p) => ({
            territory: p.name,
            caseSellPrice: 0,
            singleSellPrice: 0,
          })),
          images: [],
        };
        state.plants = payload.plants;
        state.boxes = payload.boxes;
        state.upgrades = payload.upgrades;
        state.locations = payload.locations;
        state.colours = payload.colours;
        state.categories = payload.categories;
        state.suppliers = payload.suppliers;
        state.units = payload.units;
        state.customerPlantGroups = payload.customerPlantGroups;
        state.territories = payload.territories;
        state.haapRates = payload.haapRates;
      })
      .addCase(getNewPlantDetailRejected, (state, { payload }) => {
        state.error = payload;
      })
      .addCase(updatePlantRejected, (state, { payload }) => {
        state.error = payload;
      })
      .addCase(createPlantRejected, (state, { payload }) => {
        state.error = payload;
      })
      .addCase(deletePlantRejected, (state, { payload }) => {
        state.error = payload;
      })
      .addCase(refreshUpgradesFullfilled, (state, { payload }) => {
        state.upgrades = payload.upgrades;
        const upgrade = payload.upgrades.find((u) => u.id === state.upgradeId),
          upgrade2 = payload.upgrades.find((u) => u.id === state.upgrade2Id);

        if (state.plantDetail) {
          const detail = { ...state.plantDetail };

          if (upgrade) {
            detail.upgrade = upgrade.name;
          }
          if (upgrade2) {
            detail.upgrade2 = upgrade2.name;
          }

          state.plantDetail = detail;
        }
      })
      .addCase(refreshUpgradesRejected, (state, { payload }) => {
        state.error = payload;
      })
      .addCase(refreshPlantsFullfilled, (state, { payload }) => {
        state.plants = payload.plants;
        const plant = payload.plants.find((u) => u.id === state.plantId);
        if (state.plantDetail && plant) {
          state.plantDetail = {
            ...state.plantDetail,
            size: plant.size,
            description: plant.product,
          };
        }
      })
      .addCase(refreshPlantsRejected, (state, { payload }) => {
        state.error = payload;
      }),
});

export const {
  setError,
  clearState,
  setPlantId,
  setBoxId,
  setUpgradeId,
  setUpgrade2Id,
  setPlantDetail,
  setHaapRate,
  addCustomerGroup,
  setSupplierId,
} = plantDetailSlice.actions;

export const selectPlantDetail = ({ plantDetail }: RootState) =>
  plantDetail.plantDetail;
export const selectPlantId = ({ plantDetail }: RootState) =>
  plantDetail.plantId;
export const selectUpgradeId = ({ plantDetail }: RootState) =>
  plantDetail.upgradeId;
export const selectUpgrade2Id = ({ plantDetail }: RootState) =>
  plantDetail.upgrade2Id;
export const selectBoxId = ({ plantDetail }: RootState) => plantDetail.boxId;
export const selectPlants = ({ plantDetail }: RootState) => plantDetail.plants;
export const selectUpgrades = ({ plantDetail }: RootState) =>
  plantDetail.upgrades;
export const selectBoxes = ({ plantDetail }: RootState) => plantDetail.boxes;
export const selectLocations = ({ plantDetail }: RootState) =>
  plantDetail.locations;
export const selectColours = ({ plantDetail }: RootState) =>
  plantDetail.colours;
export const selectCategories = ({ plantDetail }: RootState) =>
  plantDetail.categories;
export const selectSuppliers = ({ plantDetail }: RootState) =>
  plantDetail.suppliers;
export const selectUnits = ({ plantDetail }: RootState) => plantDetail.units;
export const selectCustomerPlantGroups = ({ plantDetail }: RootState) =>
  plantDetail.customerPlantGroups;
export const selectTerritories = ({ plantDetail }: RootState) =>
  plantDetail.territories.filter(
    (t) => !UnusedTerritories.some((u) => equals(u, t.name))
  );
export const selectHaapRates = ({ plantDetail }: RootState) =>
  plantDetail.haapRates;
export const selectError = ({ plantDetail }: RootState) => plantDetail.error;

export default plantDetailSlice.reducer;
