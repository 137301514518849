import { ApiBase } from './api-base';

class ReportsService extends ApiBase {
  configuration(): Promise<ReportsConfigurationResponse> {
    return this.get('/api/reports/configuration');
  }
}

export interface ReportsConfigurationResponse {
  reportsUrl: string;
}

export const reportsApi = new ReportsService();
