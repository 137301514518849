import { SalesAnalytic } from 'api/models/analytics';
import { LineChart, CartesianGrid, XAxis, YAxis, Line, LabelList, ResponsiveContainer } from 'recharts';

export type SummaryType = 'average' | 'sum';

interface AnalyticsChartProps<T> {
  title: string;
  value: SalesAnalytic<T>[];
  formatFunction: (value: any) => string;
  summaryType: SummaryType;
}

const margin = {top: 5, right: 30, left: 20, bottom: 5};

export function AnalyticsChart(props: AnalyticsChartProps<number | null>) {
  const {title, value, formatFunction, summaryType} = props,
    summary = summaryType === 'average' ? value.length ? value.reduce((total, v) => total + (v.value || 0), 0) / value.length : 0
      : value.reduce((total, v) => total + (v.value || 0), 0);

  const renderLabel = (props: any) => {
    const { x, y, value } = props;
  
    return (
      <text x={x} y={y - 20} fill="#545454" textAnchor="middle" dominantBaseline="middle" fontSize="0.8rem">
        {formatFunction(value)}
      </text>
      );
  };

  return (
    <div className="border rounded shadow h-100 p-3">
        <h3>{title}</h3>
        {!!value.length &&
          <h2 className="text-success">{summaryType === 'average' ? 'Average' : 'Total'}: {formatFunction(summary)}</h2>
        }
        <ResponsiveContainer maxHeight={410}>
          <LineChart data={value} margin={margin}>
            <CartesianGrid />
            <XAxis dataKey="weekDisplay" padding={{right: 25, left: 25}} />
            <YAxis padding={{top: 25}} />
            <Line dataKey="value" color="#5FA9D3" isAnimationActive={false}>
              <LabelList content={renderLabel} position="top" />
            </Line>
          </LineChart>
        </ResponsiveContainer>
    </div>
  )
}
