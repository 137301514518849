import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiBase, axiosBaseQuery } from './api-base';
import {
  CustomerListItem,
  CustomerDetail,
  SearchCustomer,
  CrmSummary,
  CustomerCallLogItem,
  CustomerSalesYear,
  CustomerSalesWeekItem,
  CustomerOrder,
  CustomerNote,
} from './models/customers';

class CustomerService extends ApiBase {
  setMinimumExempt(
    id: number,
    isWebOrderMinimumExempt: boolean
  ): Promise<CustomerDetailResponse> {
    return this.post(
      `/api/customers/${id}?IsWebOrderMinimumExempt=${isWebOrderMinimumExempt}`
    );
  }

  setStatus(id: number, year: number, week: number, status: string | null) {
    const data = { year, week, status };
    return this.post(`/api/customers/${id}/CallLog`, data);
  }

  setIsFavourite(id: number, favourite: boolean) {
    return this.post(`/api/customers/${id}/Favourite?favourite=${favourite}`);
  }

  setCustomerProductType(id: number, productType: string) {
    return this.post(
      `/api/customers/${id}/CustomerProductType?Type=${productType}`
    );
  }

  search(search: string, userId?: number): Promise<CustomerSearchResponse> {
    const query = userId ? `&userId=${userId}` : '';
    return this.get(`/api/customers/search?search=${search}${query}`);
  }
}

export const customerApi = createApi({
  reducerPath: 'customers-api',
  tagTypes: [
    'Customers',
    'CustomerOrders',
    'CustomerNotes',
    'APF',
    'CallLogStatuses',
  ],
  baseQuery: axiosBaseQuery('api/customers'),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    list: builder.query<CustomerListResponse, CustomerListArgs>({
      query: ({
        page,
        search,
        year,
        week,
        salesRep,
        zone,
        territory,
        apf,
        callLogStatus,
        customerProductType,
      }) => ({
        url:
          '?' +
          (page ? `&page=${page}` : '') +
          (search ? `&search=${search}` : '') +
          (year ? `&year=${year}` : '') +
          (week ? `&week=${week}` : '') +
          (salesRep != null ? `&salesRep=${salesRep}` : '') +
          (zone != null ? `&zone=${zone}` : '') +
          (territory != null ? `&territory=${territory}` : '') +
          (apf != null ? `&apf=${apf}` : '') +
          (callLogStatus != null ? `&callLogStatus=${callLogStatus}` : '') +
          (customerProductType != null
            ? `&customerProductType=${customerProductType}`
            : ''),
      }),
      providesTags: ['Customers'],
    }),
    detail: builder.query<CustomerDetailResponse, number>({
      query: (id) => ({ url: `/${id}` }),
      providesTags: ['Customers'],
    }),
    crm: builder.query<CustomerCrmResult, CustomerCrmArgs>({
      query: ({ customerId, year, week }) => ({
        url: `/${customerId}/crm?year=${year}&week=${week}`,
      }),
    }),
    orders: builder.query<CustomerOrdersResponse, CustomerOrderArgs>({
      query: ({ customerId, year, week, catalogType, sort }) => ({
        url:
          `/${customerId}/orders?` +
          (year ? `&year=${year}` : '') +
          (week ? `&week=${week}` : '') +
          (catalogType ? `&catalogType=${catalogType}` : '') +
          (sort ? `&sort=${sort}` : ''),
      }),
    }),
    notes: builder.query<CustomerNotesResponse, number>({
      query: (id) => ({ url: `/${id}/notes` }),
      providesTags: ['CustomerNotes'],
    }),
    createNote: builder.mutation<void, CreateNoteArgs>({
      query: ({ customerId, notes }) => ({
        url: `/${customerId}/notes`,
        method: 'POST',
        data: { notes },
      }),
      invalidatesTags: ['CustomerNotes'],
    }),
    updateNote: builder.mutation<void, UpdateNoteArgs>({
      query: ({ id, customerId, notes }) => ({
        url: `/${customerId}/notes/${id}`,
        method: 'PUT',
        data: { notes },
      }),
      invalidatesTags: ['CustomerNotes'],
    }),
    deleteNote: builder.mutation<void, number>({
      query: (id) => ({
        url: `/notes/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CustomerNotes'],
    }),
    apf: builder.query<ApfResponse, void>({
      query: () => ({ url: '/apf' }),
      providesTags: ['APF'],
    }),
    callLogStatuses: builder.query<CallLogStatusResponse, void>({
      query: () => ({ url: '/calllogstatuses' }),
      providesTags: ['CallLogStatuses'],
    }),
  }),
});

export interface CustomerListArgs {
  page: number;
  search: string;
  year: number | null;
  week: number | null;
  salesRep: string | null;
  zone: string | null;
  territory: string | null;
  apf: string | null;
  callLogStatus: string | null;
  customerProductType: string | null;
}

export interface CustomerListResponse {
  customers: CustomerListItem[];
  totalCustomerCount: number;
  salesReps: string[];
  zones: string[];
  territories: string[];
  apfs: string[];
  callLogStatuses: string[];
  customerProductTypes: string[];
}

export interface CustomerDetailResponse {
  customer: CustomerDetail;
}

export interface CustomerSearchResponse {
  customers: SearchCustomer[];
  count: number;
}

export interface CustomerCrmArgs {
  customerId: number;
  year: number;
  week: number;
}

export interface CustomerCrmResult {
  summary: CrmSummary;
  callLog: CustomerCallLogItem[];
  salesYears: CustomerSalesYear[];
  salesWeekItems: CustomerSalesWeekItem[];
}

export interface CustomerOrderArgs {
  customerId: number;
  catalogType: string | null;
  year: number | null;
  week: number | null;
  sort: string;
}

export interface CustomerOrdersResponse {
  orders: CustomerOrder[];
}

export interface CustomerNotesResponse {
  notes: CustomerNote[];
}

export interface CreateNoteArgs {
  customerId: number;
  notes: string;
}

export interface UpdateNoteArgs extends CreateNoteArgs {
  id: number;
}

export interface ApfResponse {
  apf: string[];
}

export interface CallLogStatusResponse {
  statuses: string[];
}

export const {
  useListQuery,
  useDetailQuery,
  useCrmQuery,
  useOrdersQuery,
  useNotesQuery,
  useApfQuery,
  useCallLogStatusesQuery,
  useCreateNoteMutation,
  useUpdateNoteMutation,
  useDeleteNoteMutation,
} = customerApi;

export const customerService = new CustomerService();
