import { createSlice, createAsyncThunk, AsyncThunk, createAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { confirmedOrderApi, ConfirmedOrderCubingResponse, ConfirmedOrderDetailResponse } from 'api/confirmed-order-service';
import { OrderCubing, OrderDetailWhilma } from 'api/models/orders';
import { ProblemDetails } from 'utils/problem-details';

export interface OrderDetailState {
  order: OrderDetailWhilma | null;
  loading: boolean;
  error: ProblemDetails | null;
}

const initialState: OrderDetailState = {
  order: null,
  loading: false,
  error: null
};

export const getConfirmedOrderDetail: AsyncThunk<ConfirmedOrderDetailResponse, number, {state: RootState}> = createAsyncThunk(
  'orders/getConfirmedOrderDetail',
  async(id, {rejectWithValue}) => {
    try {
      return await confirmedOrderApi.getOrderDetail(id);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

interface SetCubingArgs extends OrderCubing {
  id: number;
}

export const cubingUpdate: AsyncThunk<ConfirmedOrderDetailResponse, SetCubingArgs, {state: RootState}> = createAsyncThunk(
  'orders/setCubing',
  async(args, {rejectWithValue}) => {
    try {
      const {id, cubes, boxCount} = args;
      return await confirmedOrderApi.cubing(id, cubes, boxCount);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

const getConfirmedOrderDetailPending = createAction(getConfirmedOrderDetail.pending.type),
  getConfirmedOrderDetailFulfilled = createAction<ConfirmedOrderDetailResponse>(getConfirmedOrderDetail.fulfilled.type),
  getConfirmedOrderDetailRejected = createAction<ProblemDetails>(getConfirmedOrderDetail.rejected.type),
  cubingUpdatePending = createAction(cubingUpdate.pending.type),
  cubingUpdateFulfilled = createAction<ConfirmedOrderCubingResponse>(cubingUpdate.fulfilled.type),
  cubingUpdateRejected = createAction<ProblemDetails>(cubingUpdate.rejected.type);

export const confirmedOrderSlice = createSlice({
  name: 'confirmedOrderDetail',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    }
  },
  extraReducers: builder =>
    builder
      .addCase(getConfirmedOrderDetailPending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getConfirmedOrderDetailFulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
        state.order = action.payload.order;
      })
      .addCase(getConfirmedOrderDetailRejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(cubingUpdatePending, (state, action) => {
        state.loading = true;
      })
      .addCase(cubingUpdateFulfilled, (state, action) => {
        state.loading = false;
        state.order = action.payload.order;
      })
      .addCase(cubingUpdateRejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
});

export const { clearError } = confirmedOrderSlice.actions;

export const selectOrder = (state: RootState) => state.confirmedOrderDetail.order;
export const selectLoading = (state: RootState) => state.confirmedOrderDetail.loading;
export const selectError = (state: RootState) => state.confirmedOrderDetail.error;
export const selectSubTotal = (state: RootState) => state.confirmedOrderDetail.order?.items?.reduce((total, item) => total + (item.quantity * item.unitPrice), 0) || 0;

export default confirmedOrderSlice.reducer;
