import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Input, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import numeral from 'numeral';
import { BookingCatalogDetailInventory } from 'api/models/booking-catalogs';
import { selectBookingCatalog } from './booking-catalog-detail-slice';
import { handleFocus } from 'utils/focus';
import { contains, equals } from 'utils/equals';
import { formatNumber } from 'utils/format';

export interface AddProductsItemProps {
  item: BookingCatalogDetailInventory;
  availableFrom: moment.Moment;
  availableTo: moment.Moment;
  setItemQuantity: (
    productId: number,
    quantity: number | string | null
  ) => void;
  setItemMaximumOrderQuantity: (
    productId: number,
    quantity: number | null
  ) => void;
  setItemMinimumOrderStemQuantityCuts: (
    productId: number,
    quantity: number | null
  ) => void;
  setItemAvailabilityCutoff: (productId: number, cutoff: string | null) => void;
  setItemDataError: (productId: number, error: string | null) => void;
}

export function AddProductsItem(props: AddProductsItemProps) {
  const {
      item,
      availableFrom,
      availableTo,
      setItemQuantity,
      setItemMaximumOrderQuantity,
      setItemMinimumOrderStemQuantityCuts,
      setItemAvailabilityCutoff,
      setItemDataError,
    } = props,
    bookingCatalog = useSelector(selectBookingCatalog),
    isCuts = contains(bookingCatalog?.catalogType, 'Cut Flower'),
    quantityValue = item.quantity
      ? formatNumber(item.quantity, '0,0')
      : item.quantityMessage || '',
    [quantity, setQuantity] = useState(quantityValue),
    [maximumOrderQuantity, setMaximumOrderQuantity] = useState<number | null>(
      null
    ),
    [minimumOrderStemQuantityCuts, setMinimumOrderStemQuantityCuts] = useState<
      number | null
    >(null),
    [availabilityCutoff, setAvailabilityCutoff] =
      useState<moment.Moment | null>(null),
    cutoffError = getDataError(
      numeral(quantity).value(),
      availabilityCutoff,
      item,
      availableFrom,
      availableTo
    );

  useEffect(() => {
    setMaximumOrderQuantity(item.maximumOrderQuantity);
    setMinimumOrderStemQuantityCuts(item.minimumOrderStemQuantityCuts);
    if (item.availabilityCutoff) {
      var m = moment(item.availabilityCutoff);
      setAvailabilityCutoff(m);
    } else {
      setAvailabilityCutoff(null);
    }
  }, [item]);

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const quantity = e.target.value || '';
    setQuantity(quantity);
  };

  const handleQuantityBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    let quantity: string | number | null = parseInt(e.target.value);
    if (isNaN(quantity)) {
      quantity = e.target.value ? e.target.value : null;
    }
    setItemQuantity(item.productId, quantity);
    setItemDataError(
      item.productId,
      getDataError(
        quantity,
        availabilityCutoff,
        item,
        availableFrom,
        availableTo
      )
    );
  };

  const handleMaximumOrderQuantityChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const quantity = parseInt(e.target.value) || null;
    setMaximumOrderQuantity(quantity);
  };

  const handleMaximumOrderQuantityBlur = (
    e: React.FocusEvent<HTMLInputElement>
  ) => {
    const quantity = parseInt(e.target.value) || null;
    setItemMaximumOrderQuantity(item.productId, quantity);
  };

  const handleMinimumOrderStemQuantityCutsChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const quantity = parseInt(e.target.value) || null;
    setMinimumOrderStemQuantityCuts(quantity);
  };

  const handleMinimumOrderStemQuantityCutsBlur = (
    e: React.FocusEvent<HTMLInputElement>
  ) => {
    const quantity = parseInt(e.target.value) || null;
    setItemMinimumOrderStemQuantityCuts(item.productId, quantity);
  };

  const handleAvailabilityCutoffDateChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const date = moment(e.target.value, 'YYYY-MM-DD');
    if (date.isValid()) {
      const cutoff = (availabilityCutoff || moment())
        .clone()
        .year(date.year())
        .month(date.month())
        .date(date.date());
      setAvailabilityCutoff(cutoff);
      setItemAvailabilityCutoff(item.productId, cutoff.toISOString());
      setItemDataError(
        item.productId,
        getDataError(
          numeral(quantity).value(),
          cutoff,
          item,
          availableFrom,
          availableTo
        )
      );
    } else {
      setItemAvailabilityCutoff(item.productId, null);
      setItemDataError(item.productId, null);
    }
  };

  const handleAvailabilityCutoffTimeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const time = moment(e.target.value, 'HH:mm');
    if (time.isValid()) {
      const cutoff = (availabilityCutoff || moment())
        .clone()
        .hour(time.hour())
        .minute(time.minute());
      setAvailabilityCutoff(cutoff);
      setItemAvailabilityCutoff(item.productId, cutoff.toISOString());
      setItemDataError(
        item.productId,
        getDataError(
          numeral(quantity).value(),
          cutoff,
          item,
          availableFrom,
          availableTo
        )
      );
    } else {
      setItemAvailabilityCutoff(item.productId, null);
      setItemDataError(item.productId, null);
    }
  };

  return (
    <tr className={cutoffError ? 'table-danger' : ''}>
      <td className="py-2">{item.category}</td>
      <td className="py-2">
        {item.size}&nbsp;{item.description1}
        {item.description2}
      </td>
      <td className="py-2">{item.colour}</td>
      <td className="py-2">{item.supplier}</td>
      <td className="py-2">{item.comment}</td>
      <td className="text-right pr-2 py-2">{item.caseQuantity}</td>
      <td className="text-center py-1">
        <Input
          bsSize="sm"
          value={quantity}
          onChange={handleQuantityChange}
          onBlur={handleQuantityBlur}
          onFocus={handleFocus}
          className="quantity text-right mx-auto"
        />
      </td>
      <td className="text-center py-1">
        <Input
          bsSize="sm"
          value={maximumOrderQuantity || ''}
          onChange={handleMaximumOrderQuantityChange}
          onBlur={handleMaximumOrderQuantityBlur}
          onFocus={handleFocus}
          className="quantity text-right mx-auto"
        />
      </td>
      {isCuts && (
        <td className="text-center py-1">
          <Input
            bsSize="sm"
            value={minimumOrderStemQuantityCuts || ''}
            onChange={handleMinimumOrderStemQuantityCutsChange}
            onBlur={handleMinimumOrderStemQuantityCutsBlur}
            onFocus={handleFocus}
            className="quantity text-right mx-auto"
          />
        </td>
      )}
      <td className="py-1 pr-0">
        {cutoffError && (
          <>
            <FontAwesomeIcon
              id={`cutoff-error-${item.productId}`}
              icon={['fad', 'exclamation-triangle']}
              className="text-danger"
            />
            <UncontrolledTooltip target={`cutoff-error-${item.productId}`}>
              {cutoffError}
            </UncontrolledTooltip>
          </>
        )}
      </td>
      <td className="text-center py-1 pl-1 px-0">
        <Input
          bsSize="sm"
          type="date"
          value={availabilityCutoff?.format('YYYY-MM-DD') || ''}
          onChange={handleAvailabilityCutoffDateChange}
          onFocus={handleFocus}
          className="mx-auto"
        />
      </td>
      <td className="text-center py-1 pl-1 pr-0">
        <Input
          bsSize="sm"
          type="time"
          value={availabilityCutoff?.format('HH:mm') || ''}
          onChange={handleAvailabilityCutoffTimeChange}
          onFocus={handleFocus}
          className="mx-auto"
        />
      </td>
    </tr>
  );
}

function getDataError(
  quantity: number | string | null,
  availabilityCutoff: moment.Moment | null,
  item: BookingCatalogDetailInventory,
  availableFrom: moment.Moment,
  availableTo: moment.Moment
) {
  if (typeof quantity !== 'number') {
    return null;
  }

  if (!numeral(quantity).value()) {
    return null;
  }

  if (availabilityCutoff) {
    if (availableTo.isBefore(availabilityCutoff)) {
      return 'Cutoff is after availability expires';
    }

    if (availableFrom.isAfter(availabilityCutoff)) {
      return 'Cutoff is before availability begins';
    }
  }

  if (equals(item.unitOfMeasureCuts, 'Bu') && item.stemsPerBunch == null) {
    //return 'Item is sold per bunch but does not have a stems per bunch value in Whilma.';
  }

  return null;
}
