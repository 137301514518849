import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Input,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useListQuery } from 'api/customer-service';
import {
  selectCustomers,
  selectLoading,
  selectError,
  setSearch,
  selectSearch,
  selectPage,
  selectYears,
  selectWeeks,
  selectYear,
  selectWeek,
  selectFilter,
  setFilter,
  setYear,
  setWeek,
  selectSalesReps,
  selectZones,
  selectTerritories,
  selectApfs,
  selectCallLogStatuses,
  selectCustomerProductTypes,
} from './customer-list-slice';
import { handleDropdownToggle, maxHeightModifiers } from 'utils/dropdown';
import { ListFilter } from './ListFilter';
import { ListItem } from './ListItem';
import { CustomerPaginator } from './CustomerPaginator';

export function List() {
  const dispatch = useDispatch(),
    error = useSelector(selectError),
    loading = useSelector(selectLoading),
    search = useSelector(selectSearch),
    years = useSelector(selectYears),
    weeks = useSelector(selectWeeks),
    year = useSelector(selectYear),
    week = useSelector(selectWeek),
    page = useSelector(selectPage),
    filter = useSelector(selectFilter),
    { salesRep, zone, territory, apf, callLogStatus, customerProductType } =
      filter,
    hasFilter = Object.values(filter).some((v) => v != null),
    salesReps = useSelector(selectSalesReps),
    zones = useSelector(selectZones),
    territories = useSelector(selectTerritories),
    apfs = useSelector(selectApfs),
    callLogStatuses = useSelector(selectCallLogStatuses),
    customerProductTypes = useSelector(selectCustomerProductTypes),
    customers = useSelector(selectCustomers),
    { refetch } = useListQuery({
      page,
      search,
      year,
      week,
      salesRep,
      zone,
      territory,
      apf,
      callLogStatus,
      customerProductType,
    });

  const refresh = () => refetch();

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search = e.target.value || '';
    dispatch(setSearch(search));
  };

  const handleClearFilterClick = () => {
    const filter = {
      salesRep: null,
      zone: null,
      territory: null,
      apf: null,
      callLogStatus: null,
      customerProductType: null,
    };
    dispatch(setFilter(filter));
  };

  const handleYearClick = (year: number) => {
    dispatch(setYear(year));
  };

  const handleWeekClick = (week: number) => {
    dispatch(setWeek(week));
  };

  return (
    <div className="container-fluid px-4 mt-4">
      <div className="row">
        <h1 className="col">
          <FontAwesomeIcon icon={['fad', 'users']} />
          &nbsp; Customers
        </h1>
        <div className="col-12 col-md-auto mb-md-2">
          <Button outline color="success" onClick={refresh}>
            Refresh &nbsp;
            <FontAwesomeIcon icon={['fad', 'sync']} />
          </Button>
        </div>
      </div>
      {loading && !customers.length && (
        <h1 className="text-success text-center">
          &nbsp;
          <FontAwesomeIcon icon={['fad', 'spinner']} spin />
        </h1>
      )}
      {error && (
        <div className="alert alert-danger">
          <p className="lead">{error.title}</p>
          <p>{error.detail}</p>
        </div>
      )}
      <div className="row my-4">
        <div className="col-12 col-md-4">
          <Input
            type="search"
            value={search}
            onChange={handleSearchChange}
            autoFocus
            placeholder="Search for customers"
          />
        </div>
        {hasFilter && (
          <div className="col-12 col-md-auto">
            <Button color="secondary" onClick={handleClearFilterClick}>
              Clear Filters &nbsp;
              <FontAwesomeIcon icon={['fad', 'times']} />
            </Button>
          </div>
        )}
        <div className="col"></div>
        <div className="col-12 col-md-auto">
          <UncontrolledDropdown>
            <DropdownToggle caret color="secondary">
              {year}
            </DropdownToggle>
            <DropdownMenu>
              {years.map((y) => (
                <DropdownItem key={y} onClick={() => handleYearClick(y)}>
                  {y}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <div className="col-12 col-md-auto">
          <UncontrolledDropdown onToggle={handleDropdownToggle}>
            <DropdownToggle caret color="secondary">
              {week}
            </DropdownToggle>
            <DropdownMenu modifiers={maxHeightModifiers()}>
              {weeks.map((w) => (
                <DropdownItem key={w} onClick={() => handleWeekClick(w)}>
                  {w}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
      <table className="table table-sm">
        <thead>
          <tr>
            <th>Customer</th>
            <th>Contact</th>
            <th>Phone</th>
            <th>Mobile</th>
            <th>Email</th>
            <th>
              <ListFilter
                title="Sales Rep"
                property="salesRep"
                values={salesReps}
              />
            </th>
            <th>
              <ListFilter title="Zone" property="zone" values={zones} />
            </th>
            <th>
              <ListFilter
                title="Territory"
                property="territory"
                values={territories}
              />
            </th>
            <th>
              <ListFilter
                title="Type"
                property="customerProductType"
                values={customerProductTypes}
              />
            </th>
            <th>
              <ListFilter title="APF" property="apf" values={apfs} />
            </th>
            <th>
              <ListFilter
                title="Status"
                property="callLogStatus"
                values={callLogStatuses}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {customers.map((customer) => (
            <ListItem
              key={customer.id}
              customer={customer}
              refreshList={refresh}
            />
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th colSpan={10} className="text-center">
              <CustomerPaginator />
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}
