import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import auth from 'features/auth/auth-slice';
import home from 'features/home/home-slice';
import orders from 'features/orders/order-list-slice';
import orderDetail from 'features/orders/order-detail-slice';
import confirmedOrders from 'features/confirmed-orders/confirmed-order-list-slice';
import confirmedOrderDetail from 'features/confirmed-orders/confirmed-order-detail-slice';
import carts from 'features/carts/cart-list-slice';
import cartDetail from 'features/carts/cart-detail-slice';
import bookingCatalogs from 'features/booking-catalogs/booking-catalog-list-slice';
import bookingCatalogDetail from 'features/booking-catalogs/booking-catalog-detail-slice';
import addProductsToBookingCatalog from 'features/booking-catalogs/add-products-slice';
import bookingCatalogPricing from 'features/booking-catalogs/pricing-slice';
import customers from 'features/customers/customer-list-slice';
import customerDetail from 'features/customers/customer-detail-slice';
import customerUser from 'features/customers/customer-user-slice';
import addCustomer from 'features/customers/add-customer-slice';
import analytics from 'features/analytics/analytics-slice';
import xero from 'features/xero/xero-slice';
import reports from 'features/reports/reports-slice';
import { authorizationHeaderMiddleware } from './store/middleware/authorization-headers';
import saveOnFoods from 'features/save-on-foods/save-on-foods-slice';
import thriftysSingleOrder from 'features/thriftys/thriftys-single-order-slice';
import thriftysMultipleOrder from 'features/thriftys/thriftys-multiple-order-slice';
import marketOfChoice from 'features/market-of-choice/market-of-choice-slice';
import calgaryCoop from 'features/calgary-coop/calgary-coop-slice';
import fresonBros from 'features/freson-bros/freeson-bros-slice';
import plantList from 'features/products/plants/plant-list-slice';
import plantDetail from 'features/products/plants/plant-detail-slice';
import alerts from 'features/alerts/alert-list-slice';
import { alertApi } from 'api/alert-service';
import { customerApi } from 'api/customer-service';

export const store = configureStore({
  reducer: {
    auth,
    home,
    orders,
    orderDetail,
    confirmedOrders,
    confirmedOrderDetail,
    carts,
    cartDetail,
    bookingCatalogs,
    bookingCatalogDetail,
    addProductsToBookingCatalog,
    bookingCatalogPricing,
    customers,
    customerDetail,
    customerUser,
    addCustomer,
    analytics,
    xero,
    reports,
    saveOnFoods,
    thriftysSingleOrder,
    thriftysMultipleOrder,
    marketOfChoice,
    calgaryCoop,
    fresonBros,
    plantList,
    plantDetail,
    alerts,
    [customerApi.reducerPath]: customerApi.reducer,
    [alertApi.reducerPath]: alertApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authorizationHeaderMiddleware.middleware,
      customerApi.middleware,
      alertApi.middleware
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
