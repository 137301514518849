import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNotesQuery } from 'api/customer-service';
import { CustomerNote } from 'api/models/customers';
import { selectNotes } from './customer-detail-slice';
import { NoteItem } from './NoteItem';
import { NoteModal } from './NoteModal';

interface NotesProps {
  customerId: number;
}

export function NotesTab({ customerId }: NotesProps) {
  const notes = useSelector(selectNotes),
    [showAddNote, setShowAddNote] = useState<CustomerNote | null>(null);

  useNotesQuery(customerId);

  const handleAddNoteClick = () => {
    setShowAddNote({ id: 0, notes: '' });
  };

  const handleAddNoteCancel = () => {
    setShowAddNote(null);
  };

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <h3>Notes</h3>
        </div>
        <div className="col-12">
          <Button
            outline
            color="success"
            size="lg"
            onClick={handleAddNoteClick}>
            Add Note &nbsp;
            <FontAwesomeIcon icon={['fal', 'plus']} />
          </Button>
        </div>
      </div>
      {notes.map((note) => (
        <NoteItem key={note.id} note={note} customerId={customerId} />
      ))}
      <NoteModal
        customerId={customerId}
        note={showAddNote}
        close={handleAddNoteCancel}
      />
    </div>
  );
}
