import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import numeral from 'numeral';
import {
  Button,
  UncontrolledTooltip,
  Nav,
  NavItem,
  TabContent,
  TabPane,
} from 'reactstrap';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  selectLoading,
  selectError,
  selectCustomer,
  clearError,
  setMinimumExempt,
  setIsFavourite,
  setCustomerProductType,
  clearState,
} from 'features/customers/customer-detail-slice';
import { getUser, setUser } from 'features/customers/customer-user-slice';
import { routes } from 'app/routes';
import { useDetailQuery } from 'api/customer-service';
import { CustomerUser as User } from 'api/models/customers';
import { Error } from 'features/errors/Error';
import { formatDate } from 'utils/format';
import { CustomerUser } from './User';
import { CrmTab } from './CrmTab';
import { OrdersTab } from './OrdersTab';
import { NotesTab } from './NotesTab';
import { CustomInput } from 'reactstrap';
import { equals } from 'utils/equals';

type TabId = 'crm' | 'orders' | 'notes';

export function Detail() {
  const dispatch = useDispatch(),
    navigate = useNavigate(),
    location = useLocation(),
    hash = (location.hash?.replace('#', '') || 'crm') as TabId,
    from = location.state?.from || routes.customers.path,
    loading = useSelector(selectLoading),
    error = useSelector(selectError),
    customer = useSelector(selectCustomer),
    [confirmSetMinimumExempt, setConfirmSetMinimumExempt] = useState(false),
    [tabId, setTabId] = useState<TabId | null>(null),
    { id } = useParams<{ id: string }>(),
    idValue = numeral(id).value() || 0;

  useDetailQuery(idValue);

  useEffect(() => {
    setConfirmSetMinimumExempt(false);

    return function cleanup() {
      dispatch(clearState());
    };
  }, [idValue, dispatch]);

  useEffect(() => {
    if (!tabId) {
      setTabId(hash);
    }
  }, [hash, tabId]);

  const toggleError = () => {
    dispatch(clearError());
  };

  const handleTabClick = (tab: TabId) => {
    setTabId(tab);
  };

  const handleAddUserClick = () => {
    if (customer) {
      const newUser = {
        id: 0,
        email: '',
        name: '',
        canOrderPlants: true,
        canOrderCutFlowers: true,
        isEnabled: true,
        lastLogin: null,
        lastAccessed: null,
        customers: [
          {
            customerId: customer.id,
            name: customer.shipName,
            shipAddress: customer.shipAddress,
            shipProvince: customer.shipProvince,
          },
        ],
      };
      dispatch(setUser(newUser));
    }
  };

  const handleEditUserClick = (user: User) => {
    dispatch(getUser(user.id));
  };

  const handleFavouriteClick = () => {
    if (customer) {
      dispatch(setIsFavourite(!customer.isFavourite));
    }
  };

  const handleCustomerProductTypeChange = (type: string) => {
    dispatch(setCustomerProductType(type));
  };

  const handleToggleMinimumExemptClick = () => {
    if (customer) {
      setConfirmSetMinimumExempt(true);
    }
  };

  const handleConfirmSetMinimumExempt = () => {
    setConfirmSetMinimumExempt(false);
    dispatch(setMinimumExempt());
  };

  const handleCloseClick = () => {
    navigate(from);
  };

  return (
    <div className="container-fluid px-4 mt-4">
      <Error error={error} clearError={toggleError} />
      {loading && (
        <h1 className="text-success text-center">
          &nbsp;
          <FontAwesomeIcon icon={['fad', 'spinner']} spin />
        </h1>
      )}
      {!!customer && (
        <>
          <div className="row">
            <div className="col">
              <h1 className="ml-2">
                <FontAwesomeIcon icon={['fad', 'user']} />
                &nbsp;
                <span>{customer.shipName}</span>
              </h1>
            </div>
            <div className="col-auto">
              <Button
                to={routes.customers.path}
                size="lg"
                onClick={handleCloseClick}>
                Close
              </Button>
            </div>
          </div>
          <div className="row ml-2">
            <div className="col-12 col-lg-4">
              <div className="row border rounded">
                <div className="col-12 pt-4 border-bottom">
                  <address>
                    {customer.shipAddress}
                    <br />
                    {customer.shipProvince}
                  </address>
                </div>
                <div className="col-12 pt-4 border-bottom">
                  <p className="font-weight-bold font-italic mb-0">Territory</p>
                  <p>{customer.territory || 'None'}</p>
                </div>
                <div className="col-12 pt-4 border-bottom">
                  <p className="font-weight-bold font-italic mb-0">APF</p>
                  <p>{customer.apf || 'None'}</p>
                </div>
                <div className="col-12 pt-4 border-bottom">
                  <p className="font-weight-bold font-italic mb-0">
                    Customer Type
                  </p>
                  <div className="row mt-2">
                    <div className="col-auto">
                      <CustomInput
                        id="customer-product-type-plants"
                        name="customer-product-type"
                        type="radio"
                        label="Plants"
                        checked={equals(customer.customerProductType, 'Plants')}
                        onChange={() =>
                          handleCustomerProductTypeChange('Plants')
                        }
                        className="custom-checkbox-success"
                      />
                    </div>
                    <div className="col-auto">
                      <CustomInput
                        id="customer-product-type-cuts"
                        name="customer-product-type"
                        type="radio"
                        label="Cuts"
                        checked={
                          !equals(customer.customerProductType, 'Plants')
                        }
                        onChange={() =>
                          handleCustomerProductTypeChange('Cut Flowers')
                        }
                        className="custom-checkbox-success"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 pt-4 border-bottom">
                  <p className="font-weight-bold font-italic mb-0">Sales Rep</p>
                  <p>{customer.salesRep || 'Unassigned'}</p>
                </div>
                <div className="col-12 py-4">
                  <button
                    type="button"
                    className="bg-transparent border-0"
                    onClick={handleFavouriteClick}>
                    <FontAwesomeIcon
                      size="2x"
                      icon={[customer.isFavourite ? 'fad' : 'fal', 'star']}
                      className={customer.isFavourite ? 'text-warning' : ''}
                    />
                    &nbsp;
                    {customer.isFavourite ? 'Favourited' : 'Favourite'}
                  </button>
                </div>
              </div>
              <div className="row border rounded mt-4">
                <div className="col-12 py-4">
                  <h2 className="col-12">Minimum Order Requirement</h2>
                  <div className="col-12">
                    <Button
                      color="success"
                      outline={customer.isWebOrderMinimumExempt}
                      size="sm"
                      onClick={handleToggleMinimumExemptClick}>
                      YES
                    </Button>
                    &nbsp;
                    <Button
                      color="danger"
                      outline={!customer.isWebOrderMinimumExempt}
                      size="sm"
                      onClick={handleToggleMinimumExemptClick}>
                      NO
                    </Button>
                  </div>
                  {!!customer.webOrderMinimumExemptChanged && (
                    <div className="col-12 mt-4">
                      <p className="font-weight-bold font-italic mb-0">
                        Last Updated
                      </p>
                      <p className="mb-0">
                        {formatDate(
                          customer.webOrderMinimumExemptChanged,
                          'MMMM D, YYYY'
                        )}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="row border rounded mt-4">
                <h2 className="col-12 mt-4">Web Users</h2>
                <div className="col-12 table-responsive">
                  <table className="table table-sm">
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {customer.users.map((user) => (
                        <tr key={user.id}>
                          <td>
                            <Button
                              onClick={() => handleEditUserClick(user)}
                              size="sm">
                              <FontAwesomeIcon icon={['fad', 'edit']} />
                            </Button>
                          </td>
                          <td>{user.name}</td>
                          <td>{user.email}</td>
                          <td className="text-center">
                            {user.isEnabled && (
                              <>
                                <FontAwesomeIcon
                                  icon={['fad', 'user-plus']}
                                  fixedWidth
                                  className="text-success"
                                  id={`enabled-icon-${user.id}`}
                                />
                                <UncontrolledTooltip
                                  target={`enabled-icon-${user.id}`}>
                                  User is enabled
                                </UncontrolledTooltip>
                              </>
                            )}
                            {!user.isEnabled && (
                              <>
                                <FontAwesomeIcon
                                  icon={['fad', 'user-times']}
                                  fixedWidth
                                  className="text-danger"
                                  id={`disabled-icon-${user.id}`}
                                />
                                <UncontrolledTooltip
                                  target={`disabled-icon-${user.id}`}>
                                  User is disabled
                                </UncontrolledTooltip>
                              </>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th colSpan={6}>
                          <Button
                            color="success"
                            outline
                            size="sm"
                            onClick={handleAddUserClick}>
                            Add User &nbsp;
                            <FontAwesomeIcon icon={['fad', 'user-plus']} />
                          </Button>
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-8 d-flex flex-column">
              <Nav tabs>
                <NavItem>
                  <Link
                    to={`${routes.customerDetail.to(idValue)}#crm`}
                    state={{ from }}
                    onClick={() => handleTabClick('crm')}
                    className={'nav-link ' + (tabId === 'crm' ? 'active' : '')}>
                    CRM
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    to={`${routes.customerDetail.to(idValue)}#orders`}
                    state={{ from }}
                    onClick={() => handleTabClick('orders')}
                    className={
                      'nav-link ' + (tabId === 'orders' ? 'active' : '')
                    }>
                    Orders
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    to={`${routes.customerDetail.to(idValue)}#notes`}
                    state={{ from }}
                    onClick={() => handleTabClick('notes')}
                    className={
                      'nav-link ' + (tabId === 'notes' ? 'active' : '')
                    }>
                    Notes
                  </Link>
                </NavItem>
              </Nav>
              <TabContent
                activeTab={tabId || 'crm'}
                className="border border-top-0 rounded-bottom p-4 d-flex flex-grow-1">
                <TabPane tabId="crm" className="w-100">
                  {tabId === 'crm' && <CrmTab customerId={idValue} />}
                </TabPane>
                <TabPane tabId="orders" className="w-100">
                  {tabId === 'orders' && <OrdersTab customerId={idValue} />}
                </TabPane>
                <TabPane tabId="notes" className="w-100">
                  {tabId === 'notes' && <NotesTab customerId={idValue} />}
                </TabPane>
              </TabContent>
            </div>

            <CustomerUser customerId={customer.id} />
            {confirmSetMinimumExempt && (
              <ReactBSAlert
                title={
                  customer.isWebOrderMinimumExempt
                    ? 'Require minimum order value'
                    : 'Remove minimum order value'
                }
                confirmBtnText="Yes"
                cancelBtnText="No"
                showCancel
                confirmBtnBsStyle="success"
                onConfirm={handleConfirmSetMinimumExempt}
                onCancel={() => setConfirmSetMinimumExempt(false)}>
                Are you sure you want to{' '}
                {customer.isWebOrderMinimumExempt
                  ? 'require a minimum order value'
                  : 'remove the minimum order value'}{' '}
                for this customer?
              </ReactBSAlert>
            )}
          </div>
        </>
      )}
    </div>
  );
}
