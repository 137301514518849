import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import {
  setEditItem,
  selectBookingCatalog,
} from './booking-catalog-detail-slice';
import { BookingCatalogDetailInventory } from 'api/models/booking-catalogs';
import { contains } from 'utils/equals';
import { formatUTCDate, formatNumber } from 'utils/format';

export interface BookingCatalogItemProps {
  item: BookingCatalogDetailInventory;
}

export function BookingCatalogItem(props: BookingCatalogItemProps) {
  const { item } = props,
    dispatch = useDispatch(),
    bookingCatalog = useSelector(selectBookingCatalog),
    {
      quantity,
      quantityOnOrders,
      quantityOnWebOrders,
      quantityInCarts,
      quantityMessage,
      maximumOrderQuantity,
      minimumOrderStemQuantityCuts,
    } = item,
    isCuts = contains(bookingCatalog?.catalogType, 'Cut Flower'),
    caseQuantity = isCuts ? 1 : item.caseQuantity,
    formattedQuantity =
      quantity == null
        ? quantityMessage || 'Good Availability'
        : formatNumber(quantity / caseQuantity, '0,0'),
    cartQuantity = quantityInCarts / caseQuantity,
    webOrderQuantity = quantityOnWebOrders / caseQuantity,
    orderQuantity = quantityOnOrders / caseQuantity,
    available =
      quantity == null
        ? 0
        : Math.max(
            quantity - quantityOnOrders - quantityOnWebOrders - quantityInCarts,
            0
          ),
    availableInCases = available / caseQuantity,
    availableFormatted =
      quantity == null ? '-' : formatNumber(availableInCases, '0,0'),
    isAvailable = quantity == null || available > 0,
    isOld = isLegacyProduct(item);

  const handleEditItemClick = () => {
    dispatch(setEditItem(item));
  };

  return (
    <div className="row border-top py-1">
      <div className={`col-12 ${isCuts ? 'col-md-3' : 'col-md-4'}`}>
        <Button
          color="link"
          size="sm"
          block
          onClick={handleEditItemClick}
          className="text-body text-left text-truncate no-focus">
          <img
            src={item.imageUrl || ''}
            alt={`${item.size} ${item.description1} ${item.description2}`}
            className={`${item.imageUrl ? '' : 'invisible'}`}
            style={{ maxWidth: '35px' }}
          />
          &nbsp;
          {item.size}&nbsp;{item.description1}
          {item.description2}
          &nbsp;
          <span className="small font-italic pt-2">{item.colour}</span>
          {isOld && (
            <span className="text-danger small italic">&nbsp;(OLD)</span>
          )}
        </Button>
      </div>
      <div className="col-6 col-md-1 text-center small pt-2 px-0">
        <span className="text-nowrap">
          {formatUTCDate(item.availabilityCutoff)}
        </span>
        <br />
        <span>{formatUTCDate(item.availabilityCutoff, 'h:mm a')}</span>
      </div>
      <div className="col-6 col-md-1 text-right small pt-2">
        {item.caseQuantity}
      </div>
      <div className="col-6 col-md-1 text-right small pt-2">
        {!!maximumOrderQuantity &&
          formatNumber(maximumOrderQuantity / caseQuantity)}
      </div>
      {isCuts && (
        <div className="col-6 col-md-1 text-right small pt-2">
          {!!minimumOrderStemQuantityCuts &&
            formatNumber(minimumOrderStemQuantityCuts)}
        </div>
      )}
      <div className="col-12 col-md-1 text-right small pt-2">
        {formattedQuantity}
      </div>
      <div className="col-6 col-md-1 text-right small text-nowrap pt-2">
        {formatNumber(cartQuantity, '0,0')}
      </div>
      <div className="col-6 col-md-1 text-right small text-nowrap pt-2">
        {formatNumber(webOrderQuantity, '0,0')}
      </div>
      <div className="col-6 col-md-1 text-right small text-nowrap pt-2">
        {formatNumber(orderQuantity, '0,0')}
      </div>
      <div className="col-6 col-md-1 text-right small text-nowrap pt-2">
        {isAvailable && availableFormatted}
        {!isAvailable && <span className="text-danger">SOLD OUT!</span>}
      </div>
    </div>
  );
}

// HACK: This is the last product that was created before the new Plant screen
function isLegacyProduct(product: BookingCatalogDetailInventory) {
  return !contains(product.group, 'cut flowers') && product.productId < 9888;
}
