import { jwtDecode } from 'jwt-decode';
import { PublicClientApplication } from '@azure/msal-browser';
import { anonAxios as axios } from 'boot/axios';
import { ProblemDetails, createProblemDetails } from 'utils/problem-details';
import { User } from 'api/models/user';
import { routes } from 'app/routes';

interface ClientAuthConfig {
  clientId: string;
  authority: string;
  redirectUri: string;
  scopes: string[];
}

class AuthorizationService {
  private publicClientApplication: PublicClientApplication | null = null;
  private scopes: string[] = [];

  async init(): Promise<User | null> {
    const response = await axios.get<ClientAuthConfig>('/api/auth/config'),
      auth = response.data;

    this.scopes = auth.scopes;

    this.publicClientApplication = new PublicClientApplication({
      auth,
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true,
      },
    });
    await this.publicClientApplication.initialize();

    // If MSAL already has an account, the user
    // is already logged in
    const accounts = this.publicClientApplication.getAllAccounts();

    if (accounts && accounts.length > 0) {
      // Enhance user object with data from Graph
      return this.getUserProfile();
    }

    return null;
  }

  async login(): Promise<User | null> {
    try {
      if (!this.publicClientApplication) {
        await this.init();
      }

      if (!this.publicClientApplication) {
        return null;
      }

      // Login via popup
      await this.publicClientApplication.loginPopup({
        scopes: this.scopes,
        prompt: 'select_account',
      });

      // After login, get the user's profile
      return this.getUserProfile();
    } catch (err) {
      throw this.normalizeError(err);
    }
  }

  async logout() {
    const { protocol, host } = window.location,
      path = `${protocol}//${host}${routes.login.path}`;
    await this.publicClientApplication?.logoutPopup({
      mainWindowRedirectUri: path,
    });
  }

  async getAccessToken(): Promise<string | null> {
    if (!this.publicClientApplication) {
      await this.init();
    }

    if (!this.publicClientApplication) {
      return null;
    }

    try {
      const accounts = this.publicClientApplication.getAllAccounts();

      if (accounts.length <= 0) throw new Error('login_required');
      // Get the access token silently
      // If the cache contains a non-expired token, this function
      // will just return the cached token. Otherwise, it will
      // make a request to the Azure OAuth endpoint to get a token
      const silentResult =
        await this.publicClientApplication.acquireTokenSilent({
          scopes: this.scopes,
          account: accounts[0],
        });

      return silentResult.accessToken;
    } catch (err) {
      // If a silent request fails, it may be because the user needs
      // to login or grant consent to one or more of the requested scopes
      if (this.isInteractionRequired(err)) {
        const interactiveResult =
          await this.publicClientApplication.acquireTokenPopup({
            scopes: this.scopes,
          });

        return interactiveResult.accessToken;
      } else {
        throw err;
      }
    }
  }

  isInteractionRequired(error: Error): boolean {
    if (!error.message || error.message.length <= 0) {
      return false;
    }

    return (
      error.message.indexOf('consent_required') > -1 ||
      error.message.indexOf('interaction_required') > -1 ||
      error.message.indexOf('login_required') > -1 ||
      error.message.indexOf('no_account_in_silent_request') > -1
    );
  }

  async getUserProfile(): Promise<User | null> {
    try {
      const accessToken = await this.getAccessToken();

      if (accessToken) {
        const decoded = jwtDecode(accessToken) as any,
          { family_name, given_name, name, unique_name } = decoded,
          user: User = {
            email: unique_name || null,
            firstName: given_name || null,
            lastName: family_name || null,
            fullName: name || null,
          };

        return user;
      }

      return null;
    } catch (err) {
      throw this.normalizeError(err);
    }
  }

  normalizeError(error: string | Error): ProblemDetails {
    if (typeof error === 'string') {
      const errParts = error.split('|');
      return errParts.length > 1
        ? createProblemDetails(errParts[1], errParts[0])
        : createProblemDetails(error);
    }

    return createProblemDetails(error.message, JSON.stringify(error));
  }
}

export const authService = new AuthorizationService();
