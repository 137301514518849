import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, Link } from 'react-router-dom';
import moment from 'moment';
import numeral from 'numeral';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getOrderDetail,
  approveOrder,
  setCommentsToCustomer,
  clearError,
  selectLoading,
  selectError,
  selectOrder,
  selectSubTotal,
  cancelOrder,
} from './order-detail-slice';
import { formatNumber, formatDate, formatCurrency } from 'utils/format';
import {
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
} from 'reactstrap';
import { DetailRow } from './DetailRow';
import { routes } from 'app/routes';
import { IconName } from '@fortawesome/pro-light-svg-icons';
import { Error } from 'features/errors/Error';
import { contains } from 'utils/equals';
import { orderApi } from 'api/order-service';

export function Detail() {
  const dispatch = useDispatch(),
    navigate = useNavigate(),
    loading = useSelector(selectLoading),
    error = useSelector(selectError),
    order = useSelector(selectOrder),
    { id } = useParams<{ id: string }>(),
    idValue = numeral(id).value() || 0,
    subTotal = useSelector(selectSubTotal),
    [showApprovalModal, setShowApprovalModal] = useState(false),
    [showCancelModal, setShowCancelModal] = useState(false),
    [caseCountCuts, setCaseCountCuts] = useState<number | null>(null),
    [cubesCuts, setCubesCuts] = useState<number | null>(null),
    hasShipWeeks = order?.items.some((i) => i.shipWeek),
    hasOver1000 = order?.items.some((i) => i.quantity > 1000),
    isCuts = contains(order?.catalogType, 'cut flower'),
    // pack quantity > 1 rules out cases where we don't know how many stems/case
    hasStems = order?.items.some(
      (i) => i.unitOfMeasure !== 'St' && i.stemsPerBunch && i.packQuantity > 1
    ),
    isPrebook = contains(order?.catalogType, 'prebook'),
    subtitle =
      (isCuts ? 'Cut Flowers' : isPrebook ? 'Prebook' : 'Plants') +
      ': ' +
      order?.shipWeek,
    isOverdue = order && moment.utc(order.availableTo).isBefore();

  useEffect(() => {
    dispatch(getOrderDetail(idValue));
  }, [dispatch, idValue]);

  const handleApproveOrderClick = async () => {
    const response = await orderApi.cubing(idValue);
    if (response?.cubing) {
      setCaseCountCuts(response.cubing.boxCount);
      setCubesCuts(response.cubing.cubes);
    }

    setShowApprovalModal(true);
  };

  const handleCancelOrderClick = () => {
    setShowCancelModal(true);
  };

  const hideShowApproveModal = () => {
    setShowApprovalModal(false);
  };

  const hideShowCancelModal = () => {
    setShowCancelModal(false);
  };

  const handleCommentsToCustomerChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    dispatch(setCommentsToCustomer(e.target.value));
  };

  const handleCaseCountCutsChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const caseCountCuts = e.target.valueAsNumber || 0;
    setCaseCountCuts(caseCountCuts);
  };

  const handleCubesCutsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cubesCuts = e.target.valueAsNumber || 0;
    setCubesCuts(cubesCuts);
  };

  const approve = async () => {
    const result = (await dispatch(
      approveOrder({ caseCountCuts, cubesCuts })
    )) as any;
    if (!result.error) {
      navigate(routes.orders.path);
    }
  };

  const cancel = async () => {
    const result = (await dispatch(cancelOrder())) as any;
    if (!result.error) {
      navigate(routes.orders.path);
    }
  };

  const toggleError = () => {
    dispatch(clearError());
  };

  return (
    <div className="container mt-4">
      <div className="row">
        <h1 className="col">
          <FontAwesomeIcon icon={['fad', 'usd-square']} fixedWidth />
          &nbsp; Order Detail: {formatNumber(id, '0000')}
          <br />
          <FontAwesomeIcon
            icon={['fad', 'usd-square']}
            fixedWidth
            className="invisible"
          />
          &nbsp;
          <small className="font-italic ml-2">{subtitle}</small>
        </h1>
        <div className="col-auto">
          <Button tag={Link} size="lg" to={routes.orders.path} className="px-5">
            Cancel
          </Button>
        </div>
        {!order?.processedBy && !order?.cancelledBy && (
          <>
            <div className="col-auto">
              <Button
                size="lg"
                outline
                color="success"
                onClick={handleApproveOrderClick}>
                Approve Order &nbsp;
                <FontAwesomeIcon icon={['fad', 'thumbs-up']} />
              </Button>
            </div>
            <div className="col-auto">
              <Button
                size="lg"
                outline
                color="danger"
                onClick={handleCancelOrderClick}>
                Cancel Order &nbsp;
                <FontAwesomeIcon icon={['fad', 'thumbs-down']} />
              </Button>
            </div>
          </>
        )}
      </div>
      {loading && (
        <h1 className="text-success text-center">
          &nbsp;
          <FontAwesomeIcon icon={['fad', 'spinner']} spin />
        </h1>
      )}
      <Error error={error} clearError={toggleError} />
      {!!order && (
        <>
          {isOverdue && (
            <Alert color="danger" isOpen={true}>
              <p className="lead text-center my-2">PLEASE NOTE</p>
              <hr className="border-white my-1" />
              <p className="lead text-center my-2">
                This order is past the order submission deadline and/or the Ship
                Week. Please ensure the order can be fulfilled before Approving.
              </p>
            </Alert>
          )}
          <div className="row my-4">
            <div className="col-12 col-md-6">
              <label className="form-control-label" htmlFor="customer">
                Customer
              </label>
              <Input
                id="customer"
                value={order.customer}
                readOnly
                className="form-control-alternative bg-white"
              />
            </div>
            <div className="col-12 col-md-6">
              <label className="form-control-label" htmlFor="createdBy">
                Placed By
              </label>
              <Input
                id="createdBy"
                value={order.createdBy}
                readOnly
                className="form-control-alternative bg-white"
              />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-12 col-md-6">
              <label className="form-control-label" htmlFor="createdOn">
                Order Date
              </label>
              <Input
                id="createdOn"
                value={`${formatDate(order.createdOn)} @ ${formatDate(
                  order.createdOn,
                  'h:mm a'
                )}`}
                readOnly
                className="form-control-alternative bg-white"
              />
            </div>
            <div className="col-12 col-md-6">
              <label className="form-control-label" htmlFor="shipWeek">
                Ship Week
              </label>
              <Input
                id="shipWeek"
                value={order.shipWeek}
                readOnly
                className="form-control-alternative bg-white"
              />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-12 col-md-6">
              <label className="form-control-label" htmlFor="poNumber">
                PO Number
              </label>
              <Input
                id="poNumber"
                value={order.poNumber || ''}
                readOnly
                className="form-control form-control-alternative bg-white"
              />
            </div>
            <div className="col-12 col-md-6">
              <label className="form-control-label" htmlFor="comments">
                Comments from Customer
              </label>
              <Input
                id="comments"
                type={order.comments ? 'textarea' : 'text'}
                rows={3}
                value={order.comments || ''}
                readOnly
                className="form-control form-control-alternative bg-white"
              />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-12">
              <label
                className="form-control-label"
                htmlFor="comments-to-customer">
                Response to Customer
              </label>
              <textarea
                rows={3}
                id="comments-to-customer"
                className="form-control"
                value={order.commentsToCustomer || ''}
                onChange={handleCommentsToCustomerChange}></textarea>
            </div>
          </div>
          <div className="row">
            <div className="col font-weight-bold">Description</div>
            {hasShipWeeks && (
              <div className="col-1 font-weight-bold">
                <Input
                  value="Ship Week"
                  className="quantity pb-0"
                  readOnly
                  plaintext
                />
              </div>
            )}
            <div className="col-2 font-weight-bold">
              <Input
                value="Quantity"
                className="quantity pb-0"
                readOnly
                plaintext
              />
            </div>
            {hasStems && (
              <div className="col-1 px-0">
                <Input
                  value="Stems"
                  className="quantity pb-0 text-center"
                  readOnly
                  plaintext
                />
              </div>
            )}
            <div className="col-2 font-weight-bold">
              <Input
                value="Unit Price"
                className="quantity pb-0"
                readOnly
                plaintext
              />
            </div>
            <div className="col-2 font-weight-bold">
              <Input
                value="Total"
                className="quantity text-center pb-0"
                readOnly
                plaintext
              />
            </div>
          </div>
          {order.items.map((item) => (
            <DetailRow item={item} key={item.id} />
          ))}
          <div className="row border-top-double mt-2 pt-2">
            <div className="col">
              <Input
                value="Total"
                readOnly
                plaintext
                className="form-control-lg font-weight-bold"
              />
            </div>
            <div className="col-2">
              <Input
                value={formatCurrency(subTotal)}
                readOnly
                className="text-right bg-white quantity font-weight-bold border-0"
                tabIndex={-1}
              />
            </div>
          </div>
        </>
      )}
      <Modal isOpen={showApprovalModal} toggle={hideShowApproveModal}>
        <ModalHeader toggle={hideShowApproveModal}>Approve Order</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12">
              <p className="lead">
                Approving the order will convert it into an order in Whilma.
              </p>
            </div>
          </div>
          <div className="row mt-2">
            <label
              className="form-control-label col-12"
              htmlFor="approval-comments">
              Response to Customer
            </label>
            <div className="col-12">
              <textarea
                rows={3}
                id="approval-comments"
                value={order?.commentsToCustomer || ''}
                onChange={handleCommentsToCustomerChange}
                className="form-control"></textarea>
            </div>
          </div>
          {isCuts && (
            <div className="row mt-2">
              <div className="col-6">
                <label className="form-control-label" htmlFor="case-count-cuts">
                  Case Count
                </label>
                <Input
                  id="case-count-cuts"
                  type="number"
                  value={caseCountCuts || 0}
                  onChange={handleCaseCountCutsChange}
                  className="text-right"
                />
              </div>
              <div className="col-6">
                <label className="form-control-label" htmlFor="cubes-cuts">
                  Cubes
                </label>
                <Input
                  id="cubes-cuts"
                  type="number"
                  value={cubesCuts || 0}
                  onChange={handleCubesCutsChange}
                  className="text-right"
                />
              </div>
            </div>
          )}

          {hasOver1000 && (
            <div className="alert alert-warning">
              There are some items with an order quantity of more than 1,000.
              Please double-check before confirming.
            </div>
          )}

          {error && (
            <div className="alert alert-danger">
              <p className="lead">{error.title}</p>
              <p>{error.detail}</p>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button outline onClick={hideShowApproveModal}>
            Cancel
          </Button>
          <Button outline color="success" onClick={approve} disabled={loading}>
            Approve &nbsp;
            <FontAwesomeIcon
              icon={['fad', `${loading ? 'sync' : 'thumbs-up'}` as IconName]}
              spin={loading}
            />
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={showCancelModal} toggle={hideShowCancelModal}>
        <ModalHeader toggle={hideShowCancelModal}>Cancel Order</ModalHeader>
        <ModalBody>
          <p className="lead">
            Cancelling the order will remove the pending order from the system
            and notify the customer.
          </p>

          <label
            className="form-control-label mt-2"
            htmlFor="cancellation-comments">
            Response to Customer
          </label>
          <textarea
            rows={3}
            id="cancellation-comments"
            value={order?.commentsToCustomer || ''}
            onChange={handleCommentsToCustomerChange}
            className="form-control"></textarea>

          {error && (
            <div className="alert alert-danger">
              <p className="lead">{error.title}</p>
              <p>{error.detail}</p>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button outline onClick={hideShowCancelModal}>
            Cancel
          </Button>
          <Button outline color="danger" onClick={cancel} disabled={loading}>
            Cancel Order &nbsp;
            <FontAwesomeIcon
              icon={['fad', `${loading ? 'sync' : 'thumbs-down'}` as IconName]}
              spin={loading}
            />
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
