import {
  createSlice,
  createAsyncThunk,
  AsyncThunk,
  createAction,
  PayloadAction,
} from '@reduxjs/toolkit';
import { customerService, CustomerSearchResponse } from 'api/customer-service';
import { SearchCustomer } from 'api/models/customers';
import { ProblemDetails } from 'utils/problem-details';
import { RootState } from 'app/store';

export interface AddCustomerState {
  customers: SearchCustomer[] | null;
  resultCount: number;
  search: string;
  error: ProblemDetails | null;
}

const initialState: AddCustomerState = {
  customers: null,
  resultCount: 0,
  search: '',
  error: null,
};

export const getSearchResults: AsyncThunk<
  CustomerSearchResponse,
  number,
  { state: RootState }
> = createAsyncThunk(
  'addCustomer/getSearchResults',
  async (userId, { rejectWithValue, getState }) => {
    try {
      const state = (getState() as RootState).addCustomer,
        search = state.search;
      return await customerService.search(search, userId);
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

const getSearchResultsFulfilled = createAction<CustomerSearchResponse>(
    getSearchResults.fulfilled.type
  ),
  getSearchResultsRejected = createAction<ProblemDetails>(
    getSearchResults.rejected.type
  );

export const addCustomerSlice = createSlice({
  name: 'addCustomer',
  initialState,
  reducers: {
    setSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    clearError(state) {
      state.error = null;
    },
    clearState(state) {
      state.customers = [];
      state.search = '';
      state.error = null;
      state.resultCount = 0;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getSearchResultsFulfilled, (state, action) => {
        state.customers = action.payload.customers;
        state.resultCount = action.payload.count;
      })
      .addCase(getSearchResultsRejected, (state, action) => {
        state.error = action.payload;
      }),
});

export const { setSearch, clearError, clearState } = addCustomerSlice.actions;

export const selectCustomers = (state: RootState) =>
  state.addCustomer.customers;
export const selectResultCount = (state: RootState) =>
  state.addCustomer.resultCount;
export const selectSearch = (state: RootState) => state.addCustomer.search;
export const selectError = (state: RootState) => state.addCustomer.error;

export default addCustomerSlice.reducer;
