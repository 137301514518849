import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatCurrency, formatNumber } from 'utils/format';
import {
  selectSalesWeekItems,
  selectYear,
  expandWeek,
  ExpandableWeek,
} from './customer-detail-slice';
import { CrmSalesWeekItem } from './CrmSalesWeekItem';

interface CrmSalesWeekProps {
  week: ExpandableWeek;
}

export function CrmSalesWeek({ week }: CrmSalesWeekProps) {
  const dispatch = useDispatch(),
    year = useSelector(selectYear),
    salesWeekItems = useSelector(selectSalesWeekItems),
    itemsForWeek = salesWeekItems.filter((item) => item.week === week.week),
    totalQuantity = itemsForWeek.reduce((acc, item) => acc + item.quantity, 0),
    totalSales = itemsForWeek.reduce((acc, item) => acc + item.sales, 0);

  const handleExpandClick = () => {
    dispatch(expandWeek({ week: week.week, expanded: !week.expanded }));
  };

  return (
    <>
      <tr>
        <td>{year}</td>
        <td>{week.week}</td>
        <td>{formatNumber(totalQuantity)}</td>
        <td>{formatCurrency(totalSales)}</td>
        <td className="auto-width text-right">
          <Button outline color="success" size="sm" onClick={handleExpandClick}>
            <FontAwesomeIcon
              icon={['fal', week.expanded ? 'chevron-up' : 'chevron-down']}
            />
          </Button>
        </td>
      </tr>
      {week.expanded && (
        <tr>
          <td colSpan={5}>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Size</th>
                  <th>Description</th>
                  <th>Colour</th>
                  <th>Qty</th>
                  <th>$</th>
                </tr>
              </thead>
              <tbody>
                {salesWeekItems.map((item) => (
                  <CrmSalesWeekItem key={item.description} item={item} />
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <th colSpan={4}>Week {week.week} Total</th>
                  <th>{formatNumber(totalQuantity)}</th>
                  <th>{formatCurrency(totalSales)}</th>
                </tr>
              </tfoot>
            </table>
          </td>
        </tr>
      )}
    </>
  );
}
