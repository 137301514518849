import { ApiBase } from './api-base';
import { SalesAnalytics, AnalyticsBookingCatalog } from './models/analytics';
import { CatalogTypes } from './models/booking-catalogs';
import { Week } from './models/weeks';

class AnalyticsService extends ApiBase {
  index(): Promise<AnalyticsIndexResponse> {
    return this.get('/api/analytics');
  }

  sales(startWeekId: string | null, endWeekId: string | null, catalogType: CatalogTypes | null, bookingCatalogId: number | null): Promise<SalesAnalyticsResponse> {
    return this.get(`/api/analytics/sales?startWeekId=${startWeekId || ''}&endWeekId=${endWeekId || ''}&catalogType=${catalogType || ''}&bookingCatalogId=${bookingCatalogId || ''}`);
  }
}

export interface AnalyticsIndexResponse {
  weeks: Week[];
  catalogs: AnalyticsBookingCatalog[];
}

export interface SalesAnalyticsResponse {
  analytics: SalesAnalytics;
}

export const analyticsApi = new AnalyticsService();
