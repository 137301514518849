import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { selectError, selectFilteredBookingCatalogs, setYear, getBookingCatalogs, selectYear, selectShowPrevious, setShowPrevious, selectCatalogType, setCatalogType } from './booking-catalog-list-slice';
import { Link } from 'react-router-dom';
import { routes } from 'app/routes';
import { CatalogTypes } from 'api/models/booking-catalogs';
import { BookingCatalogListItem } from './BookingCatalogListItem';
import { ProductDownloadModal } from './ProductDownloadModal';

const years = [
  new Date().getFullYear()-1,
  new Date().getFullYear(),
  new Date().getFullYear()+1,
  new Date().getFullYear()+2,
];

export function List() {
  const dispatch = useDispatch(),
    error = useSelector(selectError),
    catalogType = useSelector(selectCatalogType),
    year = useSelector(selectYear),
    bookingCatalogs = useSelector(selectFilteredBookingCatalogs),
    showPrevious = useSelector(selectShowPrevious),
    [showDownloadProducts, setShowDownloadProducts] = useState(false);


  const refresh = () => {
    dispatch(getBookingCatalogs(year));
  };

  const handleCatalogTypeClick = (newType: CatalogTypes | 'All') => {
    if(newType !== catalogType) {
      dispatch(setCatalogType(newType));
    }
  };

  const handleShowPreviousChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setShowPrevious(e.target.checked));
  }

  const handleYearClick = (newYear: number) => {
    if(newYear !== year) {
      dispatch(setYear(newYear));
    }
  };

  const handleDownloadProductsClick = () => {
    setShowDownloadProducts(true);
  }

  const toggleShowDownloadProducts = () => {
    setShowDownloadProducts(false);
  };

  useEffect(() => {
    dispatch(getBookingCatalogs(year));
  }, [dispatch, year]);

    return (
    <div className="container mt-4">
      <div className="row">
        <h1 className="col">
          <FontAwesomeIcon icon={['fad', 'atlas']} />
          &nbsp;
          Booking Catalogs
        </h1>
      </div>
      <div className="row">
        <div className="col-12 col-md-auto mb-md-2">
          <UncontrolledDropdown>
            <DropdownToggle caret color="secondary">
              {year}
            </DropdownToggle>            
            <DropdownMenu>
              {years.map(y =>
                <DropdownItem key={y} onClick={() => handleYearClick(y)}>{y}</DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <div className="col-12 col-md-auto mb-md-2">
          <UncontrolledDropdown>
            <DropdownToggle caret color="secondary">
              {catalogType}
            </DropdownToggle>
            <DropdownMenu>
              {['All', 'Plants', 'Cut Flowers', 'Plant Prebook', 'Cut Flowers Prebook'].map(t =>
                <DropdownItem key={t} onClick={() => handleCatalogTypeClick(t as CatalogTypes | 'All')}>{t}</DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <div className="col-12 col-md-auto mb-md-2 text-nowrap text-center">
          <label className="custom-toggle custom-toggle-success" htmlFor="show-previous">
              <input id="show-previous" type="checkbox" checked={showPrevious} onChange={handleShowPreviousChange} />
              <span className="custom-toggle-slider rounded-circle" />
          </label>
          <label htmlFor="show-previous" className="d-block">Show expired</label>
        </div>
        <div className="col-12 ml-auto col-md-auto mb-md-2">
        <Button outline color="success" onClick={refresh}>
            Refresh
            &nbsp;
            <FontAwesomeIcon icon={['fad', 'sync']} />
          </Button>
          <Button tag={Link} outline color="info" to={routes.bookingCatalogNew.path}>
            New Booking Catalog
            &nbsp;
            <FontAwesomeIcon icon={['fad', 'plus-square']} />
          </Button>
          <Button outline color="warning" onClick={handleDownloadProductsClick}>
            Download Products
            &nbsp;
            <FontAwesomeIcon icon={['fad', 'file-excel']} />
          </Button>
        </div>
      </div>
      {error &&
        <div className="alert alert-danger">
          <p className="lead">{error.title}</p>
          <p>{error.detail}</p>
        </div>
      }
      {!!bookingCatalogs &&
        <>
        <div className="row d-none d-md-flex mt-4 mb-3 mb-md-0 font-weight-bold">
          <div className="col-12 col-md-1">&nbsp;</div>
          <div className="col-12 col-md-2">Type</div>
          <div className="col-12 col-md-3">Name</div>
          <div className="col-12 col-md-2">Week(s)</div>
          <div className="col-12 col-md-4">Available</div>
        </div>
        {bookingCatalogs.map(c =>
          <BookingCatalogListItem key={c.id} c={c} />
        )}
        </>
      }
      <ProductDownloadModal show={showDownloadProducts} hide={toggleShowDownloadProducts} />
    </div>
  );
}
