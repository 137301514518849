import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './api-base';
import { AlertListResult } from './models/alerts';

export const alertApi = createApi({
  reducerPath: 'alerts-api',
  tagTypes: ['Alerts'],
  baseQuery: axiosBaseQuery('api/alerts'),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    list: builder.query<AlertListResult, AlertListArgs>({
      query: ({ page, includeDismissed, includeSnoozed, type }) => ({
        url:
          '?' +
          (page ? `page=${page}&` : '') +
          (includeDismissed ? `includeDismissed=${includeDismissed}&` : '') +
          (includeSnoozed ? `includeSnoozed=${includeSnoozed}&` : '') +
          (type ? `type=${type}` : ''),
      }),
      providesTags: ['Alerts'],
    }),
    dismiss: builder.mutation<void, number>({
      query: (id) => ({ url: `/${id}/dismiss`, method: 'PUT' }),
      invalidatesTags: ['Alerts'],
    }),
    snooze: builder.mutation<void, SnoozeArgs>({
      query: ({ id, date }) => ({
        url: `/${id}/snooze?date=${date}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Alerts'],
    }),
  }),
});

export interface AlertListArgs {
  page?: number;
  includeDismissed?: boolean;
  includeSnoozed?: boolean;
  type?: string | null;
}

export interface SnoozeArgs {
  id: number;
  date: string;
}

export const { useListQuery, useDismissMutation, useSnoozeMutation } = alertApi;
