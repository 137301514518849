import { equals } from './equals';

export function unitName(unit: string, quantity = 1) {
  if (equals(unit, 'Bu')) {
    return plural(quantity) ? 'bunches' : 'bunch';
  }
  if (equals(unit, 'St')) {
    return plural(quantity) ? 'stems' : 'stem';
  }
  if (equals(unit, 'Ea')) {
    return '';
  }
  return plural(quantity) ? 'cases' : 'case';
}

function plural(quantity: number) {
  return quantity > 1 || quantity === 0;
}
