import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useListQuery, useDismissMutation } from 'api/alert-service';
import { routes } from 'app/routes';
import { selectAlerts, selectAlertCount } from './home-slice';

export function Alerts() {
  const [dismiss] = useDismissMutation(),
    alerts = useSelector(selectAlerts),
    count = useSelector(selectAlertCount),
    limited = alerts.slice(0, 10);

  useListQuery({});

  const handleDismissClick = (id: number) => {
    dismiss(id);
  };

  return (
    <div>
      <a href={routes.alerts.list.path} className="h2 btn btn-link">
        <FontAwesomeIcon icon={['fad', 'bell']} />
        &nbsp; Alerts
      </a>
      <table className="table table-sm table-responsive-md">
        <tbody>
          {limited.map((a) => (
            <tr key={a.id}>
              <td className="auto-width">
                <Button
                  color="link"
                  size="sm"
                  className="text-success"
                  onClick={() => handleDismissClick(a.id)}>
                  <FontAwesomeIcon icon={['fad', 'x']} />
                </Button>
              </td>
              <td>
                <Link
                  to={routes.customerDetail.to(a.customerId)}
                  state={{ from: routes.home.path }}>
                  {a.customerName}
                </Link>
                &nbsp;
                {a.alertText}
              </td>
            </tr>
          ))}
        </tbody>
        {!!alerts.length && (
          <tfoot>
            <tr>
              <td colSpan={2} className="text-center">
                Showing {limited.length} of {count} Alerts &nbsp;
                <a href={routes.alerts.list.path}>See All</a>
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
}
