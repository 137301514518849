import React, { useState, useEffect } from 'react';
import { Input, InputGroup, InputGroupAddon } from 'reactstrap';
import numeral from 'numeral';
import { InventoryPriceProduct } from './pricing-slice';
import { formatCurrency, formatNumber } from 'utils/format';
import { handleFocus } from 'utils/focus';
import { PriceListItem } from 'api/models/booking-catalogs';

interface PricingItemProps {
  hasPricing: boolean;
  itemPrice: InventoryPriceProduct;
  territory: string;
  nullable?: boolean;
  catalogPropName: keyof PriceListItem;
  propName: keyof PriceListItem;
  onChange: (id: number, territory: string, price: number) => void;
}

export function PricingItem({
  hasPricing,
  itemPrice,
  territory,
  nullable,
  propName,
  catalogPropName,
  onChange,
}: PricingItemProps) {
  const [price, setPrice] = useState(''),
    [catalogPrice, setCatalogPrice] = useState('');

  useEffect(() => {
    const price = itemPrice.prices[territory],
      value = price?.[propName] || 0,
      catalogValue = price?.[catalogPropName] || null;

    setPrice(formatNumber(value, '0.00'));
    setCatalogPrice(
      catalogValue == null ? 'N/A' : formatCurrency(catalogValue)
    );
  }, [catalogPropName, itemPrice, nullable, propName, territory]);

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrice(e.target.value);
  };

  const handlePriceBlur = () => {
    if (nullable && price === '') {
      onChange(itemPrice.id, territory, 0);
    } else {
      const value = numeral(price).value() || 0;
      onChange(itemPrice.id, territory, value);
    }
  };

  return (
    <td className="py-1 px-0 text-center align-middle">
      <em>{catalogPrice}</em>
      {hasPricing && (
        <>
          <br />
          <InputGroup size="sm" className="mx-auto flex-nowrap">
            <InputGroupAddon
              addonType="prepend"
              style={{ height: 'calc(1.6rem + 2px)' }}>
              $
            </InputGroupAddon>
            <Input
              bsSize="sm"
              className="text-right"
              style={{ width: '75px' }}
              value={price}
              onChange={handlePriceChange}
              onBlur={handlePriceBlur}
              onFocus={handleFocus}
            />
          </InputGroup>
        </>
      )}
    </td>
  );
}
