import { ApiBase } from './api-base';
import * as models from './models/products';

class ProductService extends ApiBase {
  plantList(args: PlantListArgs): Promise<PlantListResponse> {
    const query =
      '?' +
      (args.category ? `category=${args.category}&` : '') +
      (args.search ? `search=${args.search}&` : '') +
      `page=${args.page}&includeInactive=${args.includeInactive}`;
    return this.get(`/api/products/plants${query}`);
  }
  plantDetail(id: number): Promise<PlantDetailResponse> {
    return this.get(`/api/products/plants/${id}`);
  }
  newPlantDetail(): Promise<NewPlantDetailResponse> {
    return this.get('/api/products/plants/new');
  }
  plantCreate(data: PlantUpdate) {
    return this.post('/api/products/plants', data);
  }
  plantUpdate(id: number, data: PlantUpdate) {
    return this.put(`/api/products/plants/${id}`, data);
  }
  plantDelete(id: number) {
    return this.delete(`/api/products/plants/${id}`);
  }
  plantCategories(): Promise<string[]> {
    return this.get('/api/products/plants/categories');
  }
  plants(): Promise<PlantResponse> {
    return this.get('/api/products/plants/plants');
  }
  createPlant(data: CreatePlantArgs): Promise<models.Plant> {
    return this.post('/api/products/plants/plants', data);
  }
  deletePlant(data: DeletePlantArgs): Promise<models.Plant> {
    return this.post('/api/products/plants/plants/delete', data);
  }
  updatePlant(data: UpdatePlantArgs) {
    return this.put('/api/products/plants/plants', data);
  }
  sizes(): Promise<SizeResponse> {
    return this.get('/api/products/plants/sizes');
  }
  createSize(data: CreateArgs) {
    return this.post('/api/products/plants/sizes', data);
  }
  updateSize(data: UpdateArgs) {
    return this.put('/api/products/plants/sizes', data);
  }
  deleteSize(data: DeleteArgs) {
    return this.post('/api/products/plants/sizes/delete', data);
  }
  products(): Promise<ProductResponse> {
    return this.get('/api/products/plants/products');
  }
  createProduct(data: CreateArgs) {
    return this.post('/api/products/plants/products', data);
  }
  updateProduct(data: UpdateArgs) {
    return this.put('/api/products/plants/products', data);
  }
  deleteProduct(name: string, confirm: boolean) {
    const data: any = { name, confirm };
    return this.post('/api/products/plants/products/delete', data);
  }
  upgrades(): Promise<UpgradeResponse> {
    return this.get('/api/products/plants/upgrades');
  }
  createUpgrade(data: CreateUpgradeArgs): Promise<models.PlantUpgrade> {
    return this.post('/api/products/plants/upgrades', data);
  }
  updateUpgrade(data: UpdateUpgradeArgs) {
    return this.put('/api/products/plants/upgrades', data);
  }
  deleteUpgrade(data: DeleteUpgradeArgs) {
    return this.post('/api/products/plants/upgrades/delete', data);
  }
  createCustomerGroup(data: CreateCustomerGroupArgs) {
    return this.post('/api/products/plants/customer-groups', data);
  }
  suppliers(): Promise<SupplierResponse> {
    return this.get('/api/products/suppliers');
  }
}

export interface PlantListArgs {
  page: number;
  search: string;
  category: string | null;
  includeInactive: boolean;
}

export interface PlantListResponse {
  plants: models.PlantListItem[];
  totalPlantCount: number;
}

export interface NewPlantDetailResponse {
  plants: models.Plant[];
  upgrades: models.PlantUpgrade[];
  boxes: models.PlantBox[];
  locations: models.ProductLocation[];
  colours: string[];
  categories: string[];
  suppliers: models.Supplier[];
  units: models.UnitOfIssue[];
  customerPlantGroups: string[];
  territories: models.Territory[];
  haapRates: models.HaapRate[];
}

export interface PlantDetailResponse extends NewPlantDetailResponse {
  plant: models.PlantDetail;
}

export interface DeleteArgs {
  name: string;
}

export interface CreateArgs {
  name: string;
}

export interface UpdateArgs {
  originalName: string;
  name: string;
  isActive: boolean;
}

export interface CreateUpgradeArgs {
  name: string;
  prefix: string | null;
  cost: number;
  rawCost: number | null;
  tariffCode: string | null;
  customsDescription: string | null;
  skuPrefix: string | null;
}

export interface UpdateUpgradeArgs {
  id: number;
  name: string;
  prefix: string | null;
  cost: number;
  rawCost: number | null;
  tariffCode: string | null;
  customsDescription: string | null;
  skuPrefix: string | null;
  isActive: boolean;
}

export interface DeleteUpgradeArgs {
  id: number;
}

export interface CreatePlantArgs {
  size: string;
  product: string;
  supplierId: string | null;
  cost: number;
}

export interface UpdatePlantArgs {
  id: number;
  size: string;
  product: string;
  supplierId: string | null;
  cost: number;
  isActive: boolean;
}

export interface DeletePlantArgs {
  id: number;
}

export interface CreateCustomerGroupArgs {
  name: string;
}

export interface PlantResponse {
  plants: models.Plant[];
}

export interface SizeResponse {
  sizes: models.PlantSize[];
}

export interface ProductResponse {
  products: models.PlantProduct[];
}

export interface UpgradeResponse {
  upgrades: models.PlantUpgrade[];
}

export interface PlantUpdate {
  category: string | null;
  size: string | null;
  description: string | null;
  upgrade: string | null;
  upgrade2: string | null;
  colour: string | null;
  upc: string | null;
  itemNumber: string | null;
  boxId: number | null;
  boxName: string | null;
  packQuantity: number | null;
  locationId: number | null;
  defaultSupplierId: string | null;
  defaultSupplierName: string | null;
  details: string | null;
  sellUIPurchaseUI: string | null;
  sellUIPurchaseQuantity: number | null;
  sellUI: string | null;
  hapMarkup: number;
  hapCategory: string;
  active: boolean;

  customerPlantGroups: PlantUpdateCustomerPlantGroup[];
  prices: PlantUpdatePrice[];
  images: models.ProductImage[];
}

export interface PlantUpdatePrice {
  territory: string;
  caseSellPrice: number | null;
}

export interface PlantUpdateCustomerPlantGroup {
  id: number;
  groupName: string;
  caseSellPrice: number | null;
  upc: string | null;
  itemNumber: string | null;
}

export interface SupplierResponse {
  suppliers: models.Supplier[];
}

export const productApi = new ProductService();
