import moment from 'moment';
import numeral from 'numeral';

export interface Week {
  id: string;
  weekNumber: number;
  year: number;
}

export function createWeek(year: number, weekNumber: number): Week {
  return {
    id: `${numeral(year).format('0000')}${numeral(weekNumber).format('00')}`,
    weekNumber,
    year
  };
}

export function createWeekFromId(weekId: string): Week {
  if(!weekId) {
    throw new Error('Week Id was not supplied');
  }

  if(weekId.length !== 6) {
    throw new Error(`${weekId} is not a valid week id. Length must be 6 characters.`);
  }

  const yearPart = weekId.substring(0, 4),
      weekPart = weekId.substring(4, 6),
      year = parseInt(yearPart),
      weekNumber = parseInt(weekPart);

  if(isNaN(year)) {
      throw new Error(`${yearPart} is not a valid number.`)
  }
  if(isNaN(weekNumber)) {
      throw new Error(`${weekPart} is not a valid number.`)
  }

  return {
    id: weekId,
    weekNumber,
    year
  };
}

export function getWeekId(date?: Date) {
  return moment(date || new Date()).format('GGGGWW');
}

export function getWeek(date?: Date): Week {
  const m = moment(date || new Date()),
    id = getWeekId(date),
    weekNumber = m.get('isoWeek'),
    year = m.get('isoWeekYear');

  return {id, weekNumber, year};
}

export function previousWeek(currentWeekId?: string): Week {
  const date = dateFromWeek(currentWeekId || getWeek().id),
      m = moment(date).subtract(1, 'week'),
      weekNumber = m.isoWeek(),
      year = m.isoWeekYear(),
      id = getWeekId(m.toDate());

  return { id, weekNumber, year };
}

export function nextWeek(currentWeekId?: string): Week {
  const date = dateFromWeek(currentWeekId || getWeek().id),
      m = moment(date).add(1, 'week'),
      weekNumber = m.isoWeek(),
      year = m.isoWeekYear(),
      id = getWeekId(m.toDate());

  return { id, weekNumber, year };
}

export function dateFromWeek(weekId: string): Date {
  if(!weekId) {
    throw new Error('weekId argument was not supplied');
  }
  
  if(weekId.length !== 6) {
      throw new Error(`${weekId} is not a valid week id. Length must be 6 characters.`);
  }

  const yearPart:string = weekId.substring(0, 4),
      weekPart:string = weekId.substring(4, 6),
      year = parseInt(yearPart),
      week = parseInt(weekPart);

  if(isNaN(year)) {
      throw new Error(`${yearPart} is not a valid number.`)
  }
  if(isNaN(week)) {
      throw new Error(`${weekPart} is not a valid number.`)
  }

  return moment().isoWeekYear(year).isoWeek(week).startOf('day').toDate();
}

export function weeksInYear(year: number) {
  const m = moment().isoWeekYear(year).isoWeek(1),
    weeks: Week[] = [];

  while(m.isoWeekYear() === year) {
    weeks.push(getWeek(m.toDate()));
    m.add(1, 'week');
  }

  return weeks;
}
