import { createSlice, createAsyncThunk, AsyncThunk, createAction, PayloadAction } from '@reduxjs/toolkit';
import * as signalR from '@microsoft/signalr';
import * as models from 'api/models/xero';
import { FreightInvoicesResponse, FreightUnpostedInvoice, LoginResponse, WilmaCreditsResponse, WilmaOrdersResponse, WilmaUnpostedCredit, WilmaUnpostedOrder, xeroApi, XeroConfigResponse } from 'api/xero-service';
import { RootState } from 'app/store';
import { ProblemDetails } from 'utils/problem-details';
import { formatCurrency } from 'utils/format';
import { Amounts } from './amounts';

const connection = new signalR.HubConnectionBuilder().withUrl('/hubs/xero').build();

export interface XeroState {
  loginPath: string | null;
  tenants: models.XeroTenant[];
  token: string | null;
  tenantId: string | null;
  freightInvoices: FreightUnpostedInvoice[];
  wilmaOrders: WilmaUnpostedOrder[];
  wilmaCredits: WilmaUnpostedCredit[];
  results: models.InvoiceProcessedResult[];
  amounts: Amounts | null;
  outcome: string | null;
  loading: boolean;
  error: ProblemDetails | null;
}

const initialState: XeroState = {
  loginPath: null,
  tenants: [],
  token: null,
  tenantId: null,
  freightInvoices: [],
  wilmaOrders: [],
  wilmaCredits: [],
  amounts: null,
  results: [],
  outcome: null,
  loading: false,
  error: null
}

interface LoginArgs {
  code: string;
  state: string;
}

export const configuration: AsyncThunk<XeroConfigResponse, void, {state: RootState}> = createAsyncThunk(
  'xero/configuration',
  async(_, {rejectWithValue}) => {
    try {
      return await xeroApi.configuration();
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const login: AsyncThunk<LoginResponse, LoginArgs, {state: RootState}> = createAsyncThunk(
  'xero/login',
  async(args, {rejectWithValue}) => {
    try {
      const {code, state} = args;
      return await xeroApi.token(code, state);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const freightInvoices: AsyncThunk<FreightInvoicesResponse, string, {state: RootState}> = createAsyncThunk(
  'xero/freightInvoices',
  async (weekId, {rejectWithValue}) => {
    try {
      return await xeroApi.freightInvoices(weekId);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const wilmaOrders: AsyncThunk<WilmaOrdersResponse, Date, {state: RootState}> = createAsyncThunk(
  'xero/wilmaOrders',
  async (date, {rejectWithValue}) => {
    try {
      return await xeroApi.wilmaInvoices(date);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const wilmaCredits: AsyncThunk<WilmaCreditsResponse, void, {state: RootState}> = createAsyncThunk(
  'xero/wilmaCredits',
  async (_, {rejectWithValue}) => {
    try {
      return await xeroApi.wilmaCredits();
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export interface PostToXeroArgs {
  token: string;
  tenantId: string;
  weekId?: string;
  date?: Date
  invoices?: FreightUnpostedInvoice[];
  orders?: WilmaUnpostedOrder[];
  credits?: WilmaUnpostedCredit[];
  action: 'WholesaleOrderExport' | 'WholesaleCreditExport' | 'FreightInvoiceExport';
}

export const postInvoicesToXero: AsyncThunk<any, PostToXeroArgs, {state: RootState}> = createAsyncThunk(
  'xero/postInvoicesToXero',
  async(args, {rejectWithValue, dispatch}) => {
    try {
      const results: models.InvoiceProcessedResult[] = [];

      dispatch(xeroSlice.actions.setOutcome(null));

      if(connection.state === signalR.HubConnectionState.Disconnected) {
        await connection.start();
      }

      connection.on('Started', () => {
        dispatch(xeroSlice.actions.setLoading(true));
      });

      connection.on('Processed', (result: models.InvoiceProcessedResult) => {
        results.push(result);
        dispatch(xeroSlice.actions.addResult(result));
      });

      connection.on('Complete', () => {
        dispatch(xeroSlice.actions.setLoading(false));
        const exportedCount = results.filter(r => r.xeroInvoiceId).length,
          totalInvoices = results.reduce((total, result) => total + result.invoiceTotal, 0);
        dispatch(xeroSlice.actions.setOutcome(`${exportedCount} invoices exported successfully for a total of ${formatCurrency(totalInvoices)}`));

        connection.off('Started');
        connection.off('Processed');
        connection.off('Complete');
      });

      connection.invoke(args.action, args);
      
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export interface PostPOSToXeroArgs {
  token: string;
  tenantId: string;
  amounts: Amounts;
}

export const postPOSToXero: AsyncThunk<any, PostPOSToXeroArgs, {state: RootState}> = createAsyncThunk(
  'xero/postPOSToXero',
  async(args, {rejectWithValue}) => {
    try {
      const {token, tenantId, amounts} = args;
      return xeroApi.postPOSToXero(token, tenantId, amounts);
      
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

const loginPending = createAction(login.pending.type),
  loginFulfilled = createAction<LoginResponse>(login.fulfilled.type),
  loginRejected = createAction<ProblemDetails>(login.rejected.type),
  configurationFulfilled = createAction<XeroConfigResponse>(configuration.fulfilled.type),
  configurationRejected = createAction<ProblemDetails>(configuration.rejected.type),
  freightInvoicesPending = createAction(freightInvoices.pending.type),
  freightInvoicesFulfilled = createAction<FreightInvoicesResponse>(freightInvoices.fulfilled.type),
  freightInvoicesRejected = createAction<ProblemDetails>(freightInvoices.rejected.type),
  wilmaOrdersPending = createAction(wilmaOrders.pending.type),
  wilmaOrdersFulfilled = createAction<WilmaOrdersResponse>(wilmaOrders.fulfilled.type),
  wilmaOrdersRejected = createAction<ProblemDetails>(wilmaOrders.rejected.type),
  wilmaCreditsPending = createAction(wilmaCredits.pending.type),
  wilmaCreditsFulfilled = createAction<WilmaCreditsResponse>(wilmaCredits.fulfilled.type),
  wilmaCreditsRejected = createAction<ProblemDetails>(wilmaCredits.rejected.type),
  postPOSToXeroPending = createAction(postPOSToXero.pending.type),
  postPOSToXeroFulfilled = createAction<models.InvoiceProcessedResult>(postPOSToXero.fulfilled.type),
  postPOSToXeroRejected = createAction<ProblemDetails>(postPOSToXero.rejected.type);

interface SetEmailArgs {
  id: number;
  emailAddress: string;
}

const xeroSlice = createSlice({
  name: 'xero',
  initialState,
  reducers: {
    addResult(state, action: PayloadAction<models.InvoiceProcessedResult>) {
      state.results.push(action.payload);
    },
    clearResults(state) {
      state.results = [];
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setTenantId(state, action: PayloadAction<string>) {
      state.tenantId = action.payload;
    },
    setFreightEmail(state, action: PayloadAction<SetEmailArgs>) {
      const {id, emailAddress} = action.payload,
        invoices = state.freightInvoices.map(i => ({...i})),
        index = invoices.findIndex(i => i.invoiceId === id);

      if(index !== -1) {
        const invoice = {...invoices[index], emailAddress};
        invoices.splice(index, 1, invoice);
        state.freightInvoices = invoices;
      }
    },
    setWilmaOrderEmail(state, action: PayloadAction<SetEmailArgs>) {
      const {id, emailAddress} = action.payload,
        orders = state.wilmaOrders.map(i => ({...i})),
        index = orders.findIndex(i => i.orderId === id);

      if(index !== -1) {
        const order = {...orders[index], emailAddress};
        orders.splice(index, 1, order);
        state.wilmaOrders = orders;
      }
    },
    setWilmaCreditEmail(state, action: PayloadAction<SetEmailArgs>) {
      const {id, emailAddress} = action.payload,
        credits = state.wilmaCredits.map(i => ({...i})),
        index = credits.findIndex(i => i.orderCreditId === id);

      if(index !== -1) {
        const credit = {...credits[index], emailAddress};
        credits.splice(index, 1, credit);
        state.wilmaCredits = credits;
      }
    },
    setAmounts(state, action: PayloadAction<Amounts | null>) {
      state.amounts = action.payload;
    },
    setOutcome(state, action: PayloadAction<string | null>) {
      state.outcome = action.payload;
    },
    setError(state, action: PayloadAction<ProblemDetails>) {
      state.error = action.payload;
    }
  },
  extraReducers: builder =>
    builder
      .addCase(configurationFulfilled, (state, action) => {
        state.loginPath = action.payload.authenticationPath;
      })
      .addCase(configurationRejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(loginPending, state => {
        state.loading = true;
      })
      .addCase(loginFulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload.token.accessToken;
        state.tenants = action.payload.token.tenants;
        if(state.tenants.length === 1) {
          state.tenantId = state.tenants[0].tenantId;
        }
      })
      .addCase(loginRejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(freightInvoicesPending, state => {
        state.outcome = null;
        state.results = [];
        state.loading = true;
      })
      .addCase(freightInvoicesFulfilled, (state, action) => {
        state.loading = false;
        state.freightInvoices = action.payload.invoices;
      })
      .addCase(freightInvoicesRejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(wilmaOrdersPending, state => {
        state.outcome = null;
        state.results = [];
        state.loading = true;
      })
      .addCase(wilmaOrdersFulfilled, (state, action) => {
        state.loading = false;
        state.wilmaOrders = action.payload.orders;
      })
      .addCase(wilmaOrdersRejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(wilmaCreditsPending, state => {
        state.outcome = null;
        state.results = [];
        state.loading = true;
      })
      .addCase(wilmaCreditsFulfilled, (state, action) => {
        state.loading = false;
        state.wilmaCredits = action.payload.credits;
      })
      .addCase(wilmaCreditsRejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(postPOSToXeroPending, state => {
        state.loading = true;
        state.results = [];
      })
      .addCase(postPOSToXeroFulfilled, (state, action) => {
        state.loading = false;
        state.amounts = null;
        state.results = [action.payload];        
      })
      .addCase(postPOSToXeroRejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
});

export const {clearResults, setTenantId, setFreightEmail, setWilmaOrderEmail, setWilmaCreditEmail, setAmounts} = xeroSlice.actions;

export const selectLoginPath = (state: RootState) => state.xero.loginPath;
export const selectToken = (state: RootState) => state.xero.token;
export const selectTenantId = (state: RootState) => state.xero.tenantId;
export const selectTenants = (state: RootState) => state.xero.tenants;
export const selectResults = (state: RootState) => state.xero.results;
export const selectOutcome = (state: RootState) => state.xero.outcome;
export const selectLoading = (state: RootState) => state.xero.loading;
export const selectFreightInvoices = (state: RootState) => state.xero.freightInvoices;
export const selectWilmaOrders = (state: RootState) => state.xero.wilmaOrders;
export const selectWilmaCredits = (state: RootState) => state.xero.wilmaCredits;
export const selectAmounts = (state: RootState) => state.xero.amounts;

export default xeroSlice.reducer;
