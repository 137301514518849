import { Link } from 'react-router-dom';
import moment from 'moment';
import { UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as models from 'api/models/orders';
import { routes } from 'app/routes';
import { formatDate, formatCurrency } from 'utils/format';

export interface WebOrderItemProps {
  order: models.OrderListItem;
}

export function WebOrderItem({ order }: WebOrderItemProps) {
  const availableTo = moment.utc(order.availableTo),
    isOverdue = availableTo.isBefore();

  return (
    <div className="row no-gutters mb-1 pb-1 border-bottom">
      <div className="col-2">
        <Link to={routes.orderDetail.to(order.id)}>
          <FontAwesomeIcon icon={['fad', 'edit']} />
        </Link>
      </div>
      <div className="col-2">
        {isOverdue && (
          <>
            <span
              id={`pending-order-overdue-${order.id}`}
              className="text-danger">
              <FontAwesomeIcon icon={['fal', 'exclamation-circle']} />
              &nbsp;
            </span>
            <UncontrolledTooltip target={`pending-order-overdue-${order.id}`}>
              Order is past the order submission deadline.
              <br />
              Please ensure the order can be fulfilled before Approving.
            </UncontrolledTooltip>
          </>
        )}
        {order.catalogType}
      </div>
      <div className="col-2 text-center">
        {formatDate(order.createdOn)}
        <br />
        @&nbsp;{formatDate(order.createdOn, 'h:mm a')}
        <div className="mt-2">{order.shipWeek}</div>
      </div>
      <div className="col-4">
        <div className="font-weight-bold">{order.salesperson}</div>
        {order.customer}
        <br />
        <span className="font-italic text-muted">{order.createdBy}</span>
      </div>
      <div className="col-2 text-right">{formatCurrency(order.orderTotal)}</div>
    </div>
  );
}
