import {
  createSlice,
  createAsyncThunk,
  AsyncThunk,
  createAction,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import {
  bookingCatalogApi,
  BookingCatalogPriceListResponse,
  BookingCatalogUpdatePricesResponse,
} from 'api/booking-catalog-service';
import { ProblemDetails } from 'utils/problem-details';
import { PriceListItem } from 'api/models/booking-catalogs';

export interface PricingState {
  territories: string[];
  prices: InventoryPriceProduct[];
  isLoading: boolean;
  error: ProblemDetails | null;
}

const initialState: PricingState = {
  territories: [],
  prices: [],
  isLoading: false,
  error: null,
};

export const getPricing: AsyncThunk<
  BookingCatalogPriceListResponse,
  number,
  { state: RootState }
> = createAsyncThunk(
  'booking-catalog-pricing/getPricing',
  async (id, { rejectWithValue }) => {
    try {
      return await bookingCatalogApi.prices(id);
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const updatePricing: AsyncThunk<
  BookingCatalogUpdatePricesResponse,
  number,
  { state: RootState }
> = createAsyncThunk(
  'booking-catalog-pricing/updatePricing',
  async (id, { getState, rejectWithValue }) => {
    try {
      const { prices } = (getState() as RootState).bookingCatalogPricing,
        values = prices
          .flatMap((p) => Object.values(p.prices))
          .map((p) => ({
            bookingCatalogInventoryId: p.bookingCatalogInventoryId,
            productId: p.productId,
            territory: p.territory,
            unitPrice: p.unitPrice || 0,
            casePrice: p.casePrice || 0,
          }))
          .filter((p) => p.unitPrice || p.casePrice);

      return await bookingCatalogApi.updatePrices(id, values);
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

const getPricingPending = createAction(getPricing.pending.type),
  getPricingFulfilled = createAction<BookingCatalogPriceListResponse>(
    getPricing.fulfilled.type
  ),
  getPricingRejected = createAction<ProblemDetails>(getPricing.rejected.type),
  updatePricingPending = createAction(updatePricing.pending.type),
  updatePricingFulfilled = createAction(updatePricing.fulfilled.type),
  updatePricingRejected = createAction<ProblemDetails>(
    updatePricing.rejected.type
  );

export interface SetPriceItemValue<T> {
  id: number;
  value: T;
}

interface TerritoryPrice {
  territory: string;
  price: number;
}

export const pricingSlice = createSlice({
  name: 'booking-catalog-pricing',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
    setError(state, { payload }: PayloadAction<ProblemDetails>) {
      state.error = payload;
    },
    setItemSetPrices(
      state,
      { payload }: PayloadAction<SetPriceItemValue<boolean>>
    ) {
      const { id, value } = payload,
        territories = state.territories,
        prices = state.prices.map((p) => ({ ...p })),
        price = prices.find((p) => p.id === id);

      if (price) {
        territories.forEach((t) => {
          if (t in price.prices) {
            const priceItem = price.prices[t];
            priceItem.unitPrice = value ? priceItem.catalogUnitPrice : null;
            priceItem.casePrice = value ? priceItem.catalogCasePrice : null;
          } else {
            price.prices[t] = {
              bookingCatalogInventoryId: price.id,
              productId: price.productId,
              territory: t,
              category: price.category,
              displayText: price.displayText,
              unitPrice: 0,
              casePrice: 0,
              catalogUnitPrice: null,
              catalogCasePrice: null,
            };
          }
        });

        state.prices = prices;
      }
    },
    setItemPricePercent(
      state,
      { payload }: PayloadAction<SetPriceItemValue<number>>
    ) {
      const { id, value } = payload,
        territories = state.territories,
        prices = state.prices.map((p) => ({ ...p })),
        price = prices.find((p) => p.id === id),
        percentage = 100 - value;

      if (price) {
        territories.forEach((t) => {
          const priceItem = price.prices[t],
            unitPrice =
              Math.round((priceItem.catalogUnitPrice || 0) * percentage) / 100,
            casePrice =
              Math.round((priceItem.catalogCasePrice || 0) * percentage) / 100;

          if (t in price.prices) {
            priceItem.unitPrice = unitPrice;
            priceItem.casePrice = casePrice;
          } else {
            price.prices[t] = {
              bookingCatalogInventoryId: price.id,
              productId: price.productId,
              territory: t,
              category: price.category,
              displayText: price.displayText,
              unitPrice,
              casePrice,
              catalogUnitPrice: null,
              catalogCasePrice: null,
            };
          }
        });

        state.prices = prices;
      }
    },
    setItemPriceDollars(
      state,
      { payload }: PayloadAction<SetPriceItemValue<number>>
    ) {
      const { id, value } = payload,
        territories = state.territories,
        prices = state.prices.map((p) => ({ ...p })),
        price = prices.find((p) => p.id === id);

      if (price) {
        territories.forEach((t) => {
          const priceItem = price.prices[t],
            unitPrice = Math.max(0, (priceItem.catalogUnitPrice || 0) - value),
            casePrice = Math.max(0, (priceItem.catalogCasePrice || 0) - value);

          if (t in price.prices) {
            priceItem.unitPrice = unitPrice;
            priceItem.casePrice = casePrice;
          } else {
            price.prices[t] = {
              bookingCatalogInventoryId: price.id,
              productId: price.productId,
              territory: t,
              category: price.category,
              displayText: price.displayText,
              unitPrice,
              casePrice,
              catalogUnitPrice: null,
              catalogCasePrice: null,
            };
          }
        });

        state.prices = prices;
      }
    },
    setItemUnitPrice(
      state,
      { payload }: PayloadAction<SetPriceItemValue<TerritoryPrice>>
    ) {
      const { id, value } = payload,
        { territory, price } = value,
        prices = state.prices.map((p) => ({ ...p })),
        priceProduct = prices.find((p) => p.id === id),
        priceItem = priceProduct?.prices[territory];

      if (priceProduct && priceItem) {
        priceItem.unitPrice = price;
        state.prices = prices;
      }
    },
    setItemCasePrice(
      state,
      { payload }: PayloadAction<SetPriceItemValue<TerritoryPrice>>
    ) {
      const { id, value } = payload,
        { territory, price } = value,
        prices = state.prices.map((p) => ({ ...p })),
        priceProduct = prices.find((p) => p.id === id),
        priceItem = priceProduct?.prices[territory];

      if (priceProduct && priceItem) {
        priceItem.casePrice = price;
        state.prices = prices;
      }
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getPricingPending, (state) => {
        state.isLoading = true;
        state.prices = [];
      })
      .addCase(getPricingFulfilled, (state, { payload }) => {
        state.isLoading = false;
        const { items, territories } = payload.priceList;
        state.territories = territories.map((t) => t.territory);
        state.prices = items
          // get the unique products
          .reduce((memo, i) => {
            if (!memo.find((p) => p.productId === i.productId)) {
              memo.push(i);
            }
            return memo;
          }, [] as PriceListItem[])
          // create a map for each territory
          .map((i) => {
            const {
                bookingCatalogInventoryId,
                productId,
                category,
                displayText,
              } = i,
              prices = items
                .filter((p) => p.productId === productId)
                .reduce((memo, p) => {
                  memo[p.territory] = p;
                  return memo;
                }, {} as InventoryPriceProductPriceMap);
            return {
              id: bookingCatalogInventoryId,
              productId,
              category,
              displayText,
              prices,
            };
          });
      })
      .addCase(getPricingRejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(updatePricingPending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePricingFulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updatePricingRejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      }),
});

export const {
  clearError,
  setError,
  setItemSetPrices,
  setItemPricePercent,
  setItemPriceDollars,
  setItemUnitPrice,
  setItemCasePrice,
} = pricingSlice.actions;

export const selectIsLoading = (state: RootState) =>
  state.bookingCatalogPricing.isLoading;
export const selectError = (state: RootState) =>
  state.bookingCatalogPricing.error;
export const selectTerritories = (state: RootState) =>
  state.bookingCatalogPricing.territories;
export const selectPrices = (state: RootState) =>
  state.bookingCatalogPricing.prices;

export default pricingSlice.reducer;

interface InventoryPriceProductPriceMap {
  [territory: string]: PriceListItem;
}

export interface InventoryPriceProduct {
  id: number;
  productId: number;
  category: string;
  displayText: string;
  prices: InventoryPriceProductPriceMap;
}
