import {
  createSlice,
  createAsyncThunk,
  AsyncThunk,
  createAction,
  PayloadAction,
} from '@reduxjs/toolkit';
import moment from 'moment';
import {
  fresonBrosApi,
  FresonBrosCreateOrderResponse,
  FresonBrosResponse,
} from 'api/freson-bros-service';
import * as models from 'api/models/freson-bros';
import { getWeek, Week } from 'api/models/weeks';
import { RootState } from 'app/store';
import { ProblemDetails } from 'utils/problem-details';
import { FresonBrosCustomer, FresonBrosItem } from './excel-reader';

const { weekNumber, year } = getWeek();

export interface ProductMatch {
  itemName: string;
  product: models.Product | null;
}

interface FresonBrosState {
  isLoading: boolean;
  customers: models.Customer[];
  products: models.Product[];
  productMatches: ProductMatch[];
  weeks: Week[];
  weekNumber: number;
  year: number;
  fresonBrosItems: FresonBrosItem[];
  fresonBrosCustomers: FresonBrosCustomer[];
  error: ProblemDetails | null;
}

const initialState: FresonBrosState = {
  isLoading: false,
  customers: [],
  products: [],
  productMatches: [],
  weeks: [],
  weekNumber,
  year,
  fresonBrosItems: [],
  fresonBrosCustomers: [],
  error: null,
};

export const getHomeData: AsyncThunk<
  FresonBrosResponse,
  void,
  { state: RootState }
> = createAsyncThunk(
  'freson-bros/getHomeData',
  async (_, { rejectWithValue }) => {
    try {
      return await fresonBrosApi.homeData();
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

interface SaveOrdersArgs {
  orders: models.OrderCreationOrder[];
}

export const saveOrders: AsyncThunk<
  FresonBrosCreateOrderResponse,
  SaveOrdersArgs,
  { state: RootState }
> = createAsyncThunk(
  'freson-bros/saveOrders',
  async ({ orders }, { rejectWithValue }) => {
    try {
      return await fresonBrosApi.createOrders(orders);
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

const getHomeDataPending = createAction(getHomeData.pending.type),
  getHomeDataFulfilled = createAction<FresonBrosResponse>(
    getHomeData.fulfilled.type
  ),
  getHomeDataRejected = createAction<ProblemDetails>(getHomeData.rejected.type),
  saveOrdersPending = createAction(saveOrders.pending.type),
  saveOrdersFulfilled = createAction<FresonBrosCreateOrderResponse>(
    saveOrders.fulfilled.type
  ),
  saveOrdersRejected = createAction<ProblemDetails>(saveOrders.rejected.type);

interface SetItemPropertyArgs<T> {
  description: string;
  value: T;
}

interface SetCustomerPropertyArgs<T> {
  storeNumber: string;
  value: T;
}

interface SetOrderItemPropertyArgs<T>
  extends SetItemPropertyArgs<T>,
    SetCustomerPropertyArgs<T> {}

export const fresonBrosSlice = createSlice({
  name: 'freson-bros',
  initialState,
  reducers: {
    clearState(state) {
      state.error = null;
      state.fresonBrosItems = [];
      state.fresonBrosCustomers = [];
      state.productMatches = [];
    },
    clearError(state) {
      state.error = null;
    },
    setError(state, { payload }: PayloadAction<ProblemDetails>) {
      state.error = payload;
    },
    setFresonBrosItems(state, { payload }: PayloadAction<FresonBrosItem[]>) {
      const { products } = state,
        fresonBrosItems = payload.map((i) => ({ ...i }));

      fresonBrosItems.forEach((item) => {
        const upcProducts = products.filter((p) => p.upc === item.upc);
        if (upcProducts.length === 1) {
          item.productId = upcProducts[0].id;
        } else {
          const upcRegex = /^(\d+)-\d$/,
            test = upcRegex.test(item.upc || '');

          if (test) {
            const match = upcRegex.exec(item.upc || '');

            if (match && match.length > 1) {
              const upc = match[1],
                upcProducts = products.filter((p) => p.upc === upc);

              if (upcProducts.length === 1) {
                item.productId = upcProducts[0].id;
              }
            }
          }
        }
      });
      state.fresonBrosItems = fresonBrosItems;
    },
    setFresonBrosCustomers(
      state,
      { payload }: PayloadAction<FresonBrosCustomer[]>
    ) {
      const { customers } = state,
        fresonBrosCustomers = payload.map((c) => ({ ...c }));

      fresonBrosCustomers.forEach(
        (customer) =>
          (customer.customerId =
            customers.find(
              (c) => c.internalStoreNumber === customer.storeNumber
            )?.id || null)
      );
      state.fresonBrosCustomers = fresonBrosCustomers;
    },
    setCustomerId(
      state,
      { payload }: PayloadAction<SetCustomerPropertyArgs<number | null>>
    ) {
      const fresonBrosCustomers = state.fresonBrosCustomers.map((c) => ({
          ...c,
        })),
        customer = fresonBrosCustomers.find(
          (c) => c.storeNumber === payload.storeNumber
        );

      if (customer) {
        customer.customerId = payload.value;

        state.fresonBrosCustomers = fresonBrosCustomers;
      }
    },
    setWeek(state, { payload }: PayloadAction<Week>) {
      const { year, weekNumber } = payload;
      state.year = year;
      state.weekNumber = weekNumber;
    },
    setItemProductId(
      state,
      { payload }: PayloadAction<SetItemPropertyArgs<number | null>>
    ) {
      const fresonBrosItems = state.fresonBrosItems.map((i) => ({
          ...i,
        })),
        product = fresonBrosItems.find(
          (p) => p.description === payload.description
        );

      if (product) {
        product.productId = payload.value;

        state.fresonBrosItems = fresonBrosItems;
      }
    },
    setItemQuantity(
      state,
      { payload }: PayloadAction<SetOrderItemPropertyArgs<number>>
    ) {
      const { storeNumber, description: itemNumber, value } = payload,
        fresonBrosCustomers = state.fresonBrosCustomers.map((c) => ({
          ...c,
        })),
        customer = fresonBrosCustomers.find(
          (c) => c.storeNumber === storeNumber
        );

      if (customer) {
        const items = customer.items.map((i) => ({ ...i })),
          item = items.find((i) => i.productDescription === itemNumber);

        if (item) {
          item.quantity = value;
          customer.items = items;
          state.fresonBrosCustomers = fresonBrosCustomers;
        }
      }
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getHomeDataPending, (state) => {
        state.isLoading = true;
      })
      .addCase(getHomeDataFulfilled, (state, { payload }) => {
        state.isLoading = false;
        const { customers, products } = payload;
        state.customers = customers;
        state.products = products;

        const weeks: Week[] = [],
          date = moment(),
          nextYear = new Date().getFullYear() + 1;
        while (date.year() <= nextYear) {
          weeks.push(getWeek(date.toDate()));
          date.add(1, 'week');
        }
        state.weeks = weeks;
      })
      .addCase(getHomeDataRejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(saveOrdersPending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveOrdersFulfilled, (state, { payload }) => {
        state.isLoading = false;
        const { orders } = payload,
          fresonBrosCustomers = state.fresonBrosCustomers.map((c) => ({
            ...c,
          }));

        fresonBrosCustomers.forEach((customer) => {
          const order = orders.find(
            (o) => o.customerId === customer.customerId
          );
          if (order) {
            customer.orderId = order.orderId;
          }
        });

        state.fresonBrosCustomers = fresonBrosCustomers;
      })
      .addCase(saveOrdersRejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      }),
});

export const {
  clearState,
  clearError,
  setError,
  setFresonBrosItems,
  setFresonBrosCustomers,
  setCustomerId,
  setWeek,
  setItemProductId,
  setItemQuantity,
} = fresonBrosSlice.actions;

export const selectIsLoading = (state: RootState) => state.fresonBros.isLoading;
export const selectError = (state: RootState) => state.fresonBros.error;
export const selectCustomers = (state: RootState) => state.fresonBros.customers;
export const selectYear = (state: RootState) => state.fresonBros.year;
export const selectWeekNumber = (state: RootState) =>
  state.fresonBros.weekNumber;
export const selectProducts = (state: RootState) => state.fresonBros.products;
export const selectProductMatches = (state: RootState) =>
  state.fresonBros.productMatches;
export const selectWeeks = (state: RootState) => state.fresonBros.weeks;
export const selectFresonBrosItems = (state: RootState) =>
  state.fresonBros.fresonBrosItems;
export const selectFresonBrosCustomers = (state: RootState) =>
  state.fresonBros.fresonBrosCustomers;

export default fresonBrosSlice.reducer;
