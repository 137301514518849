import { Link } from 'react-router-dom';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as models from 'api/models/booking-catalogs';
import { routes } from 'app/routes';

export interface BookingCatalogItemProps {
  catalog: models.BookingCatalogListItem;
}

export function BookingCatalogItem({ catalog }: BookingCatalogItemProps) {
  const fromDate = moment
      .utc(catalog.availableFrom)
      .local()
      .format('MMM D, YYYY'),
    fromTime = moment.utc(catalog.availableFrom).local().format('h:mm a'),
    toDate = moment.utc(catalog.availableTo).local().format('MMM D, YYYY'),
    toTime = moment.utc(catalog.availableTo).local().format('h:mm a');

  return (
    <tr>
      <td className="text-center auto-width">
        <Link to={routes.bookingCatalogDetail.to(catalog.id)}>
          <FontAwesomeIcon icon={['fad', 'edit']} />
        </Link>
      </td>
      <td>{catalog.catalogType}</td>
      <td>{catalog.name}</td>
      <td>
        {catalog.week}
        <div>
          {fromDate}&nbsp;{fromTime}
          &nbsp;&ndash;&nbsp;
          {toDate}&nbsp;{toTime}
        </div>
      </td>
    </tr>
  );
}
