import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { selectError, selectLoading, selectOrders, getConfirmedOrders, selectPage, selectSalesperson, setSalesperson, setCatalogType, selectSalespeople, getSalespeople, selectWeek,
  selectWeeks, setWeek, selectCatalogType, setSort, selectSort, selectShowAll, setShowAll } from './confirmed-order-list-slice';
import { OrderPaginator } from './OrderPaginator';
import { Link } from 'react-router-dom';
import { routes } from 'app/routes';
import { formatCurrency, formatDate } from 'utils/format';
import { ConfirmedOrderSort, confirmedOrderSortDisplay } from 'api/models/confirmed-orders';
import { CatalogTypes } from 'api/models/booking-catalogs';
import { Week } from 'api/models/weeks';
import { handleDropdownToggle, maxHeightModifiers } from 'utils/dropdown';

export function List() {
  const dispatch = useDispatch(),
    location = useLocation(),
    error = useSelector(selectError),
    loading = useSelector(selectLoading),
    orders = useSelector(selectOrders),
    page = useSelector(selectPage),
    salesperson = useSelector(selectSalesperson),
    catalogType = useSelector(selectCatalogType),
    salespeople = useSelector(selectSalespeople),
    week = useSelector(selectWeek),
    weeks = useSelector(selectWeeks),
    sort = useSelector(selectSort),
    showAll = useSelector(selectShowAll);

  useEffect(() => {
    return function cleanup() {
      dispatch(setShowAll(false));
    }
  }, [dispatch, location]);

  const refresh = useCallback(() => {
    dispatch(getConfirmedOrders({page, salesperson, catalogType, week, sort, showAll}));
  }, [dispatch, page, salesperson, catalogType, week, sort, showAll]);

  const handleSalespersonClick = (salesperson: string) => {
    dispatch(setSalesperson(salesperson));
  };

  const handleCatalogTypeClick = (catalogType: CatalogTypes | null) => {
    dispatch(setCatalogType(catalogType));
  };

  const handleWeekClick = (week: Week | null) => {
    dispatch(setWeek(week));
  };

  const handleSortClick = (sort: ConfirmedOrderSort) => {
    dispatch(setSort(sort));
  };

  useEffect(() => {
    if(!salespeople) {
      dispatch(getSalespeople());
    }
  }, [dispatch, salespeople]);

  useEffect(() => {
    refresh();
    let timer = window.setInterval(refresh, 1000 * 5);

    return function cleanup() {
      if(timer) {
        window.clearInterval(timer);
      }
    }
    
  }, [dispatch, refresh]);

  return (
    <div className="container mt-4">
      <div className="row">
        <h1 className="col">
          <FontAwesomeIcon icon={['fad', 'box-usd']} />
          &nbsp;
          Confirmed Orders
        </h1>
        <div className="col-12 col-md-auto mb-md-2">
          <UncontrolledDropdown>
            <DropdownToggle caret color="secondary">
              {catalogType || 'All Types'}
            </DropdownToggle>            
            <DropdownMenu>
              <DropdownItem onClick={() => handleCatalogTypeClick(null)}>All Types</DropdownItem>
              <DropdownItem onClick={() => handleCatalogTypeClick('Plants')}>Plants</DropdownItem>
              <DropdownItem onClick={() => handleCatalogTypeClick('Cut Flowers')}>Cut Flowers</DropdownItem>
              <DropdownItem onClick={() => handleCatalogTypeClick('Plant Prebook')}>Prebook (Plants)</DropdownItem>
              <DropdownItem onClick={() => handleCatalogTypeClick('Cut Flowers Prebook')}>Prebook (Cut Flowers)</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>        
        <div className="col-12 col-md-auto mb-md-2">
          <UncontrolledDropdown onToggle={handleDropdownToggle}>
            <DropdownToggle caret color="secondary">
              {week ? `Week ${week.weekNumber}, ${week.year}` : 'All Weeks'}
            </DropdownToggle>            
            <DropdownMenu modifiers={maxHeightModifiers()}>
              <DropdownItem onClick={() => handleWeekClick(null)}>All Weeks</DropdownItem>
              {weeks.map(w =>
                <DropdownItem key={w.id} onClick={() => handleWeekClick(w)}>Week {w.weekNumber}, {w.year}</DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <div className="col-12 col-md-auto mb-md-2">
          {!!salespeople &&
            <UncontrolledDropdown>
              <DropdownToggle caret color="secondary">
                {salesperson || 'All Salespeople'}
              </DropdownToggle>            
              <DropdownMenu>
                <DropdownItem onClick={() => handleSalespersonClick('')}>All Salespeople</DropdownItem>
                {salespeople.filter(s => !!s.name).map(s =>
                  <DropdownItem key={s.name} onClick={() => handleSalespersonClick(s.name)}>{s.name}</DropdownItem>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          }
        </div>
        <div className="col-12 col-md-auto mb-md-2">
          <UncontrolledDropdown>
            <DropdownToggle caret color="secondary">
              {confirmedOrderSortDisplay(sort)}
            </DropdownToggle>
            <DropdownMenu>
              {(['ShipDate Asc', 'ShipDate Desc', 'OrderDate Asc', 'OrderDate Desc'] as ConfirmedOrderSort[]).map(s =>
                <DropdownItem onClick={() => handleSortClick(s)}>{confirmedOrderSortDisplay(s)}</DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <div className="col-12 col-md-auto mb-md-2">
          <Button outline color="success" onClick={refresh}>
            Refresh
            &nbsp;
            <FontAwesomeIcon icon={['fad', 'sync']} />
          </Button>
        </div>
      </div>      
      {error &&
        <div className="alert alert-danger">
          <p className="lead">{error.title}</p>
          <p>{error.detail}</p>
        </div>
      }
      <div className="row d-none d-md-flex mt-4 mb-3 mb-md-0 font-weight-bold">
        <div className="col-12 col-md-1">Order Number</div>
        <div className="col-12 col-md-2">Order Type</div>
        <div className="col-12 col-md-2 text-center">Ship Week</div>
        <div className="col-12 col-md-2 text-center">Order Date</div>
        <div className="col-12 col-md-2">Customer</div>
        <div className="col-12 col-md-1">Salesperson</div>
        <div className="col-12 col-md-2 text-right">Order Total</div>
      </div>
      {orders?.map(o =>
        <div key={o.id} className="row py-2 border-bottom">
          <div className="col-12 col-md-1 text-center">
            <Link to={routes.confirmedOrderDetail.to(o.id)}>
              {o.id}
            </Link>
          </div>
          <div className="col-12 col-md-2">
            {o.catalogType}:<br />{o.catalogName}
          </div>
          <div className="col-12 col-md-2 text-center">{o.shipWeek}</div>
          <div className="col-12 col-md-2 text-center">
            {formatDate(o.createdOn)}
            <br />
            @&nbsp;{formatDate(o.createdOn, 'h:mm a')}
          </div>
          <div className="col-12 col-md-2">
            {o.customer}
            <br />
            {o.createdBy}
          </div>
          <div className="col-12 col-md-1">{o.salesperson}</div>
          <div className="col-12 col-md-2 text-right">{formatCurrency(o.orderTotal)}</div>
        </div>
      )}
      {loading &&
        <h1 className="text-success text-center">
          &nbsp;
          <FontAwesomeIcon icon={['fad', 'spinner']} spin />
        </h1>
      }
      {!!orders && !orders.length &&
        <div className="lead text-center">No Confirmed Orders</div>
      }
      <div className="row mt-2">
        <OrderPaginator />
      </div>
    </div>
  );
}
