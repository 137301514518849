export interface ProblemDetails {
    title:string;
    status:number;
    detail:string;
    instance:string;
}

export function createProblemDetails(title: string, detail: string = '', status = 0, instance: string = ''): ProblemDetails {
    return {title, detail, status, instance};
}

export function isProblemDetails(value:any):value is ProblemDetails {
    return value && typeof value.title === 'string' && typeof value.status === 'number' && typeof value.detail === 'string' && typeof value.instance === 'string';
}
