import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, CustomInput, Input, UncontrolledTooltip } from 'reactstrap';
import { contains } from 'utils/equals';
import { handleFocus } from 'utils/focus';
import { selectBookingCatalog } from './booking-catalog-detail-slice';
import {
  InventoryPriceProduct,
  selectTerritories,
  setItemCasePrice,
  setItemPriceDollars,
  setItemPricePercent,
  setItemSetPrices,
  setItemUnitPrice
} from './pricing-slice';
import { PricingItem } from './PricingItem';

interface PricingProductProps {
  item: InventoryPriceProduct;
  previous: InventoryPriceProduct | undefined;
}

export function PricingProduct({ item, previous }: PricingProductProps) {
  const dispatch = useDispatch(),
    territories = useSelector(selectTerritories),
    bookingCatalog = useSelector(selectBookingCatalog),
    [hasPricing, setHasPricing] = useState(false),
    [adjustBy, setAdjustBy] = useState('percent'),
    [adjustByPercent, setAdjustByPercent] = useState('0'),
    [adjustByDollars, setAdjustByDollars] = useState('0'),
    isPercent = adjustBy === 'percent',
    isDollars = adjustBy === 'dollars',
    isCuts = contains(bookingCatalog?.catalogType, 'Cut Flower'),
    prices = Object.values(item.prices),
    hasCases = isCuts && prices.some((p) => p.catalogCasePrice),
    columnCount = territories.length + (isCuts ? 2 : 1),
    rowCount = hasCases ? 2 : 1;

  useEffect(() => {
    const hasPricing = Object.values(item.prices).some((p) => p.unitPrice);
    setHasPricing(hasPricing);
  }, [item.prices]);

  const handleHasPricingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked;
    setHasPricing(value);
    dispatch(setItemSetPrices({ id: item.id, value }));
  };

  const handleAdjustByChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAdjustBy(e.target.value);
  };

  const handleAdjustByPercentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAdjustByPercent(e.target.value);
  };

  const handleAdjustByDollarsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAdjustByDollars(e.target.value);
  };

  const handleAdjustByPercent = () => {
    const value = parseFloat(adjustByPercent);
    if (!isNaN(value)) {
      dispatch(setItemPricePercent({ id: item.id, value }));
    }
  };

  const handleAdjustByDollars = () => {
    const value = parseFloat(adjustByDollars);
    if (!isNaN(value)) {
      dispatch(setItemPriceDollars({ id: item.id, value }));
    }
  };

  const handleUnitPriceChange = (id: number, territory: string, price: number) => {
    dispatch(setItemUnitPrice({ id, value: { territory, price } }));
  };

  const handleCasePriceChange = (id: number, territory: string, price: number) => {
    dispatch(setItemCasePrice({ id, value: { territory, price } }));
  };

  return (
    <Fragment>
      {item.category !== previous?.category && (
        <tr className="sticky-top bg-white shadow-sm" style={{ top: '0' }}>
          <th colSpan={columnCount}>{item.category}</th>
        </tr>
      )}
      <tr>
        <th className="align-middle text-truncate cursor-default" rowSpan={rowCount} style={{ maxWidth: '325px' }}>
          <div>
            <span id={`pricing-display-name-${item.id}`}>{item.displayText}</span>
            <UncontrolledTooltip target={`pricing-display-name-${item.id}`}>{item.displayText}</UncontrolledTooltip>
          </div>
          <div className="row">
            <div className="col-auto pt-4">
              <label htmlFor={`has-pricing-${item.id}`} className="font-weight-light">
                Set Prices
              </label>
              &nbsp;
              <label
                className="custom-toggle custom-toggle-success mb--2 ml-2 d-inline-block"
                htmlFor={`has-pricing-${item.id}`}>
                <input
                  id={`has-pricing-${item.id}`}
                  type="checkbox"
                  checked={hasPricing}
                  onChange={handleHasPricingChange}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </div>
            {hasPricing && (
              <>
                <div className="col font-weight-light">
                  Discount by:
                  <div className="row no-gutters">
                    <div className="col-4">
                      <CustomInput
                        type="radio"
                        id={`has-pricing-${item.id}-percent`}
                        name={`adjust-by-${item.id}`}
                        value="percent"
                        checked={isPercent}
                        onChange={handleAdjustByChange}
                        label="%"
                        className="custom-checkbox-success mt-1"
                      />
                    </div>
                    {isPercent && (
                      <>
                        <div className="col col-auto">
                          <Input
                            bsSize="sm"
                            value={adjustByPercent}
                            onChange={handleAdjustByPercentChange}
                            onFocus={handleFocus}
                            className="text-center px-1"
                            style={{ width: '50px' }}
                          />
                        </div>
                        <div className="col-1">
                          <Button color="success" outline size="sm" onClick={handleAdjustByPercent} className="h-100">
                            <FontAwesomeIcon icon={['fal', 'chevron-right']} />
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="row no-gutters flex-nowrap">
                    <div className="col-4">
                      <CustomInput
                        type="radio"
                        id={`has-pricing-${item.id}-dollars`}
                        name={`adjust-by-${item.id}`}
                        value="dollars"
                        checked={isDollars}
                        onChange={handleAdjustByChange}
                        label="$"
                        className="custom-checkbox-success mt-1"
                      />
                    </div>
                    {isDollars && (
                      <>
                        <div className="col col-auto">
                          <Input
                            bsSize="sm"
                            value={adjustByDollars}
                            onChange={handleAdjustByDollarsChange}
                            onFocus={handleFocus}
                            className="text-center px-1"
                            style={{ width: '50px' }}
                          />
                        </div>
                        <div className="col-1">
                          <Button color="success" outline size="sm" onClick={handleAdjustByDollars} className="h-100">
                            <FontAwesomeIcon icon={['fal', 'chevron-right']} />
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </th>
        {isCuts && <td className="align-middle small">Single</td>}
        {territories.map((territory) => (
          <PricingItem
            key={territory}
            hasPricing={hasPricing}
            itemPrice={item}
            territory={territory}
            propName="unitPrice"
            catalogPropName="catalogUnitPrice"
            onChange={handleUnitPriceChange}
          />
        ))}
      </tr>

      {hasCases && (
        <tr>
          <td className="align-middle small">Case</td>

          {territories.map((territory) => (
            <PricingItem
              key={territory}
              hasPricing={hasPricing}
              itemPrice={item}
              territory={territory}
              propName="casePrice"
              catalogPropName="catalogCasePrice"
              onChange={handleCasePriceChange}
              nullable
            />
          ))}
        </tr>
      )}
    </Fragment>
  );
}
