import axios from 'boot/axios';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';
import { serializeError } from 'serialize-error';
import {
  createProblemDetails,
  isProblemDetails,
  ProblemDetails,
} from 'utils/problem-details';

export class ApiBase {
  protected get<T>(url: string): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      return axios
        .get<T>(url)
        .then((r) => checkResult(r, resolve, reject))
        .catch((e: AxiosError) => handleError(e, reject));
    });
  }
  protected post<T>(url: string, payload: any = {}): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      return axios
        .post<T>(url, payload)
        .then((r) => checkResult(r, resolve, reject))
        .catch((e: AxiosError) => handleError(e, reject));
    });
  }
  protected put<T>(url: string, payload: any = {}): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      return axios
        .put<T>(url, payload)
        .then((r) => checkResult(r, resolve, reject))
        .catch((e: AxiosError) => handleError(e, reject));
    });
  }
  protected delete<T>(url: string, payload: any = {}): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      return axios
        .delete<T>(url, payload)
        .then((r) => checkResult(r, resolve, reject))
        .catch((e: AxiosError) => handleError(e, reject));
    });
  }
}

function checkResult<T>(
  response: AxiosResponse<T> | AxiosError | Error,
  resolve: (data: T) => void,
  reject: (reason?: any) => void
): T | void {
  if (!isSuccess(response)) {
    if (isAxiosError(response)) {
      if (response.response) {
        if (isProblemDetails(response.response.data)) {
          return reject(response.response.data);
        }

        if (typeof response.response.data === 'string') {
          return reject(createProblemDetails(response.response.data));
        }
      }
    } else {
      if (response) {
        if (isError(response)) {
          const error = serializeError(response);
          return reject(
            createProblemDetails(error.name || 'Error', error.message)
          );
        }

        if (response.data && isProblemDetails(response.data)) {
          return reject(response.data);
        }

        if (typeof response.data === 'string') {
          return reject(createProblemDetails(response.data));
        }
      }

      if (response && response.data) {
        return reject(response.data);
      }

      return reject(response.data);
    }
  }

  return resolve((response as AxiosResponse).data);
}

export function handleError(e: AxiosError, reject: (reason?: any) => void) {
  console.error(e);

  if (e.response) {
    if (e.response.status === 401) {
      return;
    }

    if (isProblemDetails(e.response.data)) {
      return reject(e.response.data);
    }

    if (typeof e.response.data === 'string') {
      return reject(createProblemDetails(e.response.data));
    }
  }

  if (e.response && e.response.data) {
    return reject(e.response.data);
  }

  return reject(e.message);
}

export const axiosBaseQuery =
  (
    baseUrl: string
  ): BaseQueryFn<
    {
      url: string;
      method?: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
    },
    unknown,
    ProblemDetails
  > =>
  async ({ url, method = 'GET', data }) =>
    new Promise((resolve) =>
      axios({ url: baseUrl + url, method, data })
        .then((response) => {
          if (!isSuccess(response)) {
            if (isAxiosError(response)) {
              if (response.response) {
                if (isProblemDetails(response.response.data)) {
                  return resolve({ error: response.response.data });
                }

                if (typeof response.response.data === 'string') {
                  return resolve({
                    error: createProblemDetails(response.response.data),
                  });
                }
              }
            } else {
              if (response) {
                if (isError(response)) {
                  const error = serializeError(response);
                  return resolve({
                    error: createProblemDetails(
                      error.name || 'Error',
                      error.message
                    ),
                  });
                }

                if (response.data && isProblemDetails(response.data)) {
                  return resolve({ error: response.data });
                }

                if (typeof response.data === 'string') {
                  return resolve({
                    error: createProblemDetails(response.data),
                  });
                }
              }

              if (response && response.data) {
                return resolve({ error: response.data });
              }

              return resolve({ error: response.data });
            }
          }

          return resolve({ data: (response as AxiosResponse).data });
        })
        .catch((e: AxiosError) => {
          console.error(e);

          if (e.response) {
            if (e.response.status === 401) {
              return;
            }

            if (isProblemDetails(e.response.data)) {
              return resolve({ error: e.response.data });
            }

            if (typeof e.response.data === 'string') {
              return resolve({ error: createProblemDetails(e.response.data) });
            }
          }

          return resolve({ error: createProblemDetails(e.message) });
        })
    );

function isSuccess(response: AxiosResponse<any> | AxiosError | Error) {
  return (
    (response as AxiosResponse).status >= 200 &&
    (response as AxiosResponse).status < 300
  );
}

function isAxiosError(
  response: AxiosResponse<any> | AxiosError | Error
): response is AxiosError {
  return 'isAxiosError' in response && response.isAxiosError;
}

function isError(response: any): response is Error {
  return response instanceof Error;
}
