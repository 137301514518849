import { ApiBase } from './api-base';
import { OrderDetailWhilma } from './models/orders';
import { ConfirmedOrderListItem, ConfirmedOrderSort } from './models/confirmed-orders';
import { CatalogTypes } from './models/booking-catalogs';
import { Week } from './models/weeks';

class ConfirmedOrderService extends ApiBase {
  getList(page: number, salesperson: string, catalogType: CatalogTypes | null, week: Week | null, sort: ConfirmedOrderSort, showAll: boolean): Promise<ConfirmedOrderListResponse> {
    const query = week ? `&week=${week.weekNumber}&year=${week.year}` : '';
    return this.get(`/api/orders/whilma?page=${page}&salesperson=${salesperson}&catalogType=${catalogType || ''}&sort=${sort}${query}${showAll ? '&showAll=true': ''}`);
  }

  getOrderDetail(id: number): Promise<ConfirmedOrderDetailResponse> {
    return this.get(`/api/orders/whilma/${id}`);
  }

  cubing(id: number, cubes: number, boxCount: number): Promise<ConfirmedOrderCubingResponse> {
    const data = {cubes, boxCount};
    return this.post(`/api/orders/whilma/${id}/cubing`, data);
  }
}

export interface ConfirmedOrderListResponse {
  totalOrderCount: number;
  orders: ConfirmedOrderListItem[];
}

export interface ConfirmedOrderDetailResponse {
  order: OrderDetailWhilma;
}

export interface ConfirmedOrderCubingResponse {
  order: OrderDetailWhilma;
}

export const confirmedOrderApi = new ConfirmedOrderService();
