import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import moment from 'moment';
import numeral from 'numeral';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  selectEditItem,
  setEditItem,
  removeBookingCatalogInventory,
  editBookingCatalogInventory,
  selectEditItemError,
  setEditItemError,
  clearEditItemError,
  selectLoading,
  selectBookingCatalog,
  selectWeeks,
  EditBookingCatalogInventoryArgs,
} from './booking-catalog-detail-slice';
import { Error } from 'features/errors/Error';
import { ProductImage, productsApi } from 'api/products-service';
import {
  BookingCatalogDetailInventory,
  BookingCatalogInventoryWeek,
} from 'api/models/booking-catalogs';
import { getWeek, Week } from 'api/models/weeks';
import { contains } from 'utils/equals';
import { handleFocus } from 'utils/focus';
import { createProblemDetails, ProblemDetails } from 'utils/problem-details';
import { BookingCatalogItemEditModalWeek } from './BookingCatalogItemEditModalWeek';
import { unitName } from 'utils/units';

interface BookingCatalogItemEditModalProps {
  week: Week;
  endWeek: Week | null;
  availableFrom: moment.Moment;
  availableTo: moment.Moment;
}

export interface BookingCatalogInventoryWeekSelector
  extends BookingCatalogInventoryWeek {
  checked: boolean;
  error: ProblemDetails | null;
}

export function BookingCatalogItemEditModal(
  props: BookingCatalogItemEditModalProps
) {
  const dispatch = useDispatch(),
    { week, endWeek, availableFrom, availableTo } = props,
    item = useSelector(selectEditItem),
    error = useSelector(selectEditItemError),
    loading = useSelector(selectLoading),
    bookingCatalog = useSelector(selectBookingCatalog),
    weeks = useSelector(selectWeeks),
    [availableWeeks, setAvailableWeeks] = useState<
      BookingCatalogInventoryWeekSelector[]
    >([]),
    [useInventory, setUseInventory] = useState(true),
    [quantityInCases, setQuantityInCases] = useState<number | null>(null),
    [quantityMessage, setQuantityMessage] = useState<string | null>(null),
    [maximumInCases, setMaximumInCases] = useState<number | null>(null),
    [minimumOrderStemQuantityCuts, setMinimumOrderStemQuantityCuts] = useState<
      number | null
    >(null),
    [availabilityCutoff, setAvailabilityCutoff] =
      useState<moment.Moment | null>(null),
    [confirmDelete, setConfirmDelete] = useState(false),
    [cutoffError, setCutoffError] = useState<ProblemDetails | null>(null),
    [imageUrls, setImageUrls] = useState<ProductImage[]>([]),
    [imageError, setImageError] = useState<ProblemDetails | null>(null),
    isCuts = contains(bookingCatalog?.catalogType, 'cut flower'),
    caseQuantity = (isCuts ? 1 : item?.caseQuantity) || 1;

  useEffect(() => {
    const childError = availableWeeks.find((w) => w.error);

    if (childError) {
      setCutoffError(childError.error);
    } else if (useInventory && !numeral(quantityInCases).value()) {
      setCutoffError(null);
    } else if (!availabilityCutoff) {
      setCutoffError(null);
    } else if (moment(availableTo).isBefore(availabilityCutoff)) {
      setCutoffError(
        createProblemDetails('Cutoff is after availability expires')
      );
    } else if (moment(availableFrom).isAfter(availabilityCutoff)) {
      setCutoffError(
        createProblemDetails('Cutoff is before availability begins')
      );
    } else {
      setCutoffError(null);
    }
  }, [
    quantityInCases,
    availableFrom,
    availableTo,
    availabilityCutoff,
    useInventory,
    availableWeeks,
  ]);

  const clearItem = () => {
    dispatch(setEditItem(null));
  };

  const handleModalOpened = async () => {
    if (item) {
      setUseInventory(item?.quantity != null);
      setQuantityInCases(
        item?.quantity == null ? null : item.quantity / caseQuantity
      );
      setQuantityMessage(item?.quantityMessage || null);
      setMaximumInCases(
        (item?.maximumOrderQuantity || 0) / caseQuantity || null
      );
      setMinimumOrderStemQuantityCuts(
        item?.minimumOrderStemQuantityCuts || null
      );
      if (item?.availabilityCutoff) {
        const availabilityCutoff = moment.utc(item.availabilityCutoff).local();
        if (availabilityCutoff.isValid()) {
          setAvailabilityCutoff(availabilityCutoff);
        }
      } else {
        setAvailabilityCutoff(null);
      }

      if (endWeek) {
        const availableWeeks = (weeks || [])
          .filter((w) => w.id >= week.id && w.id <= endWeek.id)
          .map((w) => {
            const itemWeek = item?.weeks.find((week) => w.id === week.weekId),
              week = createWeek(w.id, item, weeks, itemWeek);
            return week;
          });

        setAvailableWeeks(availableWeeks);
      } else {
        setAvailableWeeks([]);
      }

      try {
        const { imageUrls } = await productsApi.images(item.productId);
        setImageUrls(imageUrls);
      } catch (e) {
        dispatch(setEditItemError(e as ProblemDetails));
      }
    }
  };

  const handleModalClosed = () => {
    setConfirmDelete(false);
    dispatch(clearEditItemError());
  };

  const handleRemoveImageUrlClick = (url: string) => {
    const index = imageUrls.findIndex((image) => image.imageUrl === url),
      update = imageUrls.map((i) => ({ ...i }));

    if (index !== -1) {
      update.splice(index, 1);
      setImageUrls(update);
    }

    const urls = update.map((url) => url.imageUrl),
      imageError = urls.some((url, index) => urls.indexOf(url) !== index)
        ? createProblemDetails('Duplicate images found.')
        : null;

    setImageError(imageError);
  };

  const handleEditImageUrlChange = (
    url: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const index = imageUrls.findIndex((image) => image.imageUrl === url);

    if (index !== -1) {
      const imageUrl = e.target.value,
        newImage = { ...imageUrls[index], imageUrl },
        update = imageUrls.map((i) => ({ ...i }));

      update.splice(index, 1, newImage);
      setImageUrls(update);

      const urls = update.map((url) => url.imageUrl),
        imageError = urls.some((url, index) => urls.indexOf(url) !== index)
          ? createProblemDetails('Duplicate images found.')
          : null;
      setImageError(imageError);
    }
  };

  const handleAddImageUrlClick = () => {
    const imageUrl = '',
      sortOrder =
        imageUrls.reduce((max, i) => Math.max(max, i.sortOrder), 1) + 1,
      urls = imageUrls.map((i) => ({ ...i }));
    urls.push({ imageUrl, sortOrder });
    setImageUrls(urls);
  };

  const handleMoveImageUpClick = (url: string) => {
    const index = imageUrls.findIndex((image) => image.imageUrl === url);

    if (index !== -1) {
      const images = imageUrls.map((i) => ({ ...i })),
        image = images[index],
        sortOrder = image.sortOrder;

      image.sortOrder--;

      images[index] = images[index - 1];
      images[index].sortOrder = sortOrder;
      images[index - 1] = image;

      setImageUrls(images);
    }
  };

  const handleMoveImageDownClick = (url: string) => {
    const index = imageUrls.findIndex((image) => image.imageUrl === url);

    if (index !== -1 && index < imageUrls.length - 1) {
      const images = imageUrls.map((i) => ({ ...i })),
        image = images[index],
        sortOrder = image.sortOrder;

      image.sortOrder++;

      images[index] = images[index + 1];
      images[index].sortOrder = sortOrder;
      images[index + 1] = image;

      setImageUrls(images);
    }
  };

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const quantityInCases = e.target.valueAsNumber || 0;
    setQuantityInCases(quantityInCases);

    if (availableWeeks.length) {
      const quantity =
          quantityInCases == null ? null : quantityInCases * caseQuantity,
        weeks = availableWeeks.map((w) => {
          const week = { ...w };
          if (week.checked) {
            week.quantity = quantity;
          }

          return week;
        });

      setAvailableWeeks(weeks);
    }
  };

  const handleQuantityMessageChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const message = e.target.value || null;
    setQuantityMessage(message);

    if (availableWeeks.length) {
      const weeks = availableWeeks.map((w) => {
        const week = { ...w };
        if (week.checked) {
          week.quantityMessage = message;
        }

        return week;
      });

      setAvailableWeeks(weeks);
    }
  };

  const handleUseInventoryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked,
      quantityInCases = checked ? 1 : null,
      message = checked ? null : quantityMessage;

    setUseInventory(checked);
    setQuantityInCases(quantityInCases);
    setQuantityMessage(message);

    if (availableWeeks.length) {
      const quantity =
          quantityInCases == null ? null : quantityInCases * caseQuantity,
        weeks = availableWeeks.map((w) => {
          const week = { ...w };
          if (week.checked) {
            week.quantity = quantity;
            week.quantityMessage = message;
          }

          return week;
        });

      setAvailableWeeks(weeks);
    }
  };

  const handleMaxQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const maxQuantityInCases = e.target.valueAsNumber || null;
    setMaximumInCases(maxQuantityInCases);

    if (availableWeeks.length) {
      const maxQuantity =
          maxQuantityInCases == null ? null : maxQuantityInCases * caseQuantity,
        weeks = availableWeeks.map((w) => {
          const week = { ...w };
          if (week.checked) {
            week.maximumOrderQuantity = maxQuantity;
          }

          return week;
        });

      setAvailableWeeks(weeks);
    }
  };

  const handleMinStemsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const minStems = e.target.valueAsNumber || null;
    setMinimumOrderStemQuantityCuts(minStems);

    if (availableWeeks.length) {
      const weeks = availableWeeks.map((w) => {
        const week = { ...w };
        if (week.checked) {
          week.minimumOrderStemQuantityCuts = minStems;
        }

        return week;
      });

      setAvailableWeeks(weeks);
    }
  };

  const handleAvailabilityCutoffDateChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const date = moment(e.target.value, 'YYYY-MM-DD');
    if (date.isValid()) {
      const cutoff = (availabilityCutoff || moment())
        .clone()
        .year(date.year())
        .month(date.month())
        .date(date.date());
      setAvailabilityCutoff(cutoff);
    }
  };

  const handleAvailabilityCutoffTimeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const time = moment(e.target.value, 'HH:mm');
    if (time.isValid()) {
      const cutoff = (availabilityCutoff || moment())
        .clone()
        .hour(time.hour())
        .minute(time.minute());
      setAvailabilityCutoff(cutoff);
    }
  };

  const handleClearCutoffClick = () => {
    setAvailabilityCutoff(null);
  };

  const handleAvailableWeekChange = (
    week: BookingCatalogInventoryWeekSelector
  ) => {
    const weeks = availableWeeks.map((w) => ({ ...w })),
      index = weeks.findIndex((w) => w.weekId === week.weekId);
    if (index !== -1) {
      weeks.splice(index, 1, week);
      setAvailableWeeks(weeks);
    }
  };

  const handleDeleteClick = () => {
    setConfirmDelete(true);
  };

  const handleSaveClick = async () => {
    if (item) {
      try {
        const images = imageUrls
          .filter((i) => !!i.imageUrl)
          .map((i, index) => ({ imageUrl: i.imageUrl, sortOrder: index + 1 }));
        await productsApi.saveImages(item.productId, images);

        if (quantityInCases && quantityInCases % 1) {
          dispatch(
            setEditItemError(
              createProblemDetails(
                'Please ensure that the Inventory quantity is a round number, without any decimals.'
              )
            )
          );
          return;
        }

        const quantity =
            quantityInCases == null ? null : quantityInCases * caseQuantity,
          maximumOrderQuantity = maximumInCases
            ? maximumInCases * caseQuantity
            : null,
          cutoff = availabilityCutoff
            ? availabilityCutoff.clone().utc().toISOString()
            : null,
          weeks = availableWeeks.length
            ? availableWeeks.filter((w) => w.checked)
            : null,
          args: EditBookingCatalogInventoryArgs = {
            item: {
              productId: item.productId,
              quantity,
              quantityMessage,
              maximumOrderQuantity,
              minimumOrderStemQuantityCuts,
              availabilityCutoff: cutoff,
              weeks,
            },
          };

        const result: any = await dispatch(editBookingCatalogInventory(args));

        if (!result.error) {
          clearItem();
        }
      } catch (e) {
        dispatch(setEditItemError(e as ProblemDetails));
      }
    }
  };

  const handleConfirmDelete = async () => {
    if (item) {
      const response: any = dispatch(removeBookingCatalogInventory(item));
      if (!response.error) {
        clearItem();
      }
    }
  };

  const toggleError = () => {
    dispatch(clearEditItemError());
  };

  return (
    <Modal
      isOpen={!!item}
      toggle={clearItem}
      size="xl"
      onOpened={handleModalOpened}
      onClosed={handleModalClosed}
      scrollable>
      {!!item && (
        <>
          <ModalHeader toggle={clearItem}>
            Edit Product: {item.size}&nbsp;{item.description1}&nbsp;
            {item.description2}
          </ModalHeader>
          <ModalBody>
            <div className="row no-gutters">
              <div className="col-8">
                <div className="row mb-2">
                  <div className="col-6">
                    <label htmlFor="use-inventory" className="d-inline-block">
                      Fixed Inventory Quantity
                    </label>
                    &nbsp;
                    <label
                      className="custom-toggle custom-toggle-success mb--2 ml-2"
                      htmlFor="use-inventory">
                      <input
                        id="use-inventory"
                        type="checkbox"
                        checked={useInventory}
                        onChange={handleUseInventoryChange}
                        onFocus={handleFocus}
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    {useInventory && (
                      <>
                        <label htmlFor="quantity">
                          Inventory
                          {isCuts && !!item.unitOfMeasureCuts && (
                            <em>
                              &nbsp; ({unitName(item.unitOfMeasureCuts, 2)})
                            </em>
                          )}
                        </label>
                        <Input
                          id="quantity"
                          type="number"
                          value={quantityInCases || ''}
                          onChange={handleQuantityChange}
                          onFocus={handleFocus}
                          className="text-right"
                        />
                      </>
                    )}
                    {!useInventory && (
                      <>
                        <label htmlFor="quantity-message">Message</label>
                        <Input
                          id="quantity-message"
                          value={quantityMessage || ''}
                          onChange={handleQuantityMessageChange}
                          onFocus={handleFocus}
                        />
                      </>
                    )}
                  </div>
                  <div className="col-6">
                    <label htmlFor="max-quantity">Max Order Qty</label>
                    <Input
                      id="max-quantity"
                      type="number"
                      value={maximumInCases || ''}
                      onChange={handleMaxQuantityChange}
                      onFocus={handleFocus}
                      className="text-right"
                    />
                  </div>
                </div>
                {isCuts && (
                  <div className="row">
                    <div className="col-6">
                      <label htmlFor="minimum-stems">Minimum Stem Qty</label>
                      <Input
                        id="minimum-stems"
                        type="number"
                        value={minimumOrderStemQuantityCuts || ''}
                        onChange={handleMinStemsChange}
                        onFocus={handleFocus}
                        className="text-right"
                      />
                    </div>
                  </div>
                )}
                {!availableWeeks.length && (
                  <div className="row">
                    <div className="col-6">
                      <label htmlFor="cutoff-date">Cutoff Date</label>
                      <Input
                        id="cutoff-date"
                        type="date"
                        value={availabilityCutoff?.format('YYYY-MM-DD') || ''}
                        onChange={handleAvailabilityCutoffDateChange}
                        onFocus={handleFocus}
                      />
                    </div>
                    <div className="col-6">
                      <label htmlFor="cutoff-time">Cutoff Time</label>
                      <div className="input-group">
                        <Input
                          id="cutoff-time"
                          type="time"
                          value={availabilityCutoff?.format('HH:mm') || ''}
                          onChange={handleAvailabilityCutoffTimeChange}
                          onFocus={handleFocus}
                        />
                        <div className="input-group-append">
                          <Button
                            id="clear-cutoff"
                            size="sm"
                            color="danger"
                            outline
                            onClick={handleClearCutoffClick}>
                            <FontAwesomeIcon icon={['fal', 'times']} />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {!!availableWeeks.length && (
                <div className="col-12 row mt-5">
                  <label className="form-control-label col-12">
                    Available Weeks
                  </label>

                  {availableWeeks.map((week) => (
                    <div key={week.weekId} className="col-4 mb-2">
                      <BookingCatalogItemEditModalWeek
                        week={week}
                        availableFrom={availableFrom}
                        availableTo={availableTo}
                        setWeek={handleAvailableWeekChange}
                      />
                    </div>
                  ))}
                </div>
              )}
              <div className="col-12 row mt-5">
                <label className="form-control-label col-12">
                  Product Images
                </label>
                <div className="col-12">
                  <div className="m-2">
                    {imageUrls.map((url, index) => (
                      <div
                        key={url.sortOrder}
                        className="d-inline-block text-center my-2"
                        style={{ width: '25%' }}>
                        {!!url.imageUrl && index > 0 && (
                          <Button
                            size="sm"
                            color="secondary"
                            outline
                            onClick={() =>
                              handleMoveImageUpClick(url.imageUrl)
                            }>
                            <FontAwesomeIcon icon={['fal', 'chevron-left']} />
                          </Button>
                        )}
                        {/* blank image url courtesy of https://stackoverflow.com/a/14115340 */}
                        <img
                          src={
                            url.imageUrl ||
                            'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
                          }
                          alt={item.description1}
                          style={{ height: '75px' }}
                          className={url.imageUrl ? '' : 'invisible'}
                        />
                        {!!url.imageUrl && index < imageUrls.length - 1 && (
                          <Button
                            size="sm"
                            color="secondary"
                            outline
                            onClick={() =>
                              handleMoveImageDownClick(url.imageUrl)
                            }>
                            <FontAwesomeIcon icon={['fal', 'chevron-right']} />
                          </Button>
                        )}
                        <div className="row">
                          <div className="col">
                            <Input
                              bsSize="sm"
                              value={url.imageUrl}
                              onChange={(e) =>
                                handleEditImageUrlChange(url.imageUrl, e)
                              }
                              className="d-inline"
                            />
                          </div>
                          <div className="col-auto">
                            <Button
                              color="danger"
                              outline
                              size="sm"
                              onClick={() =>
                                handleRemoveImageUrlClick(url.imageUrl)
                              }
                              className={`position-absolute ${
                                url.imageUrl ? '' : 'invisible'
                              }`}
                              style={{ top: 0, right: '15px' }}>
                              <FontAwesomeIcon icon={['fal', 'times']} />
                            </Button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-12 mt-2">
                  {!imageError && (
                    <Button
                      color="success"
                      outline
                      size="sm"
                      onClick={handleAddImageUrlClick}
                      disabled={imageUrls.some((url) => !url.imageUrl)}>
                      <FontAwesomeIcon icon={['fal', 'plus']} />
                    </Button>
                  )}
                  <Error
                    error={imageError}
                    clearError={() => setImageError(null)}
                  />
                </div>
              </div>
            </div>
            {confirmDelete && (
              <ReactBSAlert
                title="Remove Product"
                confirmBtnText="Yes"
                cancelBtnText="No"
                showCancel
                confirmBtnBsStyle="danger"
                onConfirm={handleConfirmDelete}
                onCancel={() => setConfirmDelete(false)}>
                Are you sure you want to remove this Product from the Booking
                Catalog?
              </ReactBSAlert>
            )}
            <Error error={error} clearError={toggleError} />
            <Error error={cutoffError} clearError={() => console.log('')} />
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              outline
              onClick={handleDeleteClick}
              className="mr-auto">
              <FontAwesomeIcon icon={['fad', 'trash-alt']} />
              &nbsp; Delete
            </Button>
            <Button onClick={clearItem}>Cancel</Button>
            <Button
              color="info"
              outline
              onClick={handleSaveClick}
              disabled={loading || !!cutoffError}>
              <FontAwesomeIcon icon={['fad', 'save']} />
              &nbsp; Save
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
}

function createWeek(
  weekId: string,
  item: BookingCatalogDetailInventory | null,
  allWeeks: Week[],
  inventoryWeek?: BookingCatalogInventoryWeek
): BookingCatalogInventoryWeekSelector {
  const { weekNumber, year } =
      allWeeks.find((w) => w.id === weekId) || getWeek(),
    // if there are weeks, but this isn't one of them, don't check it by default
    hasWeeks = !!item?.weeks.length,
    checked = !!inventoryWeek || !hasWeeks;

  return {
    checked,
    productId: item?.productId || 0,
    weekId,
    weekNumber,
    year,
    quantity: inventoryWeek?.quantity || null,
    maximumOrderQuantity: inventoryWeek?.maximumOrderQuantity || null,
    availabilityCutoff: inventoryWeek?.availabilityCutoff || null,
    quantityMessage: inventoryWeek?.quantityMessage || null,
    minimumOrderStemQuantityCuts:
      inventoryWeek?.minimumOrderStemQuantityCuts || null,
    error: null,
  };
}
