import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  clearError,
  selectError,
  selectIsLoading,
  selectPrices,
  selectTerritories,
  getPricing,
  updatePricing
} from './pricing-slice';
import { selectBookingCatalog } from './booking-catalog-detail-slice';
import { contains } from 'utils/equals';
import { Error } from 'features/errors/Error';
import { PricingProduct } from './PricingProduct';

interface PricingProps {
  isOpen: boolean;
  toggle: () => void;
}

export function Pricing(props: PricingProps) {
  const { isOpen, toggle } = props,
    dispatch = useDispatch(),
    prices = useSelector(selectPrices),
    bookingCatalog = useSelector(selectBookingCatalog),
    error = useSelector(selectError),
    isLoading = useSelector(selectIsLoading),
    territories = useSelector(selectTerritories),
    isCuts = contains(bookingCatalog?.catalogType, 'cut flower');

  const handleModalOpened = () => {
    if (bookingCatalog) {
      dispatch(getPricing(bookingCatalog.id));
    }
  };

  const handleSaveClick = async () => {
    if (bookingCatalog) {
      const response: any = await dispatch(updatePricing(bookingCatalog.id));

      if (!('error' in response)) {
        toggle();
      }
    }
  };

  const toggleError = () => {
    dispatch(clearError());
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="xl" onOpened={handleModalOpened} scrollable>
      <ModalHeader toggle={toggle}>Product Pricing</ModalHeader>
      <ModalBody>
        <div className="bg-white h-100 overflow-auto-y">
          <table className="table">
            <thead>
              <tr className="bg-white sticy-top border-bottom shadow">
                <th>Product</th>
                {isCuts && (
                  // single / case
                  <th>&nbsp;</th>
                )}
                {territories.map((territory) => (
                  <th key={territory}>
                    {territory.split(' ').map((word) => (
                      <div>{word}</div>
                    ))}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {prices.map((inventoryItem, index) => (
                <PricingProduct key={inventoryItem.id} item={inventoryItem} previous={prices[index - 1]} />
              ))}
            </tbody>
          </table>
        </div>
        <Error error={error} clearError={toggleError} />
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle}>Cancel</Button>
        <Button color="success" outline onClick={handleSaveClick} disabled={isLoading}>
          <FontAwesomeIcon icon={['fad', 'save']} />
          &nbsp; Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}
