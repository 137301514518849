import { ApiBase } from 'api/api-base';

class ProductsService extends ApiBase {
  images(productId: number) : Promise<ProductImagesResponse> {
    return this.get(`/api/products/${productId}/images`);
  }

  saveImages(productId: number, imageUrls: ProductImage[]) {
    return this.post(`/api/products/${productId}/images`, {imageUrls});
  }
}

export interface ProductImagesResponse {
  imageUrls: ProductImage[];
}

export interface ProductImage {
  imageUrl: string;
  sortOrder: number;
}

export const productsApi = new ProductsService();
