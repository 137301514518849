import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  AsyncThunk,
  createAction,
} from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { cartApi, CartDetailResponse } from 'api/cart-service';
import { CartDetail } from 'api/models/carts';
import { ProblemDetails } from 'utils/problem-details';

export interface CartDetailState {
  cart: CartDetail | null;
  loading: boolean;
  error: ProblemDetails | null;
}

const initialState: CartDetailState = {
  cart: null,
  loading: false,
  error: null,
};

export const getCartDetail: AsyncThunk<
  CartDetailResponse,
  number,
  { state: RootState }
> = createAsyncThunk(
  'orders/getCartDetail',
  async (id, { rejectWithValue }) => {
    try {
      return await cartApi.getCartDetail(id);
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

const getCartDetailPending = createAction(getCartDetail.pending.type),
  getCartDetailFulfilled = createAction<CartDetailResponse>(
    getCartDetail.fulfilled.type
  ),
  getCartDetailRejected = createAction<ProblemDetails>(
    getCartDetail.rejected.type
  );

export const cartSlice = createSlice({
  name: 'cartDetail',
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getCartDetailPending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getCartDetailFulfilled,
        (state, action: PayloadAction<CartDetailResponse>) => {
          state.error = null;
          state.loading = false;
          state.cart = action.payload.cart;
        }
      )
      .addCase(
        getCartDetailRejected,
        (state, action: PayloadAction<ProblemDetails>) => {
          state.loading = false;
          state.error = action.payload;
        }
      ),
});

export const { clearError } = cartSlice.actions;

export const selectCart = (state: RootState) => state.cartDetail.cart;
export const selectLoading = (state: RootState) => state.cartDetail.loading;
export const selectError = (state: RootState) => state.cartDetail.error;
export const selectSubTotal = (state: RootState) =>
  state.cartDetail.cart?.items?.reduce(
    (total, item) => total + item.quantity * item.unitPrice,
    0
  ) || 0;

export default cartSlice.reducer;
