import axios from 'boot/axios';
import { setUser } from 'features/auth/auth-slice';
import { authService } from 'features/auth/authorization-service';
import { createListenerMiddleware } from '@reduxjs/toolkit';

export const authorizationHeaderMiddleware = createListenerMiddleware();

authorizationHeaderMiddleware.startListening({
  actionCreator: setUser,
  effect: async (action) => {
    if (action.type === setUser.type) {
      if (action.payload) {
        const token = await authService.getAccessToken();
        axios.defaults.headers['Authorization'] = `Bearer ${token}`;
      } else {
        axios.defaults.headers['Authorization'] = null;
      }
    }
  },
});
