import {
  createSlice,
  createAsyncThunk,
  AsyncThunk,
  createAction,
} from '@reduxjs/toolkit';
import { reportsApi, ReportsConfigurationResponse } from 'api/reports-service';
import { RootState } from 'app/store';
import { ProblemDetails } from 'utils/problem-details';

export const frameSandbox =
  'allow-forms allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation allow-downloads';
export const reportParameters =
  '&rs:Command=Render&rs:LinkTarget=_top&rs:Embed=True';

export interface ReportsState {
  reportsUrl: string | null;
  error: ProblemDetails | null;
}

const initialState: ReportsState = {
  reportsUrl: null,
  error: null,
};

export const configuration: AsyncThunk<
  ReportsConfigurationResponse,
  void,
  { state: RootState }
> = createAsyncThunk(
  'reports/configuration',
  async (_, { rejectWithValue }) => {
    try {
      return await reportsApi.configuration();
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

const configurationFulfilled = createAction<ReportsConfigurationResponse>(
    configuration.fulfilled.type
  ),
  configurationRejected = createAction<ProblemDetails>(
    configuration.rejected.type
  );

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(configurationFulfilled, (state, action) => {
        state.reportsUrl = action.payload.reportsUrl;
      })
      .addCase(configurationRejected, (state, action) => {
        state.error = action.payload;
      }),
});

export const { clearError } = reportsSlice.actions;

export const selectError = (state: RootState) => state.reports.error;
export const selectReportsUrl = (state: RootState) => state.reports.reportsUrl;

export default reportsSlice.reducer;
