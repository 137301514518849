import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import numeral from 'numeral';
import * as XLSX from 'xlsx';
import { PayloadAction } from '@reduxjs/toolkit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  selectBookingCatalog,
  clearError,
  selectError,
  clearZeroPriceError,
  selectZeroPriceError,
  setIgnoreZeroPriceError,
  selectIgnoreZeroPriceError,
  getBookingCatalogDetail,
  selectWeeks,
  updateBookingCatalogInventory,
  updateBookingCatalog,
  setLoading,
  setError,
  selectSendingNotifications,
  updateBookingCatalogFeatures,
  updateBookingCatalogAlert,
  sendBookingCatalogNotifications,
  selectInventory,
  clearState,
  deleteBookingCatalog,
  getBookingCatalogInventory,
} from './booking-catalog-detail-slice';
import {
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
  Alert,
} from 'reactstrap';
import { routes } from 'app/routes';
import { createWeek, getWeek, Week } from 'api/models/weeks';
import { BookingCatalogItem } from './BookingCatalogItem';
import { AddProductsModal } from './AddProductsModal';
import {
  BookingCatalogDetailInventory,
  BookingCatalogDetail,
  CatalogTypes,
} from 'api/models/booking-catalogs';
import {
  UpdateBookingCatalogRequest,
  bookingCatalogApi,
  BookingCatalogDetailResponse,
} from 'api/booking-catalog-service';
import { AlertModal, SaveAlertArgs } from './AlertModal';
import { FeaturesModal, SaveFeaturesArgs } from './FeaturesModal';
import { Error } from 'features/errors/Error';
import { UnusedTerritories } from 'features/products/plants/plant-detail-slice';
import { contains } from 'utils/equals';
import { formatDate } from 'utils/format';
import { ProblemDetails, createProblemDetails } from 'utils/problem-details';
import { BookingCatalogItemEditModal } from './BookingCatalogItemEditModal';
import { OrderedProductsModal } from './OrderedProductsModal';
import { Pricing } from './Pricing';
import { handleDropdownToggle, maxHeightModifiers } from 'utils/dropdown';

interface InventoryUpdateRow {
  'Product ID': number;
  Item: string;
  Category: string;
  Comment: string;
  Quantity: number | string;
  MaximumOrderQuantity: number | null;
  MinimumStemOrderQuantity?: number | null;
  'Availability Cutoff': string | null;
  error?: string;
}

export function Detail() {
  const dispatch = useDispatch(),
    navigate = useNavigate(),
    error = useSelector(selectError),
    zeroPriceError = useSelector(selectZeroPriceError),
    ignoreZeroPriceError = useSelector(selectIgnoreZeroPriceError),
    bookingCatalog = useSelector(selectBookingCatalog),
    inventory = useSelector(selectInventory),
    weeks = useSelector(selectWeeks),
    sendingNotifications = useSelector(selectSendingNotifications),
    [name, setName] = useState(''),
    [catalogType, setCatalogType] = useState<CatalogTypes>('Plants'),
    [week, setWeek] = useState(
      createWeek(getWeek().year, getWeek().weekNumber)
    ),
    [endWeek, setEndWeek] = useState<Week | null>(null),
    [availableFrom, setAvailableFrom] = useState(moment()),
    [availableTo, setAvailableTo] = useState(moment()),
    [notifyAutomatically, setNotifyAutomatically] = useState(false),
    [showAvailableOnly, setShowAvailableOnly] = useState(false),
    [showUploadModal, setShowUploadModal] = useState(false),
    [showAddProductsModal, setShowAddProductsModal] = useState(false),
    [showFeaturesModal, setShowFeaturesModal] = useState(false),
    [showAddAlertModal, setShowAddAlertModal] = useState(false),
    [showOrderedProductsModal, setShowOrderedProductsModal] = useState(false),
    [showPricingModal, setShowPricingModal] = useState(false),
    [rows, setRows] = useState<InventoryUpdateRow[] | null>(null),
    [showSendNotificationModal, setShowSendNotificationModal] = useState(false),
    [showConfirmDelete, setShowConfirmDelete] = useState(false),
    [showDownload, setShowDownload] = useState(false),
    [dataFetched, setDataFetched] = useState(false),
    thisWeek = getWeek(),
    today = moment().format('YYYY-MM-DD'),
    startWeeks = (weeks || [])
      .slice()
      .filter(
        (w) =>
          (w.year === thisWeek.year && w.weekNumber >= thisWeek.weekNumber) ||
          w.year > thisWeek.year
      ),
    endWeeks = (weeks || [])
      .slice()
      .filter(
        (w) =>
          (w.year === week.year && w.weekNumber >= week.weekNumber) ||
          w.year > week.year
      ),
    { id } = useParams<{ id: string }>(),
    idValue = numeral(id).value() || 0,
    isLive = moment().isBetween(availableFrom, availableTo),
    hasError = rows?.some((r) => r.error),
    isPrebook = contains(catalogType, 'Prebook'),
    isCuts = contains(catalogType, 'Cut Flower'),
    notificationWasSent = !!bookingCatalog?.notificationEmailSent,
    hasOrders = !!bookingCatalog?.hasOrders,
    deleteReason =
      notificationWasSent && hasOrders
        ? 'the email notification was sent, and orders have been placed against it.'
        : notificationWasSent
        ? 'the email notifications have been sent.'
        : hasOrders
        ? 'orders have been placed against it.'
        : '',
    deleteMessage =
      notificationWasSent || hasOrders
        ? `This Booking Catalog cannot be deleted because ${deleteReason}`
        : '';

  const refresh = useCallback(() => {
    dispatch(getBookingCatalogInventory());
  }, [dispatch]);

  const save = useCallback(
    async (
      bookingCatalog: BookingCatalogDetail,
      inventory: BookingCatalogDetailInventory[]
    ): Promise<any | null> => {
      if (!inventory.length) {
        const error = createProblemDetails(
          'No Inventory',
          'Please add inventory to the Booking Catalog.'
        );
        dispatch(setError(error));
        return null;
      }

      if (endWeek && endWeek.id < week.id) {
        const error = createProblemDetails(
          'Invalid End Week',
          'The End Week must be after the Start Week.'
        );
        dispatch(setError(error));
        return null;
      }

      const weekId = week.id,
        endWeekId = endWeek?.id || null,
        args: UpdateBookingCatalogRequest = {
          id: bookingCatalog.id,
          name,
          catalogType,
          weekId,
          endWeekId,
          availableFrom: availableFrom.clone().utc().toISOString(),
          availableTo: availableTo.clone().utc().toISOString(),
          notifyAutomatically,
        };
      const result: any = await dispatch(updateBookingCatalog(args));
      return result;
    },
    [
      availableFrom,
      availableTo,
      catalogType,
      dispatch,
      endWeek,
      name,
      notifyAutomatically,
      week.id,
    ]
  );

  useEffect(() => {
    const fetchData = async () => {
      const result: unknown = await dispatch(getBookingCatalogDetail(idValue)),
        payloadAction = result as PayloadAction<BookingCatalogDetailResponse>,
        bookingCatalog = payloadAction?.payload.bookingCatalog;

      if (bookingCatalog) {
        setName(bookingCatalog.name);
        setCatalogType(bookingCatalog.catalogType);

        const from = moment.utc(bookingCatalog.availableFrom).local(),
          to = moment.utc(bookingCatalog.availableTo).local(),
          week = weeks.find((w) => w.id === bookingCatalog.weekId) || getWeek(),
          endWeek =
            weeks.find((w) => w.id === bookingCatalog.endWeekId) || null;

        setWeek(week);
        setAvailableFrom(from);
        setAvailableTo(to);
        setNotifyAutomatically(bookingCatalog.notifyAutomatically);

        if (week) {
          setWeek(week);
        }

        setEndWeek(endWeek);

        setDataFetched(true);
      }
    };

    let timer = window.setInterval(refresh, 1000 * 5);

    fetchData();

    return function cleanup() {
      dispatch(clearState());
      if (timer) {
        window.clearInterval(timer);
      }
    };
  }, [dispatch, idValue, refresh, weeks]);

  useEffect(() => {
    if (dataFetched && bookingCatalog && inventory?.length && week) {
      const fromValue = availableFrom.clone().utc().toISOString(),
        toValue = availableTo.clone().utc().toISOString(),
        from = moment.utc(bookingCatalog.availableFrom).toISOString(),
        to = moment.utc(bookingCatalog.availableTo).toISOString();

      if (
        name !== bookingCatalog.name ||
        catalogType !== bookingCatalog.catalogType ||
        week.id !== bookingCatalog.weekId ||
        (endWeek?.id || null) !== bookingCatalog.endWeekId ||
        fromValue !== from ||
        toValue !== to ||
        notifyAutomatically !== bookingCatalog.notifyAutomatically
      ) {
        save(bookingCatalog, inventory);
      }
    }
  }, [
    dispatch,
    name,
    catalogType,
    availableFrom,
    availableTo,
    week,
    endWeek,
    bookingCatalog,
    inventory,
    notifyAutomatically,
    dataFetched,
    save,
  ]);

  const handleCatalogTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const type = e.target.value as CatalogTypes;
    setCatalogType(type);

    if (!contains(type, 'Prebook')) {
      setNotifyAutomatically(false);
      setEndWeek(null);
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value || '');
  };

  const handleWeekClick = (value: Week) => {
    setWeek(value);
    const from = availableFrom
        .clone()
        .isoWeek(value.weekNumber)
        .startOf('isoWeek'),
      to = from.clone().isoWeekday(4).hour(15).minute(0);
    setAvailableFrom(from);
    setAvailableTo(to);
  };

  const handleEndWeekClick = (value: Week | null) => {
    setEndWeek(value);
  };

  const handleAvailableFromDateChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const date = moment(e.target.value, 'YYYY-MM-DD');
    if (date.isValid()) {
      const from = availableFrom
        .clone()
        .year(date.year())
        .month(date.month())
        .date(date.date())
        .startOf('day');
      setAvailableFrom(from);
      if (date.isBefore(moment().startOf('day'))) {
        dispatch(
          setError(createProblemDetails('From Date is prior to today.'))
        );
      } else {
        dispatch(clearError());
      }
    }
  };

  const handleAvailableFromTimeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const time = moment(e.target.value, 'HH:mm');
    if (time.isValid()) {
      const from = availableFrom
        .clone()
        .hour(time.hour())
        .minute(time.minute());
      setAvailableFrom(from);
    }
  };

  const handleAvailableToDateChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const date = moment(e.target.value, 'YYYY-MM-DD');
    if (date.isValid()) {
      const to = availableTo
        .clone()
        .year(date.year())
        .month(date.month())
        .date(date.date())
        .startOf('day');
      setAvailableTo(to);

      if (date.isBefore(moment().startOf('day'))) {
        dispatch(setError(createProblemDetails('To Date is prior to today.')));
      } else {
        dispatch(clearError());
      }
    }
  };

  const handleAvailableToTimeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const time = moment(e.target.value, 'HH:mm');
    if (time.isValid()) {
      const to = availableTo.clone().hour(time.hour()).minute(time.minute());
      setAvailableTo(to);
    }
  };

  const handleNotifyAutomaticallyChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNotifyAutomatically(e.target.checked);
  };

  const handleShowNotificationModalClick = () => {
    setShowSendNotificationModal(true);
  };

  const handleSendNotificationClick = async () => {
    setShowSendNotificationModal(false);

    if (bookingCatalog && inventory) {
      const saved: any = await save(bookingCatalog, inventory);
      if (saved && !saved.error) {
        const result: any = await dispatch(sendBookingCatalogNotifications());
        if (!result.error) {
          setShowSendNotificationModal(false);
        }
      }
    }
  };

  const toggleShowSendNotificationModal = () => {
    setShowSendNotificationModal((show) => !show);
  };

  const handleSetShowAvailableOnlyChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const availableOnly = e.target.checked;
    setShowAvailableOnly(availableOnly);
  };

  const handleDownloadClick = () => {
    setShowDownload(true);
  };

  const toggleShowDownload = () => {
    setShowDownload(false);
  };

  const handleDownloadConfirm = async (all: boolean) => {
    if (bookingCatalog) {
      try {
        dispatch(setLoading(true));
        let territories = [] as string[];
        const { products, prices } = await bookingCatalogApi.products(
            bookingCatalog.catalogType,
            bookingCatalog.id
          ),
          priceMap = prices.reduce((map, p) => {
            if (
              territories.indexOf(p.territory) === -1 &&
              UnusedTerritories.indexOf(p.territory) === -1 &&
              p.unitPrice
            ) {
              territories.push(p.territory);
            }
            if (map[p.productId] === undefined) {
              map[p.productId] = {};
            }

            const territoryMap = map[p.productId];
            territoryMap[p.territory] = p.unitPrice;
            return map;
          }, {} as { [key: string]: { [key: string]: number | null } });

        territories.sort();
        const header = isCuts
            ? [
                'Product ID',
                'Category',
                'Item',
                'Comment',
                'Quantity',
                'MaximumOrderQuantity',
                'MinimumStemOrderQuantity',
                'Availability Cutoff',
              ]
            : [
                'Product ID',
                'Category',
                'Item',
                'Comment',
                'Quantity',
                'MaximumOrderQuantity',
                'Availability Cutoff',
              ].concat(territories),
          data = ([header] as any[][]).concat(
            products.map((i) => {
              const caseQuantity = isCuts ? 1 : i.caseQuantity,
                quantityInCases =
                  i.quantity == null
                    ? i.quantityMessage || ''
                    : Math.floor(i.quantity / caseQuantity),
                maxInCases =
                  i.maximumOrderQuantity == null
                    ? null
                    : Math.floor(i.maximumOrderQuantity / caseQuantity),
                availabilityCutoff =
                  i.availabilityCutoff == null
                    ? null
                    : moment
                        .utc(i.availabilityCutoff)
                        .local()
                        .format('YYYY-MM-DD HH:mm'),
                colour = isCuts && i.colour ? ` (${i.colour})` : '',
                territoryMap = priceMap[i.productId] || {};
              return isCuts
                ? [
                    i.productId,
                    i.category,
                    `${i.size} ${i.description1} ${i.description2}${colour}`,
                    i.comment,
                    quantityInCases,
                    maxInCases,
                    i.minimumOrderStemQuantityCuts,
                    availabilityCutoff,
                  ]
                : [
                    i.productId,
                    i.category,
                    `${i.size} ${i.description1} ${i.description2}${colour}`,
                    i.comment,
                    quantityInCases,
                    maxInCases,
                    availabilityCutoff,
                  ].concat(territories.map((t) => territoryMap[t] || null));
            })
          ),
          book = XLSX.utils.book_new(),
          sheet = XLSX.utils.aoa_to_sheet(data);

        XLSX.utils.book_append_sheet(book, sheet);

        XLSX.writeFile(book, `${bookingCatalog.name}.xlsx`);

        setShowDownload(false);
      } catch (e) {
        dispatch(setError(e as ProblemDetails));
      } finally {
        dispatch(setLoading(false));
      }
    }
  };

  const handleShowOrderedClick = () => {
    setShowOrderedProductsModal(true);
  };

  const toggleShowOrdered = () => {
    setShowOrderedProductsModal(false);
  };

  const handleShowPricingModalClick = () => {
    setShowPricingModal(true);
  };

  const toggleShowPricingModal = () => {
    setShowPricingModal(false);
  };

  const handleUploadClick = () => {
    setRows(null);
    setShowUploadModal(true);
  };

  const handleAddProductsClick = () => {
    setShowAddProductsModal(true);
  };

  const handleSetFeaturesClick = () => {
    setShowFeaturesModal(true);
  };

  const handleSetFeaturesSave = async (args: SaveFeaturesArgs) => {
    if (bookingCatalog) {
      const response: any = await dispatch(updateBookingCatalogFeatures(args));
      if (response.error) {
        return response.error;
      }
    }

    return null;
  };

  const handleAddAlertClick = () => {
    setShowAddAlertModal(true);
  };

  const handleAddAlertSave = async (args: SaveAlertArgs) => {
    if (bookingCatalog) {
      const response: any = await dispatch(updateBookingCatalogAlert(args));
      if (response.error) {
        return response.error;
      }
    }

    return null;
  };

  const handleDeleteClick = () => {
    setShowConfirmDelete(true);
  };

  const handleDeleteConfirm = async () => {
    if (bookingCatalog) {
      const deleted: any = await dispatch(
        deleteBookingCatalog(bookingCatalog.id)
      );
      if (deleted && !deleted.error) {
        setShowConfirmDelete(false);
        navigate(routes.bookingCatalogs.path);
      }
    }
  };

  const handleDeleteCancel = () => {
    setShowConfirmDelete(false);
  };

  const handleFileUploadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (bookingCatalog) {
      const availableTo = moment(bookingCatalog.availableTo),
        availableFrom = moment(bookingCatalog.availableFrom),
        files = e.target.files,
        file = files?.length && files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (ev) => {
          if (ev?.target?.result instanceof ArrayBuffer) {
            const data = new Uint8Array(ev.target.result),
              book = XLSX.read(data, { type: 'array' }),
              inventory = XLSX.utils.sheet_to_json<InventoryUpdateRow>(
                book.Sheets[book.SheetNames[0]]
              );

            // The date is returned as a serial number.
            // We use a couple of magic numbers from https://stackoverflow.com/a/22352911
            inventory.forEach((i) => {
              if (i['Availability Cutoff']) {
                const serial: number = parseFloat(i['Availability Cutoff']),
                  milliseconds = Math.round((serial - 25569) * 86400 * 1000);
                let m = moment.utc(milliseconds);

                // the date can be a string value starting with the year
                if (!m.isValid() || milliseconds < 0) {
                  m = moment(i['Availability Cutoff']);
                }

                i['Availability Cutoff'] = m.format('YYYY-MM-DD HH:mm');

                if (availableTo.isBefore(m)) {
                  i.error = 'Cutoff is after availability expires';
                } else if (availableFrom.isAfter(m)) {
                  i.error = 'Cutoff is before availability begins';
                }
              }
            });

            setRows(inventory);
          }
        };
        reader.readAsArrayBuffer(file);
      }
    }
  };

  const handleUpdateInventoryClick = async () => {
    if (bookingCatalog && rows?.length) {
      const updatedInventory = rows
        .map((i) => {
          const availabilityCutoff = i['Availability Cutoff']
              ? moment(i['Availability Cutoff']).utc().toISOString()
              : null,
            quantity = typeof i.Quantity === 'number' ? i.Quantity : null,
            quantityMessage =
              typeof i.Quantity === 'string' ? i.Quantity : null;
          return {
            productId: i['Product ID'],
            quantity,
            maximumOrderQuantity: i.MaximumOrderQuantity,
            minimumOrderStemQuantityCuts: i.MinimumStemOrderQuantity || null,
            availabilityCutoff,
            quantityMessage,
            weeks:
              inventory?.find((inv) => inv.productId === i['Product ID'])
                ?.weeks || [],
          };
        })
        .filter(
          (i) => (i.quantity != null && i.quantity > 0) || i.quantityMessage
        );

      await dispatch(updateBookingCatalogInventory(updatedInventory));
      toggleShowUploadModal();
    }
  };

  const toggleShowUploadModal = () => {
    setShowUploadModal(!showUploadModal);
  };

  const toggleError = () => {
    dispatch(clearError());
  };

  const handleClearZeroPriceErrorClick = () => {
    dispatch(clearZeroPriceError());
  };

  const handleIgnoreZeroPriceErrorClick = () => {
    dispatch(setIgnoreZeroPriceError(true));
  };

  return (
    <div className="container mt-4">
      <div className="row no-gutters mb-4">
        <h1 className="col-12">
          <FontAwesomeIcon icon={['fad', 'atlas']} />
          &nbsp;
          <span>Booking Catalog Detail: {bookingCatalog?.name}</span>
        </h1>
        <div className="col-auto">
          <UncontrolledDropdown>
            <DropdownToggle outline color="warning" caret>
              Product Management
            </DropdownToggle>
            <DropdownMenu style={{ zIndex: 1025 }}>
              <DropdownItem onClick={handleAddProductsClick}>
                <FontAwesomeIcon
                  icon={['fal', 'plus-circle']}
                  fixedWidth
                  className="text-warning"
                />
                Edit Products
              </DropdownItem>
              <DropdownItem onClick={handleShowPricingModalClick}>
                <FontAwesomeIcon
                  icon={['fal', 'dollar-sign']}
                  fixedWidth
                  className="text-warning"
                />
                Pricing
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={handleShowOrderedClick}>
                <FontAwesomeIcon
                  icon={['fal', 'user-chart']}
                  fixedWidth
                  className="text-warning"
                />
                Show Ordered
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={handleDownloadClick}>
                <FontAwesomeIcon
                  icon={['fal', 'file-excel']}
                  fixedWidth
                  className="text-warning"
                />
                Download
              </DropdownItem>
              <DropdownItem onClick={handleUploadClick}>
                <FontAwesomeIcon
                  icon={['fal', 'cloud-upload-alt']}
                  fixedWidth
                  className="text-warning"
                />
                Update
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown>
            <DropdownToggle outline color="info" caret>
              Catalog Management
            </DropdownToggle>
            <DropdownMenu style={{ zIndex: 1025 }}>
              <DropdownItem onClick={handleAddAlertClick}>
                <FontAwesomeIcon
                  icon={['fal', 'exclamation-circle']}
                  fixedWidth
                  className="text-info"
                />
                Alert
              </DropdownItem>
              <DropdownItem onClick={handleSetFeaturesClick}>
                <FontAwesomeIcon
                  icon={['fal', 'sparkles']}
                  fixedWidth
                  className="text-info"
                />
                Features
              </DropdownItem>
              <DropdownItem divider />
              {!!bookingCatalog?.id && (
                <>
                  <span
                    id="delete-booking-catalog"
                    className="d-inline-block"
                    tabIndex={0}>
                    <DropdownItem
                      onClick={handleDeleteClick}
                      className="text-danger"
                      disabled={!!deleteMessage}
                      style={{
                        pointerEvents: `${deleteMessage ? 'none' : 'all'}`,
                      }}>
                      <FontAwesomeIcon
                        icon={['fad', 'trash-alt']}
                        fixedWidth
                        className="text-danger"
                      />
                      Delete Catalog
                    </DropdownItem>
                  </span>
                  <>
                    {!!deleteMessage && (
                      <UncontrolledTooltip
                        target="delete-booking-catalog"
                        container="body"
                        placement="top-start">
                        {deleteMessage}
                      </UncontrolledTooltip>
                    )}
                  </>
                </>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <div className="col text-right">
          <Button tag={Link} to={routes.bookingCatalogs.path}>
            Close
          </Button>
        </div>
      </div>
      <Error error={error} clearError={toggleError} />
      {!ignoreZeroPriceError && (
        <Alert
          color="neutral"
          className="text-danger"
          isOpen={!!zeroPriceError}>
          <div className="row">
            <div className="col">
              <p className="lead d-inline-block">{zeroPriceError?.title}</p>
              <Button
                size="sm"
                onClick={handleIgnoreZeroPriceErrorClick}
                className="ml-2">
                Ignore
              </Button>
            </div>
            <div className="col-auto ml-auto">
              <Button close onClick={handleClearZeroPriceErrorClick} />
            </div>
          </div>
          {!!zeroPriceError?.detail && <p>{zeroPriceError.detail}</p>}
        </Alert>
      )}
      {!!bookingCatalog && (
        <>
          <div className="row">
            {!isPrebook && (
              <div className="col-auto">
                <label className="form-control-label">Week</label>
                <div>
                  <UncontrolledDropdown onToggle={handleDropdownToggle}>
                    <DropdownToggle caret color="secondary">
                      {week.weekNumber}, {week.year}
                    </DropdownToggle>
                    <DropdownMenu
                      modifiers={maxHeightModifiers()}
                      style={{ zIndex: 1025 }}>
                      {startWeeks.map((w) => (
                        <DropdownItem
                          key={w.id}
                          onClick={() => handleWeekClick(w)}>
                          {w.weekNumber}, {w.year}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
            )}
            <div className="col-3">
              <label htmlFor="catalog-type" className="form-control-label">
                Type
              </label>
              <Input
                id="catalog-type"
                type="select"
                value={catalogType}
                onChange={handleCatalogTypeChange}>
                <option value="Plants">Plants</option>
                <option value="Cut Flowers">Cut Flowers</option>
                <option value="Plant Prebook">Plant Prebook</option>
                <option value="Cut Flowers Prebook">Cut Flowers Prebook</option>
              </Input>
            </div>
            <div className="col">
              <label className="form-control-label" htmlFor="name">
                Name
              </label>
              <Input id="name" value={name} onChange={handleNameChange} />
            </div>
          </div>
          <div className="row my-4">
            {isPrebook && (
              <>
                <div className="col-auto text-center">
                  <label className="form-control-label" htmlFor="week">
                    {isPrebook ? 'Products Available From' : 'Week'}
                  </label>
                  <div>
                    <UncontrolledDropdown onToggle={handleDropdownToggle}>
                      <DropdownToggle caret color="secondary">
                        {week.weekNumber}, {week.year}
                      </DropdownToggle>
                      <DropdownMenu
                        modifiers={maxHeightModifiers()}
                        style={{ zIndex: 1025 }}>
                        {startWeeks.map((w) => (
                          <DropdownItem
                            key={w.id}
                            onClick={() => handleWeekClick(w)}>
                            {w.weekNumber}, {w.year}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
                <div className="col-auto text-center">
                  <label className="form-control-label" htmlFor="end-week">
                    Products Available To
                  </label>
                  <div>
                    <UncontrolledDropdown>
                      <DropdownToggle caret color="secondary">
                        {endWeek?.weekNumber || '--'}
                        {endWeek ? `, ${endWeek.year}` : ''}
                      </DropdownToggle>
                      <DropdownMenu
                        modifiers={maxHeightModifiers()}
                        style={{ zIndex: 1025 }}>
                        {endWeeks.map((w) => (
                          <DropdownItem
                            key={w.id}
                            onClick={() => handleEndWeekClick(w)}>
                            {w.weekNumber}, {w.year}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    {endWeek && (
                      <Button
                        color="danger"
                        outline
                        size="sm"
                        onClick={() => handleEndWeekClick(null)}
                        className="mt-2">
                        <FontAwesomeIcon icon={['fal', 'times']} />
                      </Button>
                    )}
                  </div>
                </div>
              </>
            )}
            <div className="col-4">
              <label
                className="form-control-label"
                htmlFor="available-from-date">
                Catalog Available From
              </label>
              <div className="row no-gutters">
                <div className="col">
                  <Input
                    id="available-from-date"
                    type="date"
                    min={today}
                    value={availableFrom.format('YYYY-MM-DD')}
                    onChange={handleAvailableFromDateChange}
                  />
                </div>
                <div className="col">
                  <Input
                    id="available-from-time"
                    type="time"
                    value={availableFrom.format('HH:mm')}
                    onChange={handleAvailableFromTimeChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-4">
              <label
                className="form-control-label"
                htmlFor="available-from-date">
                Catalog Available To
              </label>
              <div className="row no-gutters">
                <div className="col">
                  <Input
                    id="available-to-date"
                    type="date"
                    min={today}
                    value={availableTo.format('YYYY-MM-DD')}
                    onChange={handleAvailableToDateChange}
                  />
                </div>
                <div className="col">
                  <Input
                    id="available-to-time"
                    type="time"
                    value={availableTo.format('HH:mm')}
                    onChange={handleAvailableToTimeChange}
                  />
                </div>
              </div>
            </div>
            {!isPrebook && (
              <div className="col-4">
                <div className="row no-gutters">
                  {sendingNotifications && (
                    <div className="col-12">
                      <span className="font-italic text-success">
                        Sending notifications&hellip; &nbsp;
                        <FontAwesomeIcon icon={['fad', 'spinner']} spin />
                      </span>
                    </div>
                  )}
                  {!sendingNotifications && (
                    <>
                      {!!bookingCatalog?.notificationEmailSent && (
                        <div className="col-12 font-italic pt-3 text-success text-center">
                          Email Notification Sent
                          <br />
                          {formatDate(
                            bookingCatalog.notificationEmailSent
                          )} @{' '}
                          {formatDate(
                            bookingCatalog.notificationEmailSent,
                            'h:mm a'
                          )}
                        </div>
                      )}
                      {!bookingCatalog?.notificationEmailSent && (
                        <>
                          <div className="col-6">
                            <label
                              htmlFor="automatically-notify"
                              className="font-weight-bold">
                              Email Notification
                            </label>
                            &nbsp;
                            <label
                              className="custom-toggle custom-toggle-success mb--2 ml-2"
                              htmlFor="automatically-notify">
                              <input
                                id="automatically-notify"
                                type="checkbox"
                                checked={notifyAutomatically}
                                onChange={handleNotifyAutomaticallyChange}
                              />
                              <span className="custom-toggle-slider rounded-circle" />
                            </label>
                          </div>
                          <div className="col-6 pt-4 px-0">
                            {!sendingNotifications &&
                              !bookingCatalog?.notificationEmailSent && (
                                <Button
                                  color="success"
                                  size="sm"
                                  outline
                                  onClick={handleShowNotificationModalClick}
                                  disabled={!isLive}
                                  className="text-nowrap">
                                  Send Notification Now &nbsp;
                                  <FontAwesomeIcon
                                    icon={['fad', 'paper-plane']}
                                  />
                                </Button>
                              )}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
          <div
            className="row bg-white py-2 sticky-top border-bottom shadow-sm"
            style={{ top: '75px' }}>
            <div className={`col-12 ${isCuts ? 'col-md-3' : 'col-md-4'}`}>
              <label htmlFor="show-available-only" className="d-inline-block">
                Only show available inventory
              </label>
              <label
                className="custom-toggle custom-toggle-success mb--2 ml-2"
                htmlFor="show-available-only">
                <input
                  id="show-available-only"
                  type="checkbox"
                  checked={showAvailableOnly}
                  onChange={handleSetShowAvailableOnlyChange}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </div>
            <div className="col-6 col-md-1 text-center">Cutoff</div>
            <div className="col-6 col-md-1 text-center">Case Qty</div>
            <div className="col-6 col-md-1 text-center">Max</div>
            {isCuts && (
              <div className="col-6 col-md-1 text-center">Min Stems</div>
            )}
            <div className="col-12 col-md-1 text-center">Inventory</div>
            <div className="col-6 col-md-1 text-center">Shopping Carts</div>
            <div className="col-6 col-md-1 text-center">Pending Orders</div>
            <div className="col-6 col-md-1 text-center">Confirmed Orders</div>
            <div className="col-6 col-md-1 text-center">Available</div>
          </div>
          {inventory
            ?.filter(filterAvailable(showAvailableOnly))
            .map((item, index) => (
              <div key={item.id}>
                {isNew(inventory, index) && (
                  <div className="row border-top py-1">
                    <div className="col-12 font-weight-bold">
                      {item.category}
                    </div>
                  </div>
                )}
                <BookingCatalogItem item={item} />
              </div>
            ))}
          <AddProductsModal
            isOpen={showAddProductsModal}
            bookingCatalog={bookingCatalog}
            availableFrom={availableFrom}
            availableTo={availableTo}
            hide={() => setShowAddProductsModal(false)}
          />
          <FeaturesModal
            isOpen={showFeaturesModal}
            hide={() => setShowFeaturesModal(false)}
            save={handleSetFeaturesSave}
          />
          <AlertModal
            isOpen={showAddAlertModal}
            bookingCatalog={bookingCatalog}
            hide={() => setShowAddAlertModal(false)}
            save={handleAddAlertSave}
          />
        </>
      )}
      <Modal
        isOpen={showUploadModal}
        toggle={toggleShowUploadModal}
        size="xl"
        scrollable>
        <ModalHeader toggle={toggleShowUploadModal}>
          Upload Inventory
        </ModalHeader>
        <ModalBody>
          <p>Choose the Excel file with the updated inventory values.</p>
          <Input type="file" onChange={handleFileUploadChange} accept=".xlsx" />
          {rows && (
            <table className="table">
              <thead>
                <tr>
                  {hasError && <th>&nbsp;</th>}
                  <th>Category</th>
                  <th>Item</th>
                  <th className="text-right">Quantity</th>
                  <th className="text-right">Maximum Order Qty</th>
                  {isCuts && <th className="text-right">Minimum Stems</th>}
                  <th className="text-center" colSpan={2}>
                    Cutoff
                  </th>
                </tr>
              </thead>
              <tbody>
                {rows.map((i) => (
                  <tr
                    key={i['Product ID']}
                    className={i.error ? 'table-danger' : ''}>
                    {hasError && (
                      <td>
                        {!!i.error && (
                          <>
                            <FontAwesomeIcon
                              icon={['fad', 'exclamation-triangle']}
                              className="text-danger"
                              id={`import-error-${i['Product ID']}`}
                            />
                            <UncontrolledTooltip
                              target={`import-error-${i['Product ID']}`}>
                              {i.error}
                            </UncontrolledTooltip>
                          </>
                        )}
                      </td>
                    )}
                    <td>{i.Category}</td>
                    <td>{i.Item}</td>
                    <td className="text-right">{i.Quantity}</td>
                    <td className="text-right">{i.MaximumOrderQuantity}</td>
                    {isCuts && (
                      <td className="text-right">
                        {i.MinimumStemOrderQuantity}
                      </td>
                    )}
                    <td className="text-right">
                      {formatDate(i['Availability Cutoff'])}
                    </td>
                    <td className="text-right">
                      {formatDate(i['Availability Cutoff'], 'h:mm a')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </ModalBody>
        <ModalFooter>
          {hasError && (
            <div className="text-danger">
              There are errors in the Excel file. Please adjust &amp; try again.
            </div>
          )}
          <Button onClick={toggleShowUploadModal}>Cancel</Button>
          <Button
            outline
            color="success"
            onClick={handleUpdateInventoryClick}
            disabled={!rows || hasError}>
            Save &nbsp;
            <FontAwesomeIcon icon={['fad', 'save']} />
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={showSendNotificationModal}
        toggle={toggleShowSendNotificationModal}>
        <ModalHeader toggle={toggleShowSendNotificationModal}>
          Send Booking Catalog Notification
        </ModalHeader>
        <ModalBody>
          <p>
            This will send an email notification to all active web users that
            this Booking Catalog is live.
          </p>
          <p>Would you like to continue?</p>
          <Error error={error} clearError={toggleError} />
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggleShowSendNotificationModal}>Cancel</Button>
          <Button outline color="success" onClick={handleSendNotificationClick}>
            Send &nbsp;
            <FontAwesomeIcon icon={['fad', 'paper-plane']} />
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={showDownload} toggle={toggleShowDownload}>
        <ModalHeader toggle={toggleShowDownload}>Download Products</ModalHeader>
        <ModalBody>
          <p className="lead text-center">
            Please choose the products to download:
          </p>
          <div className="row">
            <div className="col-6 text-center">
              <Button
                color="warning"
                outline
                size="lg"
                onClick={() => handleDownloadConfirm(true)}>
                All Products
              </Button>
            </div>
            <div className="col-6 text-center">
              <Button
                color="warning"
                outline
                size="lg"
                onClick={() => handleDownloadConfirm(false)}>
                Active Products
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <BookingCatalogItemEditModal
        week={week}
        endWeek={endWeek}
        availableFrom={availableFrom}
        availableTo={availableTo}
      />
      <OrderedProductsModal
        show={showOrderedProductsModal}
        hide={toggleShowOrdered}
        bookingCatalogId={idValue}
      />
      <Pricing isOpen={showPricingModal} toggle={toggleShowPricingModal} />
      <Modal isOpen={showConfirmDelete} toggle={handleDeleteCancel}>
        <ModalHeader toggle={handleDeleteCancel}>
          Delete Booking Catalog?
        </ModalHeader>
        <ModalBody>
          <p className="lead text-center">
            Are you sure you want to delete this Booking Catalog?
          </p>
          <Error error={error} clearError={toggleError} />
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleDeleteCancel}>No</Button>
          <Button color="danger" outline onClick={handleDeleteConfirm}>
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

function isNew(items: BookingCatalogDetailInventory[], index: number) {
  if (index === 0) {
    return true;
  }

  const current = items[index]?.category,
    previous = items[index - 1]?.category;

  return current && previous && current !== previous;
}

function filterAvailable(showAvailableOnly: boolean) {
  return function (item: BookingCatalogDetailInventory) {
    if (!showAvailableOnly) {
      return true;
    }

    const { quantity, quantityOnOrders, quantityOnWebOrders, quantityInCarts } =
        item,
      availableQuantity = quantity == null ? Number.MAX_VALUE : quantity,
      available = Math.max(
        availableQuantity -
          quantityOnOrders -
          quantityOnWebOrders -
          quantityInCarts,
        0
      );

    return available > 0;
  };
}
